import React from 'react';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import chatgptGif from '../assets/images/chatgpt.gif';
import "particles.js";
import { Auth } from '../core/utils/auth';
import img1 from '../assets/images/img1.png';
import img13 from '../assets/images/img13.png';
import img6 from '../assets/images/img6.png';
import img19 from '../assets/images/img19.png';
import img29 from '../assets/images/img29.png';
import img18 from '../assets/images/img18.png';
import img28 from '../assets/images/img28.png';
import img3 from '../assets/images/img3.png';
import { Helmet } from 'react-helmet';

function Home() {
  const auth = Auth();
  if(auth === undefined) return;
  const checkSubscription = () => {
    if(!(auth)){
      window.location.href = '/register';
    } else if (auth && auth.status === 'active') {
      window.location.href = '/my-account';
    } else {
      window.location.href = '/pricing';
    }
  };
  setTimeout(function(){
    startParticle();
  }, 2000);
  function startParticle() {
    window.particlesJS('particles-js',

  {
    "particles": {
      "number": {
        "value": 150,
        "density": {
          "enable": true,
          "value_area": 800
        }
      },
      "color": {
        "value": "#8bcaff"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 0,
          "color": "#F3F4F6"
        },
        "polygon": {
          "nb_sides": 3
        },
        "image": {
          "src": "img/github.svg",
          "width": 100,
          "height": 100
        }
      },
      "opacity": {
        "value": 0.5,
        "random": false,
        "anim": {
          "enable": false,
          "speed": 1,
          "opacity_min": 0.1,
          "sync": false
        }
      },
      "size": {
        "value": 5,
        "random": true,
        "anim": {
          "enable": false,
          "speed": 40,
          "size_min": 0.1,
          "sync": false
        }
      },
      "line_linked": {
        "enable": true,
        "distance": 150,
        "color": "#3ba5ff",
        "opacity": 0.2,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 2,
        "direction": "none",
        "random": false,
        "straight": false,
        "out_mode": "out",
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode": "bubble"
        },
        "onclick": {
          "enable": true,
          "mode": "repulse"
        },
        "resize": true
      },
      "modes": {
        "grab": {
          "distance": 100,
          "line_linked": {
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 100,
          "size": 6,
          "duration": 10,
          "opacity": 1,
          "speed": 1
        },
        "repulse": {
          "distance": 100
        },
        "push": {
          "particles_nb": 4
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    "retina_detect": true,
    "config_demo": {
      "hide_card": false,
      "background_color": "#b61924",
      "background_image": "",
      "background_position": "50% 50%",
      "background_repeat": "no-repeat",
      "background_size": "cover"
    }
  }

);
  }
  return (
    <>
      <Helmet>
        <title>AI Pro | Home</title>
        <meta name="description" content="Join AI-PRO and take your first steps in the exciting world of AI. Our comprehensive collection of resources and expert insights can help you understand and master the latest trends and technologies, and achieve success in the field." />
      </Helmet>
      <Header auth={auth}/>
        <div className="bg-gray-100">
          <div id="particles-js"></div>
          <div className="intro container mx-auto py-10">
            <div className="flex flex-col items-center pt-16 pb-16 lg:p-[100px] lg:pb-16">
              <h1 className="text-3xl lg:text-6xl font-bold text-center mb-8 lg:mb-8">
                <span className="text-gradient">Amazing AI</span>
              </h1>
              <p className="introsubtext text-xs lg:text-lg max-w-xl text-center leading-relaxed mb-10 lg:mb-12">
                Access the Latest AI Application: ChatGPT powered applications, Text to Image, and much more.<br/><br/>

                Unlock the power of Artificial Intelligence and step into a world of endless possibilities. AI-Pro is your gateway to the latest AI applications, revolutionizing the way we live, work, and play.<br/><br/>
                Whether you're a seasoned AI enthusiast or just curious about this rapidly evolving field, we have everything you need to harness the full potential of AI.
              </p>
              <button className="ctabtn gradient-hover-effect text-white font-bold py-3 px-6 rounded-lg" onClick={checkSubscription}>DISCOVER AI&nbsp;<i className="fas fa-sign-in-alt"></i></button>
            </div>
          </div>

          <div className="demogif container mx-auto">
            <div className="lg:flex lg:justify-center">
              <div className="lg:w-1/3 custom-class">
                <div className="text-center">
                  <img
                    className="mx-auto lg:mx-0 lg:w-full"
                    src={chatgptGif}
                    alt="chatgpt"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="features container mx-auto">
            <div className="lg:flex lg:justify-center text-center">
              <div className="w-full max-w-screen-lg mt-16">
                <h2 className="text-3xl lg:text-4xl font-bold mb-8">
                  Join the AI revolution<br />
                  today!
                </h2>
                <p className="mx-auto max-w-2xl mb-16 text-center">
                  Empower yourself with AI technology to solve real-world problems. Whether it's writing content, writing contracts, generating code, creating amazing artwork, the possibilities are endless.<br/><br/>
                  Stay Ahead of the Curve: In the fast-paced world of AI, staying up-to-date is crucial. AI-Pro constantly provides the latest AI related applications, ensuring you're always at the forefront of AI Innovation.
                </p>
              </div>
            </div>
{/*            <div className="lg:flex lg:justify-center">
              <div className="lg:w-full lg:pl-16">
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-window-restore text-blue-600" aria-hidden="true"></i> Image AI
                    </h4>
                    <p className="text-md mx-auto">
                      Our comprehensive collection of resources and expert insights can help you understand how these powerful tools work and how to use them to create stunning images. With AI-PRO, you'll have everything you need to take your image generation skills to professional-level.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-building text-blue-600" aria-hidden="true"></i> AI Chatbots and Text Generators
                    </h4>
                    <p className="text-md mx-auto">
                      Discover how to communicate better with AI chatbots and text generators. Our vast collection of resources and expert guides can help you understand how these powerful tools work and how to use them to improve customer experiences, streamline business processes, and generate engaging content.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-10">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-download text-blue-600" aria-hidden="true"></i> AI Videos
                    </h4>
                    <p className="text-md mx-auto">
                      AI-PRO is your go-to destination for learning about AI video generators. These powerful tools can help you create stunning videos, from simple to complex, in ways you never imagined. Let AI-PRO guide you on your journey to tell stories like never before with the help of AI video generators.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-code text-blue-600" aria-hidden="true"></i> Basic to Advanced Prompts
                    </h4>
                    <p className="text-md mx-auto">
                      Learn about basic to advanced prompts and commands for AIs. Our extensive collection of resources and expert insights can help you get started with AI and take your skills to the next level. Connect and communicate better with your AI.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-list  text-blue-600" aria-hidden="true"></i> AI Productivity Tools
                    </h4>
                    <p className="text-md mx-auto">
                      Our comprehensive collection of tools and resources can help you automate tasks and boost productivity. Streamline the way you work. Whether you're a business owner looking to increase efficiency or an individual looking to get more done.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-10">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-cogs text-blue-600" aria-hidden="true"></i> AI Toolkits
                    </h4>
                    <p className="text-md mx-auto">
                      Our toolkits are packed with the tools and resources you need to build and deploy AI models, including code libraries, tutorials, and demos. With AI-PRO, you'll have everything you need to take your first steps in the world of AI.
                    </p>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
          <div className="gallery container mx-auto">
            <div className="container mx-auto py-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <div className="overflow-hidden rounded-lg shadow-lg">
                  <img src={img1} alt="img1" className="w-full h-56 object-cover object-center"/>
                </div>
                <div className="overflow-hidden rounded-lg shadow-lg">
                  <img src={img13} alt="img13" className="w-full h-56 object-cover object-center"/>
                </div>
                <div className="overflow-hidden rounded-lg shadow-lg">
                  <img src={img6} alt="img6" className="w-full h-56 object-cover object-center"/>
                </div>
                <div className="overflow-hidden rounded-lg shadow-lg">
                  <img src={img19} alt="img19" className="w-full h-56 object-cover object-center"/>
                </div>
                <div className="overflow-hidden rounded-lg shadow-lg">
                  <img src={img29} alt="img29" className="w-full h-56 object-cover object-center"/>
                </div>
                <div className="overflow-hidden rounded-lg shadow-lg">
                  <img src={img18} alt="img18" className="w-full h-56 object-cover object-center"/>
                </div>
                <div className="overflow-hidden rounded-lg shadow-lg">
                  <img src={img28} alt="img28" className="w-full h-56 object-cover object-center"/>
                </div>
                <div className="overflow-hidden rounded-lg shadow-lg">
                  <img src={img3} alt="img3" className="w-full h-56 object-cover object-center"/>
                </div>
              </div>
            </div>
          </div>

          <div className="ask container mx-auto">
            <div className="flex flex-col items-center py-10 lg:py-16 border-2 border-gray-200 rounded-2xl">
              <h2 className="text-3xl font-bold text-center mb-6 lg:mb-8">
                Why Choose AI-Pro?
              </h2>
              <p className="text-md max-w-xl text-center leading-relaxed mb-10 lg:mb-12">
                Access to Cutting-Edge Tools: AI-Pro provides a comprehensive suite of AI applications, enabling you to turn your ideas into reality. Our user-friendly interfaces ensures that you can focus on the creative aspects of creating & building while we handle the technical complexities.<br/><br/>
                Don't miss out on the incredible opportunities AI has to offer. Start your journey with AI-Pro and unlock a world of innovation, automation, and limitless potential. Embrace the power of AI and together, let's redefine what's possible.<br/><br/>
                Sign up now and experience the AI revolution at AI-Pro!
              </p>
              <button className="ctabtn gradient-hover-effect text-white font-bold py-3 px-6 rounded-lg" onClick={checkSubscription}>
                Discover AI Now
              </button>
            </div>
            <div className="bgradient"></div>
          </div>
        </div>
      <Footer auth={auth}/>
    </>
  );
}

export default Home;
