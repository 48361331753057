import { SetCookie } from './cookies';
import { isPro, isBasic, isAuthV1Paid } from './auth';
import axios from 'axios';


var totalApps = 0;
const requestOptions = {
  method: "POST",
  mode: "cors",
};
const strUrl = `${process.env.REACT_APP_BTUTIL_API_URL}e/get-total-app`;

fetch(strUrl, requestOptions)
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    totalApps = data.total;
  })
  .catch((error) => {});


export const returnAppHref = (auth, url) => {
  if(!auth) {
    return url;
  }
  // for unpaid user
  if(auth.status === 'pending') {
    if(
      url.includes('storybook')
      || url.includes('teacher.ai')
      || url.includes('chat.ai')
      || url.includes('chatpro.ai')
      || url.includes('chatpdf.ai')
      || url.includes('search')
      || url.includes('chatlibrary')
      || url.includes('start-chatbot')
      || url.includes('text-to-image')
      || url.includes('convert-to-proper-english')
      || url.includes('interior')
    ) {
      return url;
    }
    if(url.includes('dream-photo')){
      return "javascript:void(0)";
    }
  }
  if(checkRestrictPlan(auth, url)) {
    return "javascript:void(0)";
  }
  // for v1 user
  if( isAuthV1Paid(auth)
  || url.includes('chat.ai')
  || isPro(auth)) {
    return url;
  }
  // for basic user
  if( isBasic(auth)
  && ( url.includes('chatbot') || url.includes('create-art') || url.includes('dream-photo') || url.includes('chatgpt') || url.includes("chatpro.ai"))
  ) {
    return url;
  }
  return "javascript:void(0)";
}

const checkRestrictPlan = (auth, url) => {
  if(isPro(auth)) {
    if(url.includes('chatpdf') && auth.plan_restriction && auth.plan_restriction.includes('chatpdf')) {
      return true;
    }
  }
  return false;
}

const setPPG = (ppg) => {
  SetCookie('ppg', ppg, { path: '/' });
  SetCookie('ppg', ppg, { domain: '.ai-pro.org', path: '/' });
}

const setPMT = (pmt) => {
  SetCookie('pmt', pmt, { path: '/' });
  SetCookie('pmt', pmt, { domain: '.ai-pro.org', path: '/' });
};

export const redirectApp = async(auth, url) => {
  if(returnAppHref(auth, url) === url) return;
  // for unpaid user
  if(url.includes('create-art')
  || url.includes('dream-photo')
  || url.includes('text-to-image')
  || url.includes('chatgpt')
  || url.includes('chatbot')
  || url.includes("chatpro.ai")) {
    SetCookie('app', 'basic', { domain: '.ai-pro.org', path: '/' });
  } else {
    SetCookie('app', 'pro', { domain: '.ai-pro.org', path: '/' });
  }

  const cta_url = auth.status !== 'pending' && auth.expired === 'no' ? window.baseURL + 'upgrade' : window.baseURL + 'pricing';
  const DEFAULT_PPG = process.env.REACT_APP_DEFAULT_PPG ? process.env.REACT_APP_DEFAULT_PPG : "14";

  if(auth && cta_url === window.baseURL + 'pricing') {
    const currency = auth.currency.toLowerCase();
    if(currency === 'gbp'){
      setPPG('24');
      setPMT('st');
    }else if(currency === 'eur'){
      setPPG('26');
      setPMT('st');
    }else if(currency === 'brl'){
      setPPG('28');
      setPMT('st');
    }else if(currency === 'sar'){
      setPPG('30');
      setPMT('st');
    }else if(currency === 'aed'){
      setPPG('32');
      setPMT('st');
    }else{
      setPPG(DEFAULT_PPG);
    }
  }
  if(checkRestrictPlan(auth, url)) {
    SetCookie('app', 'promax', { domain: '.ai-pro.org', path: '/' });
    document.querySelectorAll("#modal-container .plan-name").forEach((item)=>{ item.innerHTML = 'PRO MAX'; });
  } else {
    document.querySelectorAll("#modal-container .plan-name").forEach((item)=>{ item.innerHTML = 'PRO'; });
  }

  if(checkRestrictPlan(auth, url)) {
    SetCookie('app', 'promax', { domain: '.ai-pro.org', path: '/' });
    document.querySelectorAll("#modal-container .plan-name").forEach((item)=>{ item.innerHTML = 'PRO MAX'; });
  } else {
    document.querySelectorAll("#modal-container .plan-name").forEach((item)=>{ item.innerHTML = 'PRO'; });
  }

  if(url.includes('chatgpt')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'Chatbot+';
  } else if(url.includes('chat.ai') || url.includes('chatbot') || url.includes('start-chatbot')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'ChatGPT';
  } else if(url.includes('chatpro.ai')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'ChatGPTPro';
  } else if(url.includes('chatpdf')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'ChatPDF';
  } else if(url.includes('convert-to-proper-english')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'Grammar AI';
  } else if(url.includes('chatlibrary')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'Prompt Library';
  } else if(url.includes('teacher')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'TeacherAI';
  } else if(url.includes('search')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'SearchAI';
  } else if(url.includes('create-art') || url.includes('dream-photo') || url.includes('text-to-image')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'DreamPhoto';
  } else if(url.includes('interior')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'Interior AI';
  } else if(url.includes('restorephoto')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'Restore Photo';
  } else if(url.includes('clearbg')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'Remove Background';
  } else if(url.includes('avatarmaker')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'Avatar Maker';
  } else if(url.includes('storybook')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'StoryBook';
  } else if(url.includes('multillm')) {
    document.querySelector("#modal-container .app-name").innerHTML = 'Multi-Chat';
  }
  document.querySelector("#modal-container .app-total").innerHTML = totalApps;
  await axios.post(`${process.env.REACT_APP_API_URL}/click-app-upgrade`, { url }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  SetCookie('appurl', url, { domain: '.ai-pro.org', path: '/' });
  if (auth.plan==='enterprise'){
    document.getElementById("modal-container-enterprise").style.display = 'block';
  }else{
    document.querySelector('#modal-container #modal-cta').setAttribute('href', cta_url);
    document.getElementById("modal-container").style.display = 'block';
  }
};