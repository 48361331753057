import React, { useState, useEffect } from 'react';
import './style.css';
import { motion } from "framer-motion";
import Header from '../header/headerlogo';
import { Auth } from '../core/utils/auth';
import teampic from '../assets/images/team.png';
import personalpic from '../assets/images/personal.png';
import studentpic from '../assets/images/school.png';
import { Carousel } from 'react-responsive-carousel';
import { SetCookie } from '../core/utils/cookies';
import { Helmet } from 'react-helmet';

function Splash() {
  const auth = Auth();
  const [selectedBox, setSelectedBox] = useState(null);
  const [continueEnabled, setContinueEnabled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const checkSubscription = () => {
    if (auth) {
      if(auth.status === 'active')
        window.location.href = '/my-account';
      else
        window.location.href = '/pricing';
    } else {
      window.location.href = '/register';
    }
  };

  const handleBoxClick = (boxIndex) => {
    setSelectedBox(boxIndex);
    setContinueEnabled(true);

    let selectedOption = '';
    switch (boxIndex) {
      case 1:
        selectedOption = 'for-my-team';
        break;
      case 2:
        selectedOption = 'for-personal-use';
        break;
      case 3:
        selectedOption = 'for-school';
        break;
      default:
        selectedOption = '';
        break;
    }

    SetCookie('howdoiplantouse', selectedOption, { path: '/' });
    SetCookie('howdoiplantouse', selectedOption, { path: '.ai-pro.org' });

  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>AI-Pro | How would you like to setup your account?</title>
        <meta name="description" content="Join millions of students, researchers and professionals to instantly answer questions and understand research with AI." />
      </Helmet>
      <Header />
      <div className="splash bg-white flex justify-center items-center mt-10 md:mt-20 lg:mt-26 pt-10 md:pt-28">
        <div className="container mx-auto py-10 px-0 md:px-4 sm:px-0 w-full mx-auto">
          <div className="reg_col text-center mb-8">
            <h1 className="text-lg sm:text-3xl font-bold text-center mb-2 sm:mb-8">How do you plan to use AI?</h1>
            <div className="pt-2 sm:pt-10 md:p-8">

              <div className="mbcontain flex flex-wrap justify-center">

                {isMobile ? (
                  <Carousel className="pb-0 pt-2 sm:py-4" showArrows={false} emulateTouch={false} autoPlay={false} centerSlidePercentage={100}>
                    <div
                      style={{ userSelect: 'none' }}
                      className={`mx-auto w-5/6 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 ${
                        selectedBox === 1 ? 'bg-gray-200' : ''
                      }`}
                      onClick={() => handleBoxClick(1)}
                    >
                      <div className="relative border rounded-lg pt-2 pb-2 px-4 sm:p-4 shadow-md bg-white cursor-pointer min-h-[250px] sm:min-h-[300px] sm:min-h-[350px] lg:min-h-[400px]">
                        <input
                          type="radio"
                          className="absolute top-0 right-0 m-2"
                          checked={selectedBox === 1}
                          readOnly
                        />
                        <img src={teampic} alt="for teams" className="teams mx-auto"/>
                        <h2 className="text-gray-700 text-lg font-semibold mb-0 sm:mb-4">For my team</h2>
                        <p className="text-gray-500">Collaborate on your docs, projects, and wikis.</p>
                      </div>
                    </div>
                    <div
                      style={{ userSelect: 'none' }}
                      className={`mx-auto w-5/6 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 ${
                        selectedBox === 2 ? 'bg-gray-200' : ''
                      }`}
                      onClick={() => handleBoxClick(2)}
                    >
                      <div className="relative border rounded-lg pt-2 pb-2 px-4 sm:p-4 shadow-md bg-white cursor-pointer min-h-[250px] sm:min-h-[300px] sm:min-h-[350px] lg:min-h-[400px]">
                        <input
                          type="radio"
                          className="absolute top-0 right-0 m-2"
                          checked={selectedBox === 2}
                          readOnly
                        />
                        <img src={personalpic} alt="for personal use" className="personal mx-auto"/>
                        <h2 className="text-gray-700 text-lg font-semibold mb-0 sm:mb-4">For personal use</h2>
                        <p className="text-gray-500">Write better. Think more clearly. Stay organized.</p>
                      </div>
                    </div>
                    <div
                      style={{ userSelect: 'none' }}
                      className={`mx-auto w-5/6 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 ${
                        selectedBox === 3 ? 'bg-gray-200' : ''
                      }`}
                      onClick={() => handleBoxClick(3)}
                    >
                      <div className="relative border rounded-lg pt-2 pb-2 px-4 sm:p-4 shadow-md bg-white cursor-pointer min-h-[250px] sm:min-h-[300px] sm:min-h-[350px] lg:min-h-[400px]">
                        <input
                          type="radio"
                          className="absolute top-0 right-0 m-2"
                          checked={selectedBox === 3}
                          readOnly
                        />
                        <img src={studentpic} alt="for school" className="school mx-auto"/>
                        <h2 className="text-gray-700 text-lg font-semibold mb-0 sm:mb-4">For school</h2>
                        <p className="text-gray-500">Keep your notes, research, and tasks all in one place.</p>
                      </div>
                    </div>
                  </Carousel>
                ) : (
                  <>
                    <div
                      style={{ userSelect: 'none' }}
                      className={`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 ${
                        selectedBox === 1 ? 'bg-gray-200' : ''
                      }`}
                      onClick={() => handleBoxClick(1)}
                    >
                      <div className="relative border rounded-lg p-4 shadow-md bg-white cursor-pointer min-h-[auto] sm:min-h-[300px] sm:min-h-[350px] lg:min-h-[400px]">
                        <input
                          type="radio"
                          className="absolute top-0 right-0 m-2"
                          checked={selectedBox === 1}
                          readOnly
                        />
                        <img src={teampic} alt="for teams" className="teams mx-auto"/>
                        <h2 className="text-gray-700 text-lg font-semibold mb-4">For my team</h2>
                        <p className="text-gray-500">Collaborate on your docs, projects, and wikis.</p>
                      </div>
                    </div>
                    <div
                      style={{ userSelect: 'none' }}
                      className={`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 ${
                        selectedBox === 2 ? 'bg-gray-200' : ''
                      }`}
                      onClick={() => handleBoxClick(2)}
                    >
                      <div className="relative border rounded-lg p-4 shadow-md bg-white cursor-pointer min-h-[auto] sm:min-h-[300px] sm:min-h-[350px] lg:min-h-[400px]">
                        <input
                          type="radio"
                          className="absolute top-0 right-0 m-2"
                          checked={selectedBox === 2}
                          readOnly
                        />
                        <img src={personalpic} alt="for personal use" className="personal mx-auto"/>
                        <h2 className="text-gray-700 text-lg font-semibold mb-4">For personal use</h2>
                        <p className="text-gray-500">Write better. Think more clearly. Stay organized.</p>
                      </div>
                    </div>
                    <div
                      style={{ userSelect: 'none' }}
                      className={`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 ${
                        selectedBox === 3 ? 'bg-gray-200' : ''
                      }`}
                      onClick={() => handleBoxClick(3)}
                    >
                      <div className="relative border rounded-lg p-4 shadow-md bg-white cursor-pointer min-h-[auto] sm:min-h-[300px] sm:min-h-[350px] lg:min-h-[400px]">
                        <input
                          type="radio"
                          className="absolute top-0 right-0 m-2"
                          checked={selectedBox === 3}
                          readOnly
                        />
                        <img src={studentpic} alt="for school" className="school mx-auto"/>
                        <h2 className="text-gray-700 text-lg font-semibold mb-4">For school</h2>
                        <p className="text-gray-500">Keep your notes, research, and tasks all in one place.</p>
                      </div>
                    </div>

                  </>
                )}
              </div>

              <div className="px-6 pt-2 pb-10 sm:py-10">

                <motion.button
                  style={{ userSelect: 'none' }}
                  id="arc_splash_cta"
                  className={`py-2 px-4 bg-blue-500 text-white rounded font-bold py-3 px-6 my-3 rounded-lg w-full md:w-1/2 ${
                    continueEnabled ? '' : 'opacity-50 cursor-not-allowed'
                  }`}
                  disabled={!continueEnabled}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={checkSubscription}
                >
                  Continue
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Splash;
