import { React, useState } from 'react';
import './style.css';
import { motion } from "framer-motion";
import Header from '../header/headerlogo';
// import Header from '../header';
// import Footer from '../footer';
// import Faq from '../faq';
import { useQuery } from "react-query";
import axios from 'axios';
import { GetCookie } from '../core/utils/cookies';
import { getPricePlan } from '../core/utils/main';
import getPlan from './utils/pricing';
import _ from 'underscore';

var plan = null;
const view_data = window.view_data;
const user_plan = view_data.user_plan ? view_data.user_plan : "";
const user_plan_currency = view_data.user_plan_currency ? view_data.user_plan_currency : "";
var ppg_id = getPlan(user_plan, user_plan_currency);
var showToggle = false;
var hasAnnual = false;
if(ppg_id) ppg_id = ppg_id.split(",");

async function getPPG() {
  if(!ppg_id) return [];
  if(plan) return plan;
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-pricing`, { ppg_id : JSON.stringify(ppg_id), akey: 'downgrade' }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  const output = response.data;
  if(output.success) {
    plan = output.data;
    hasAnnual = _.some(plan, function(o) { return o.payment_interval.toLowerCase() === "yearly"; });
    showToggle = _.some(plan, function(o) { return o.payment_interval.toLowerCase() === "monthly"; }) && hasAnnual;
    return output.data;
  } else {
    return [];
  }
}

function Pricing() {
  const { data } = useQuery("users", getPPG);
  const [ planInterval, setPlanInterval ] = useState("monthly");
  const vPrice_token = GetCookie("vprice") ? GetCookie("vprice") : "";
  if(data === undefined) return;
  const tk = GetCookie("access");
  if(!ppg_id) return;

  const setPricing = function(id) {
    window.location.href = "/downgrade/" + id;
  };

  const intervalChange = function() {
    if(planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const checkPlanInterval = function(plan) {
    if(!showToggle) return true;
    if(plan.payment_interval.toLowerCase() === planInterval) return true;
    return false;
  }

  return (
    <>
      <Header auth={tk}/>
        <div className="pricing-downgrade bg-gray-100">
          <div className="pricing_columns container mx-auto py-10">
            <div className="flex flex-col items-center py-10 lg:py-16">
              <h1 className="text-4xl lg:text-4xl font-bold text-center mb-6 lg:mb-8">
                Downgrade your Plan
              </h1>
              <p className="text-1xl lg:text-1xl font-bold text-center mb-6 lg:mb-8">
                { data.length <= 1 ?
                  ("Want to save up? Feel free to downgrade to our more afforable plan below") :
                  ("Want to save up? Feel free to downgrade to any of our more afforable plans below")
                }
              </p>
              {showToggle ? (
              <div className="flex items-center justify-center w-full mb-12">
                <label for="toggleB" className="flex items-center cursor-pointer">
                  <div className={`${planInterval === 'monthly' ? "text-blue-700 font-bold" : "text-gray-700"} mr-3 uppercase`}>
                    Monthly
                  </div>
                  <div className="relative">
                    <input type="checkbox" id="toggleB" className="sr-only toggle" onChange={intervalChange}/>
                    <div className="block bg-gray-400 w-12 h-6 rounded-full"></div>
                    <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                  </div>
                  <div className={`${planInterval === 'yearly' ? "text-blue-700 font-bold" : "text-gray-700"} ml-3 uppercase`}>
                    Yearly
                  </div>
                </label>
              </div>
              ) : ""}


              <div className="flex flex-col md:flex-row justify-center">
                {data?.map((plan, index) => (
                  checkPlanInterval(plan) ? (
                  <div key={index} className={`price_col text-center px-4 mb-8 ${ index === 1 ? "relative" : "" }`}>
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                      <div className="px-6 py-10 price-content">
                        <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                        { plan.trial_price ?
                        <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.trial_price)}</p>
                        : <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.price)}<span className="text-sm"> /{ plan.payment_interval === "Monthly" ? "month" : "year" }</span></p>
                        }
                        <div className='py-4'>
                          <motion.button
                            className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                            whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => setPricing(plan.plan_id)}
                          >
                            Subscribe
                          </motion.button>
                        </div>
                        <div className="mb-6 plan-description">
                          <ul className="text-sm text-gray-600">
                            {
                              vPrice_token === 'vP1zx12mXk' ?
                              <>
                              { plan.plan_description ? <li className="mb-2" dangerouslySetInnerHTML={{__html: plan.plan_description}}></li> : null }
                              </>
                              :
                              <>
                              { plan.display_txt2 ? <li className="mb-2" dangerouslySetInnerHTML={{__html: plan.display_txt2}}></li> : null }
                              </>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                    { planInterval === 'yearly' || (hasAnnual && !showToggle) ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                      <div>Up to <span className='font-bold underline-offset-1'>20% OFF</span> on an annual subscription</div>
                    </div> : null }
                  </div>
                  ) : ""
                ))}
              </div>


              <p className="text-xs max-w-md text-center leading-relaxed mb-10 lg:mb-12">
                *The pricing is exclusive of taxes and additional local tax may be collected.
              </p>
            </div>
          </div>
        </div>
    </>
  );
}

export default Pricing;
