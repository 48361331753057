import React from 'react';
import Header from '../header';
import Footer from '../footer';
import { motion } from "framer-motion";

const NotFoundPage = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <h1 className="text-red-950 text-[6rem] md:text-[8rem] font-black mt-4 text-gradient">404</h1>
        <p className="text-2xl pb-10">Page not found</p>
        <motion.button
          className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg shadow"
          whileTap={{ scale: 0.9 }}
          onClick={() => { window.location.href = "/home"; }}
        >
        Back to Home
        </motion.button>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundPage;