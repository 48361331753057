import { useEffect } from 'react';
import Header from '../header/headerlogo';
import imgsess from '../assets/images/sess-icon.jpg';
import './style.css';
import { motion } from "framer-motion";
import { initSocket } from '../core/utils/socket';
import { GetCookie, SetCookie } from '../core/utils/cookies';
import { Auth } from '../core/utils/auth';
import { FaUserPlus } from 'react-icons/fa';
var client_io;

function ActiveSession() {
  const auth = Auth();
  useEffect(() => {
    document.querySelector("html").setAttribute("id", "active-session");
    client_io = initSocket();
  }, []);

  if(auth === undefined) return;
  if(auth === false) {
    window.location.href = '/login';
    return;
  }
  const email = auth.email;
  const logoutAccount = () => {
    if(client_io){
      let socket = client_io.socket;
      socket.emit('logout-account', {
        id: client_io.sidx, email: GetCookie('user_email'), socket_key: process.env.REACT_APP_SOCKET_KEY
      });
    }
  };
  const logoutAllSession = () => {
    if(!client_io) return;
    logoutAccount();
    setTimeout(function(){
      window.location.href = '/my-account';
    }, 1000);
  };
  const redirectEnterprisePricing = () => {
    window.mixpanel.people.set_once({ "$email": email });
    window.mixpanel.identify(email);
    window.mixpanel.track("add_user", {});
    if(auth.plan === 'enterprise') {
      logoutAccount();
      setTimeout(function(){
        window.location.href = '/manage-account?tab=mem';
      }, 1000);
      return;
    }
    SetCookie('tag-as', 1, { path: '/' });
    SetCookie('pricing', 62, { path: '/' });
    window.location.href = "/pay";
  };
  const isEntMember = () => {
    if(auth.ent_parent_user_id !== null && auth.ent_parent_user_id !== '') return true;
    return false;
  };
  return (
    <>
      <Header/>
      <div className="min-h-[500px] flex items-center pt-4 sm:pt-8 lg:pt-12">
        <div className="container mx-auto py-10 px-2 lg:px-8 pt-6">
          <div className="mx-auto lg:px-4 pb-8 lg:p-8 pt-12 lg:flex lg:flex-row text-center md:text-left">
            <div className='lg:flex lg:flex-col lg:w-2/5 text-center'>
              <img src={imgsess} alt="imgsess" className="lp_img block md:inline w-1/2 lg:w-full p-2 mx-auto "/>
            </div>
            <div className='lg:flex lg:flex-col lg:w-3/5 p-4 pt-4 lg:pt-12 sm:px-6 md:px-12'>
              <div className='font-bold text-xl lg:text-3xl pb-4'>You have too many active sessions</div>
              <div className='md:w-3/4 pb-6'>
                If you need your team to access AI-PRO, purchase more users and work without interruptions.
              </div>
              <div style={{ "padding-bottom": "30px", "margin-bottom": "30px", "border-bottom": "1px solid lightgray" }}>
                { isEntMember()  ? "Please contact your Administrator" :
                <motion.button
                  className="bg-blue-500 text-white font-bold py-2 px-6 rounded-lg shadow hover:text-white hover:bg-blue-500"
                  whileTap={{ scale: 0.9 }}
                  onClick={redirectEnterprisePricing}
                > <span className='mr-3'>Add Users</span> <FaUserPlus className='inline' style={{ "margin-top": "-3px" }}/>
                </motion.button> }
              </div>
              <div className='pb-4'>
                If it's you, please click "Stop all sessions" to proceed with AI-PRO.
              </div>
              <div>
                <motion.button
                  className="bg-white-500 border border-solid border-blue-500 text-blue-500 font-bold py-2 px-6 rounded-lg shadow mr-4 hover:text-white hover:bg-blue-500 btn-stop-session"
                  whileTap={{ scale: 0.9 }}
                  onClick={logoutAllSession}
                > Stop all sessions
                </motion.button>
                <span className='text-blue-500 cont-working'>and continue working</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ActiveSession;