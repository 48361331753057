import React, { useState, useEffect, useRef } from 'react';
import './start-chatgpt-go.css';
import Header from '../header';
import Headerlogo from '../header/headerlogo';
import Footer from '../footer';
import "particles.js";
import { Auth } from '../core/utils/auth';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
import introbg1 from '../assets/images/chatgo.gif';
import simpli1 from '../assets/images/simpli1.png';
import simpli2 from '../assets/images/simpli2.png';
import simpli3 from '../assets/images/simpli3.png';
import simpli4 from '../assets/images/simpli4.gif';
import simpli5 from '../assets/images/simpli5.png';
import simpli6 from '../assets/images/simpli6.gif';
import lpPic1 from '../assets/images/sd2.png';
import blkpic1 from '../assets/images/x1.gif';
import blkpic2 from '../assets/images/x2.gif';
import blkpic2a from '../assets/images/blk2.png';
import bkgpng from '../assets/images/bkg.png';
import elonmuska from '../assets/images/elonmusk.gif';
import aminpic1 from '../assets/images/cchat.gif';
import aminpic2 from '../assets/images/codex.png';
import l1 from '../assets/images/l1.png';
import l2 from '../assets/images/l2.png';
import l3 from '../assets/images/l3.png';
import { GetCookie } from '../core/utils/cookies';

import para1 from '../assets/images/parallaximage.png';
import { FaRegEdit, FaFileSignature, FaMoneyCheckAlt, FaMailBulk, FaBookOpen, FaLaptopCode } from 'react-icons/fa';
import BackToTopButton from '../footer/backtotop';

function StartChatGptgo() {
  const auth = Auth();
  const [navmenu, setNavmenu] = useState('hide');
  const draftRef = useRef(null);
  const scriptRef = useRef(null);
  const emailsRef = useRef(null);
  const storiesRef = useRef(null);
  const promptsRef = useRef(null);
  const moneyRef = useRef(null);
  const headerRef = useRef(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const hideLinks = GetCookie('qW1eMlya') && GetCookie('qW1eMlya') === 'on' ? true : false;

  const checkSubscription = () => {
    if(!(auth)){
      window.location.href = '/register';
    } else if (auth && auth.status === 'active') {
      window.location.href = '/my-account';
    } else {
      window.location.href = '/pricing';
    }
  };

 const draftClick = () => {
    draftRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scriptClick = () => {
    scriptRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const emailsClick = () => {
    emailsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const storiesClick = () => {
    storiesRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const promptsClick = () => {
    promptsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const moneyClick = () => {
    moneyRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // checkflag
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('navmenu=')) {
        setNavmenu(cookie.substring('navmenu='.length));
        break;
      }
    }
    // end checkflag

    const parallaxImage = document.querySelector('.parallax-image');

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerBottom = headerRef.current.getBoundingClientRect().bottom;
      setIsHeaderVisible(window.pageYOffset < headerBottom);
      parallaxImage.style.transform = `translateY(-${scrollPosition * 0.3}px)`;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, [auth]);
  const iframeSrc = process.env.REACT_APP_CHATBOT_URL;
  // const fullHostname = window.location.hostname;
  // const hostnameParts = fullHostname.split('.');
  // const subdomain = hostnameParts.length > 2 ? hostnameParts[0] : '';
  // const iframeSrc = (subdomain === 'dev' || subdomain === 'staging') ? 'https://staging.app.ai-pro.org/start-chatbot' : 'https://app.ai-pro.org/start-chatbot';

  if(auth === undefined) return;
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>AI Pro | ChatGPT - Your AI Language Expert</title>
        <meta name="description" content="Dive into seamless interactions and witness the future of AI-powered communication." />
      </Helmet>
      {navmenu === 'show' ? (
        <Header hideNavLink={hideLinks} />
      ) : (
        <Headerlogo hideNavLink={hideLinks} />
      )}
        <div ref={headerRef}></div>
        <BackToTopButton isHeaderVisible={isHeaderVisible} />
        <div className="startchatgptgo block bg-gray-100">

          <div className="intro mx-auto pt-10 pb-0 md:pt-20">
            <div className="mainbanner">
              <div className="w-full md:w-full text-center pt-20 p-10 sm:p-20 lg:p-20 mb-20">
                <h1 className="text-4xl md:text-6xl mb-4 sm:pt-4 pb-8 font-bold drop-shadow-2xl">Start Using<br/>ChatGPT</h1>
                <p className="text-[16px] mb-4 w-full md:w-3/6 mx-auto">
                  Discover how to use ChatGPT to automate your workflow and tackle tedious tasks. Streamline your work and free up your time with ChatGPT’s powerful capabilities.<br/><br/> Explore ChatGPT’s various uses, learn the proper prompts, and get access to expert guides.
                </p>
                <motion.button
                  onClick={checkSubscription}
                  className="cta bg-blue-800 hover:bg-blue-700 mb-1 text-white mx-auto text-center font-bold py-3 px-6 my-3 rounded-2xl md:w-1/3"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Start Now
                </motion.button>
              </div>
              <div className="introbg w-full pt-40 sm:pt-24 text-center mx-auto">
                <img src={introbg1} alt="AI-Pro" className="w-5/6 lg:w-1/2 drop-shadow-xl rounded-2xl" />
              </div>
            </div>
          </div>

          <div className="features relative mx-auto bg-white pt-12 p-0 sm:p-12">
            <div className="w-full">
              <iframe className="w-full" id="chatgptv2demo" title="ChatGPT V2 Demo" src={iframeSrc} frameBorder="0">
              </iframe>
            </div>
            <div className="lg:justify-center text-center">
              <div className="w-full mt-24 block">
                <h2 className="text-3xl lg:text-6xl font-bold px-4">
                  Simplify Your Workflow
                </h2>
                <h4 className="text-2xl p-6">Reinventing the Experience</h4>
                <p className="mx-auto max-w-2xl mb-16 text-center px-4">
                  ChatGPT automates text generation, saving time & effort while improving consistency & accuracy. It can also integrate with other systems to streamline workflow.
                </p>
              </div>
              <div className="cascadeimg relative w-full block">
                <img src={simpli1} alt="AI-Pro" className="simpli1 absolute drop-shadow-xl" />
                  <img src={simpli2} alt="AI-Pro" className="simpli2 absolute drop-shadow-xl" />
                <img src={simpli3} alt="AI-Pro" className="simpli3 absolute drop-shadow-xl" />
                <img src={simpli4} alt="AI-Pro" className="simpli4 absolute drop-shadow-xl" />
                  <img src={simpli5} alt="AI-Pro" className="simpli5 absolute drop-shadow-xl" />
                <img src={simpli6} alt="AI-Pro" className="simpli6 absolute drop-shadow-xl" />
              </div>
            </div>
          </div>

          <div className="parallax-container">
            <div className="parallax-image"></div>
              <div className="parallax-content">
                <div className="lg:justify-center text-center">
                  <div className="pcont w-full mt-16 block text-white">
                    <div className="phead pt-10">
                      <h2 className="text-3xl lg:text-6xl font-bold">
                        ChatGPT Features
                      </h2>
                      <h4 className="text-2xl p-6">Reinventing the Experience</h4>
                    </div>
                    <div className="flex flex-wrap justify-center text-left mx-auto">

                      <div className="pcontent grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="first col-span-1 sm:col-span-2 lg:col-span-1 block justify-center text-center lg:text-left items-end mx-8 p-8 sm:mr-0 lg:pt-[100px] pb-12">
                          <p className="block text-sm lg:text-md">
                            ChatGPT is a large language model trained by OpenAI that can generate human-like text. It can be fine-tuned for a variety of tasks such as conversation, summarization, question answering, and more. It's based on the transformer architecture and has 175 billion parameters. It can be used for both text generation and text completion tasks.
                          </p>
                          <motion.button
                            onClick={draftClick}
                            className="cta bg-white hover:bg-gray-100 mb-1 text-black block font-bold py-3 px-3 my-3 rounded-2xl w-full sm:w-1/2 mx-auto lg:mx-0 text-sm lg:text-md"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            See Features
                          </motion.button>
                        </div>

                        <div className="second col-span-1 sm:col-span-1 lg:col-span-1 flex justify-center items-end">
                          <div className="flex justify-center items-center">
                            <img src={para1} alt="AI-Pro" className="parallaximg max-w-full" />
                          </div>
                        </div>

                        <div className="third col-span-1 sm:col-span-1 lg:col-span-1 block justify-center items-end mx-8 p-8 lg:pt-[150px] pb-12 mx-auto">
                          <ul className="text-left">
                            <li><FaRegEdit className="inline text-lg mr-2" /><a href="#anchor" onClick={draftClick}>Draft Contracts</a></li>
                            <li><FaFileSignature className="inline text-lg mr-2" /><a href="#anchor" onClick={scriptClick}>Create Scripts</a></li>
                            <li><FaMailBulk className="inline text-lg mr-2" /><a href="#anchor" onClick={emailsClick}>Write Emails</a></li>
                            <li><FaBookOpen className="inline text-lg mr-2" /><a href="#anchor" onClick={storiesClick}>Write Stories</a></li>
                            <li><FaLaptopCode className="inline text-lg mr-2" /><a href="#anchor" onClick={promptsClick}>Use Prompts</a></li>
                            <li><FaMoneyCheckAlt className="inline text-lg mr-2" /><a href="#anchor" onClick={moneyClick}>Make Money</a></li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
          </div>

          <div ref={draftRef} className="cntblack bg-black text-white mx-auto block pt-0 pb-0 md:pt-20">
            <div className="block md:flex md:flex-row pt-20 pb-0 sm:py-20 md:py-0">
              <div className="w-full lg:w-1/2 text-left pt-0 px-10 lg:pt-8 lg:px-20">
                <h1 className="text-2xl lg:text-6xl mb-4 sm:pt-4 pb-8 font-bold drop-shadow-2xl">Draft Contracts & Proposals</h1>
                <p className="text-[16px] mb-4">
                  ChatGPT can help you create contracts and proposals more easily. This saves you time and effort because you don't have to start from scratch. ChatGPT can also check your drafts for mistakes and make sure they are formatted correctly. Make the process of creating contracts and proposals quicker and easier with ChatGPT.
                </p>
              </div>
              <div className="lg:w-1/2 block sm:flex sm:flex-wrap">
                <div className="w-full md:w-1/2">
                  <img src={blkpic1} alt="AI-Pro" className="blkpic h-[auto] mx-auto" />
                </div>
                <div className="w-full md:w-1/2">
                  <img src={blkpic2} alt="AI-Pro" className="blkpic h-[auto] mx-auto" />
                </div>
              </div>
            </div>
          </div>

          <div ref={scriptRef} className="cntblack2 bg-black text-white mx-auto block pt-0 pb-10 md:pt-20 ">
            <div className="block md:flex md:flex-row py-20 md:py-0">
              <div className="w-full lg:w-1/2 inline-block">
                <img src={blkpic2a} alt="AI-Pro" className="p-12" />
              </div>
              <div className="w-full lg:w-1/2 text-left pt-0 px-10 lg:pt-40 lg:px-20">
                <h1 className="text-2xl lg:text-6xl mb-4 sm:pt-4 font-bold drop-shadow-2xl">Create Video Scripts</h1>
                <p className="text-[16px] mb-4">
                  ChatGPT can assist you in creating video scripts by automating the process of generating draft scripts based on certain prompts or keywords. ChatGPT can also help with editing and proofreading the generated drafts to ensure they are accurate and well-written. Utilize ChatGPT's capabilities to streamline the process of creating video scripts.
                </p>
              </div>
            </div>
          </div>

          <div ref={emailsRef} className="cntblack3 bg-black text-white mx-auto block pt-0 pb-0 md:pt-20">
            <div className="block md:flex md:flex-row py-20 md:py-0">
              <div className="w-full lg:w-1/2 text-left pt-0 px-10 lg:pt-8 lg:px-20">
                <h1 className="text-2xl lg:text-6xl mb-4 sm:pt-4 pb-8 font-bold drop-shadow-2xl">Write Emails & Chat Replies</h1>
                <p className="text-[16px] mb-4">
                  ChatGPT can help you save time and effort by writing emails and chat replies for your work or business. ChatGPT can also assist with editing and proofreading the generated responses to ensure they are accurate and professional. Let ChatGPT handle your email and chat communication, freeing you up to focus on more important tasks.
                </p>
              </div>
              <div className="w-full lg:w-1/2 inline-block">
                <img src={elonmuska} alt="elon musk chat" className="blkpic w-full h-[auto]" />
              </div>
            </div>
          </div>


          <div ref={storiesRef} className="cntblack4 bg-black text-white mx-auto block pt-0 pb-10 md:pt-20">
            <div className="block md:flex md:flex-row py-20 md:py-0">
              <div className="w-full lg:w-1/2 inline-block">
                <img src={bkgpng} alt="AI-Pro" className="p-12" />
              </div>
              <div className="w-full lg:w-1/2 text-left pt-0 px-10 lg:pt-40 lg:px-20">
                <h1 className="text-2xl lg:text-6xl mb-4 sm:pt-4 font-bold drop-shadow-2xl">Write Stories, Poems & Songs</h1>
                <p className="text-[16px] mb-4">
                  ChatGPT is a powerful language model that can assist with writing tasks, including songwriting, poetry, stories, and books. Simply provide a prompt and let the model generate creative and original ideas. ChatGPT can write in various styles and tones. Try it out to boost creativity and productivity.
                </p>
              </div>
            </div>
          </div>


          <div ref={promptsRef} className="cntblack5 bg-black text-white mx-auto block pt-0 pb-0 md:pt-20">
            <div className="block md:flex md:flex-row py-20 md:py-0">
              <div className="w-full lg:w-1/2 text-left pt-0 px-10 lg:pt-8 lg:px-20">
                <h1 className="text-2xl lg:text-6xl mb-4 sm:pt-4 pb-8 font-bold drop-shadow-2xl">Use Prompts & Commands</h1>
                <p className="text-[16px] mb-4">
                  Explore our comprehensive resources and enhance your understanding of AI prompts and commands. From beginner to advanced, our guides will help you improve your skills and strengthen your communication with your AI. Get started now and take your AI capabilities to the next level.
                </p>
              </div>
              <div className="block sm:flex sm:flex-wrap max-h-auto xs:max-h-[200px] sm:max-h-auto">
                <div className="inline-block">
                  <img src={aminpic1} alt="AI-Pro" className="p-2 w-full sm:w-1/2 h-[auto] inline-block" />
                  <img src={aminpic2} alt="AI-Pro" className="p-2 w-full sm:w-1/2 h-[auto] inline-block" />
                </div>
              </div>
            </div>
          </div>


          <div ref={moneyRef} className="cntblack6 text-center bg-black text-white mx-auto block pt-0 pb-0 md:pt-20">
            <div className="block pb-[100px] mb-[300px]">
              <div className="w-full pt-0 px-10 lg:pt-8 lg:px-20">
                <h1 className="text-2xl lg:text-6xl mb-4 sm:pt-4 pb-8 font-bold drop-shadow-2xl">Make Money with AI</h1>
                <p className="text-[16px] mb-4">
                  Looking to leverage the power of AI to generate income? We will show you how to utilize AI to create new streams of revenue. You'll learn the skills and strategies you need to succeed in the lucrative world of AI. Don't miss out on this opportunity to transform your financial future with AI!
                </p>
              </div>
              <div className="flex flex-wrap p-8 mt-8 mb-[-350px]">
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3">
                  <img src={l1} alt="AI-Pro" className="mx-auto rounded-2xl" />
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 my-6 md:mt-[120px]">
                  <img src={l2} alt="AI-Pro" className="mx-auto rounded-2xl" />
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3">
                  <img src={l3} alt="AI-Pro" className="mx-auto rounded-2xl" />
                </div>
              </div>

            </div>
          </div>

          <div className="useai mx-auto pt-10 z-1 my-12">
            <div className="flex flex-col items-center p-10 lg:pt-8 lg:pb-0 min-h-[470px]">
              <div className="w-full lg:w-5/6 p-6 text-center mx-4 lg:mx-0">
                <h2 className="text-white text-xl md:text-5xl font-bold text-center pb-6">
                  Use Artificial Intelligence
                </h2>
                <p className="text-md text-white text-center md:p-6 leading-8">
                  At AI-PRO, we believe that everyone should have access to the resources and guidance they need to succeed in the world of AI. That’s why we offer a variety of membership options to suit your needs and budget. Whether you’re an individual looking to learn about AI or a business looking to adopt AI solutions, we have a plan that’s right for you.
                </p>
                <motion.button
                  onClick={checkSubscription}
                  className="cta bg-white hover:bg-gray-100 mb-1 text-black mx-auto text-center font-bold py-3 px-6 my-3 rounded-2xl md:w-1/2"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Discover AI Now
                </motion.button>
              </div>
            </div>
            <div className="bg-gray-600 ">
              <div className="useaibg flex flex-col items-center w-full text-center h-[400px]">
                <img src={lpPic1} alt="ChatGPT-Now AI" className="mx-auto mt-2 md:mt-[-30px]" />
              </div>
            </div>
          </div>
        </div>
      <Footer auth={auth} hideNavLink={hideLinks}/>
    </>
  );
}

export default StartChatGptgo;