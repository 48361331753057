import React, { useState  } from 'react';
import './style.css';
import Header from '../header/headerlogo';
import { Auth } from '../core/utils/auth';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function Payment() {
  const [referenceNumber, setreferenceNumber] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
	const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

  const auth = Auth('/login');
  if(auth === undefined || auth === false) return;

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

	const handleAmountPaidChange = (event) => {
    let input = event.target.value;
    input = input.replace(/\D/g, "");
    input = input.slice(0, 5);

		setAmountPaid(input);
	}

	const handleReferenceNumberChange = (event) => {
    let input = event.target.value;
		setreferenceNumber(input);
	}

	const submitConfirmation = (event) => {
		event.preventDefault();

		var hasError = validate();
		if (hasError){
			return;
		}

    var selected_file = selectedFile;
    var url = `${process.env.REACT_APP_API_URL}/t/send-enterprise-payment-confirm`;

    document.querySelector(".loader-container").classList.add('active');

    const formData = new FormData();
    formData.append('file', selected_file);
    formData.append('reference_number', referenceNumber);
    formData.append('amount', amountPaid);

    axios.post(url, formData, { headers: { 'content-type': 'multipart/form-data' } }).then(function(res) {
      let output = res.data;

			if(output.success) {
				window.location.href = '/payment-confirm';
      }else{
        window.toastr.error("Email Failed.");
      }
    }).catch(function (error) {
      document.querySelector(".loader-container").classList.remove('active');
      if (error.response && error.response.status===429) {
        document.querySelector(".loader-container").classList.remove('active');
        window.toastr.error("Sorry, too many requests. Please try again in a bit!");
      }
    });
	}

	function validate(){
		document.querySelector("#amountPaid").classList.remove('border-red-500');
		document.querySelector("#referenceNumber").classList.remove('border-red-500');

		var hasError = false;
		if (amountPaid.trim()===''){
      document.querySelector("#amountPaid").classList.add('border-red-500');
			hasError = true;
		}
		if (referenceNumber.trim()===''){
      document.querySelector("#referenceNumber").classList.add('border-red-500');
			hasError = true;
		}

		return hasError;
	}

  return (
    <>
      <Helmet>
        <title>AI Pro | Enterprise Account Payment Confirmation</title>
        <meta name="description" content="Safely complete your purchase with our secure payment options. Buy now with confidence!" />
      </Helmet>
      <Header auth={auth} />
      <div className="Payment bg-gray-100 min-h-[600px] mx-auto">
					<form  onSubmit={submitConfirmation}>
						<div className="container mx-auto py-10">
							<div className="flex flex-col items-center py-8 lg:py-16">
									<h2 className="text-xl font-bold mb-4 py-4 md:py-10 text-center">Enterprise Account Payment Confirmation</h2>
									<div className="py-2 text-sm text-center">
										<p>Please enter the payment reference number below.</p>
										<p>An account manager will contact you upon confirmation.</p>
									</div>
									<div className="mx-auto py-4">
									<input className="w-full mb-2 px-3 py-2 border border-gray-300 rounded fs-exclude"
										type="text"
										id="amountPaid"
										name="amountPaid"
										placeholder="Amount Paid*"
										value={amountPaid}
										onChange={handleAmountPaidChange}
									/>
									<input className="w-full mb-2 px-3 py-2 border border-gray-300 rounded fs-exclude"
										type="text"
										id="referenceNumber"
										name="referenceNumber"
										placeholder="Reference Number*"
										value={referenceNumber}
										onChange={handleReferenceNumberChange}
									/>
									</div>
									<div className="mx-auto text-center">
										<label className="text-xs block mb-0 mt-1 text-center">Upload your payment receipt for easier verification.</label>
										<label for="file" class="font-bold bg-gray-100 block cursor-pointer custom-file-upload border border-gray-300 px-4 py-2 my-2">
										  Upload File
										</label>
										<input className="order border-gray-300 px-4 py-2 my-2" id="file" type="file" name="file" onChange={changeHandler} />
									</div>
									{isSelected ? (
										<div className="text-[10px] text-center text-blue-800">
											<p className="">Filename: {selectedFile.name}</p>
										</div>
									) : (
										null
									)}
									<div>
									<button className="mt-6 bg-blue-500 px-4 py-2 rounded text-white" type="submit">Send Confirmation</button>
									</div>
							</div>
						</div>
					</form>
      </div> 
    </>
  );
}

export default Payment;