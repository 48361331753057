import React from 'react';
import PaymentForm from './components/stripe_payment_form';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { GetCookie } from '../core/utils/cookies';

const mode = GetCookie("mode") ? GetCookie("mode") : "";

var publishableKey = '';
if (mode==='test'){
  publishableKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST;
}else{
  publishableKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE;
}

const stripePromise = loadStripe(publishableKey)

function Payment() {
  return (
    <>
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
    </>
  );
}

export default Payment;