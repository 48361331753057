import React, { useState } from 'react';
import { motion } from "framer-motion";
import Header from '../header/headerlogo';
import { Auth } from '../core/utils/auth';
import { GetCookie } from '../core/utils/cookies';
import axios from 'axios';

const tk = GetCookie("access") ? GetCookie("access") : "";
function ChangeCard() {
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [nameError, setNameError] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardDateError, setCardDateError] = useState("");
  const [cvvError, setCVVError] = useState("");
  const [zip, setZIP] = useState("");
  const [zipError, setZIPError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const auth = Auth('/register');
  //
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const account_pid = urlParams.get('pid');

  if(auth === undefined || auth === false) return;

  const handleNameChange = (event) => {
    let input = event.target.value;
    // Remove non-alphabetic characters
    input = input.replace(/[^A-Za-z ]/g, "");
    // Limit the input to 50 characters
    input = input.slice(0, 50);
    setName(input);
  };

  const handleCardNumberChange = (event) => {
    let input = event.target.value;
    // Remove non-numeric characters
    input = input.replace(/\D/g, "");
    // Remove any existing dashes from the input
    input = input.replace(/-/g, "");
    // Add a dash after every fourth digit
    input = input.replace(/(\d{4})/g, "$1-");
    // Remove any trailing dash
    input = input.replace(/-$/, "");
    // Limit the input to 16 digits
    input = input.slice(0, 19);
    setCardNumber(input);
  };

  const handleCardDateChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    // Limit the input to 4 characters
    input = input.slice(0, 4);
    // Add "/" after the first 2 characters
    if (input.length >= 3) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }
    setCardDate(input);
  };

  const handleCVVChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    // Limit the input to 5 characters
    input = input.slice(0, 5);
    setCVV(input);
  };

  const handlePhoneChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    setPhone(input);
  };

  const handleZIPChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    // input = input.replace(/\D/g, "");
    // Limit the input to 3 characters
    // input = input.slice(0, 10);
    setZIP(input);
  };

  const submitChangeCard = () => {

    setNameError("");
    setCardNumberError("");
    setCardDateError("");
    setCVVError("");

    // Perform validation
    let isValid = true;


    if (!name.includes(" ")) {
      setNameError("enter at least two names separated by a space");
      isValid = false;
    }

    if (!cardNumber) {
      setCardNumberError("required");
      isValid = false;
    }

    if (!cardDate || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(cardDate)) {
      setCardDateError("MM/YY");
      isValid = false;
    }

    if (!cvv || !/^\d{3,5}$/.test(cvv)) {
      setCVVError("required");
      isValid = false;
    }

    if(!zip) {
      setZIPError("required");
      isValid = false;
    }

    if(!phone) {
      setPhoneError("required");
      isValid = false;
    }

    var name_split = name.split(" ");
    var first_name = name_split[0];
    var last_name = name_split[name_split.length - 1];
    var ccmonth = cardDate.split("/")[0];
    var ccyr = cardDate.split("/")[1];

    if (first_name==='' && last_name===''){
      setNameError("required");
      isValid = false;
    }else if (first_name==='' || last_name===''){
      setNameError("enter at least two names separated by a space");
      isValid = false;
    }

    // If any validation error occurred, stop further processing
    if (!isValid) {
      return;
    }

    document.querySelector(".loader-container").classList.add('active');
    axios.post(`${process.env.REACT_APP_API_URL}/change-card`, {
        tk,
        first_name,
        last_name,
        cc: cardNumber,
        ccmonth: ccmonth,
        ccyr: "20" + ccyr,
        cvv: cvv,
        pid: account_pid,
        phone: phone,
        postal: zip
      }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
        let output = res.data;
        if(output.success) {
          window.toastr.success("Success");
          setTimeout(function(){
            window.location.href = '/manage-account';
          }, 2000);
          return;
        }
        document.querySelector(".loader-container").classList.remove('active');
        if(output.data) window.toastr.error(output.data.msg);
      });
  }

  return (
    <>
      <Header auth={auth} />
      <div className="Payment bg-gray-100 min-h-[600px] flex">

          <div className="container mx-auto py-10">
            <div className="flex flex-col items-center py-10 lg:py-16">
              <div className="flex flex-wrap md:flex-wrap justify-center w-full">
                <div className="pay_left px-4 mb-8 w-full md:w-1/2">
                  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="px-6 pb-10">
                      <div className="">
                        <h2 className="text-xl font-bold mb-4 pt-10">Enter Billing Details</h2>
                          <div className="mb-4">
                            <label className="text-xs block mb-0 mt-1" htmlFor="name">Name on Card <span className="text-red-500">*</span>
                            { nameError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{nameError}</span> }</label>
                            <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="John Doe"
                            value={name}
                            onChange={handleNameChange}
                            onKeyUp={(event) => {
                              setName(event.target.value);
                            }}/>
                          </div>
                          <div className="mb-4">
                            <label className="text-xs block mb-0 mt-1" htmlFor="card-number">Card Number <span className="text-red-500">*</span>
                            { cardNumberError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cardNumberError}</span> }</label>
                            <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                            type="text"
                            id="card-number"
                            name="card-number"
                            placeholder="1234 5678 9012 3456"
                            value={cardNumber}
                            onChange={handleCardNumberChange}
                            onKeyUp={(event) => {
                              setCardNumber(event.target.value);
                            }}/>
                          </div>

                          <div className="mb-4 flex">
                            <div className="expdate w-full md:w-2/3 mr-2 md:mr-5">
                              <label className="text-xs block mb-0 mt-1" htmlFor="expiration-date">Expiration Date <span className="text-red-500">*</span>
                              { cardDateError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cardDateError}</span> }</label>
                              <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                              type="text"
                              id="expiration-date"
                              name="expiration-date"
                              placeholder="MM/YY"
                              value={cardDate}
                              onChange={handleCardDateChange}
                              onKeyUp={(event) => {
                                setCardDate(event.target.value);
                              }}/>
                            </div>

                            <div className=' w-full sm:w-full md:w-2/4 flex'>
                              <div className="cvv w-full sm:w-3/6 mr-2 md:mr-5">
                                <label className="text-xs block mb-0 mt-1" htmlFor="cvv">CVV <span className="text-red-500">*</span>
                                { cvvError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cvvError}</span> }</label>
                                <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                                type="text"
                                id="cvv"
                                name="cvv"
                                placeholder="CVV"
                                value={cvv}
                                onChange={handleCVVChange}
                                onKeyUp={(event) => {
                                  setCVV(event.target.value);
                                }}/>
                              </div>
                              <div className="zip w-full sm:w-3/6">
                                <label className="text-xs block mb-0 mt-1" htmlFor="zip">Zip <span className="text-red-500">*</span>
                                { zipError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{zipError}</span> }</label>
                                <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                                type="text"
                                id="zip"
                                name="zip"
                                placeholder="ZIP"
                                value={zip}
                                onChange={handleZIPChange}
                                onKeyUp={(event) => {
                                  setZIP(event.target.value);
                                }}/>
                              </div>
                            </div>
                          </div>

                          <div className="mb-4">
                            <label className="text-xs block mb-0 mt-1" htmlFor="phone-number">Phone Number <span className="text-red-500">*</span>
                            { phoneError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{phoneError}</span> }</label>
                            <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                            type="text"
                            id="phone-number"
                            name="phone-number"
                            placeholder="1234567890"
                            value={phone}
                            onChange={handlePhoneChange}
                            onKeyUp={(event) => {
                              setPhone(event.target.value);
                            }}/>
                          </div>

                          <motion.button
                            className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg w-full my-4 proceed-pmt"
                            whileHover={{ backgroundColor: "#5997fd" }}
                            whileTap={{ scale: 0.9 }}
                            onClick={submitChangeCard}
                          >
                            Submit
                          </motion.button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default ChangeCard;