import React, { useState, useEffect } from 'react';
import './style.css';
import aiproLogo from '../assets/images/AIPRO.png';
import '@fortawesome/fontawesome-free/css/all.css';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import { GetCookie } from '../core/utils/cookies';
import { useLocation } from 'react-router-dom';
import { AiOutlineArrowUp } from "react-icons/ai";
import { motion } from "framer-motion";
import { initSocket } from '../core/utils/socket';
import { returnAppHref, redirectApp } from '../core/utils/app';
import { isSubscriber } from '../core/utils/auth';

const tools = process.env.REACT_APP_TOOLS_URL ? JSON.parse(process.env.REACT_APP_TOOLS_URL) : {};
const trial = process.env.REACT_APP_TOOLS_TRIAL_URL ? JSON.parse(process.env.REACT_APP_TOOLS_TRIAL_URL) : {};
var client_io;

async function checkPlan() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-subscription`, {
    'tk': GetCookie('access')
  }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })

  const output = response.data;
  if(output.success) {
    const plans = Object.values(output.data);
    return plans;
  } else {
    return [];
  }
}

function Header(props) {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } })
  const slide = useSpring({ transform: 'translateY(0)', from: { transform: 'translateY(-100%)' } })
  const auth = props && props.auth ? props.auth : null;
  const showLink = props.hideNavLink ? false : true;
  const [plans, setPlans] = useState([]);
  const location = useLocation();
  const urlEndsWithManage = location.pathname.endsWith('/manage-account');
  const urlEndsWithMyAccount = location.pathname.endsWith('/my-account');

  useEffect(() => {
    async function fetchPlans() {
      const fetchedPlans = await checkPlan();
      setPlans(fetchedPlans);
    }
    fetchPlans();
    client_io = initSocket();
    if(client_io) {
      let socket = client_io.socket;
      let sidx = client_io.sidx;
      socket.on('session-exist', (data) => {
        if( data.id === sidx ) {
          window.location.href = '/active-session';
        }
      });
      socket.on('logout-account', (data) => {
        if( data.email === GetCookie('user_email') && ( !data.id || data.id !== sidx ) ) {
          window.location.href = '/stop-session';
        }
      });
    }
  }, []);

  const checkSubscription = () => {
    if (auth) {
      window.location.href = '/my-account';
    } else {
      window.location.href = '/register';
    }
  };

  const upid = auth && auth.user_pid ? "?upid=" + auth.user_pid : "";
  //use AI Art
  let text2imagelink = tools.txt2img ? tools.txt2img + upid : 'https://app.ai-pro.org/dream-photo'+upid;
  const text2imagelinktrial = trial.txt2img ? trial.txt2img + upid : 'https://app.ai-pro.org/text-to-image'+upid;
  text2imagelink = auth && ( auth.status === 'active' || isSubscriber(auth) ) ? text2imagelink : text2imagelinktrial;
  const interiorgptlink = tools.interiorgpt ? tools.interiorgpt + upid : 'https://interiorgpt.ai-pro.org'+upid;
  const restorephotolink = tools.restorephoto ? tools.restorephoto + upid : 'https://restorephotos.ai-pro.org'+upid;
  const storybooklink = tools.storybook ? tools.storybook + upid : 'https://storybook.ai-pro.org'+upid;

  //use Chatbot+
  let chatbotlink = tools.chatbot ? tools.chatbot + upid : 'https://app.ai-pro.org/chatbot'+upid;
  const chatbotlinktrial = trial.chatbot ? trial.chatbot + upid : 'https://app.ai-pro.org/start-chatbot'+upid;
  chatbotlink = auth && ( auth.status === 'active' || isSubscriber(auth) ) ? chatbotlink : chatbotlinktrial;
  const chatbotprolink = tools.chatpro ? tools.chatpro + upid : 'https://chatpro.ai-pro.org/chat'+upid;
  const chatpdflink = tools.chatpdf ? tools.chatpdf + upid : 'https://chatpdf.ai-pro.org'+upid;
  const promptlibrarylink = tools.promptlibrary ? tools.promptlibrary + upid : 'https://chatlibrary.ai-pro.org'+upid;
  const convertenglishlink = tools.convert2english ? tools.convert2english + upid : 'https://app.ai-pro.org/convert-to-proper-english'+upid;
  const removebg = tools.removebg ? tools.removebg + upid : 'https://clearbg.ai-pro.org'+upid;
  const chatgptlink = tools.chatgpt ? tools.chatgpt + upid : 'https://chatgpt.ai-pro.org'+upid;
  const teacherailink = tools.teacherai ? tools.teacherai + upid : 'https://teacher.ai-pro.org'+upid;
  const avatarmakerlink = tools.avatarmaker ? tools.avatarmaker + upid : 'https://avatarmaker.ai-pro.org'+upid;
  const searchailink = tools.searchailink ? tools.searchailink + upid : 'https://search.ai-pro.org'+upid;
  const multillm_link = tools.multillm ? tools.multillm + upid : 'https://multillm.ai-pro.org'+upid;

  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleShowEnterpriseUpgrade = () => {
    props.setshowAddMoreMember(true);
  }

  const fnRedirectApp = (url) => {
    redirectApp(auth, url);
  };

  const fnReturnAppHref = (url) => {
    return returnAppHref(auth, url);
  };

  const handleKeyDown = (e, url) => {
    if (e.key === 'Enter') { // Trigger the link action on Enter key press30169_wcag_aipro
      fnRedirectApp(url);
    }
  };
  return (
    <animated.header style={fade} className="headernav border-gray-500 bg-white text-black w-3/4 mx-auto rounded-3xl mt-3 py-2 z-[8888]">
      <div className="headerStyle container mr-auto ml-0 flex justify-between items-center px-4">
        <animated.img style={slide} src={aiproLogo} alt="AI-Pro Logo" className="aiprologo"/>
        { showLink ? <>
        {auth ? '' : <button id="ctaStartHere" aria-label="Register Button" className="headerctabtn gradient-hover-effect font-semibold text-white rounded-3xl hidden sm:block sm:hidden ml-auto mr-3" onClick={checkSubscription}>START HERE</button> }
        <nav className="text-xs lg:text-sm hidden md:block inline-flex" id="menu">
          {urlEndsWithManage || urlEndsWithMyAccount ? (
            plans.map((singlePlan, index) => (
              <div className="flex inline-flex sm:mr-2" key={index}>
                {((singlePlan.plan_type === 'Basic' && singlePlan.status === 'active') ||
                  (singlePlan.plan_type === 'Pro' && singlePlan.status === 'active') ||
                  (singlePlan.plan_type === 'ProMax' && singlePlan.status === 'active')
                  ) && (
                  <a href="/upgrade" name="upgrade link" aria-label="upgrade link" className="block w-full font-bold text-white py-2 px-4 rounded-md bg-[#DB7E00] hover:bg-[#f99d1f]">Upgrade</a>
                )}
                {(singlePlan.plan_type === 'Enterprise' && singlePlan.status === 'active') && (
                  <button className="block w-full font-bold text-white py-2 px-4 rounded-md bg-[#ffa500] hover:bg-[#FFC034]" onClick={() => handleShowEnterpriseUpgrade()}>Upgrade</button>
                )}
              </div>
            ))
          ):('')}

          <ul className="headnav flex inline-flex">
            <li className="mr-1 md:mr-2 lg:mr-3">
              <div className="dropdown-wrapper">
                <motion.a style={{cursor:'pointer'}}
                href={fnReturnAppHref(chatbotprolink)}
                target={ fnReturnAppHref(chatbotprolink) === chatbotprolink ? "_blank" : "" }
                onClick={()=>{fnRedirectApp(chatbotprolink)}}
                name="Chatbot Pro" aria-label="Chatbot Pro"
                tabIndex={1}
                onKeyDown={(e) => handleKeyDown(e, chatbotprolink)}
                >Chatbot Pro</motion.a>
                <ul className="dropdown">
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="Chatbot+" aria-label="Chatbot+"
                    href={fnReturnAppHref(chatgptlink)}
                    target={ fnReturnAppHref(chatgptlink) === chatgptlink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(chatgptlink) }}
                    tabIndex={2}
                    onKeyDown={(e) => handleKeyDown(e, chatgptlink)}
                    >Chatbot+</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="ChatPDF" aria-label="ChatPDF"
                    href={fnReturnAppHref(chatpdflink)}
                    target={ fnReturnAppHref(chatpdflink) === chatpdflink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(chatpdflink) }}
                    tabIndex={3}
                    onKeyDown={(e) => handleKeyDown(e, chatpdflink)}
                    >ChatPDF</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="Grammar AI" aria-label="Grammar AI"
                    href={fnReturnAppHref(convertenglishlink)}
                    target={ fnReturnAppHref(convertenglishlink) === convertenglishlink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(convertenglishlink) }}
                    tabIndex={4}
                    onKeyDown={(e) => handleKeyDown(e, convertenglishlink)}
                    >Grammar AI</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="ChatGPT Prompt Library" aria-label="ChatGPT Prompt Library"
                    href={fnReturnAppHref(promptlibrarylink)}
                    target={ fnReturnAppHref(promptlibrarylink) === promptlibrarylink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(promptlibrarylink) }}
                    tabIndex={5}
                    onKeyDown={(e) => handleKeyDown(e, promptlibrarylink)}
                    >ChatGPT Prompt Library</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="TeacherAI" aria-label="TeacherAI"
                    href={fnReturnAppHref(teacherailink)}
                    target={ fnReturnAppHref(teacherailink) === teacherailink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(teacherailink) }}
                    tabIndex={6}
                    onKeyDown={(e) => handleKeyDown(e, teacherailink)}
                    >TeacherAI</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="SearchAI" aria-label="SearchAI"
                    href={fnReturnAppHref(searchailink)}
                    target={ fnReturnAppHref(searchailink) === searchailink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(searchailink) }}
                    tabIndex={7}
                    onKeyDown={(e) => handleKeyDown(e, searchailink)}
                    >SearchAI</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="Multi-Chat" aria-label="Multi-Chat"
                    href={fnReturnAppHref(multillm_link)}
                    target={ fnReturnAppHref(multillm_link) === multillm_link ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(multillm_link) }}
                    tabIndex={8}
                    onKeyDown={(e) => handleKeyDown(e, multillm_link)}
                    >Multi-Chat</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="Chatbot" aria-label="Chatbot"
                    href={fnReturnAppHref(chatbotlink)}
                    target={ fnReturnAppHref(chatbotlink) === chatbotlink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(chatbotlink) }}
                    tabIndex={9}
                    onKeyDown={(e) => handleKeyDown(e, chatbotlink)}
                    >Chatbot</motion.a>
                  </li>
                  <li><a href="/my-account" name="my-account" aria-label="my-account" tabIndex={10}>AI Tools</a></li>
                </ul>
              </div>
            </li>
            <li className="mr-1 md:mr-2 lg:mr-3">
              <div className="dropdown-wrapper">
                <span name="AI Art" aria-label="AI Art" role="menuitem" tabIndex={11}>AI Art</span>
                <ul className="dropdown">
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="DreamPhoto" aria-label="DreamPhoto"
                    href={fnReturnAppHref(text2imagelink)}
                    target={ fnReturnAppHref(text2imagelink) === text2imagelink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(text2imagelink) }}
                    tabIndex={12}
                    onKeyDown={(e) => handleKeyDown(e, text2imagelink)}
                    >DreamPhoto</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="Interior AI" aria-label="Interior AI"
                    href={fnReturnAppHref(interiorgptlink)}
                    target={ fnReturnAppHref(interiorgptlink) === interiorgptlink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(interiorgptlink) }}
                    tabIndex={13}
                    onKeyDown={(e) => handleKeyDown(e, interiorgptlink)}
                    >Interior AI</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="Restore Photo" aria-label="Restore Photo"
                    href={fnReturnAppHref(restorephotolink)}
                    target={ fnReturnAppHref(restorephotolink) === restorephotolink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(restorephotolink) }}
                    tabIndex={14}
                    onKeyDown={(e) => handleKeyDown(e, restorephotolink)}
                    >Restore Photo</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="Remove Background" aria-label="Remove Background"
                    href={fnReturnAppHref(removebg)}
                    target={ fnReturnAppHref(removebg) === removebg ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(removebg) }}
                    tabIndex={15}
                    onKeyDown={(e) => handleKeyDown(e, removebg)}
                    >Remove Background</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="Avatar Maker" aria-label="Avatar Maker"
                    href={fnReturnAppHref(avatarmakerlink)}
                    target={ fnReturnAppHref(avatarmakerlink) === avatarmakerlink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(avatarmakerlink) }}
                    tabIndex={16}
                    onKeyDown={(e) => handleKeyDown(e, avatarmakerlink)}
                    >Avatar Maker</motion.a>
                  </li>
                  <li>
                    <motion.a style={{cursor:'pointer'}}
                    name="StoryBook" aria-label="StoryBook"
                    href={fnReturnAppHref(storybooklink)}
                    target={ fnReturnAppHref(storybooklink) === storybooklink ? "_blank" : "" }
                    onClick={() => { fnRedirectApp(storybooklink) }}
                    tabIndex={17}
                    onKeyDown={(e) => handleKeyDown(e, storybooklink)}
                    >StoryBook</motion.a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mr-1 md:mr-2 lg:mr-3"><a href="https://ai-pro.org/enterprise" name="Enterprise" aria-label="Enterprise" tabIndex={18}>Enterprise</a></li>
            {!auth || auth.status !== 'active' ? <li className="mr-1 md:mr-2 lg:mr-3"><a href="/pricing" name="Pricing" aria-label="Pricing" tabIndex={19}>Pricing</a></li> : '' }
            {auth ? <li className="mr-1 md:mr-2 lg:mr-3"><a href="/my-account" name="My App" aria-label="My App" tabIndex={20}>My Apps</a></li> : '' }
            {auth ? <li className="mr-1 md:mr-2 lg:mr-3"><a href="/manage-account" name="Manage Account" aria-label="Manage Account" tabIndex={21}>Manage Account</a></li> : <li className="mr-1 md:mr-2 lg:mr-6"><a href="/login" name="Sign In" aria-label="Sign In" tabIndex={22}>Sign In</a></li> }
          </ul>
          {auth ? '' : <button id="ctaStartHere" className="text-[10px] md:text-[12px] headerctabtn inline-flex gradient-hover-effect font-semibold text-white rounded-3xl" onClick={checkSubscription} name="Start Here" aria-label="Register Button" tabIndex={23}>START HERE</button> }
        </nav>
        {urlEndsWithManage || urlEndsWithMyAccount ? (
          plans.map((singlePlan, index) => (
            <div className={`absolute right-16 md:hidden bg-[#FFC034] rounded mt-[-2px] px-[6px] ${showMenu ? 'active' : ''}`} key={index}>
              {((singlePlan.plan_type === 'Basic' && singlePlan.status === 'active') ||
                (singlePlan.plan_type === 'ProMax' && singlePlan.status === 'active') ||
                (singlePlan.plan_type === 'Pro' && singlePlan.payment_interval === 'Monthly' && singlePlan.status === 'active')) && (
                <a href="/upgrade" className="block w-full p-[2px] w-[35px]" name="Upgrade" aria-label="Upgrade"><AiOutlineArrowUp className="mx-auto text-[18px] text-[#ffffff]" /></a>
              )}
            </div>
          ))
        ):('')}
        <button
          className={`hamburger-menu md:hidden  ${showMenu ? 'active' : ''} ${auth ? 'logged' : ''}`}
          onClick={toggleMenu}
        >
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>

        <nav className={`mobilenav text-xs lg:text-sm ${showMenu ? '' : 'hidden'}`} id="menu">
          <div className="menu-container">
            <ul className="headnav flex flex-wrap absolute w-full md:hidden">
              <li className="w-full md:w-auto mb-2 md:mb-0 md:mr-2 lg:mr-6">
                <div className="wrapper">
                  <motion.a className="block w-full font-bold" style={{cursor:'pointer'}}
                  name="Chatbot Pro" aria-label="Chatbot Pro"
                  href={fnReturnAppHref(chatbotprolink)}
                  target={ fnReturnAppHref(chatbotprolink) === chatbotprolink ? "_blank" : "" }
                  onClick={()=>{fnRedirectApp(chatbotprolink)}}
                  tabIndex={1}
                  onKeyDown={(e) => handleKeyDown(e, chatbotprolink)}
                  >Chatbot Pro</motion.a>
                  <ul className="pl-4 md:relative md:h-auto md:overflow-y-visible">
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="Chatbot+" aria-label="Chatbot+"
                        href={fnReturnAppHref(chatgptlink)}
                        target={ fnReturnAppHref(chatgptlink) === chatgptlink ? "_blank" : "" }
                        onClick={() => { fnRedirectApp(chatgptlink) }}
                        tabIndex={2}
                        onKeyDown={(e) => handleKeyDown(e, chatgptlink)}
                        >Chatbot+</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="ChatPDF" aria-label="ChatPDF"
                      href={fnReturnAppHref(chatpdflink)}
                      target={ fnReturnAppHref(chatpdflink) === chatpdflink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(chatpdflink) }}
                      tabIndex={3}
                      onKeyDown={(e) => handleKeyDown(e, chatpdflink)}
                      >ChatPDF</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="Grammar AI" aria-label="Grammar AI"
                      href={fnReturnAppHref(convertenglishlink)}
                      target={ fnReturnAppHref(convertenglishlink) === convertenglishlink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(convertenglishlink) }}
                      tabIndex={4}
                      onKeyDown={(e) => handleKeyDown(e, convertenglishlink)}
                      >Grammar AI</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="ChatGPT Prompt Library" aria-label="ChatGPT Prompt Library"
                      href={fnReturnAppHref(promptlibrarylink)}
                      target={ fnReturnAppHref(promptlibrarylink) === promptlibrarylink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(promptlibrarylink) }}
                      tabIndex={5}
                      onKeyDown={(e) => handleKeyDown(e, promptlibrarylink)}
                      >ChatGPT Prompt Library</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="TeacherAI" aria-label="TeacherAI"
                      href={fnReturnAppHref(teacherailink)}
                      target={ fnReturnAppHref(teacherailink) === teacherailink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(teacherailink) }}
                      tabIndex={6}
                      onKeyDown={(e) => handleKeyDown(e, teacherailink)}
                      >TeacherAI</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="SearchAI" aria-label="SearchAI"
                      href={fnReturnAppHref(searchailink)}
                      target={ fnReturnAppHref(searchailink) === searchailink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(searchailink) }}
                      tabIndex={7}
                      onKeyDown={(e) => handleKeyDown(e, searchailink)}
                      >SearchAI</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="Multi-Chat" aria-label="Multi-Chat"
                      href={fnReturnAppHref(multillm_link)}
                      target={ fnReturnAppHref(multillm_link) === multillm_link ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(multillm_link) }}
                      tabIndex={8}
                      onKeyDown={(e) => handleKeyDown(e, multillm_link)}
                      >Multi-Chat</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="Chatbot" aria-label="Chatbot"
                      href={fnReturnAppHref(chatbotlink)}
                      target={ fnReturnAppHref(chatbotlink) === chatbotlink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(chatbotlink) }}
                      tabIndex={9}
                      onKeyDown={(e) => handleKeyDown(e, chatbotlink)}
                      >Chatbot</motion.a>
                    </li>
                    <li><a href="/my-account" className="block w-full" tabIndex={9}>AI Tools</a></li>
                  </ul>
                </div>
              </li>
              <li className="w-full md:w-auto mb-2 md:mb-0 md:mr-2 lg:mr-6">
                <div className="wrapper">
                  <span className="block w-full font-bold" name="AI Art" aria-label="AI Art" role="menuitem" tabIndex={10}>AI Art</span>
                  <ul className="pl-4 md:relative md:h-auto md:overflow-y-visible">
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="DreamPhoto" aria-label="DreamPhoto"
                      href={fnReturnAppHref(text2imagelink)}
                      target={ fnReturnAppHref(text2imagelink) === text2imagelink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(text2imagelink) }}
                      tabIndex={11}
                      onKeyDown={(e) => handleKeyDown(e, text2imagelink)}
                      >DreamPhoto</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="Interior AI" aria-label="Interior AI"
                      href={fnReturnAppHref(interiorgptlink)}
                      target={ fnReturnAppHref(interiorgptlink) === interiorgptlink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(interiorgptlink) }}
                      tabIndex={12}
                      onKeyDown={(e) => handleKeyDown(e, interiorgptlink)}
                      >Interior AI</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="Restore Photo" aria-label="Restore Photo"
                      href={fnReturnAppHref(restorephotolink)}
                      target={ fnReturnAppHref(restorephotolink) === restorephotolink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(restorephotolink) }}
                      tabIndex={13}
                      onKeyDown={(e) => handleKeyDown(e, restorephotolink)}
                      >Restore Photo</motion.a>
                    </li>
                    <li>
                    <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="Remove Background" aria-label="Remove Background"
                      href={fnReturnAppHref(removebg)}
                      target={ fnReturnAppHref(removebg) === removebg ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(removebg) }}
                      tabIndex={14}
                      onKeyDown={(e) => handleKeyDown(e, removebg)}
                      >Remove Background</motion.a>
                    </li>
                    <li>
                      <motion.a style={{cursor:'pointer'}}
                      name="Avatar Maker" aria-label="Avatar Maker"
                      href={fnReturnAppHref(avatarmakerlink)}
                      target={ fnReturnAppHref(avatarmakerlink) === avatarmakerlink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(avatarmakerlink) }}
                      tabIndex={15}
                      onKeyDown={(e) => handleKeyDown(e, avatarmakerlink)}
                      >Avatar Maker</motion.a>
                    </li>
                    <li>
                      <motion.a className="block w-full" style={{cursor:'pointer'}}
                      name="StoryBook" aria-label="StoryBook"
                      href={fnReturnAppHref(storybooklink)}
                      target={ fnReturnAppHref(storybooklink) === storybooklink ? "_blank" : "" }
                      onClick={() => { fnRedirectApp(storybooklink) }}
                      tabIndex={16}
                      onKeyDown={(e) => handleKeyDown(e, storybooklink)}
                      >StoryBook</motion.a>
                    </li>
                  </ul>
                </div>
              </li>

              {auth ? (
                <>
                  <li className="w-full md:w-auto mb-2 md:mb-0 md:mr-2 lg:mr-6">
                    <a href="https://ai-pro.org/enterprise" className="block w-full font-bold" name="Enterprise" aria-label="Enterprise" tabIndex={17}>Enterprise</a>
                  </li>
                  <li className="w-full md:w-auto mb-2 md:mb-0 md:mr-2 lg:mr-6">
                    <a href="/my-account" className="block w-full font-bold" name="My Apps" aria-label="My Apps" tabIndex={18}>My Apps</a>
                  </li>
                  <li className="w-full md:w-auto mb-2 md:mb-0 md:mr-2 lg:mr-6">
                    <a href="/manage-account" className="block w-full font-bold" name="Manage Account" aria-label="Manage Account" tabIndex={19}>Manage Account</a>
                  </li>
                </>
              ) : (
                <>
                  <li className="w-full md:w-auto mb-2 md:mb-0 md:mr-2 lg:mr-6">
                    <a href="https://ai-pro.org/enterprise" className="block w-full font-bold" name="Enterprise" aria-label="Enterprise" tabIndex={17}>Enterprise</a>
                  </li>
                  <li className="w-full md:w-auto mb-2 md:mb-0 md:mr-2 lg:mr-6">
                    <a href="/pricing" className="block w-full font-bold" name="Pricing" aria-label="Pricing" tabIndex={18}>Pricing</a>
                  </li>
                  <li className="w-full md:w-auto mb-2 md:mb-0 md:mr-2 lg:mr-6">
                    <a href="/login" className="block w-full font-bold" name="Sign In" aria-label="Sign In" tabIndex={19}>Sign In</a>
                  </li>
                  {auth ? '' : <button id="ctaStartHere" aria-label="Register Button" className="headerctabtn gradient-hover-effect font-semibold text-white rounded-3xl block md:hidden ml-0 mr-3" tabIndex={20} onClick={checkSubscription}>START HERE</button> }
                </>
              )}
            </ul>
          </div>
        </nav>
        </> : <></>}
      </div>
    </animated.header>
  );
}

export default Header;
