import React, { useState, useEffect, useRef, useCallback } from "react";
import "./style.css";
import { motion } from "framer-motion";
import axios from "axios";
import { Auth } from "../core/utils/auth";
import { SetCookie, GetSubdomain, GetCookie } from "../core/utils/cookies";
import { Helmet } from "react-helmet";
import { initSocket } from "../core/utils/socket";
import errors from "../locales/errors.json";
import img1 from "../assets/images/google_icon.png";
const Header = GetSubdomain() ? null : require("../header").default;
const Footer = GetSubdomain() ? null : require("../footer").default;

const params = new URLSearchParams(window.location.search);

var client_io;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showBanner, setShowBanner] = useState(
    window.view_data["stop-session"] ? true : false
  );
  const [bannerMessage, setBannerMessage] = useState("");
  const renderHeader = !GetSubdomain();
  const auth = Auth();
  const smooth_login = GetCookie("smooth_login") || "off";
  const spanErrorRefs = useRef([]);
  const formRef = useRef(null);

  const handleRedirect = (path) => {
    window.location.href = path;
  };

  const setSpanHeights = useCallback(() => {
    if (smooth_login === "on") {
      spanErrorRefs.current.forEach((ref, i) => {
        if (ref) {
          const errMsg = ref.textContent;
          const currHeight = ref.offsetHeight;
          const height = !!errMsg
            ? currHeight !== 0
              ? currHeight
              : (ref.scrollHeight ?? 0) + 12
            : 0;

          setTimeout(() => {
            ref.style.height = `${height}px`;
          }, 100);
        }
      });
    }
  }, [smooth_login]);

  /**
   * useEffect(() => {}, [formRef.current]) is currently not working.
   * Using setTimeout instead.
   */
  setTimeout(() => {
    const form = formRef.current;
    const isChromium =
      navigator.userAgent.includes("Chrome") ||
      navigator.userAgent.includes("Edg");

    if (form && isChromium) {
      const inputs = form.querySelectorAll("input");

      if (inputs && inputs.length > 0) {
        inputs.forEach((input) => {
          if (input.matches(":-internal-autofill-selected")) {
            input.classList.add("autofilled");
          }
        });
      }
    }
  }, 200);

  useEffect(() => {
    client_io = initSocket();
  }, []);

  useEffect(() => {
    //Display google error from google signin
    let google_error_code = params.get("e");
    let google_error = "";
    if (google_error_code !== null && google_error_code !== "") {
      google_error = errors.find(
        ({ error_code }) => error_code === google_error_code
      );
      if (google_error !== undefined) {
        window.toastr.error(google_error.msg);
      } else {
        window.toastr.error(google_error_code);
      }
    }
  }, []);

  useEffect(() => {
    if (smooth_login === "on") {
      setSpanHeights();
      window.addEventListener("resize", setSpanHeights);

      return () => {
        window.removeEventListener("resize", setSpanHeights);
      };
    }
  }, [smooth_login, setSpanHeights]);

  useEffect(() => {
    if (smooth_login === "on") {
      setSpanHeights();
    }
  }, [emailError, passwordError, smooth_login, setSpanHeights]);

  if (auth === undefined) return null;
  if (auth) {
    handleRedirect("/my-account");
    return null;
  }

  const validateEmail = () => {
    let isPassed = false;
    if (!email) {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
      isPassed = true;
    }
    return isPassed;
  };

  const validatePassword = () => {
    let isPassed = false;
    if (!password) {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
      isPassed = true;
    }
    return isPassed;
  };

  function loginUser() {
    var isEmailPassed = validateEmail();
    var isPasswordPassed = validatePassword();
    if (!isEmailPassed || !isPasswordPassed) return;

    document.querySelector(".loader-container").classList.add("active");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email,
          password,
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then(function (res) {
        let output = res.data;

        if (output.success) {
          if (output.success === 2) {
            document
              .querySelector(".loader-container")
              .classList.remove("active");
            setShowBanner(true);
            setBannerMessage(output.data.msg);

            if (client_io) {
              let socket = client_io.socket;
              socket.emit("logout-account", {
                id: null,
                email,
                socket_key: process.env.REACT_APP_SOCKET_KEY,
              });
            }
            return;
          }
          window.toastr.success("Success");
          SetCookie("access", output.data, { path: "/" });
          SetCookie("isloggedin", 1, { path: "/" });
          handleRedirect("/my-account");
          return;
        }
        document.querySelector(".loader-container").classList.remove("active");
        if (output.data.msg) window.toastr.error(output.data.msg);
      });
  }

  const closeBanner = () => {
    setShowBanner(false);
  };

  const handleChange = (event, inputType) => {
    const el = event.target;
    const value = el.value;
    const isFocus = event.type === "focus";
    const isInput = event.type === "change" || event.type === "keyup";

    if (!!value || isFocus || isInput) {
      el.classList.add("autofilled");
    } else {
      el.classList.remove("autofilled");
    }

    if (smooth_login !== "on" || (smooth_login === "on" && isInput)) {
      switch (inputType) {
        case "email":
          setEmailError("");
          setEmail(value);
          break;
        case "password":
          setPasswordError("");
          setPassword(value);
          break;
        default:
          if (event.keyCode === 13) {
            loginUser();
          }
          break;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Pro | Login</title>
        <meta
          name="description"
          content="Login to access your account and unlock a world of possibilities. Your gateway to exclusive applications and special offers awaits. Join us now!"
        />
      </Helmet>
      {renderHeader && <Header />}
      <div className="login bg-gray-100">
        {showBanner ? (
          <div className="absolute info-banner">
            <div className="relative px-8 py-4 mb-6 bg-[#f7ead9] block w-full rounded-md">
              <span
                className="absolute top-1 right-4 text-lg cursor-pointer close"
                onClick={closeBanner}
                aria-label="close"
              >
                x
              </span>
              <div>
                {bannerMessage
                  ? bannerMessage
                  : "You have been automatically logged out as a result of multiple active sessions linked to your account."}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`${
            showBanner ? "login-container" : "min-h-[600px]"
          } h-screen flex justify-center items-center pt-16 mt-4`}
        >
          <div className="container mx-auto py-10 px-0 md:px-4 sm:px-0 w-96">
            <div className="reg_col text-center mb-8">
              <h1 className="text-2xl lg:text-2xl font-bold text-center mb-6 lg:mb-8">
                Sign in your Account
              </h1>
              <div
                className="bg-white rounded-lg shadow-lg overflow-hidden"
                ref={formRef}
              >
                <div className="px-6 py-10">
                  {smooth_login === "on" ? (
                    <>
                      <div className="bg-white w-full relative mb-3 sm:text-sm">
                        <input
                          className="peer block border-2 border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1  w-full pt-6 pb-2 px-6 placeholder:text-transparent placeholder:[user-select:_none]"
                          placeholder="Email *"
                          type="email"
                          name="email"
                          onBlur={(e) => handleChange(e, "email")}
                          onChange={(e) => handleChange(e, "email")}
                          onFocus={(e) => handleChange(e, "email")}
                          onKeyUp={handleChange}
                        />
                        <label
                          className="transition-all text-slate-400 duration-100 ease-in absolute top-1/2 -translate-y-1/2 left-6 pointer-events-none [.autofilled~&]:top-[17px] [.autofilled~&]:text-xs [.autofilled~&]:left-[25px]"
                          for="email"
                        >
                          Email
                        </label>
                      </div>
                      <span
                        className="block text-red-500 text-xs text-center w-full h-0 transition-[height] duration-200 ease-in overflow-hidden"
                        ref={(el) => (spanErrorRefs.current[0] = el)}
                      >
                        {emailError}
                      </span>
                      <div className="bg-white w-full relative mb-3 sm:text-sm">
                        <input
                          className="peer block border-2 border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1  w-full pt-6 pb-2 px-6 placeholder:text-transparent placeholder:[user-select:_none]"
                          placeholder="Password *"
                          type="password"
                          name="password"
                          onBlur={(e) => handleChange(e, "password")}
                          onChange={(e) => handleChange(e, "password")}
                          onFocus={(e) => handleChange(e, "password")}
                          onKeyUp={handleChange}
                        />
                        <label
                          className="transition-all text-slate-400 duration-100 ease-in absolute top-1/2 -translate-y-1/2 left-6 pointer-events-none [.autofilled~&]:top-[17px] [.autofilled~&]:text-xs [.autofilled~&]:left-[25px]"
                          for="password"
                        >
                          Password
                        </label>
                      </div>
                      <span
                        className="block text-red-500 text-xs text-center w-full h-0 transition-[height] duration-200 ease-in overflow-hidden"
                        ref={(el) => (spanErrorRefs.current[1] = el)}
                      >
                        {passwordError}
                      </span>
                    </>
                  ) : (
                    <>
                      <label className="relative block">
                        {emailError && (
                          <span className="text-red-500 text-xs text-left w-full mb-2">
                            {emailError}
                          </span>
                        )}
                        <input
                          className="placeholder:italic placeholder:text-[#6E7277] block bg-white w-full border border-slate-300 rounded-md mb-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          aria-label="Email Address"
                          placeholder="Email Address *"
                          type="email"
                          name="email"
                          onBlur={validateEmail}
                          onKeyUp={handleChange}
                          onChange={(event) => handleChange(event, "email")}
                        />
                        {passwordError && (
                          <span className="text-red-500 text-xs text-left w-full mb-2">
                            {passwordError}
                          </span>
                        )}
                        <input
                          className="placeholder:italic placeholder:text-[#6E7277] block bg-white w-full border border-slate-300 rounded-md mb-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          aria-label="Password"
                          placeholder="Password *"
                          type="password"
                          name="password"
                          onBlur={validatePassword}
                          onKeyUp={handleChange}
                          onChange={(event) => handleChange(event, "password")}
                        />
                      </label>
                    </>
                  )}
                  <motion.button
                    onClick={loginUser}
                    className="bg-blue-600 text-white font-bold py-3 px-6 my-3 rounded-lg w-full login-btn"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    aria-label="login"
                  >
                    Login
                  </motion.button>
                  <motion.button
                    onClick={() => handleRedirect("/register")}
                    className="bg-gray-50 mb-1 text-black py-3 rounded-lg w-full"
                    whileHover={{ backgroundColor: "#eee" }}
                    whileTap={{ scale: 0.9 }}
                    aria-label="register"
                  >
                    Register
                  </motion.button>
                  <motion.button
                    onClick={() => handleRedirect("/forgot")}
                    className="bg-gray-50 mb-1 text-black py-3 rounded-lg w-full"
                    whileHover={{ backgroundColor: "#eee" }}
                    whileTap={{ scale: 0.9 }}
                    aria-label="forgot password"
                  >
                    Forgot Password
                  </motion.button>

                  <div className="google-button">
                    <button
                      type="button"
                      aria-label="login via google"
                      className="bg-white w-full py-3 border border-slate-300 rounded-lg"
                      onClick={() => handleRedirect("/google/login")}
                    >
                      <div className="google-button-icon">
                        <img
                          src={img1}
                          alt="Google Logo"
                          loading="lazy"
                          className="w-[20px] max-h-[20px]"
                        />
                      </div>
                      <div
                        className="google-button-text"
                        type="button"
                        aria-label="login via google"
                      >
                        Login via Google
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderHeader && <Footer />}
    </>
  );
}

export default Login;
