import { React, useState } from 'react';
import { motion } from "framer-motion";
import { getPricePlan } from '../../core/utils/main';
import { GetCookie } from '../../core/utils/cookies';
import '../css/style.css';
import TpReviews from '../../features/tpreviews';
import { useTranslation } from 'react-i18next';

function VPrice03(props) {
  const data = props.data ? props.data : null;
  const setPricing = props.setPricing ? props.setPricing : ()=>{};
  var ppg = GetCookie("ppg") ? GetCookie("ppg") : process.env.REACT_APP_DEFAULT_PPG ? process.env.REACT_APP_DEFAULT_PPG : "14";
  const ppgArrayWithToggle = ['40','48','52'];
  const showToggle = (ppgArrayWithToggle.includes(ppg) && props.showToggle) ? props.showToggle : false;
  var billedAnnualDisplay = false;

  if (ppg==='48'){
    billedAnnualDisplay = true;
  }

  const [ planInterval, setPlanInterval ] = useState(billedAnnualDisplay ? "yearly" : "monthly");
  const tpreviews = GetCookie("tp_reviews") ? GetCookie("tp_reviews") : "";
  const enterprise = GetCookie("enterprise") ? GetCookie("enterprise") : "off";
  const { t } = useTranslation();

  const intervalChange = function() {
    if(planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const checkPlanInterval = function(plan) {
    if(!showToggle) return true;
    if(plan.payment_interval.toLowerCase() === planInterval) return true;
    return false;
  }

  const displayDescription = function(plan) {
    let basicPlan = "";
    let proPlan = "";
    let enterprisePlan = "";
    let proMaxPlan = "";

    basicPlan += "<br/>";
    basicPlan += "<div><strong>ChatGPT-powered tools</strong></div>";
    basicPlan += "<div>4,096 context window</div>";
    basicPlan += "<div>250,000 Token Cap - comprehensive and exhaustive responses</div>";
    basicPlan += "<div>Customizable Response Models - can provide scenario & persona-based responses</div>";
    basicPlan += "<div>Save Chat History - store up to hundreds of research results accessible any time</div>";
    basicPlan += "<br/>";
    basicPlan += "<div><strong>DreamPhoto - Stable Diffusion-powered</strong> AI image generator</div>";
    basicPlan += "<br/>";
    basicPlan += "<div>Export Conversations - Image, Text, CSV or JSON files</div>";
    basicPlan += "<div>Live Support</div>";

    proPlan += "<br/>";
    proPlan += "<div><strong>ChatGPT 4-powered tools</strong> to generate any text content you need</div>";
    if(plan.plan_type.toLowerCase() === 'promax') proPlan += "<div>No Response Cap - no word count limit</div>";
    else proPlan += "<div>8,192 context window</div><div>500,000 Token Cap - comprehensive and exhaustive responses</div>";
    proPlan += "<div>Customizable Response Models - can provide scenario & persona-based responses</div>";
    proPlan += "<div>Save Chat History - store up to hundreds of research results accessible any time</div>";
    if(plan.plan_id !== '86') proPlan += "<div><strong>ChatPDF</strong> - ask questions based on your PDF document</div>";
    proPlan += "<div><strong>Teacher AI</strong> - streamlined learning app for any subject</div>";
    proPlan += "<br/>";
    proPlan += "<div><strong>Stable Diffusion-powered tools</strong> to create images</div>";
    proPlan += "<div><strong>DreamPhoto</strong> - Stable Diffusion-powered AI image generator</div>";
    proPlan += "<div><strong>Storybook AI</strong> - generate images for stories</div>";
    proPlan += "<div><strong>Interior AI</strong> - reinvent your rooms with AI</div>";
    proPlan += "<div><strong>AvatarMaker</strong> - create fantastical digital avatars</div>";
    proPlan += "<div><strong>RestorePhoto</strong> - preserve and restore old photos</div>";
    proPlan += "<br/>";
    proPlan += "<div>Choose Light or Dark Mode - for all screen types</div>";
    proPlan += "<div>Export Conversations - Image, Text, CSV or JSON files</div>";
    proPlan += "<div>AI Art Prompt Gallery</div>";
    proPlan += "<div>Live Support</div>";

    proMaxPlan += "<br/>";
    proMaxPlan += "<div><strong>ChatGPT 4-powered tools</strong> to generate any text content you need</div>";
    proMaxPlan += "<div>128,000 context window</div>";
    proMaxPlan += "<div>No Response Cap - no word count limit</div>";
    proMaxPlan += "<div>Customizable Response Models - can provide scenario & persona-based responses</div>";
    proMaxPlan += "<div>Save Chat History - store up to hundreds of research results accessible any time</div>";
    proMaxPlan += "<div><strong>ChatPDF</strong> - ask questions based on your PDF document</div>";
    proMaxPlan += "<div><strong>Teacher AI</strong> - streamlined learning app for any subject</div>";
    proMaxPlan += "<br/>";
    proMaxPlan += "<div><strong>Stable Diffusion-powered tools</strong> to create images</div>";
    proMaxPlan += "<div><strong>DreamPhoto</strong> - Stable Diffusion-powered AI image generator</div>";
    proMaxPlan += "<div><strong>Storybook AI</strong> - generate images for stories</div>";
    proMaxPlan += "<div><strong>Interior AI</strong> - reinvent your rooms with AI</div>";
    proMaxPlan += "<div><strong>AvatarMaker</strong> - create fantastical digital avatars</div>";
    proMaxPlan += "<div><strong>RestorePhoto</strong> - preserve and restore old photos</div>";
    proMaxPlan += "<br/>";
    proMaxPlan += "<div>Choose Light or Dark Mode - for all screen types</div>";
    proMaxPlan += "<div>Export Conversations - Image, Text, CSV or JSON files</div>";
    proMaxPlan += "<div>AI Art Prompt Gallery</div>";
    proMaxPlan += "<div>Live Support</div>";

    enterprisePlan += "<br/>";
    enterprisePlan += "<div><strong>Take your AI-powered applications to the next level with our exclusive Enterprise Plan.</strong></div>";
    enterprisePlan += "<div>Crafted for businesses seeking a tailored approach to harnessing the power of artificial intelligence, <br>this plan offers a suite of features designed to drive innovation and deliver exceptional user experiences.</div>";

    if (plan.plan_type.toLowerCase()==='basic'){
      return basicPlan;
    }else if (plan.plan_type.toLowerCase()==='promax'){
      return proMaxPlan;
    }else if (plan.plan_type.toLowerCase()==='enterprise'){
      return enterprisePlan;
    }else if (plan.plan_type.toLowerCase()==='promax'){
      return proMaxPlan;
    }else{
      return proPlan;
    }


  }

  const enterpriseTab = function() {
    return (
      <div className="price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 relative">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-10 price-content">
            <h3 className="text-xl font-bold mb-4">{t('arcana.pricing.vprice_02.enterprise.title')}</h3>
            <p className={`text-4xl font-bold text-gray-800 ${planInterval === 'yearly' && ppg === '48'  ? "" :"mb-4"}`}>{t('arcana.pricing.vprice_02.enterprise.price')}</p>
            { planInterval === 'yearly' && ppg === '48' ? <div className='text-xs mb-4'>(billed yearly)</div> : '' }
            <div className='py-4'>
              <motion.button
                className="bg-blue-500 text-white font-bold py-3 px-3 rounded-lg"
                whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                whileTap={{ scale: 0.9 }}
                onHoverStart={() => {
                  console.log("hover:", data)
                  console.log("hover2:", data[0].currency)
                }}
                onClick={() => setPricing(62)}
              >
              {t('arcana.pricing.vprice_02.enterprise.cta')}
              </motion.button>
            </div>
            <div className="mb-6 plan-description">
              <ul className="text-sm text-gray-600">
                <li className="mb-2 text-left"><br />
                  <div className="font-bold">{t('arcana.pricing.vprice_02.enterprise.desc1')}</div>
                  <div>{t('arcana.pricing.vprice_02.enterprise.desc2')}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
      <div className="v-pricing-01 pricing bg-gray-100">
        <div className="pricing_columns container mx-auto py-10">
          <div className="flex flex-col items-center py-10 lg:py-14">
            <h1 className="text-3xl md:text-4xl font-bold text-center mb-4">
              Get Full Access
            </h1>

            { showToggle ? (
              <div className="p-4">
                <div className="text-1xl lg:text-1xl font-bold text-center mb-4">
                  <div>Choose between our monthly and yearly options below</div>
                </div>
                <div className="flex items-center justify-center w-full mb-8">
                  <label for="toggleB" className="flex items-center cursor-pointer">
                    <div className={`${planInterval === 'monthly' ? "text-blue-700 font-bold" : "text-gray-700"} mr-3 uppercase`}>
                      Monthly
                    </div>
                    <div className="relative">
                      <input type="checkbox" id="toggleB" className="sr-only toggle" onChange={intervalChange} defaultChecked={billedAnnualDisplay}/>
                      <div className="block bg-gray-400 w-12 h-6 rounded-full"></div>
                      <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                    </div>
                    <div className={`${planInterval === 'yearly' ? "text-blue-700 font-bold" : "text-gray-700"} ml-3 uppercase`}>
                      Yearly
                    </div>
                  </label>
                </div>
              </div>
            ) : ""}

            { showToggle ? (
              <div className="pricing-toggle flex flex-col lg:flex-row justify-center">
                {data?.map((plan, index) => (
                  checkPlanInterval(plan) ? (
                    <div key={index} className={`price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 ${ index === 1 ? "relative" : "" }`}>
                      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="px-6 py-10 price-content">
                          <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                          {
                          billedAnnualDisplay && plan.payment_interval==='Yearly' ? <p className="text-4xl font-bold text-gray-800">{getPricePlan(plan.currency, parseFloat(plan.price/12).toFixed(2))}<span className="text-sm"> /month</span></p> :
                          plan.trial_price ?
                          <p className="text-3xl md:text-4x1 font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.trial_price)}</p>
                          : <p className="text-3xl md:text-4x1 font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.price)}<span className="text-sm"> /{ plan.payment_interval === "Monthly" ? "month" : "year" }</span></p>
                          }
                          {
                            billedAnnualDisplay && plan.payment_interval==='Yearly' ? <div className='text-sm mb-4'>(billed yearly)</div> : ''
                          }
                          <div className='py-4'>
                            <motion.button
                              className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                              whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => setPricing(plan.plan_id)}
                            >
                              Subscribe
                            </motion.button>
                          </div>
                          <div className="mb-6 plan-description">
                            <ul className="text-sm text-gray-600">
                              { plan.plan_description ? <li className="mb-2" dangerouslySetInnerHTML={{__html: displayDescription(plan)}}></li> : null }
                            </ul>
                          </div>
                        </div>
                      </div>
                      { planInterval === 'yearly' && showToggle ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                        <div>Up to <span className='font-bold underline-offset-1'>20% OFF</span> on an annual subscription</div>
                      </div> : null }
                    </div>
                  ) : ""
                ))}
                {(enterprise === 'on' && ppg !== '46' && planInterval === 'yearly' && data[0].currency === 'USD') ?
                  <>
                  {enterpriseTab()}
                  </>
                : null }
              </div>
            ) : (
              <div className="flex flex-col lg:flex-row justify-center">
                {data?.map((plan, index) => (
                  <div key={index} className={`price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 ${ index === 1 ? "relative" : "" }`}>
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                      <div className="px-6 py-10 price-content">
                        <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                        {
                        plan.label.toLowerCase()==="enterprise" ? <p className="text-3xl md:text-4x1 font-bold text-gray-800 mb-4">Custom Plan</p> :
                        plan.trial_price ?
                        <p className="text-3xl md:text-4x1 font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.trial_price)}</p>
                        : <p className="text-3xl md:text-4x1 font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.price)}<span className="text-sm"> /{ plan.payment_interval === "Monthly" ? "month" : "year" }</span></p>
                        }
                        <div className='py-4'>
                          <motion.button
                            className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                            whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => setPricing(plan.plan_id)}
                          >
                            Subscribe
                          </motion.button>
                        </div>
                        <div className="mb-6 plan-description">
                          <ul className="text-sm text-gray-600">
                            { plan.plan_description ? <li className="mb-2" dangerouslySetInnerHTML={{__html: displayDescription(plan)}}></li> : null }
                          </ul>
                        </div>
                      </div>
                    </div>
                    { index === 1 ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                      <span>Most Popular</span>
                    </div> : null }
                  </div>
                ))}
                {(enterprise === 'on' && ppg !== '46' && data[0].currency === 'USD') ?
                  <>
                  {enterpriseTab()}
                  </>
                : null }
              </div>
            )}

            <p className="text-xs max-w-md text-center leading-relaxed mb-10 lg:mb-12">
              *The pricing is exclusive of taxes and additional local tax may be collected.
            </p>

            { tpreviews === 'on' ?
            <>
              <TpReviews/>
            </>
          : null }
          </div>
        </div>

      </div>
  )
}

export default VPrice03;