import axios from 'axios';
import { useQuery } from "react-query";
import Cookies from 'universal-cookie';
import { SetCookie } from './cookies';
const cookies = new Cookies();

async function checkAuth() {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/check-auth`, { tk: cookies.get("access") }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
    const output = response.data;
    if(output.success) {
      SetCookie('user_plan', output.user.plan, { domain: '.ai-pro.org', path: '/' });
      return output.user;
    } else {
      return false;
    }
}

export function Auth(redirect = false) {
    const { data } = useQuery("auth", checkAuth);
    if(data === undefined) return data;
    if(data === false && redirect) window.location.href=redirect;
    return data;
}

export function isSubscriber(auth) {
  // console.log("auth:",auth)
  return (auth.status === 'active' || auth.status === 'inactive') && auth.expired === 'no';
}

export function isPro(auth) {
  // return (auth.plan === 'pro' || auth.plan === 'enterprise') && auth.status !== 'pending' && auth.expired === 'no';
  return (auth.plan.includes('pro') || auth.plan === 'enterprise') && auth.status !== 'pending' && auth.expired === 'no';

}

export function isBasic(auth) {
  return auth.plan === 'basic' && auth.status !== 'pending' && auth.expired === 'no';
}

export function isAuthV1Paid(auth) {
  return auth.authversion === 'v1' && auth.status !== 'pending' && auth.expired === 'no';
}