import React, { useState, useEffect, useRef, useCallback } from "react";
import "./style.css";
import { motion } from "framer-motion";
import Header from "../header";
import Footer from "../footer";
import axios from "axios";
import { GetSubdomain, GetCookie } from "../core/utils/cookies";

function Forgotpass() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const renderHeader = !GetSubdomain();
  const smooth_login = GetCookie("smooth_login") || "off";
  const spanErrorRefs = useRef([]);
  const formRef = useRef(null);

  const setSpanHeights = useCallback(() => {
    if (smooth_login === "on") {
      spanErrorRefs.current.forEach((ref, i) => {
        if (ref) {
          const errMsg = ref.textContent;
          const currHeight = ref.offsetHeight;
          const height = !!errMsg
            ? currHeight !== 0
              ? currHeight
              : (ref.scrollHeight ?? 0) + 12
            : 0;

          setTimeout(() => {
            ref.style.height = `${height}px`;
          }, 100);
        }
      });
    }
  }, [smooth_login]);

  /**
   * useEffect(() => {}, [formRef.current]) is currently not working.
   * Using setTimeout instead.
   */
  setTimeout(() => {
    const form = formRef.current;
    const isChromium =
      navigator.userAgent.includes("Chrome") ||
      navigator.userAgent.includes("Edg");

    if (form && isChromium) {
      const inputs = form.querySelectorAll("input");

      if (inputs && inputs.length > 0) {
        inputs.forEach((input) => {
          if (input.matches(":-internal-autofill-selected")) {
            input.classList.add("autofilled");
          }
        });
      }
    }
  }, 200);

  useEffect(() => {
    if (smooth_login === "on") {
      setSpanHeights();
      window.addEventListener("resize", setSpanHeights);

      return () => {
        window.removeEventListener("resize", setSpanHeights);
      };
    }
  }, [smooth_login, setSpanHeights]);

  useEffect(() => {
    if (smooth_login === "on") {
      setSpanHeights();
    }
  }, [emailError, smooth_login, setSpanHeights]);

  const validateEmail = () => {
    let isPassed = false;
    if (!email) {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
      isPassed = true;
    }
    return isPassed;
  };

  const handleResetPassword = () => {
    let isPassedValidate = validateEmail();
    if (!isPassedValidate) return;

    document.querySelector(".loader-container").classList.add("active");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        {
          email,
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then(function (res) {
        let output = res.data;
        if (output.success) {
          window.toastr.success("Success. Please check your email.");
          setTimeout(function () {
            window.location.reload();
          }, 3000);
          return;
        }
        document.querySelector(".loader-container").classList.remove("active");
        if (output.data) window.toastr.error(output.data.msg);
        else window.toastr.error("Invalid email address");
      });
  };

  const handleChange = (event, inputType) => {
    const el = event.target;
    const value = el.value;
    const isFocus = event.type === "focus";
    const isInput = event.type === "change" || event.type === "keyup";

    if (!!value || isFocus || isInput) {
      el.classList.add("autofilled");
    } else {
      el.classList.remove("autofilled");
    }

    if (smooth_login !== "on" || (smooth_login === "on" && isInput)) {
      switch (inputType) {
        case "email":
          setEmailError("");
          setEmail(value);
          break;
        default:
          if (event.keyCode === 13) {
            handleResetPassword();
          }
          break;
      }
    }
  };

  return (
    <>
      {renderHeader && <Header />}
      <div className="register bg-gray-100 h-screen flex justify-center items-center pt-10 md:pt-2">
        <div className="container py-10 px-0 md:px-4 sm:px-0 w-96 mx-auto">
          <div className="reg_col text-center mb-8">
            <h1 className="text-2xl lg:text-2xl font-bold text-center mb-6 lg:mb-8">
              Forgot Password?
            </h1>
            <div
              className="bg-white rounded-lg shadow-lg overflow-hidden"
              ref={formRef}
            >
              <div className="px-6 py-10">
                {smooth_login === "on" ? (
                  <>
                    <div className="bg-white w-full relative mb-3 sm:text-sm">
                      <input
                        className="peer block border-2 border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1  w-full pt-6 pb-2 px-6 placeholder:text-transparent placeholder:[user-select:_none]"
                        placeholder="Email *"
                        type="email"
                        name="email"
                        onBlur={(e) => handleChange(e, "email")}
                        onChange={(e) => handleChange(e, "email")}
                        onFocus={(e) => handleChange(e, "email")}
                        onKeyUp={handleChange}
                      />
                      <label
                        className="transition-all text-slate-400 duration-100 ease-in absolute top-1/2 -translate-y-1/2 left-6 pointer-events-none [.autofilled~&]:top-[17px] [.autofilled~&]:text-xs [.autofilled~&]:left-[25px]"
                        for="email"
                      >
                        Email
                      </label>
                    </div>
                    <span
                      className="block text-red-500 text-xs text-center w-full h-0 transition-[height] duration-200 ease-in overflow-hidden"
                      ref={(el) => (spanErrorRefs.current[0] = el)}
                    >
                      {emailError}
                    </span>
                  </>
                ) : (
                  <label className="relative block">
                    {emailError && (
                      <span className="text-red-500 text-xs text-left w-full mb-2">
                        {emailError}
                      </span>
                    )}
                    <input
                      className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mb-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      placeholder="Email Address *"
                      aria-label="Email Address"
                      type="email"
                      name="email"
                      onKeyUp={(e) => {
                        setEmail(e.target.value);
                        if (e.keyCode === 13) handleResetPassword();
                      }}
                      onBlur={validateEmail}
                      required
                    />
                  </label>
                )}
                <motion.button
                  className="bg-blue-600 text-white font-bold py-3 px-6 my-3 rounded-lg w-full"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    smooth_login === "on" && validateEmail();
                    handleResetPassword();
                  }}
                  aria-label="Reset Password"
                >
                  Reset Password
                </motion.button>
                <motion.button
                  className="bg-gray-50 mb-1 text-black py-3 rounded-lg w-full"
                  whileHover={{ backgroundColor: "#eee" }}
                  whileTap={{ scale: 0.9 }}
                  aria-label="Back to Login"
                  onClick={() => {
                    window.location.href = "/register";
                  }}
                >
                  Register
                </motion.button>
                <motion.button
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                  className="bg-gray-50 mb-1 text-black py-3 rounded-lg w-full"
                  whileHover={{ backgroundColor: "#eee" }}
                  whileTap={{ scale: 0.9 }}
                  aria-label="Back to Login"
                >
                  Login
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Forgotpass;
