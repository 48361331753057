import React, {Component} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.css';
import Slider from "react-slick";
import "../assets/slick/slick.css";
import "../assets/slick/slick-theme.css";
import tpic from '../assets/images/tp.png';
import './tpreviews.css';
import { withTranslation } from 'react-i18next';

const rawData = [
  'arcana.features.tp_reviews.user1.',
  'arcana.features.tp_reviews.user2.',
  'arcana.features.tp_reviews.user3.',
  'arcana.features.tp_reviews.user4.',
  'arcana.features.tp_reviews.user5.',
]

const reviewData = rawData.concat(rawData) // used for slider behavior since there are only 5 reviews

class TrustPilotReviews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nav1: null,
      nav2: null
    }
  }

componentDidMount() {
  this.setState({
    nav1: this.slider1,
    nav2: this.slider2
  })

  
}

render() {
  
  const {t} = this.props
  function getUserImage(image){
    const imgSrc = require(`../assets/images/${image}`)

    return (<img src={imgSrc} alt={image} width="65" height="65" className="lp_img pb-2 tp-img-reviewer tp-rev" />)
  }

  const settingsReview = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    dots: false,
    adaptiveHeight: true,
    autoplay: false,
 
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
        },
      },

    ],

    };
  
    const settingsReviewer = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplaySpeed: 5000,
    autoplay:true,
    centerMode: true,
    focusOnSelect: true,

    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          arrows: true,
          centerMode: true
        },
      },

    ],
    };

  return (
    <section className='tp-sec'>
      <div className="tp-review fluid-container">
        <div className="container">
          <p className="tp-hear">{t('arcana.features.tp_reviews.title')}</p>
          <div className="tp-review-con">
          <Slider asNavFor={this.state.nav2} 
        ref={slider => (this.slider1 = slider)}
        {...settingsReview}>
        {reviewData.map((review, index) => {
          let rev = t(review + 'review')
          const highlights = t(review + 'highlighted').split(';')

          highlights.forEach(highlight => {
            let regex = new RegExp(highlight.trim(), 'gi')
            rev = rev.replace(regex, `<span class="tp-highlighted">${highlight.trim()}</span>`)
          });
          return (
            <div className="tp-review-box" key={index}>
              <p className="title">{t(review+'title')}</p>
              <div className="desc-container">
                <p className="description" dangerouslySetInnerHTML={{ __html: rev }} />
                <p className="tp-reviewer-name">- {t(review+'name')}</p>
              </div>
              <div className="tp-logo">
                <img src={tpic} width="80" height="50" alt="" />
              </div>
            </div>
          );
        })}
      </Slider>
          </div>
        </div>
        <div className="tp-reviewer-main container">
          
          <Slider className='tp-reviewer-container'
            asNavFor={this.state.nav1} 
            ref={slider => (this.slider2 = slider)}
            {...settingsReviewer}>
	            {reviewData.map((review, index) => (
	              <div className='tp-rev' key={index}>
                  {getUserImage(t(review+'img'))}
	              </div>
	            ))}
	          </Slider>
          
        </div>
      </div>
    </section>

  )
}


}

export default withTranslation()(TrustPilotReviews);

