import { React, useState } from 'react';
import { motion } from "framer-motion";
import { getPricePlan } from '../../core/utils/main';
import { GetCookie } from '../../core/utils/cookies';
import '../css/style.css';
import TpReviews from '../../features/tpreviews';
import { useTranslation } from 'react-i18next';


function VPrice01(props) {
  const data = props.data ? props.data : null;
  const setPricing = props.setPricing ? props.setPricing : ()=>{};
  var ppg = GetCookie("ppg") ? GetCookie("ppg") : process.env.REACT_APP_DEFAULT_PPG ? process.env.REACT_APP_DEFAULT_PPG : "14";
  const ppgArrayWithToggle = ['40','48','52'];
  const showToggle = (ppgArrayWithToggle.includes(ppg) && props.showToggle) ? props.showToggle : false;
  const enterprise = GetCookie("enterprise") ? GetCookie("enterprise") : "off";
  var billedAnnualDisplay = false;
  const { t } = useTranslation();

  if (ppg==='48'){
    billedAnnualDisplay = true;
  }

  const [ planInterval, setPlanInterval ] = useState(billedAnnualDisplay ? "yearly" : "monthly");
  const tpreviews = GetCookie("tp_reviews") ? GetCookie("tp_reviews") : "";
  const intervalChange = function() {
    if(planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const checkPlanInterval = function(plan) {
    if(!showToggle) return true;
    if(plan.payment_interval.toLowerCase() === planInterval) return true;
    return false;
  }

  const displayLabel = function(plan){
    if (plan.label.toLowerCase()==='enterprise'){
      return (
        <p className="text-4xl md:text-[26px] lg:text-4xl font-bold text-gray-800 mb-4 md:mb-[16px] lg:mb-4">Custom Plan</p>
      )
    }

    if (billedAnnualDisplay && plan.payment_interval==='Yearly'){
      return (
        <div>
          <p className="text-4xl font-bold text-gray-800">{getPricePlan(plan.currency, parseFloat(plan.price/12).toFixed(2))}<span className="text-sm"> /month</span></p> 
          <div className='text-xs mb-4'>(billed yearly)</div>
        </div>
      )
    } 
    
    if (plan.trial_price){
      return (
        <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.trial_price)}</p>        
      )
    }else{
      return (
        <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.price)}<span className="text-sm"> /{ plan.payment_interval === "Monthly" ? "month" : "year" }</span></p>        
      )
    }
  }

  const enterpriseTab = function() {
    return (

        <div className="price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 relative">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="px-6 py-10 price-content">
              <h3 className="text-xl font-bold mb-4">{t('arcana.pricing.vprice_02.enterprise.title')}</h3>
              <p className={`text-4xl font-bold text-gray-800 ${planInterval === 'yearly' && ppg === '48'  ? "" :"mb-4"}`}>{t('arcana.pricing.vprice_02.enterprise.price')}</p>
              { planInterval === 'yearly' && ppg === '48' ? <div className='text-xs mb-4'>(billed yearly)</div> : '' }
              <div className='py-4'>
              <motion.button
                className="bg-blue-500 text-white font-bold py-3 px-3 rounded-lg"
                whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setPricing(62)}
              >
              {t('arcana.pricing.vprice_02.enterprise.cta')}
              </motion.button>
              </div>
              <div className="mb-6 plan-description">
                <ul className="text-sm text-gray-600">
                  <li className="mb-2 font-bold"><div>{t('arcana.pricing.vprice_02.enterprise.desc1')}</div></li>
                  <li className="mb-2"><div>{t('arcana.pricing.vprice_02.enterprise.desc2')}</div></li>
                </ul>
              </div>

            </div>
          </div>
        </div>
    );
  };

    return (
        <div className="v-pricing-01 pricing bg-gray-100">
          <div className="pricing_columns container mx-auto py-10">
            <div className="flex flex-col items-center py-10 lg:py-14">
              <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">
                Get Full Access
              </h1>

              { showToggle ? (
                <div className="p-4">
                  <div className="text-1xl lg:text-1xl font-bold text-center mb-4">
                    <div>Choose between our monthly and yearly options below</div>
                  </div>
                  <div className="flex items-center justify-center w-full mb-8">
                    <label for="toggleB" className="flex items-center cursor-pointer">
                      <div className={`${planInterval === 'monthly' ? "text-blue-700 font-bold" : "text-gray-700"} mr-3 uppercase`}>
                        Monthly
                      </div>
                      <div className="relative">
                        <input type="checkbox" id="toggleB" className="sr-only toggle" onChange={intervalChange} defaultChecked={billedAnnualDisplay}/>
                        <div className="block bg-gray-400 w-12 h-6 rounded-full"></div>
                        <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                      </div>
                      <div className={`${planInterval === 'yearly' ? "text-blue-700 font-bold" : "text-gray-700"} ml-3 uppercase`}>
                        Yearly
                      </div>
                    </label>
                  </div>
                </div>
              ) : ""}

              { showToggle ? (
                <div className="pricing-toggle flex flex-col lg:flex-row justify-center">
                  {data?.map((plan, index) => (
                    checkPlanInterval(plan) ? (
                      <div key={index} className={`price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 ${ index === 1 ? "relative" : "" }`}>
                        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                          <div className="px-6 py-10 price-content">
                            <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                            {displayLabel(plan)}
                            <div className='py-4'>
                              <motion.button
                                className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                                whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => setPricing(plan.plan_id)}
                              >
                              {plan.label.toLowerCase()==="enterprise" ? "Build My Plan" : "Subscribe" }
                              </motion.button>
                            </div>
                            <div className="mb-6 plan-description">
                              <ul className="text-sm text-gray-600">
                                { plan.plan_description ? <li className="mb-2" dangerouslySetInnerHTML={{__html: plan.plan_description}}></li> : null }
                              </ul>
                            </div>
                          </div>
                        </div>
                        { planInterval === 'yearly' && showToggle ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                          <div>Up to <span className='font-bold underline-offset-1'>20% OFF</span> on an annual subscription</div>
                        </div> : null }
                      </div>
                    ) : ""
                  ))}
                  {(enterprise === 'on' && ppg !== '46' && planInterval === 'yearly' && data[0].currency === 'USD') ?
                    <>
                    {enterpriseTab()}
                    </>
                  : null }
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row justify-center">
                  {data?.map((plan, index) => (
                    <div key={index} className={`price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 ${ index === 1 ? "relative" : "" }`}>
                      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="px-6 py-10 price-content">
                          <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                          {displayLabel(plan)}
                          <div className='py-4'>
                            <motion.button
                              className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                              whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => setPricing(plan.plan_id)}
                            >
                              {plan.label.toLowerCase()==="enterprise" ? "Build My Plan" : "Subscribe" }
                            </motion.button>
                          </div>
                          <div className="mb-6 plan-description">
                            <ul className="text-sm text-gray-600">
                              { plan.plan_description ? <li className="mb-2" dangerouslySetInnerHTML={{__html: plan.plan_description}}></li> : null }
                            </ul>
                          </div>
                        </div>
                      </div>
                      { index === 1 ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                        <span>Most Popular</span>
                      </div> : null }
                    </div>
                  ))}
                  {(enterprise === 'on' && ppg !== '46' && data[0].currency === 'USD') ?
                    <>
                    {enterpriseTab()}
                    </>
                  : null }
                </div>
              )}

              <p className="text-xs max-w-md text-center leading-relaxed mb-10 lg:mb-12">
                *The pricing is exclusive of taxes and additional local tax may be collected.
              </p>

              { tpreviews === 'on' ?
              <>
                <TpReviews/>
              </>
            : null }
            </div>
          </div>

        </div>
    )
}

export default VPrice01;