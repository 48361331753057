import { React } from 'react';
import './style.css';
import Header from '../header/headerlogo';
import { useQuery } from "react-query";
import axios from 'axios';
import { GetCookie, SetCookie } from '../core/utils/cookies';
import VPrice from './components/vprice.jsx';
import VPrice01 from './components/vprice_01.jsx';
import VPrice02 from './components/vprice_02.jsx';
import VPrice03 from './components/vprice_03.jsx';
import { Helmet } from 'react-helmet';
import _ from 'underscore';

var plan = null;
var showToggle = false;
var hasAnnual = false;
async function getPPG() {
  var ppg = GetCookie("ppg") ? GetCookie("ppg") : process.env.REACT_APP_DEFAULT_PPG ? process.env.REACT_APP_DEFAULT_PPG : "14";
  if(plan) return plan;
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-pricing`, { ppg }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  const output = response.data;
  if(output.success) {
    plan = output.data;
    hasAnnual = _.some(plan, function(o) { return o.payment_interval.toLowerCase() === "yearly"; });
    showToggle = _.some(plan, function(o) { return o.payment_interval.toLowerCase() === "monthly"; }) && hasAnnual;
    return plan;
  } else {
    return [];
  }
}

function Pricing() {
  const { data } = useQuery("users", getPPG);

  if(data === undefined) return;
  const tk = GetCookie("access");

  const setPricing = function(id) {
    SetCookie('pricing', id, { path: '/' });
    window.location.href = "/pay";
  };
  const vPrice_token = GetCookie("vprice") ? GetCookie("vprice") : "";

  return (
    <>
      <Helmet>
        <title>AI Pro | Plans and Pricing</title>
        <meta name="description" content="Discover our flexible AI pricing plans designed to suit your needs. Explore our features and find the perfect plan to elevate your projects." />
      </Helmet>
      <Header auth={tk}/>
      { (vPrice_token === '' && GetCookie("ppg")==='46')  ?
        <VPrice01 showToggle={showToggle} hasAnnual={hasAnnual}  data={data} setPricing={setPricing}/> :
        (vPrice_token === 'vP1zx12mXk')  ?
        <VPrice01 showToggle={showToggle} hasAnnual={hasAnnual}  data={data} setPricing={setPricing}/> :
        vPrice_token === 'vP2xyYxjjj' ?
        <VPrice02 showToggle={showToggle} hasAnnual={hasAnnual}  data={data} setPricing={setPricing}/> : 
        vPrice_token === 'waYmXFAgLs' ?
        <VPrice03 showToggle={showToggle} hasAnnual={hasAnnual}  data={data} setPricing={setPricing}/> 
        :         
        <VPrice showToggle={showToggle} hasAnnual={hasAnnual}  data={data} setPricing={setPricing}/> }
    </>
  );
}

export default Pricing;
