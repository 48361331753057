import React from 'react';
import './style.css';
import Header from '../header/headerlogo';
import { Auth } from '../core/utils/auth';
// import axios from 'axios';
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";


function Payment() {
  const auth = Auth('/register');
  if(auth === undefined || auth === false) return;


	// if(auth.status === 'active' && auth.expired === 'no' ) {
	// 		window.location.href = '/my-account';
	// 		return;
	// }
  const redirectToMyAccount = () => {
    window.location.href = '/my-account';
  }

  return (
    <>
      <Helmet>
        <title>AI Pro | Enterprise Account Payment Confirmation</title>
        <meta name="description" content="Safely complete your purchase with our secure payment options. Buy now with confidence!" />
      </Helmet>
      <Header auth={auth} />
      <div className="Payment bg-gray-100 min-h-[600px] flex">
          <div className="container mx-auto py-10 text-center">
            <div className="flex flex-col items-center py-10 lg:py-16">
                <h2 className="text-xl font-bold mb-4 py-10">Your payment confirmation has been received.</h2>
                <div className="text-center text-md w-full md:w-[80%] px-2">As soon as it is verified by the bank (usually within 2-3 days), our dedicated account manager will reach out to guide you through the process of setting up your Enterprise account for a seamless experience.</div>
            </div>
            <motion.button
              className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
              whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
              whileTap={{ scale: 0.9 }}
              onClick={redirectToMyAccount}
            >
              Continue to your Account
            </motion.button>
          </div>
      </div> 
    </>
  );
}

export default Payment;