import React from 'react';
import './style.css';
import aiproLogo from '../assets/images/AIPRO.png';
import '@fortawesome/fontawesome-free/css/all.css';
import { useSpring, animated } from 'react-spring';
import { Auth } from '../core/utils/auth';

function HeaderLogo(props) {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } })
  const slide = useSpring({ transform: 'translateY(0)', from: { transform: 'translateY(-100%)' } })
  const isChatGptGo = /\/start-chatgpt-go\/?$/.test(window.location.pathname);
  const isTexttoImage = /\/text-to-image\/?$/.test(window.location.pathname);
  const isChatGptV2 = /\/start-chatgpt-v2\/?$/.test(window.location.pathname);
  const showLink = props.hideNavLink ? false : true;
  const auth = Auth();

  return (
    <animated.header id="header" style={fade} className="headernav border-gray-500 bg-white text-black w-3/4 mx-auto rounded-3xl mt-3 py-2 z-[8888]">
      <div className="container mx-auto flex justify-between items-center px-4">
        <animated.img style={slide} src={aiproLogo} alt="AI-Pro Logo" className="aiprologo" role="img"/>
        {( (isChatGptGo || isTexttoImage || isChatGptV2) && showLink )  &&
          <nav className="text-xs lg:text-sm block inline-flex" id="menu">
            <ul className="headnav flex inline-flex">
              {auth ? <li className="mr-1 md:mr-2 lg:mr-6"><a href="/my-account" className="font-bold" name="my-account" aria-label="my-account">My Apps</a></li> : <li className="mr-1 md:mr-2 lg:mr-6"><a href="/login" className="font-bold" name="login" aria-label="login">LOG IN</a></li> }
            </ul>
          </nav>
        }
      </div>

    </animated.header>
  );
}

export default HeaderLogo;