import React, { useState, useEffect, useRef } from 'react';
import './start-stable-diffusion.css';
import Header from '../header';
import Footer from '../footer';
import { Auth } from '../core/utils/auth';
import { motion } from "framer-motion";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import img1 from '../assets/images/img1.png';
import img2 from '../assets/images/img2.png';
import img3 from '../assets/images/img3.png';
import img4 from '../assets/images/img4.png';
import img5 from '../assets/images/img5.png';
import img6 from '../assets/images/img6.png';
import img7 from '../assets/images/img7.png';
import img8 from '../assets/images/img8.png';
import img9 from '../assets/images/img9.png';
import img10 from '../assets/images/img10.png';
import img11 from '../assets/images/img11.png';
import img12 from '../assets/images/img12.png';
import img13 from '../assets/images/img13.png';
import img14 from '../assets/images/img14.png';
import img15 from '../assets/images/img15.png';
import img16 from '../assets/images/img16.png';
import img17 from '../assets/images/img17.png';
import img18 from '../assets/images/img18.png';
import img19 from '../assets/images/img19.png';
import img20 from '../assets/images/img20.png';
import img21 from '../assets/images/img21.png';
import img22 from '../assets/images/img_stablediffusion.png';
import img26 from '../assets/images/img26.png';
import img27 from '../assets/images/img27.png';
import img28 from '../assets/images/img28.png';
import img29 from '../assets/images/img29.png';
import img1b from '../assets/images/img1b.png';
import img2b from '../assets/images/img2b.png';
import img3b from '../assets/images/img3b.png';
import img4b from '../assets/images/img4b.png';
import img5b from '../assets/images/img5b.png';
import img6b from '../assets/images/img6b.png';
import img7b from '../assets/images/img7b.png';
import img8b from '../assets/images/img8b.png';
import img9b from '../assets/images/img9b.png';
import img10b from '../assets/images/img10b.png';
import img11b from '../assets/images/img11b.png';
import img12b from '../assets/images/img12b.png';
import img13b from '../assets/images/img13b.png';
import img14b from '../assets/images/img14b.png';
import img15b from '../assets/images/img15b.png';
import img16b from '../assets/images/img16b.png';
import img17b from '../assets/images/img17b.png';
import img18b from '../assets/images/img18b.png';
import img19b from '../assets/images/img19b.png';
import img20b from '../assets/images/img20b.png';
import img21b from '../assets/images/img21b.png';
import img1c from '../assets/images/img1c.png';
import img2c from '../assets/images/img2c.png';
import img3c from '../assets/images/img3c.png';
import img4c from '../assets/images/img4c.png';
import img5c from '../assets/images/img5c.png';
import img6c from '../assets/images/img6c.png';
import img7c from '../assets/images/img7c.png';
import img8c from '../assets/images/img8c.png';
import img9c from '../assets/images/img9c.png';
import img10c from '../assets/images/img10c.png';
import img11c from '../assets/images/img11c.png';
import img12c from '../assets/images/img12c.png';
import img13c from '../assets/images/img13c.png';
import img14c from '../assets/images/img14c.png';
import img15c from '../assets/images/img15c.png';
import img16c from '../assets/images/img16c.png';
import img17c from '../assets/images/img17c.png';
import img18c from '../assets/images/img18c.png';
import img19c from '../assets/images/img19c.png';
import img20c from '../assets/images/img20c.png';
import img21c from '../assets/images/img21c.png';
import { Helmet } from 'react-helmet';
import BackToTopButton from '../footer/backtotop';
import { GetCookie } from '../core/utils/cookies';

function StartStableDiffusion() {
  const auth = Auth();
  const [isMobile, setIsMobile] = useState(false);
  const headerRef = useRef(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const hideLinks = GetCookie('qW1eMlya') && GetCookie('qW1eMlya') === 'on' ? true : false;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      const headerBottom = headerRef.current.getBoundingClientRect().bottom;
      setIsHeaderVisible(window.pageYOffset < headerBottom);
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };

  }, [auth]);

  const checkSubscription = () => {
    if(!(auth)){
      window.location.href = '/register';
    } else if (auth && auth.status === 'active') {
      window.location.href = '/my-account';
    } else {
      window.location.href = '/pricing';
    }
  };

  if(auth === undefined) return;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>AI Pro | Start Using Stable Diffusion</title>
        <meta name="description" content="Discover the power of AI Art. Generate Amazing Art & Images from Text. Access Latest Version Now." />
      </Helmet>
      <Header auth={auth} hideNavLink={hideLinks}/>
        <div ref={headerRef}></div>
        <BackToTopButton isHeaderVisible={isHeaderVisible} />
        <div className="stabledif bg-gray-100">
          <div id="particles-jsbg" className="z-0 top-0 md:top-8"></div>
          <div className="intro container mx-auto pt-0 pb-10 md:pt-10 md:px-20">

            <div className="flex flex-col md:flex-row pt-16 md:pt-8 lg:pt-12 pb-10 lg:p-[50px] lg:pb-10">
              <div className="md:w-1/2 p-8 lg:ml-8 text-center md:text-left">
                <h4 className="text-md font-bold pt-0 md:pt-8 lg:pt-6 xl:pt-14">Text-to-Image Artwork</h4>
                <h1 className="text-4xl font-black mb-4 sm:pt-4">Start Using Stable Diffusion</h1>
                <p className="text-sm mb-4">
                  Discover the power of AI Art. Generate Amazing Art & Images from Text. Access Latest Version Now.
                </p>
                <button className="ctabtn gradient-hover-effect text-white font-bold py-3 px-6 rounded-lg mx-auto md:mx-0" onClick={checkSubscription}>Start Now</button>
              </div>
              <div className="md:w-1/2 banner_img">
                <img src={img22} alt="stable diffusion" className="object-cover w-full"/>
              </div>
            </div>
          </div>

          <div className="features container mx-auto md:px-28">
            <div className="lg:flex lg:justify-center text-center">
              <div className="w-full max-w-screen-lg mt-16">
                <h2 className="text-3xl lg:text-4xl font-bold mb-8">
                  Use Stable Diffusion and Create Art
                </h2>
                <p className="mx-auto max-w-2xl mb-16 text-center">
                  Stable Diffusion is the latest AI Art Generation software to be released. Anyone can use this software to generate the most amazing artwork and images. We provide you with example prompts on how to generate certain looks and styles.
                </p>
              </div>
            </div>
            <div className="lg:flex lg:justify-center">
              <div className="lg:w-full">
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Hyper-realistic Portraits
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img1} alt="img1" className="lp_img pb-2"/>
                      <img src={img1b} alt="img1b" className="lp_img pb-2"/>
                      <img src={img1c} alt="img1c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Create stunning close-up photographs of lifelike individuals with the striking effect of sharp foregrounds contrasted against soft, out-of-focus backgrounds.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Fictional Portraits
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img2} alt="img2" className="lp_img pb-2"/>
                      <img src={img2b} alt="img2b" className="lp_img pb-2"/>
                      <img src={img2c} alt="img2c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Bring your story to life with vivid, imaginative characters for concept art in a game, comic book, or fantasy novel. Create an immersive experience that resonates deeply with the audience.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Mobile Game Characters
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img3} alt="img3" className="lp_img pb-2"/>
                      <img src={img3b} alt="img3b" className="lp_img pb-2"/>
                      <img src={img3c} alt="img3c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Unleash your artistic potential and craft beautiful visuals for mobile game characters. Design artworks that are not only visually stunning but also convey the game’s personality.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Landscape Photography
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img4} alt="img4" className="lp_img pb-2"/>
                      <img src={img4b} alt="img4b" className="lp_img pb-2"/>
                      <img src={img4c} alt="img4c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Capture the breathtaking splendor of nature with stunning, award-winning landscape photos. From majestic mountain panoramas to peaceful woodlands, this tool will help you create awe-inspiring shots that are sure to impress.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Architectural Design Concepts
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img5} alt="img5" className="lp_img pb-2"/>
                      <img src={img5b} alt="img5b" className="lp_img pb-2"/>
                      <img src={img5c} alt="img5c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Create beautiful, state-of-the art architectural designs from award-winning architects for structures that blend seamlessly into the natural environment.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Interior Design Ideas
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img6} alt="img6" className="lp_img pb-2"/>
                      <img src={img6b} alt="img6b" className="lp_img pb-2"/>
                      <img src={img6c} alt="img6c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Let this highly dynamic AI tool be your source of inspiration for a modern interior design. Easily design an area emphasizing the importance of natural light, with large windows to let in the daylight and lighter colors to complement it.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Anime Characters
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img7} alt="img7" className="lp_img pb-2"/>
                      <img src={img7b} alt="img7b" className="lp_img pb-2"/>
                      <img src={img7c} alt="img7c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Design mesmerizing anime-inspired scenes, characters, and settings – bring your creative vision to life.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Vector Arts
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img8} alt="img8" className="lp_img pb-2"/>
                      <img src={img8b} alt="img8b" className="lp_img pb-2"/>
                      <img src={img8c} alt="img8c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Constructing vector art for landing pages, emails, and social media posts has never been easier. Create eye-catching visuals that will dazzle your viewers.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      19th Century-Style Illustrations
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img9} alt="img9" className="lp_img pb-2"/>
                      <img src={img9b} alt="img9b" className="lp_img pb-2"/>
                      <img src={img9c} alt="img9c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Follow in the footsteps of renowned botanical illustrators such as Pierre-Joseph Redoute and create intricate, breathtaking 19th century naturalistic drawings.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Web and App Design Mockups
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img10} alt="img10" className="lp_img pb-2"/>
                      <img src={img10b} alt="img10b" className="lp_img pb-2"/>
                      <img src={img10c} alt="img10c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Whether it be for a mobile app, desktop software, or web application in any sector and with any theme, we can generate gorgeous mock-up designs that are visually appealing.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Landing Page Mockups
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img11} alt="img11" className="lp_img pb-2"/>
                      <img src={img11b} alt="img11b" className="lp_img pb-2"/>
                      <img src={img11c} alt="img11c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Designing the perfect landing page for nearly any industry or theme can help to maximize conversion rates, leading to an influx of leads, sales, and inquiries. Get landing page inspirations here.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Email Template Designs
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img12} alt="img12" className="lp_img pb-2"/>
                      <img src={img12b} alt="img12b" className="lp_img pb-2"/>
                      <img src={img12c} alt="img12c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Creative and visually-appealing email template designs are suitable for any industry, giving your business the perfect opportunity to engage with customers and boost conversions.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Tattoo Art Ideas
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img13} alt="img13" className="lp_img pb-2"/>
                      <img src={img13b} alt="img13b" className="lp_img pb-2"/>
                      <img src={img13c} alt="img13c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Unveil the artistry of your vision with intricate tattoo design inspirations. Let us help you create the perfect tattoo for your own style and personality.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Jewelry Design Ideas
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img14} alt="img14" className="lp_img pb-2"/>
                      <img src={img14b} alt="img14b" className="lp_img pb-2"/>
                      <img src={img14c} alt="img14c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Let your creativity shine and design stunning jewelry pieces, no matter the materials you use – be it precious metals, stones or other elements.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Collectible Toy Designs
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img15} alt="img15" className="lp_img pb-2"/>
                      <img src={img15b} alt="img15b" className="lp_img pb-2"/>
                      <img src={img15c} alt="img15c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Get unlimited toy design inspirations here from robotic mobile suits to monstrous designs. Get all the innovative concepts your mind desires.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Fashion Design
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img16} alt="img16" className="lp_img pb-2"/>
                      <img src={img16b} alt="img16b" className="lp_img pb-2"/>
                      <img src={img16c} alt="img16c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Unlock your creative potential and express yourself through fabulous fashion designs. Stable Diffusion can help you craft the perfect look that reflects your style and individuality. Discover how easy it is to make stylish clothing with us.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Furniture Design
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img17} alt="img17" className="lp_img pb-2"/>
                      <img src={img17b} alt="img17b" className="lp_img pb-2"/>
                      <img src={img17c} alt="img17c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Bring your unique vision to life with beautiful furniture design inspirations. Allow us to collaborate with you so we can craft the ideal piece of furniture that speaks directly to your individual style and personality.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Vehicle Concept Design
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img18} alt="img18" className="lp_img pb-2"/>
                      <img src={img18b} alt="img18b" className="lp_img pb-2"/>
                      <img src={img18c} alt="img18c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Uncover your creative side and bring to life the vehicle you envision. We will help make a reality of your unique, individualized style with our intricate design inspirations. Let us aid in creating the perfect automobile for who you are.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Diorama Artworks
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img19} alt="img19" className="lp_img pb-2"/>
                      <img src={img19b} alt="img19b" className="lp_img pb-2"/>
                      <img src={img19c} alt="img19c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Unleash your creativity and let your imagination run wild while crafting awe-inspiring dioramas! From detailed battle scenes to magnificent castles, the possibilities are truly endless.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Patterned Artworks
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img20} alt="img20" className="lp_img pb-2"/>
                      <img src={img20b} alt="img20b" className="lp_img pb-2"/>
                      <img src={img20c} alt="img20c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Transform dull and boring pieces into vibrant works of art and liven up any event with our eye-catching patterned artwork that can be used as wallpapers and gift wraps.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left  mb-4 lg:mb-0 py-5 px-6">
                    <h4 className="text-md font-bold mb-4 text-center">
                      Pixel Art
                    </h4>
                    <Carousel className="py-2" showArrows={false} emulateTouch={true} autoPlay={false} centerSlidePercentage={true}>
                      <img src={img21} alt="img21" className="lp_img pb-2"/>
                      <img src={img21b} alt="img21b" className="lp_img pb-2"/>
                      <img src={img21c} alt="img21c" className="lp_img pb-2"/>
                    </Carousel>
                    <p className="text-sm text-left mx-auto px-5 md:px-3 lg:px-5">
                      Unlock your creative potential and stoke the flame of inspiration with pixel art for your next game, story, or novel.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:flex lg:justify-center border-t p-12 border-gray-400">
              <p className="text-sm text-center mx-auto">
                Unlock the gateway to captivating digital artwork with Stable Diffusion! Our AI powered software has been designed for all levels of artistic ability, giving users a comprehensive platform to generate awe-inspiring works of art. We provide our customers with handy templates and prompts that will help them achieve their desired look and feel in no time at all.
              </p>
            </div>
            <div className="flex flex-col mx-auto">
              <motion.button
                onClick={checkSubscription}
                className="bg-blue-500 text-white font-bold py-3 px-6 mb-12 rounded-lg mx-auto ctabtn"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Start Now
              </motion.button>
            </div>
          </div>

          <div className="ask container mx-auto">
            <div className="flex flex-col items-center py-10 lg:py-16 border-2 border-gray-200 rounded-2xl">
              <h2 className="text-3xl font-bold text-center mb-6 lg:mb-8">
                Use Artificial Intelligence
              </h2>
              <p className="text-md max-w-xl text-center leading-relaxed mx-2 mb-10 lg:mb-12">
                At AI-PRO, we believe that everyone should have access to the resources and guidance they need to succeed in the world of AI. That’s why we offer a variety of membership options to suit your needs and budget. Whether you’re an individual looking to use AI or a business looking to adopt AI solutions, we have a plan that’s right for you.
              </p>
              <button className="ctabtn gradient-hover-effect text-white font-bold py-3 px-6 rounded-lg" onClick={checkSubscription}>
                Discover AI Now
              </button>
            </div>
            <div className="bgradient"></div>
          </div>

          <div className="gallery mx-auto my-6">
            <div className="flex flex-col items-center lg:py-8 bg-blue-400">
              {isMobile ? (
                <div className="flex flex-col lg:flex-row justify-center">
                  <Carousel className="py-4" showArrows={true} emulateTouch={true} autoPlay={true} centerSlidePercentage={true}>
                    <img src={img26} alt="img26" className="lp_img block md:inline w-full lg:w-4 p-2"/>
                    <img src={img27} alt="img27" className="lp_img block md:inline w-full lg:w-4 p-2"/>
                    <img src={img28} alt="img28" className="lp_img block md:inline w-full lg:w-4 p-2"/>
                    <img src={img29} alt="img29" className="lp_img block md:inline w-full lg:w-4 p-2"/>
                  </Carousel>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <img src={img26} alt="img26" className="lp_img block md:inline flex-grow w-full lg:w-4 p-2"/>
                    <img src={img27} alt="img27" className="lp_img block md:inline flex-grow w-full lg:w-4 p-2"/>
                    <img src={img28} alt="img28" className="lp_img block md:inline flex-grow w-full lg:w-4 p-2"/>
                    <img src={img29} alt="img29" className="lp_img block md:inline flex-grow w-full lg:w-4 p-2"/>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      <Footer auth={auth} hideNavLink={hideLinks}/>
    </>
  );
}

export default StartStableDiffusion;
