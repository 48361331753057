import React, { useState } from 'react';
import { motion } from "framer-motion";
// import Header from '../header';
import './style.css';
import Header from '../header/headerlogo';
import { FaLock } from 'react-icons/fa';
import ccImages from '../assets/images/cc_v2.png';
import ccAuth from '../assets/images/secure90x72.gif';
import { Auth } from '../core/utils/auth';
import { GetCookie } from '../core/utils/cookies';
import axios from 'axios';
import { getPrice, getPricePlan } from '../core/utils/main';


function Payment() {
  const auth = Auth('/register');
  const [view_data] = useState(window.view_data ? window.view_data : {});
  const [isTrial] = useState(view_data.isUserTrial ? view_data.isUserTrial === 1 ? true : false : false);
  const [data] = useState(view_data.plan ? view_data.plan : null);
  if(auth === undefined || auth === false) return;
  if(!data) return;

  const tk = GetCookie("access");
  const merchant = auth.merchant.toLowerCase();

  const submitPayPal = () => {
    document.querySelector(".loader-container").classList.add('active');

    axios.post(`${process.env.REACT_APP_API_URL}/create-subscription-paypal`, {
      tk,
      plan_id: data.plan_id,
      upgrade: '1'
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;

      if(output.success) {
        window.location.replace(output.data.link);
        return;
      }

      document.querySelector(".loader-container").classList.remove('active');
      if(output.data) window.toastr.error(output.data.msg);
    });

  }

  const submitPayment = () => {
    document.querySelector(".loader-container").classList.add('active');
    axios.post(`${process.env.REACT_APP_API_URL}/update-subscription`, {
      tk,
      plan_id: data.plan_id
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;
      if(output.success) {
        window.toastr.success("Success");
        var old_plan = output.data.old_plan;
        var new_plan = output.data.new_plan;

        window.mixpanel.track("update_subscription", {
          currency: output.data.currency,
          action: view_data.update_action,
          old_plan: old_plan.plan_type + " - " + old_plan.payment_interval,
          new_plan: new_plan.plan_type + " - " + new_plan.payment_interval
        });
        
        if(view_data.update_action === 'cancel_downgrade'){
          axios.post(`${process.env.REACT_APP_API_URL}/save-survey`, {}).then((res2) => {
            let output2 = res2.data;
            if(output2.success){
              window.toastr.success("Survey submitted successfully!");
              window.location.href = '/thankyou';
              document.querySelector(".loader-container").classList.remove('active');
              return;
            }else{
              if(output2.data) {
                if(output.data.message) {
                  window.toastr.error(output.data.message);
                } else {
                  window.toastr.error(output.data.msg);
                }
              }
            }
          });
        }else{
          window.location.href = '/thankyou';
          document.querySelector(".loader-container").classList.remove('active');
          return;
        }
      }else{
        if(output.data) {
          if(output.data.message) {
            window.toastr.error(output.data.message);
          } else {
            window.toastr.error(output.data.msg);
          }
        }
      }
    });
  }
  
  const submitHandler = function(){
    console.log(merchant)
    if (merchant==='paypal'){
      submitPayPal();
    }else{
      submitPayment();
    }
  }

  return (
    <>
      <Header auth={auth} />
      { data ?
      <div className="Payment-upgrade bg-gray-100 min-h-[600px] flex">

          <div className="container mx-auto py-10">
            <div className="flex flex-col items-center py-10 lg:py-16">
              <div className="flex flex-wrap md:flex-wrap justify-center w-full">
                <div className="pay_right px-4 mb-8 md:w-2/5">
                  <h2 className="text-xl font-bold mb-4 py-10">Order Summary</h2>
                  <div className="flex">
                    <div className="mb-2 w-4/5 text-sm mr-4"><b className="text-md text-uppercase">{ data.plan_type_display } PLAN</b><br/>
                    { isTrial ? (<div>You will be charged {getPricePlan(data.currency, getPrice(data))} per { data.payment_interval && data.payment_interval.toLowerCase() === 'yearly' ? "year" : "month" } after trial ends.</div>) : <></> }
                    { data.display_txt3 ? data.display_txt3 : "Your subscription will renew monthly until you cancel it."}
                    </div>
                    <div className="font-bold mt-4 w-1/5">Total: { getPricePlan(data.currency, getPrice(data)) }</div>
                  </div>
                  <div className="flex">
                    <div className="mb-2 w-full text-sm">
                        <motion.button
                            className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg w-full my-4 proceed-pmt"
                            whileHover={{ backgroundColor: "#5997fd" }}
                            whileTap={{ scale: 0.9 }}
                            onClick={submitHandler}
                        >
                            Complete Purchase
                        </motion.button>
                    </div>
                  </div>
                  <div className="securecont border-t-2 border-gray-300 flex py-5">
                    <div className="securetext mb-2 text-sm w-1/2"><FaLock className="inline text-lg mr-1 text-orange-500 text-xs"/> Secure Checkout</div>
                    <div className="securelogo mb-2 text-sm w-1/2">
                      <img src={ccImages} alt="Secure Logo" className="cclogo inline"/>
                      <img src={ccAuth} alt="Authorize" className="text-center md:text-right py-2 w-20 mx-auto"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div> : "" }
    </>
  );
}

export default Payment;