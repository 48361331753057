import React, { useState } from 'react';
import './style.css';
import Header from '../header';
import { Auth } from '../core/utils/auth';
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
import axios from 'axios';
import { getPricePlan } from '../core/utils/main';

const PageOne = ({ selectedOptionPageOne, handleOptionChangePageOne, onNext, setSurveyData}) => {
  const [page2opt2, setPage2opt2] = useState('');
  const [page2opt3a, setPage2opt3a] = useState('');
  const [page2opt3b, setPage2opt3b] = useState('');
  const [page2opt3c, setPage2opt3c] = useState('');
  const [page2opt4, setPage2opt4] = useState('');
  const [page2opt4a, setPage2opt4a] = useState('');
  const [page2opt4b, setPage2opt4b] = useState('');
  const [page2opt4c, setPage2opt4c] = useState('');
  const [page2opt4d, setPage2opt4d] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const option2Ans = 'Missing functionalities';
  const option3Ans = 'Difficulty in navigating the site';
  const option4Ans = 'Technical issues (bugs, downtimes)';

  const handleopt2Change = (event) => { 
    setPage2opt2(event.target.value);
    setSurveyData((prevData) => ({
        Question1: {
          Answer: option2Ans,
          WhatfeaturesAreYoulookingFor: event.target.value 
        }
    }));
  };
  const handleopt3aChange = (event) => { 
    setPage2opt3a(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: option3Ans,
        WhichPartOfTheSiteWereYouHavingAHardTimeUsing: event.target.value 
      }
    }));
  };
  const handleopt3bChange = (event) => { 
    setPage2opt3b(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: option3Ans,
        WhichPartOfTheSiteWereYouHavingAHardTimeUsing: prevData.Question1.WhichPartOfTheSiteWereYouHavingAHardTimeUsing ? prevData.Question1.WhichPartOfTheSiteWereYouHavingAHardTimeUsing : '',
        WhichPartOfTheSiteWhereYouGotStuck: event.target.value 
      }
    }));
  };
  const handleopt3cChange = (event) => { 
    setPage2opt3c(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: option3Ans,
        WhichPartOfTheSiteWereYouHavingAHardTimeUsing: prevData.Question1.WhichPartOfTheSiteWereYouHavingAHardTimeUsing ? prevData.Question1.WhichPartOfTheSiteWereYouHavingAHardTimeUsing : '',
        WhichPartOfTheSiteWhereYouGotStuck: prevData.Question1.WhichPartOfTheSiteWhereYouGotStuck ? prevData.Question1.WhichPartOfTheSiteWhereYouGotStuck : '',
        WhatDoYouThinkWeCanImproveOn: event.target.value
      }
    }));
  };
  const handleopt4Change = (event) => { 
    setPage2opt4(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: option4Ans,
        WhatDeviceWereYouUsingWhenYouEncounteredAnIssue: event.target.value 
      }
    }));
  };
  const handleopt4aChange = (event) => { 
    setPage2opt4a(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: option4Ans,
        WhatDeviceWereYouUsingWhenYouEncounteredAnIssue: prevData.Question1.WhatDeviceWereYouUsingWhenYouEncounteredAnIssue ? prevData.Question1.WhatDeviceWereYouUsingWhenYouEncounteredAnIssue : '',
        WhatBrowserDidYyouUse: event.target.value
      }
    }));
  };
  const handleopt4bChange = (event) => { 
    setPage2opt4b(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: option4Ans,
        WhatDeviceWereYouUsingWhenYouEncounteredAnIssue: prevData.Question1.WhatDeviceWereYouUsingWhenYouEncounteredAnIssue ? prevData.Question1.WhatDeviceWereYouUsingWhenYouEncounteredAnIssue : '',
        WhatBrowserDidYyouUse: prevData.Question1.WhatBrowserDidYyouUse ? prevData.Question1.WhatBrowserDidYyouUse : '',
        WhatIssueDidYouEncounter: event.target.value
      }
    }));
  };
  const handleopt4cChange = (event) => { 
    setPage2opt4c(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: option4Ans,
        WhatDeviceWereYouUsingWhenYouEncounteredAnIssue: prevData.Question1.WhatDeviceWereYouUsingWhenYouEncounteredAnIssue ? prevData.Question1.WhatDeviceWereYouUsingWhenYouEncounteredAnIssue : '',
        WhatBrowserDidYyouUse: prevData.Question1.WhatBrowserDidYyouUse ? prevData.Question1.WhatBrowserDidYyouUse : '',
        WhatIssueDidYouEncounter: prevData.Question1.WhatIssueDidYouEncounter ? prevData.Question1.WhatIssueDidYouEncounter : '',
        PleaseSpecifyTheProcessStepsThatYouDidBeforeYouEncounteredTheIssue: event.target.value
      }
    }));
  };
  const handleopt4dChange = (event) => { 
    setPage2opt4d(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: option4Ans,
        WhatDeviceWereYouUsingWhenYouEncounteredAnIssue: prevData.Question1.WhatDeviceWereYouUsingWhenYouEncounteredAnIssue ? prevData.Question1.WhatDeviceWereYouUsingWhenYouEncounteredAnIssue : '',
        WhatBrowserDidYyouUse: prevData.Question1.WhatBrowserDidYyouUse ? prevData.Question1.WhatBrowserDidYyouUse : '',
        WhatIssueDidYouEncounter: prevData.Question1.WhatIssueDidYouEncounter ? prevData.Question1.WhatIssueDidYouEncounter : '',
        AreYouWillingToBeContactedSoWeCanAskMoreDetailsToResolveTheBug: event.target.value
      }
    }));
  };
  const handleOtherReasonChange = (event) => { 
    setOtherReason(event.target.value);
    setSurveyData((prevData) => ({
      Question1: {
        Answer: event.target.value,
      }
    }));
  };

  const isOption2SelectedWithNoReason = selectedOptionPageOne === 'option2' && page2opt2.trim() === '';
  const isOption3aSelectedWithNoReason = selectedOptionPageOne === 'option3' && page2opt3a.trim() === '';
  const isOption3bSelectedWithNoReason = selectedOptionPageOne === 'option3' && page2opt3b.trim() === '';
  const isOption3cSelectedWithNoReason = selectedOptionPageOne === 'option3' && page2opt3c.trim() === '';
  const isOption4SelectedWithNoReason = selectedOptionPageOne === 'option4' && page2opt4.trim() === '';
  const isOption4aSelectedWithNoReason = selectedOptionPageOne === 'option4' && page2opt4a.trim() === '';
  const isOption4bSelectedWithNoReason = selectedOptionPageOne === 'option4' && page2opt4b.trim() === '';
  const isOption4cSelectedWithNoReason = selectedOptionPageOne === 'option4' && page2opt4c.trim() === '';
  const isOption4dSelectedWithNoReason = selectedOptionPageOne === 'option4' && page2opt4d.trim() === '';
  const isOption5SelectedWithNoReason = selectedOptionPageOne === 'option5' && otherReason.trim() === '';
  const isButtonDisabled =
    selectedOptionPageOne === '' ||
    isOption2SelectedWithNoReason ||
    isOption3aSelectedWithNoReason ||
    isOption3bSelectedWithNoReason ||
    isOption3cSelectedWithNoReason ||
    isOption4SelectedWithNoReason ||
    isOption4aSelectedWithNoReason ||
    isOption4bSelectedWithNoReason ||
    isOption4cSelectedWithNoReason ||
    isOption4dSelectedWithNoReason ||
    isOption5SelectedWithNoReason;

  return (
    <div>
      <h2 className="font-bold py-4">Question 1 <span className="text-red-500">*</span></h2>
      <p className="mt-0 font-medium">What is your primary reason for cancelling your AI-Pro subscription? <span className="text-red-500">*</span></p>
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="option1" checked={selectedOptionPageOne === 'option1'} onChange={handleOptionChangePageOne}/>
        Too expensive
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="option2" checked={selectedOptionPageOne === 'option2'} onChange={handleOptionChangePageOne}/>
        Missing functionalities
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="option3" checked={selectedOptionPageOne === 'option3'} onChange={handleOptionChangePageOne}/>
        Difficulty in navigating the site
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="option4" checked={selectedOptionPageOne === 'option4'} onChange={handleOptionChangePageOne}/>
        Technical issues (bugs, downtimes)
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="option5" checked={selectedOptionPageOne === 'option5'} onChange={handleOptionChangePageOne}/>
        Other (please specify)
      </label>
      <br />
      <div className="my-8">
        {selectedOptionPageOne === 'option2' && (
          <>
            <label className="mt-4 font-medium">What features are you looking for? <span className="text-red-500">*</span></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={page2opt2}
              onChange={handleopt2Change}
            />
          </>
        )}

        {selectedOptionPageOne === 'option3' && (
          <>
            <label className="mt-4 font-medium">Which part of the site were you having a hard time using? <span className="text-red-500">*</span></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={page2opt3a}
              onChange={handleopt3aChange}
            />
            <label className="mt-4 font-medium">Which part of the site where you got stuck? <span className="text-red-500">*</span></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={page2opt3b}
              onChange={handleopt3bChange}
            />
            <label className="mt-4 font-medium">What do you think we can improve on? <span className="text-red-500">*</span></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={page2opt3c}
              onChange={handleopt3cChange}
            />
          </>
        )}

        {selectedOptionPageOne === 'option4' && (
          <>
            <div className="my-6">
              <label className="mt-4 font-medium">What device were you using when you encountered an issue? <span className="text-red-500">*</span></label>
              <label className="block"><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value='desktop' checked={page2opt4 === 'desktop'} onChange={handleopt4Change}/>
                  Desktop
              </label>
              <label className="block"><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value='mobile' checked={page2opt4 === 'mobile'} onChange={handleopt4Change}/>
                  Mobile
              </label>
              <label className="block"><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value='tablet' checked={page2opt4 === 'tablet'} onChange={handleopt4Change}/>
                  Tablet
              </label>
            </div>
            <label className="mt-4 font-medium">What browser did you use? <span className="text-red-500">*</span></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={page2opt4a}
              onChange={handleopt4aChange}
            />
            <label className="mt-4 font-medium">What issue did you encounter? <span className="text-red-500">*</span></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={page2opt4b}
              onChange={handleopt4bChange}
            />
            <label className="mt-4 font-medium">Please specify the process/steps that you did before you encountered the issue. <span className="text-red-500">*</span></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={page2opt4c}
              onChange={handleopt4cChange}
            />
            <label className="mt-4 font-medium">Are you willing to be contacted so we can ask more details to resolve the bug? <span className="text-red-500">*</span></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={page2opt4d}
              onChange={handleopt4dChange}
            />
          </>
        )}

        {selectedOptionPageOne === 'option5' && (
          <>
            <label className="mt-4 font-medium"></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={otherReason}
              onChange={handleOtherReasonChange}
            />
          </>
        )}
      </div>

      <div className="flex justify-end mt-4 sm:mt-10">
        <button
          className={`w-full sm:w-auto font-bold text-white px-8 py-2 rounded-md ${isButtonDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 cursor-pointer'}`}
          onClick={onNext}
          disabled={isButtonDisabled}
        >
          Next Question
        </button>
      </div>
    </div>
  );
};

const PageTwo = ({ selectedOptionPageTwo, handleOptionChangePageTwo, onNext, onPrevious, setSurveyData}) => {
  const [otherReason, setOtherReason] = useState('');
  const handleOtherReasonChange = (event) => { 
    setOtherReason(event.target.value);
    setSurveyData((prevData) => ({
      ...prevData,
      Question2: {
        Answer: event.target.value
      }
    }));
  };

  const isOption5SelectedWithNoReason = selectedOptionPageTwo === 'p2_option5' && otherReason.trim() === '';
  const isButtonDisabled = selectedOptionPageTwo === '' || isOption5SelectedWithNoReason;

  return (
    <div>
      <h2 className="font-bold py-4">Question 2<span className="text-red-500">*</span></h2>
      <p className="mt-0 font-medium">What features did not meet your needs? <span className="text-red-500">*</span></p>
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="p2_option1" checked={selectedOptionPageTwo === 'p2_option1'} onChange={handleOptionChangePageTwo}/>
        Chat prompts
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="p2_option2" checked={selectedOptionPageTwo === 'p2_option2'} onChange={handleOptionChangePageTwo}/>
        Image generator
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="p2_option3" checked={selectedOptionPageTwo === 'p2_option3'} onChange={handleOptionChangePageTwo}/>
        Text generator
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="p2_option4" checked={selectedOptionPageTwo === 'p2_option4'} onChange={handleOptionChangePageTwo}/>
        Sharing/Downloading Functionalities
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="p2_option5" checked={selectedOptionPageTwo === 'p2_option5'} onChange={handleOptionChangePageTwo}/>
        Other (please specify)
      </label>
      <br />
      <div className="my-8">
        {selectedOptionPageTwo === 'p2_option5' && (
          <>
            <label className="mt-4 font-medium"></label>
            <textarea type="text" className="border border-gray-400 p-2 rounded-md mt-1 mb-6 w-full h-[80px]"
              value={otherReason}
              onChange={handleOtherReasonChange}
            />
          </>
        )}
      </div>

      <div className="flex sm:absolute mt-2">
        <button
          className='w-full sm:w-auto font-bold text-white px-8 py-2 rounded-md bg-black cursor-pointer'
          onClick={onPrevious}
        >
          Previous Question
        </button>
      </div>

      <div className="flex justify-end mt-4 sm:mt-10">
        <button
          className={`w-full sm:w-auto font-bold text-white px-8 py-2 rounded-md ${isButtonDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 cursor-pointer'}`}
          onClick={onNext}
          disabled={isButtonDisabled}
        >
          Next Question
        </button>
      </div>
    </div>
  );
};

const PageThree = ({ auth, selectedOptionPageThree, setSelectedOptionPageThree, handleOptionChangePageThree, onPrevious, onSubmit, onPay }) => {
  const isOption1Selected = selectedOptionPageThree === 'p3_option1';
  const isButtonDisabled = selectedOptionPageThree === '' || isOption1Selected;
  const handleCloseModal = () => {
    setSelectedOptionPageThree('');
  };

  let downgrade_price = '5';
  if (auth.currency==='SAR' ){
    downgrade_price = '20';
  }else if (auth.currency==='AED' ){
    downgrade_price = '20';
  }

  return (
    <div>
      <h2 className="font-bold py-4">Question 3 <span className="text-red-500">*</span></h2>
      <p className="mt-0 font-medium">If we offer our subscription plan at a discounted rate, will you be interested in continuing using AI-PRO? <span className="text-red-500">*</span></p>
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="p3_option1" checked={isOption1Selected} onChange={handleOptionChangePageThree}/>
        Yes
      </label>
      <br />
      <label><input type="radio" className="mx-2 mr-4 my-4 sm:mx-6" value="p3_option2" checked={selectedOptionPageThree === 'p3_option2'} onChange={handleOptionChangePageThree}/>
        No
      </label>
      <br />
      <div className="my-8 relative text-center z-[99999]">
        {selectedOptionPageThree === 'p3_option1' && (
          <>
            <div className={`popup-overlay fixed top-0 left-0 w-full h-full bg-black opacity-50 ${selectedOptionPageThree === 'p3_option1' ? 'block' : 'hidden'}`}
              onClick={handleCloseModal}
            />
            <div className={`popup-modal fixed w-[80%] md:w-[600px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 sm:p-8 rounded-md shadow-lg ${selectedOptionPageThree === 'p3_option1' ? 'block' : 'hidden'}`}>
              <div className="text-[36px] font-bold text-black mb-4">
               We Hate To See You Go
              </div>
              <p className="text-md">Here’s a <b>special offer</b> just for you!<br/>
              Downgrade to <span className="text-gradient whitespace-nowrap font-bold">BASIC</span> plan for only</p>
              <p className="text-xl pt-[30px] pb-[20px]"><span className="text-6xl font-extrabold">{getPricePlan((auth.currency), downgrade_price)}</span> /mo.</p>
              <motion.button
                className="w-full sm:w-auto font-bold text-white px-8 py-2 rounded-xl bg-black hover:bg-gray-700 cursor-pointer shadow"
                onClick={onPay}
                whileHover={{ scale: 1.1 }}
              >
                Unlimited access to our Chatbot apps
              </motion.button>
              <p className="text-xs text-center pt-4">By downgrading to BASIC plan, your subscription's start date will reset immediately. This action will also result in the cancellation of your existing plan.</p>
            </div>

          </>
        )}
      </div>
      <div className="flex sm:absolute mt-2">
        <button
          className='w-full sm:w-auto font-bold text-white px-8 py-2 rounded-md bg-black cursor-pointer'
          onClick={onPrevious}
        >
          Previous Question
        </button>
      </div>
      <div className="flex justify-end mt-4 sm:mt-10">
        <button
          className={`w-full sm:w-auto font-bold text-white px-8 py-2 rounded-md ${isButtonDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 cursor-pointer'}`}
          onClick={onSubmit}
          disabled={isButtonDisabled}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

function Survey() {

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOptionPageOne, setSelectedOptionPageOne] = useState('');
  const [selectedOptionPageTwo, setSelectedOptionPageTwo] = useState('');
  const [selectedOptionPageThree, setSelectedOptionPageThree] = useState('');
  const [surveyData, setSurveyData] = useState({});
  const auth = Auth();

  if(auth === undefined) return;
  if(auth === false) {
    window.location.href = '/login';
    return;
  }

  if((auth.surveydata !== '' && auth.surveydata !== null) || auth.status !== 'active') {
      window.location.href = 'my-account'
      return;
  }

  const handlePay = () => {
    
    switch(auth.currency.toLowerCase()) {
      case 'usd':
        saveData('/downgrade/23',true);
      break;
      case 'eur':
        saveData('/downgrade/24',true);
      break;
      case 'gbp':
        saveData('/downgrade/25',true);
      break;
      case 'brl':
        saveData('/downgrade/26',true);
      break;
      case 'sar':
        saveData('/downgrade/57',true);
      break;
      case 'aed':
        saveData('/downgrade/58',true);
      break;
      default: saveData('/downgrade/23',true);
    }
  };

  const handlePrevious = () => {
    if (currentPage === 1 && selectedOptionPageOne === '' && selectedOptionPageTwo === '' && selectedOptionPageThree === '') {
      return;
    }
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNext = () => {
    if (currentPage === 1 && selectedOptionPageOne === '' && selectedOptionPageTwo === '' && selectedOptionPageThree === '') {
      return;
    }
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleOptionChangePageOne = (event) => {
    setSelectedOptionPageOne(event.target.value);
    
    const val = event.target.nextSibling !== null ? event.target.nextSibling.textContent : '';
    setSurveyData((prevData) => ({
        Question1: {
          Answer: val
        }
    }));
  };

  const handleOptionChangePageTwo = (event) => {
    setSelectedOptionPageTwo(event.target.value);
    const val = event.target.nextSibling !== null ? event.target.nextSibling.textContent : '';
    setSurveyData((prevData) => ({
      ...prevData,
      Question2: {
        Answer: val
      }
    }));
  };

  const handleOptionChangePageThree = (event) => {
    setSelectedOptionPageThree(event.target.value);
    const val = event.target.nextSibling !== null ? event.target.nextSibling.textContent : '';
    setSurveyData((prevData) => ({
      ...prevData,
      Question3: {
        Answer: val
      }
    }));
  };

  const handleSubmit = () => {
    document.querySelector(".loader-container").classList.add('active');
    saveData();
    cancelUser();
  };

  function saveData(loc,saveTosession){
    const save_session = saveTosession ? '1' : '0'; 
    const data = JSON.stringify(surveyData);
    axios.post(`${process.env.REACT_APP_API_URL}/save-survey`, {
      data, save_session:save_session
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res){
      let output = res.data;

      if(output.success) {
        if(loc){
          window.location.href = loc;
          return;
        }else{
          window.toastr.success("Survey submitted successfully!");
        }
      }else{
        window.toastr.error(output.data);
        return;
      }
    });
  }

  function cancelUser() {
    axios.post(`${process.env.REACT_APP_API_URL}/cancel-subscription`, {
      }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res){
        let output = res.data;
        if(output.success) {
          window.toastr.success("Subscription cancelled successfully!");
          setTimeout(function(){
              document.querySelector(".loader-container").classList.remove('active');
            window.location.href = '/my-account';
          }, 3000);
          return;
        }
        if(output.data) window.toastr.error(output.data.msg);
      });

  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>AI-Pro | Cancellation Survey</title>
        <meta name="description" content="Cancellation Survey" />
      </Helmet>
      <Header auth={auth} />
      <div className="survey bg-white flex justify-center items-center mt-10 md:mt-20 lg:mt-26 pt-10 md:pt-28">
        <div className="container mx-auto py-4 px-0 md:px-4 sm:px-0 w-full mx-auto">
          <div className="max-w-6xl mx-auto p-4 sm:p-8">
            <div className="pt-2 sm:pt-10 md:p-8">
            <div className="block p-4 sm:p-8 rounded-md border-2 bg-white">
                <h1 className="text-lg sm:text-3xl font-bold text-left mb-6">Cancellation Survey</h1>
                {currentPage === 1 && 
                  <PageOne 
                    selectedOptionPageOne={selectedOptionPageOne} 
                    handleOptionChangePageOne={handleOptionChangePageOne}
                    surveyData={surveyData}
                    setSurveyData={setSurveyData}
                    onNext={handleNext} 
                  />}
                {currentPage === 2 && 
                  <PageTwo 
                    selectedOptionPageTwo={selectedOptionPageTwo} 
                    handleOptionChangePageTwo={handleOptionChangePageTwo}
                    setSurveyData={setSurveyData}  
                    onPrevious={handlePrevious}
                    onNext={handleNext} 
                  />}
                {currentPage === 3 && 
                  <PageThree 
                    selectedOptionPageThree={selectedOptionPageThree}  
                    setSelectedOptionPageThree={setSelectedOptionPageThree} 
                    handleOptionChangePageThree={handleOptionChangePageThree} 
                    onPrevious={handlePrevious} 
                    onSubmit={handleSubmit} 
                    onPay={handlePay}
                    auth={auth} 
                  />}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Survey;