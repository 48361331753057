import { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import axios from 'axios';
import aiproLogo from '../assets/images/AIPRO.png';
import { FaInfoCircle } from 'react-icons/fa';
import { Auth } from '../core/utils/auth';

var price_per_member = '0';
var user_id = '';

export function AddMoreMemberModal ({showAddMoreMember, setshowAddMoreMember, setMoreToAddMember, setMoreToAddMemberTotalAmount, setShowCompletePurchase}) {
  const [membersToAdd, setMembersToAdd] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);

  const auth = Auth();

  useEffect(() => {
    if (auth !== undefined && auth.plan==='enterprise'){
      price_per_member = auth.price_per_member;
    }
  }, [auth]);


  const modalAddMoreMembersOpen = () => {
    let modal = document.getElementById("modalAddMoreMembers");		
    if (modal!==null){
      modal.style.display = "block";
    }
  }

  const modalAddMoreMembersClose = () => {
    let modal = document.getElementById("modalAddMoreMembers");		
    if (modal!==null){
      modal.style.display = "none";		 
      setshowAddMoreMember(false);
    }
  }

  const handlePlus = () => {
    setMembersToAdd(membersToAdd+1);
  }

  const handleMinus = () => {
    if (membersToAdd>1){
      setMembersToAdd(membersToAdd-1);
    }
  }

  useEffect(() => {
    let amount = membersToAdd * price_per_member;
    setTotalAmount(amount);
  }, [membersToAdd]);

  useEffect(() => {
    setMembersToAdd(1);

    let amount = membersToAdd * price_per_member;
    setTotalAmount(amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [showAddMoreMember]);

  const handleUupgradeNow = () => {
    setMoreToAddMember(membersToAdd);
    setMoreToAddMemberTotalAmount(totalAmount);

    setshowAddMoreMember(false);
    setShowCompletePurchase(true)
  }

  if (showAddMoreMember!==undefined && showAddMoreMember === true){
    modalAddMoreMembersOpen();
  }
  if (showAddMoreMember!==undefined && showAddMoreMember === false){
    modalAddMoreMembersClose();
  }

  return (
    <>
		<div id="modalAddMoreMembers" className="modal z-[9999]">
			<div class="modal-content w-full md:w-[60%] p-3 md:p-4">
        <div>
          <div className="mb-4 flex border-b pb-2">
            <div className="text-blue-500 w-full md:w-2/3 mr-2 md:mr-5 font-bold">
            Add More Members
            </div>
            <div className="float-right mt-[-5px] w-full md:w-1/3">
              <span class="close" onClick={()=> modalAddMoreMembersClose()}>&times;</span>
            </div>
          </div>
          <div className="p-2 md:p-4 border rounded text-sm text-center">
            <div>Your enterprise account has hit its maximum user capacity.</div>
            <div>Add more members to your Enterprise Account.</div>
            <div className="py-4 text-center">
              <span className="font-bold text-[12px] sm:text-sm inline px-1 sm:px-2">Add</span>
              <div className="border rounded px-2 py-4 inline">
                <input className="bg-blue-500 rounded px-3 py-2 m-2 text-white cursor-pointer" type="button" value="-" onClick={()=>handleMinus()}/>
                <span className="text-blue-500 p-2 mx-auto font-bold">{membersToAdd}</span>
                <input className="bg-blue-500 rounded px-3 py-2 m-2 text-white cursor-pointer" type="button" value="+" onClick={()=>handlePlus()} />
              </div>
              <span className="font-bold text-[12px] sm:text-sm inline px-1 sm:px-2">Member/s</span>
            </div>
            <div className="font-bold text-sm">
              Total Amount: ${totalAmount}
            </div>
            <div className="font-bold text-sm">
              PER YEAR
            </div>
            <div>
              <motion.button
              className="bg-sky-600 w-full md:w-70 text-white font-bold my-4 py-2 px-6 rounded proceed-pmt"
              whileHover={{ backgroundColor: "#49b1df" }}
              whileTap={{ scale: 0.9 }}
              onClick={()=> handleUupgradeNow()}
              >
              UPGRADE NOW
              </motion.button>
            </div>
          </div>
        </div>
			</div>
		</div>
    </>
  );
}

export function MemberCompletePurchase ({moreToAddMember, moreToAddMemberTotalAmount, setShowCompletePurchase, showCompletePurchase}) {

  const auth = Auth();

  useEffect(() => {
    if (auth !== undefined && auth.plan==='enterprise'){
      user_id = auth.user_id;
    }
  }, [auth]);

  const modalOpen = () => {
    let modal = document.getElementById("modalComplete");		
    if (modal!==null){
      modal.style.display = "block";		
    }
  }

  const modalClose = () => {
    let modal = document.getElementById("modalComplete");		
    if (modal!==null){
      modal.style.display = "none";		 
      setShowCompletePurchase(false);
    }
  }

  const submitPaymentInformation = () => {
    window.location.href = '/payment-reference';
    return;
  }

  const sendViaEmail = () => {
    var members = moreToAddMember;
    var total_amount = moreToAddMemberTotalAmount;
    var url = `${process.env.REACT_APP_API_URL}/t/send-enterprise-payment-info`;

    modalClose();    
    document.querySelector(".loader-container").classList.add('active');

    axios.post(url, {
      members,
      total_amount
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;

      document.querySelector(".loader-container").classList.remove('active');

      if(output.success) {
        window.toastr.success("Email sent to "+output.data);
      }else{
        window.toastr.error("Email Failed.");
      }
    }).catch(function (error) {
      document.querySelector(".loader-container").classList.remove('active');
      if (error.response && error.response.status===429) {
        document.querySelector(".loader-container").classList.remove('active');
        window.toastr.error("Sorry, too many requests. Please try again in a bit!");
      }
    });
  }

  if (showCompletePurchase!==undefined && showCompletePurchase === true){
    modalOpen();
  }
  if (showCompletePurchase!==undefined && showCompletePurchase === false){
    modalClose();
  }

  return (
    <>
    <div id="modalComplete" className="modal z-[9999]">
      <div class="w-full md:w-[600px] border-[#888] md:mt-[15px] mx-[auto] bg-[#fefefe] p-6">
        <span class="close" onClick={()=> modalClose()}>&times;</span>
        <div className="border-b pb-[10px] border-[#d5d5d5]"><img src={aiproLogo} alt="AI-Pro Logo" className="aiprologo mx-auto"/></div>
        <h1 className="font-bold text-center p-2 text-gray-700 text-[20px] md:text-[24px]">Payment Details<br/>for Enterprise Order</h1>
        <div className="text-center text-[12px] md:text-[14px]">Adding more than 10 Enterprise users requires prior payment.</div>
        <div className="text-center text-[12px] md:text-[14px]">Please use the provided payment details below to settle your Enterprise account.</div>
        <div className="py-2">
          <div className="font-bold text-[11px]">No. of Members: {moreToAddMember}</div>
          <div className="font-bold text-[11px]">Enterprise - Total: ${moreToAddMemberTotalAmount}</div>
        </div>
        <div className="border rounded p-2 text-[12px] md:text-[14px] leading-7 my-2">
          <div className="font-bold">Bank Information</div>
          <div className="float-right text-blue-400 font-bold cursor-pointer mt-[-28px] text-[12px]" onClick={()=> sendViaEmail()}>Send via Email</div>
          <div><span className="font-bold">Beneficiary:</span> TELECOM BUSINESS SOLUTIONS INC.</div>
          <div><span className="font-bold">SWIFT:</span> BOFAUS3N</div>
          <div><span className="font-bold">Bank Name:</span> Bank of America</div>
          <div><span className="font-bold">Routing (Wire):</span> 026009593</div>
          <div><span className="font-bold">Routing Number (Paper & Electronic):</span> 021200339</div>
          <div><span className="font-bold">Account Number:</span> 3810-6766-2647</div>
          <div><span className="font-bold">Customer Number:</span> {user_id}</div>
          <div className="bg-[#dddddd] px-4 py-2 rounded text-center mt-4"><FaInfoCircle className="inline text-lg mr-2"/>Customer Number must be included in the bank transfer description field for your funds to transfer successfully.</div>
        </div>

        <div className="text-center text-[12px] md:text-[14px] mt-4">
          Once the payment is received, our dedicated account manager will contact you to assist in the seamless setup of your Enterprise account.
        </div>
        <div className="text-center text-[12px] md:text-[14px]">
          Please allow <b>2-3 banking days</b> for the payment to reflect in the account.
        </div>
        <div className="text-center">
          <motion.button
            className="bg-blue-500 text-white font-bold py-3 px-4 rounded my-4 proceed-pmt"
            whileHover={{ backgroundColor: "#5997fd" }}
            whileTap={{ scale: 0.9 }}
            onClick={submitPaymentInformation}
          >
            Send Payment Confirmation
          </motion.button>
        </div>
      </div>
    </div>    
    </>
  )
}
