import React, { useEffect, useState } from 'react';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import AiArts from '../aiapps/aiarts';
// import AiEducations from '../aiapps/aieducations';
import AiChatbots from '../aiapps/aichatbots';
import { Auth } from '../core/utils/auth';
import { GetCookie, SetCookie, RemoveCookie } from '../core/utils/cookies';
import { FaArrowRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { AddMoreMemberModal } from '../modal/enterprise';
import { MemberCompletePurchase } from '../modal/enterprise';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';

const { getSignedUrl } = require("@aws-sdk/s3-request-presigner");

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

async function checkBanner() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-banner`, {
  }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })

  const output = response.data;
  if (output.success) {
    const banner_data = output.data[0].banner_content;
    return banner_data;
  } else {
    return '';
  }
}

function MyAccount() {
  const auth = Auth();
  const feat_request = 'https://pages.ai-pro.org/request-feature';
  const [downAppBanner, setDownAppBanner] = useState(false);
  const [showTokenMaxoutWarning, setshowTokenMaxoutWarning] = useState(current => false);
  const [showTokenMaxoutFinal, setshowTokenMaxoutFinal] = useState(current => false);

  useEffect(() => {
    async function fetchBanner() {
      const fetchedBanner = await checkBanner();
      if (fetchedBanner !== undefined) {
        setDownAppBanner(fetchedBanner);
      }
    }
    fetchBanner();
  }, []);

  useEffect(() => {
    if (auth !== undefined && auth !== false) {
      let max_token = auth.max_tokens;
      let total_usage = auth.total_usage;
      let eightyPercentOfToken = (max_token * 0.8);

      if (eightyPercentOfToken <= total_usage) {
        setshowTokenMaxoutWarning(current => true);
        setshowTokenMaxoutFinal(current => false);
      }
      if (max_token <= total_usage) {
        setshowTokenMaxoutWarning(current => false);
        setshowTokenMaxoutFinal(current => true);
      }
    };
  }, [auth]);

  useEffect(() => {
    setTimeout(() => {
      const isPro = GetCookie('user_plan');
      const avatarMaker = GetCookie('avatarmaker');
      const appUrl = GetCookie('appurl');

      if (appUrl && (avatarMaker && appUrl.includes('avatar') && isPro === 'pro')) {
        const path = `avatarmaker/${process.env.REACT_APP_AWS_ENV}/${avatarMaker}/image_0.png`;
        const command = new GetObjectCommand({
          Bucket: process.env.REACT_APP_AWS_BUCKET,
          Key: path,
        });
        getSignedUrl(s3Client, command)
          .then((url) => {
            if (url !== '') {
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'highresimage.png');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          })
          .catch((error) => console.log('Error:', error));
        RemoveCookie('avatarmaker', { domain: '.ai-pro.org', path: '/' });
      }
    }, 500);
  }, [])

  const [showAddMoreMember, setshowAddMoreMember] = useState(false);
  const [showCompletePurchase, setShowCompletePurchase] = useState(false);
  const [moreToAddMember, setMoreToAddMember] = useState("1");
  const [moreToAddMemberTotalAmount, setMoreToAddMemberTotalAmount] = useState("0");

  var ShowUpgrade = false;
  var ShowExpiredEnterprise = false;
  var isEnterprise = false;
  var entParentUserID = "";

  if (auth === undefined) return;
  if (auth === false) {
    window.location.href = '/login';
    return;
  } else {
    if (auth.expired === 'yes') {
      ShowUpgrade = true;

      if (auth.plan === 'enterprise') {
        ShowExpiredEnterprise = true;
        entParentUserID = auth.ent_parent_user_id
      }
    } else {
      if (auth.plan === 'enterprise') {
        isEnterprise = true;
      }
    }
  }

  const handleUpgradeClick = () => {
    const DEFAULT_PPG = process.env.REACT_APP_DEFAULT_PPG ? process.env.REACT_APP_DEFAULT_PPG : "14";
    const currency = auth.currency.toLowerCase();

    SetCookie('reactivateSubscription', "reactivateSubscription");
    SetCookie('reactivateSubscription', "reactivateSubscription", { path: '/' });
    SetCookie('reactivateSubscription', "reactivateSubscription", { path: 'ai-pro.org' });
    SetCookie('reactivateSubscription', "reactivateSubscription", { path: '.ai-pro.org' });

    const emailid = process.env.REACT_APP_EMAILID || "comeback5sys";
    SetCookie('emailid', emailid);
    SetCookie('emailid', emailid, { path: '/' });
    SetCookie('emailid', emailid, { path: 'ai-pro.org' });
    SetCookie('emailid', emailid, { path: '.ai-pro.org' });

    if (currency === 'gbp') {
      window.location.href = '/pricing/?ppg=24&pmt=st';
    } else if (currency === 'eur') {
      window.location.href = '/pricing/?ppg=26&pmt=st';
    } else if (currency === 'brl') {
      window.location.href = '/pricing/?ppg=28&pmt=st';
    } else if (currency === 'sar') {
      window.location.href = '/pricing/?ppg=30&pmt=st';
    } else if (currency === 'aed') {
      window.location.href = '/pricing/?ppg=32&pmt=st';
    } else {
      window.location.href = '/pricing/?ppg=' + DEFAULT_PPG;
    }
    return;
  };



  return (
    <>
      <Helmet>
        <title>AI Pro | Member's Area</title>
        <meta name="description" content="Welcome to your Dashboard, your central hub to access key information and manage all your activities in one place. Stay organized and in control." />
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      </Helmet>
      <Header auth={auth} setshowAddMoreMember={setshowAddMoreMember} />
      <div className="myaccount bg-gray-100 min-h-[500px] flex items-center">
        <div className="container mx-auto py-10 px-0">
          <div className="max-w-6xl mx-auto py-8 px-2 md:p-8 md:flex md:flex-wrap">
            <h1 className="text-2xl font-bold text-blue-600 mt-6 mb-0 text-center md:text-left md:inline">
            </h1>
            {(ShowUpgrade === true && ShowExpiredEnterprise === false) ?
              <>
                <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5">
                  <span>
                    Important Account Update: Your Subscription Has Expired or Is Currently Inactive. <span onClick={handleUpgradeClick} className="pointer"> Reactivate</span> Now for Uninterrupted Access and Enjoyment!
                  </span>
                </div>
              </>
              : (ShowUpgrade === true && ShowExpiredEnterprise === true && entParentUserID === '') ?
                <>
                  <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5">
                    <span>
                      Important Account Update: Your subscription is either expired or currently inactive. For continuous access, kindly reach out to our <a href="https://ai-pro.org/contact-us/" rel="noreferrer" target='_blank'><span className="pointer">support team</span></a>.
                    </span>
                  </div>
                </>
                : (ShowUpgrade === true && ShowExpiredEnterprise === true && entParentUserID !== '') ?
                  <>
                    <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5">
                      <span>
                        Important Account Update: Your subscription Has Expired or Is Currently Inactive. Please contact your account administrator for uninterrupted access.
                      </span>
                    </div>
                  </>

                  : (auth.status === 'active' && showTokenMaxoutWarning === true && isEnterprise !== true) ?
                    <>
                      <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5">
                        <span>
                          <strong>Token Limit Warning:</strong> You're just a few tokens away from reaching you limit for this month.&nbsp;
                          <a href="/upgrade/?mx=1" className="text-[#3b82f6]"><strong>UPGRADE NOW</strong></a> to continue accessing and enjoying our services without interruption.
                        </span>
                      </div>
                    </>
                    : (auth.status === 'active' && showTokenMaxoutFinal === true && isEnterprise !== true) ?
                      <>
                        <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5">
                          <span>
                            <strong>Token Limit Exceeded:</strong> You have reached your token limit for this month.&nbsp;
                            <a href="/upgrade/?mx=1" className="text-[#3b82f6]"><strong>UPGRADE NOW</strong></a> to continue accessing and enjoying our services without interruption.
                          </span>
                        </div>
                      </>
                      : (auth.status === 'active' && showTokenMaxoutWarning === true && isEnterprise === true) ?
                        <>
                          <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5">
                            <span>
                              <strong>Token Limit Warning:</strong> You're just a few tokens away from reaching you limit for this month.&nbsp;
                              <a href="https://ai-pro.org/contact-us/" className="text-[#3b82f6]"><strong>CONTACT US</strong></a> to continue accessing and enjoying our services without interruption.
                            </span>
                          </div>
                        </>
                        : (auth.status === 'active' && showTokenMaxoutFinal === true && isEnterprise === true) ?
                          <>
                            <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5">
                              <span>
                                <strong>Token Limit Exceeded:</strong> You have reached your token limit for this month.&nbsp;
                                <a href="https://ai-pro.org/contact-us/" className="text-[#3b82f6]"><strong>CONTACT US</strong></a> to continue accessing and enjoying our services without interruption.
                              </span>
                            </div>
                          </>

                          : (
                            downAppBanner ?
                              <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5 info-banner">
                                <div dangerouslySetInnerHTML={{ __html: downAppBanner }} />
                              </div> : <></>
                            //   <div className="p-6 bg-[#f7ead9] block w-full rounded-md mt-5">
                            //     <div>Happy to see you back! Unfortunately, our <b>{ downAppBanner === 'chat' ? 'ChatGPT tools' : 'Stable Diffusion-powered tools' }</b> are experiencing issues due to a global ChatGPT outage. We expect to be fully online shortly.</div>
                            //     <div className="mt-5">In the mean time, check out our <b>{ downAppBanner === 'sd' ? 'ChatGPT powered tools' : 'Stable Diffusion-powered tools' }</b> to discover other fun things to do here in AI-Pro.</div>
                            //   </div>
                          )}

            {auth.plan && auth.status === 'active' ? <>
              <div id="tp-container" className="flex flex-row text-center md:text-left w-[205px] mx-auto md:mx-0 md:mt-[10px]">
                <div className='flex flex-col justify-start'>
                  {/* <!-- TrustBox widget - Mini -->  */}
                  <div id="tp-star-container">
                    <div class="trustpilot-widget" data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="63f8938353044ed29109ad33" data-style-height="150px" data-style-width="100%" data-theme="light">
                      <a href="https://www.trustpilot.com/review/ai-pro.org" target="_blank" rel="noreferrer">Trustpilot</a>
                    </div>
                  </div>
                  {/* <!-- End TrustBox widget --> */}
                </div>
                <div className='flex flex-col justify-start'>
                  <div className="trustpilot-widget ml-[-10px] sm:ml-[-15px] mt-[27px] h-[40px]" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="63f8938353044ed29109ad33" data-style-height="52px" data-style-width="225px" data-font-family="Poppins" data-border-color="#00b67a">
                    <a href="https://www.trustpilot.com/review/ai-pro.org" target="_blank" rel="noreferrer">Trustpilot</a>
                  </div>
                </div>
              </div>
            </> : <></>}
            <span className="text-sm text-center md:text-right md:ml-auto font-bold py-8 hidden md:inline">Your headquarters for powerful AI apps.</span>
            <AiChatbots auth={auth} />
            <AiArts auth={auth} />
            {/*<AiEducations />*/}
            <div className="bg-gray-100 flex items-center mt-4 md:mt-0 w-full">
              <div className="container mx-auto px-4 sm:px-0">
                <div className="mx-auto pb-5">
                  <div className="bg-white drop-shadow-sm p-6 rounded-tr-md rounded-br-md rounded-bl-md flex flex-col">
                    <div className="apptitle block">
                      <h1 className="text-xl font-bold text-gray-800">
                        Feature Request
                      </h1>
                    </div>
                    <div className="container flex flex-wrap">
                      <div className="w-full sm:w-1/2 lg:w-3/4 p-4">
                        <span className="text-[14px] text-left ml-auto">Is there a feature you are looking for or need for your business? Tell us about it.
                        </span>
                      </div>
                      <div className="w-full sm:w-1/2 lg:w-1/4 p-4 text-right flex flex-col">
                        <a
                          className="bg-blue-500 text-blue-500 bg-white border border-blue-500 text-left text-[12px] font-bold py-2 pl-3 pr-6 w-full rounded-md"
                          style={{ textDecoration: 'none' }}
                          href={feat_request}
                          target="_blank"
                          rel="noreferrer"
                          role="button"
                          whileHover={{ backgroundColor: "#5997fd" }}
                          whileTap={{ scale: 0.9 }}
                        >
                          Send a Request <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddMoreMemberModal showAddMoreMember={showAddMoreMember} setshowAddMoreMember={setshowAddMoreMember} setMoreToAddMember={setMoreToAddMember} setMoreToAddMemberTotalAmount={setMoreToAddMemberTotalAmount} setShowCompletePurchase={setShowCompletePurchase} />
      <MemberCompletePurchase moreToAddMember={moreToAddMember} moreToAddMemberTotalAmount={moreToAddMemberTotalAmount} setShowCompletePurchase={setShowCompletePurchase} showCompletePurchase={showCompletePurchase} />
      <Footer auth={auth} />
    </>
  );
}

export default MyAccount;