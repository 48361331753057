import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from "react-query";
import './style.css';
import { motion } from "framer-motion";
import Header from '../header';
import Footer from '../footer';
import { Auth } from '../core/utils/auth';
import { GetCookie, RemoveCookie } from '../core/utils/cookies';
import { AiFillWarning } from "react-icons/ai";
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { getPricePlan, formatDate, formatDateTime, diffMin } from '../core/utils/main';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Helmet } from 'react-helmet';
import { install } from "resize-observer";
import { AddMoreMemberModal } from '../modal/enterprise';
import { MemberCompletePurchase } from '../modal/enterprise';
import imgWarning from '../assets/images/warning-icon.png';

var user_subscription = [];
var user_order = [];
var date_now = "";
var ent_max_members = 0;

function Manage() {
  const [enterpriseMembers, setEnterpriseMembers] = useState([]);
  const [tokenUsage, setTokenUsage] = useState([]);
  const [userMaxToken, setUserMaxToken] = useState(0);
  const [tokenUsageTotal, setTokenUsageTotal] = useState(0);
  const [currentDate, setCurrentDate] = useState("");
  const [activeTab, setActiveTab] = useState('subscription');
  const [isShowEnterprise, setisShowEnterprise] = useState(false);
  const [showEditEnterprise, setShowEditEnterprise] = useState(false);
  const [editMemberDetails, setEditMemberDetails] = useState([]);

  const [showAddMember, setshowAddMember] = useState(false);
  const [showAddMoreMember, setshowAddMoreMember] = useState(false);
  const [showCompletePurchase, setShowCompletePurchase] = useState(false);
  const [moreToAddMember, setMoreToAddMember] = useState("");
  const [moreToAddMemberTotalAmount, setMoreToAddMemberTotalAmount] = useState("");
  const [entParentUserID, setEntParentUserID] = useState("");
  const [currentPlan, setCurrentPlan] = useState("");

  const auth = Auth();

  useEffect(() => {
    if (auth !== undefined && auth.plan==='enterprise'){
      ent_max_members = auth.max_members;
      setisShowEnterprise(true);
    }
    if (auth !== undefined){
      let ent_parent_user_id = auth.ent_parent_user_id === null ? '' : auth.ent_parent_user_id;
      setEntParentUserID(ent_parent_user_id)
      setUserMaxToken(auth.max_tokens);
      setCurrentDate(auth.max_end);
      setCurrentPlan(auth.plan);
      if (window.view_data.active_tab !== 'mem' ) { setActiveTab('account'); }
    } else if (window.view_data.active_tab === 'mem' ) {
      setActiveTab('members');
    }
  }, [auth]);

  useEffect(() => {
    const fetchData = async () => {
      let entMembers = await getEnterpriseMembers();
      setEnterpriseMembers(entMembers);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchTokens = async () => {
      let tokens = await getTokenUsage();
      console.log("tokens",tokens)
      setTokenUsage(JSON.parse(tokens.data))
      setTokenUsageTotal(tokens.total)
    }
    fetchTokens();
  }, []);

  

  if(auth === undefined) return;
  if(auth === false) {
    window.location.href = '/login';
    return;
  }
  if(user_subscription.length === 0) getSubscription();
  if(user_order.length === 0) getOrder();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

	const handleShowAddMember = () => {
    if (enterpriseMembers.length>=ent_max_members){
      setshowAddMoreMember(true)
    }else{
      setshowAddMember(true);
    }
	}

  const handleReloadMembers = async () => {
    let entMembers = await getEnterpriseMembers();
    setEnterpriseMembers(entMembers);
  }

  const handleShowEditMemberModal = (showEditEnterprise, params) => {
    setShowEditEnterprise(true);
    setEditMemberDetails(params)
  }

  async function getTokenUsage() {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-token-usage`, {
      'tk': GetCookie('access')
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
  
    const output = response.data ? response.data : [];
    if(output.success) {
      return output;
    } else {
      return [];
    }
  }
  

  install();
  return (
    <>
      <Helmet>
        <title>AI Pro | Manage Account</title>
        <meta name="description" content="Take control of your account with ease through our My Account page. Review billing details, update contact information, and tailor your preferences." />
      </Helmet>
      <Header auth={auth} setshowAddMoreMember={setshowAddMoreMember} />
      <div className="Manage bg-gray-100 min-h-[500px] flex items-center">
        <div className="container mx-auto py-10 px-4 sm:px-0">
          <div className="max-w-6xl mx-auto pt-8 pb-8 sm:p-8">
            <h1 className="text-xl font-bold text-blue-600 my-6 lg:my-8">
              Manage Your Account
            </h1>
            <div className="">
              <ul className="flex flex-wrap text-xs md:text-sm">
                {entParentUserID==='' ? (
                <TabItem
                tabName="subscription"
                activeTab={activeTab}
                onClick={handleTabChange}
                >
                  Subscription
                </TabItem>
                ) : ""}

                {isShowEnterprise && entParentUserID==='' ? (
                <TabItem
                tabName="members"
                activeTab={activeTab}
                onClick={handleTabChange}
                >
                  Members
                </TabItem>
                ) : ""}

                {entParentUserID==='' ? (
                <TabItem
                  tabName="order"
                  activeTab={activeTab}
                  onClick={handleTabChange}
                >
                  Invoice
                </TabItem>
                ) : ""}

                <TabItem
                  tabName="token-usage"
                  activeTab={activeTab}
                  onClick={handleTabChange}
                >
                  Token Usage
                </TabItem>
                <TabItem
                  tabName="account"
                  activeTab={activeTab}
                  onClick={handleTabChange}
                >
                  Account
                </TabItem>
                <TabItem
                  tabName="help"
                  activeTab={activeTab}
                  onClick={handleTabChange}
                >
                  Help
                </TabItem>
                <TabItem
                  tabName="logout"
                  activeTab={activeTab}
                  onClick={handleTabChange}
                >
                  Logout
                </TabItem>
              </ul>
            </div>

            <div className="bg-white drop-shadow-sm p-6 rounded-tr-md rounded-br-md rounded-bl-md min-h-[400px] flex">
              {activeTab === 'subscription' && <SubscriptionTabContent setshowAddMoreMember={setshowAddMoreMember} />}
              {activeTab === 'members' && <MembersTabContent handleShowAddMember={handleShowAddMember} enterpriseMembers={enterpriseMembers} handleReloadMembers={handleReloadMembers} handleShowEditMemberModal={handleShowEditMemberModal}/>}
              {activeTab === 'order' && <OrderTabContent />}
              {activeTab === 'token-usage' && <TokenUsageContent tokenUsage={tokenUsage} tokenUsageTotal={tokenUsageTotal} userMaxToken={userMaxToken} currentDate={currentDate} isShowEnterprise={isShowEnterprise} currentPlan={currentPlan} />}
              {activeTab === 'account' && <AccountTabContent auth={auth} />}
              {activeTab === 'help' && <HelpTabContent />}
              {activeTab === 'logout' && <LogOutContent />}
            </div>
          </div>
        </div>
      </div>

      <EditMemberModal showEditMember={showEditEnterprise} setShowEditEnterprise={setShowEditEnterprise} editMemberDetails={editMemberDetails} handleReloadMembers={handleReloadMembers} />
      <AddMemberModal showAddMember={showAddMember} setshowAddMember={setshowAddMember} handleReloadMembers={handleReloadMembers} entMaxMembers={ent_max_members} enterpriseMembers={enterpriseMembers} />
      <AddMoreMemberModal showAddMoreMember={showAddMoreMember} setshowAddMoreMember={setshowAddMoreMember} setMoreToAddMember={setMoreToAddMember} setMoreToAddMemberTotalAmount={setMoreToAddMemberTotalAmount}  setShowCompletePurchase={setShowCompletePurchase} />
      <MemberCompletePurchase moreToAddMember={moreToAddMember} moreToAddMemberTotalAmount={moreToAddMemberTotalAmount} setShowCompletePurchase={setShowCompletePurchase} showCompletePurchase={showCompletePurchase}/>
      <Footer auth={auth}/>
    </>
  );
}

const TabItem = ({ tabName, activeTab, onClick, children }) => {
  const isActive = activeTab === tabName;

  return (
    <li
      className={` pb-2 cursor-pointer py-3 px-6 rounded-t-lg ${
        isActive ? 'border-b-2 border-white bg-white' : ''
      }`}
      onClick={() => onClick(tabName)}
    >
      {children}
    </li>
  );
};

const AccountTabContent = (props) => {
  const [newEmail, setNewEmail] = useState("");
  const [email] = useState(props.auth.email);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");

  const validatePassword = () => {
    let error_msg = "";
    if(!oldPassword) {
      error_msg = "Current Password is required.";
    } else if (newPassword.length){
      if(newPassword !== confPassword) {
        error_msg = "New Password do not match.";
      } else if (newPassword.length < 6) {
        error_msg = "New Password should be at least 6 characters.";
      }
    }
    return error_msg;
  };

  const validateEmail = () => {
    let error_msg = "";
    if (newEmail.length) {
      if (!/\S+@\S+\.\S+/.test(newEmail)) {
        error_msg = 'Invalid email format';
      }
    }
    return error_msg;
  };

  const updateUserDetails = () => {
    let error_msg = validatePassword();
    if(error_msg) {
      window.toastr.error(error_msg);
      return;
    }
    error_msg = validateEmail();
    if(error_msg) {
      window.toastr.error(error_msg);
      return;
    }
    document.querySelector(".loader-container").classList.add('active');
    axios.post(`${process.env.REACT_APP_API_URL}/update-account`, {
      tk: GetCookie('access'),
      newemail: newEmail,
      password: oldPassword,
      newpassword: newPassword,
      confpassword: confPassword
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;
      if(output.success) {
        window.toastr.success("Success!<br> Please re-login your account.");
        setTimeout(function(){
          window.location.reload();
        }, 1000);
        return;
      }
      document.querySelector(".loader-container").classList.remove('active');
      if(output.data) window.toastr.error(output.data.msg);
    });
  };

  return (
    <div className="w-96">
      <p className="text-sm py-4">You may edit your account details below:</p>
      <div className="relative block">
        <div className='border-solid border-2 border-gray-100 rounded-sm'>
          <div className='p-3 bg-gray-50 border-b-2 border-gray-100 sm:text-sm'>
            Change Email Address
          </div>
          <div className='p-2 pt-1 pb-1'>
            <input className="placeholder:italic placeholder-text-slate-400 block bg-gray-100 w-full border border-slate-300 rounded-md my-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-gray-400"
            placeholder="abc@email.com"
            type="email"
            name="email"
            readOnly
            value={props.auth.email}
            />
            <input className="placeholder:italic placeholder-text-slate-400 block w-full border border-slate-300 rounded-md my-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="New Email"
            type="email"
            name="new_email"
            autocomplete="new-email"
            onKeyUp={(event) => {
              setNewEmail(event.target.value);
            }}
            />
          </div>
        </div>
        <div className='border-solid border-2 border-gray-100 rounded-sm mt-2'>
          <div className='p-3 bg-gray-50 border-b-2 border-gray-100 sm:text-sm'>
            Change Password
          </div>
          <div className='p-2 pt-1 pb-1'>
            <input className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md my-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="New Password"
            type="password"
            name="new_password"
            autocomplete="new-password"
            onKeyUp={(event) => {
              setNewPassword(event.target.value);
            }}
            />
            <input className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md my-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="Confirm New Password"
            type="password"
            name="conf_password"
            onKeyUp={(event) => {
              setConfPassword(event.target.value);
            }}
            />
          </div>
        </div>
      </div>
      <UpdateAccount updateUserDetails={updateUserDetails} setOldPassword={setOldPassword}></UpdateAccount>
      <div className="text-sm text-slate-400 py-4 mt-2 ml-2">
        <DeleteAccount email={email}></DeleteAccount>
      </div>
    </div>
  );
};

const UpdateAccount = (props) => {
  const setOldPassword = props.setOldPassword;
  const [isShowModal, setIsShowModal] = useState(false);

  const showUpdateAccountModal = () => {
    setIsShowModal(true);
  };
  const closeModal = () => {
    setIsShowModal(false);
  };

  const proceedUpdateAccount = () => {
    props.updateUserDetails();
  }
  return (
    <>
      <motion.button
        className="bg-blue-500 mb-1 text-white font-bold py-3 px-6 my-3 rounded-lg change-password hover:bg-[#2563eb]"
        whileTap={{ scale: 0.9 }}
        onClick={showUpdateAccountModal}
      >
        Save Changes
      </motion.button>
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Update Account
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">
                      Please enter your password to proceed.
                    </div>
                    <div>
                      <input className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md my-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      placeholder="Current Password *"
                      type="password"
                      name="current_password"
                      onKeyUp={(event) => {
                        setOldPassword(event.target.value);
                        if(event.keyCode === 13) proceedUpdateAccount();
                      }}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 mr-2 text-sm font-medium text-white hover:bg-[#2563eb]"
                      onClick={proceedUpdateAccount}
                    >
                      Proceed
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

async function getSubscription() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-subscription`, {
    'tk': GetCookie('access')
  }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })

  const output = response.data;
  if(output.success) {
    user_subscription = output.data;
    date_now = output.date_now;
    return output.data;
  } else {
    return [];
  }
}

async function getEnterpriseMembers() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-enterprise-members`, {
    'tk': GetCookie('access')
  }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })

  const output = response.data;
  if(output.success) {
    return output.data;
  } else {
    return [];
  }
}

const SubscriptionTabContent = ({setshowAddMoreMember}) => {
  const { data } = useQuery("users", getSubscription);
  const tk = GetCookie('access');

  if(data === undefined) return;
  const changeCard = (event) => {
    let p_id = event.target.getAttribute("pid");
    if(!p_id) return;
    document.querySelector(".loader-container").classList.add('active');
    window.location.href = '/change-card?pid=' + p_id;
  }

  const upgradeSub = (plan) => {
    console.log(plan);
    if (plan==='enterprise'){
      setshowAddMoreMember(true);
    }else{
      window.location.href = '/upgrade';
    }
  }

  const downgradeSub = () => {
    window.location.href = '/downgrade';
  };

  const getMerchant = (sub) => {
    return sub.merchant ? sub.merchant.toLowerCase() : "";
  };

  const getCurrency = (sub) => {
    return sub.currency ? sub.currency.toLowerCase() : "";
  }

  const getPlanType = (sub) => {
    return sub.plan_type ? sub.plan_type.toLowerCase() : "";
  }

  const getPaymentInterval = (sub) => {
    return sub.payment_interval ? sub.payment_interval.toLowerCase() : "";
  };

  return (
    <>
    <div className="overflow-x-auto overflow-y-visible container-full-width cm-scrollbar">
      {user_subscription && user_subscription.length ?
      <table className="subs-table min-w-full divide-y bg-gray-50 divide-gray-200 min-h-[50px]">
        <thead>
          <tr className="sub_tbl h-3">
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Plan Details</td>
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Amount</td>
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Trial Period</td>
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Starts On</td>
            {user_subscription && user_subscription.length > 0 && user_subscription.some(sub => sub.status === 'inactive') && (
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Expires On</td>
            )}
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Status</td>

            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Actions</td>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {user_subscription?.map((sub, index) => (
            <tr key={index} className="sub_tbl h-3">
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.plan_type.replace("ProMax"," pro max")}</td>
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.price_label}</td>
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.trial_days ? "Yes" : "No"}</td>
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{formatDate(sub.start_date)}</td>

            { user_subscription && user_subscription.length > 0 && user_subscription.some(sub => sub.status === 'inactive') ? (
                sub.status === 'active' ? (
                  <td className="inactive px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">
                  </td>
                ) : (
                  <td className="inactive px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">
                    {formatDate(sub.end_date)}
                  </td>
                )

              ) : sub.status === 'active' ? (
                ''
              ) : (
                <td className="active px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">
                  {formatDate(sub.end_date)}
                </td>
            )}

            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.status}</td>
            <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">
              { sub.status === 'active' ?
              <div className="text-[12px]">
                <Menu as="div" className="relative inline-block text-center w-full text-[12px]">
                  <div className='w-full'>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-sky-500/100 px-4 py-2 font-medium text-white hover:bg-[#49b1df]">
                      Options
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className={`${
                        index < 3 ? "dp-top" : "dp-bot"
                      } absolute w-50 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-center min-w-full text-center`}>
                      <div className="text-center min-w-full">
                        { getMerchant(sub) === 'recurly' || getMerchant(sub) === 'stripe' ? (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? 'bg-sky-500/100 text-white' : 'text-gray-900'
                              } group min-w-full items-center px-4 py-2 text-left border-b border-b-slate-100`}
                              pid={sub.pid}
                              onClick={changeCard}
                            >
                              Change Card
                            </button>
                          )}
                        </Menu.Item>
                        ) : "" }
                        { (getCurrency(sub) === 'usd' || getMerchant(sub) === 'paddle' || getMerchant(sub) === 'stripe' || getMerchant(sub) === 'paypal')
                        && diffMin(formatDateTime(date_now), formatDateTime(sub.start_date)) >= 0 ?
                        <>
                          { (getPlanType(sub) !== "enterprise") ? (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active ? 'bg-sky-500/100 text-white' : 'text-gray-900'
                                } group min-w-full items-center px-4 py-2 text-left border-b border-b-slate-100`}
                                onClick={(plan) => upgradeSub(getPlanType(sub))}
                              >
                                Upgrade
                              </button>
                            )}
                          </Menu.Item>
                          ) : "" }
                          { (!(getPlanType(sub) === "basic" && getPaymentInterval(sub) === 'monthly') && getPlanType(sub) !== "enterprise") ? (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active ? 'bg-sky-500/100 text-white' : 'text-gray-900'
                                } group min-w-full items-center px-4 py-2 text-left border-b border-b-slate-100`}
                                onClick={downgradeSub}
                              >
                                Downgrade
                              </button>
                            )}
                          </Menu.Item>
                          ) : "" }
                        </> : <></> }
                        <Menu.Item>
                          {({ active }) => (
                            <CancelSubscription pid={sub.pid} tk={tk}/>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div> : "" }
            </td>
          </tr>
          ))}
        </tbody>
      </table>
      : <div>
        <span className="text-[12px] text-gray-600"><FaInfoCircle className="inline text-lg mr-1"/> No active subscription. Look for available <a href="/pricing" className="text-blue-400 font-bold">SUBSCRIPTIONS</a></span>
        </div>
      }
    </div>
    </>
  );
};

async function getOrder() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-payment`, {
    'tk': GetCookie('access')
  }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })

  const output = response.data;
  if(output.success) {
    user_order = output.data;
    return output.data;
  } else {
    return [];
  }
}

const OrderTabContent = () => {
  const { data } = useQuery("users", getOrder);
  if(data === undefined) return;
  return (
    <div className="overflow-x-auto custom-scrollbar container-full-width bg-gray-50">
      {user_order && user_order.length ?
      <table className="min-w-full divide-y divide-gray-200 min-h-[50px]">
        <thead>
          <tr className="sub_tbl h-3">
            <th className="px-6 py-3 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider">Invoice</th>
            <th className="px-6 py-3 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider">Membership</th>
            <th className="px-6 py-3 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider">Amount</th>
            <th className="px-6 py-3 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider">Status</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {user_order?.map((sub, index) => (
            <tr key={index} className="sub_tbl h-3">
            <td className="px-6 py-3 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.charge_id}</td>
            <td className="px-6 py-3 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.label}</td>
            <td className="px-6 py-3 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.amount ? getPricePlan(sub.currency, sub.amount) : ""}</td>
            <td className="px-6 py-3 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{formatDate(sub.created_at)}</td>
            <td className="px-6 py-3 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.refund_at !== "0000-00-00 00:00:00" ? "Refunded" : "Completed"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      : <span className="text-[12px] text-gray-600"><FaInfoCircle className="inline text-lg mr-1"/> No orders have been made yet. Look for available <a href="/pricing" className="text-blue-400 font-bold">SUBSCRIPTIONS</a></span> }
    </div>
  );
};

const TokenUsageContent = ({tokenUsage, tokenUsageTotal, userMaxToken, currentDate, isShowEnterprise, currentPlan}) => {
  return (
    <div className="w-full">
      {(currentPlan !=='' && userMaxToken <= tokenUsageTotal ) ? (
        <div>
          <p className="text-md font-bold text-red-700">              
            <span className='total1'>
              <img src={imgWarning} alt="usage-warning" className="block pr-2 w-8 inline-flex"/>
              TOTAL TOKEN USAGE:
            </span>
            &nbsp;
            <span className='total2'>
            {parseInt(tokenUsageTotal).toLocaleString("en-US")} 
            {userMaxToken!=='99999999' ? <span> out of {parseInt(userMaxToken).toLocaleString("en-US")}</span> : ""}
            </span>
          </p>
          {userMaxToken!=='99999999' ? 
          <p className="text-sm font-bold pb-2"><span className='total1'>Token count will reset on:</span>  <span className='total2'>{currentDate}</span></p>
          : ""}
        </div>  
      ) : 
      (
        <div>
          <p className="text-md font-bold">              
            <span className='total1'>TOTAL TOKEN USAGE:</span>
            &nbsp;
            <span className='total2'>
            {parseInt(tokenUsageTotal).toLocaleString("en-US")} 
            {userMaxToken!=='99999999' ? <span> out of {parseInt(userMaxToken).toLocaleString("en-US")}</span> : ""}
            </span>

          </p>
          {currentPlan !=='' && userMaxToken!=='99999999' ? 
          <p className="text-sm font-bold pb-2"><span className='total1'>Token count will reset on:</span>  <span className='total2'>{currentDate}</span></p>
          : ""}
        </div>
      )}

      {(currentPlan !=='' && userMaxToken <= tokenUsageTotal && isShowEnterprise === false ) ? (
      <div>
        <p className="text-md text-red-700">UPGRADE is required to continue</p>
        <div className='flex inline-flex sm:mr-2'>
          <p>
            <a href="/upgrade/?mx=1" class="block w-full font-bold text-white py-2 px-4 rounded-md bg-[#ffa500] hover:bg-[#FFC034]">Upgrade</a>      
          </p>
        </div>
        </div>
      ) : ""}

    {(currentPlan !=='' && userMaxToken <= tokenUsageTotal && isShowEnterprise === true ) ? (
      <div>
        <p className="text-md text-red-700">For continous access, kindly reach out to our support team</p>
        <div className='flex inline-flex sm:mr-2'>
          <p>
            <a href="https://ai-pro.org/contact-us" class="block w-full font-bold text-white py-2 px-4 rounded-md bg-[#ffa500] hover:bg-[#FFC034]">Contact Support</a>      
          </p>
        </div>
        </div>
      ) : ""}

      <ul className='pt-5'>
      {tokenUsage?.map((sub, index) => (
        <li><span className='font-bold'>{sub.app}:</span> {parseInt(sub.total_token).toLocaleString("en-US")}</li>
      ))}
      </ul>
    </div>
  );
};

const HelpTabContent = () => {
  return (
    <div className="w-full">
      <p className="text-sm py-4">If you have any comments about our website, AI tools. and articles, or if you have questions about your account access, please don’t hesitate to get in touch with us. Leave your messages through the <a href="https://ai-pro.org/contact-us/" className="text-blue-400 font-bold">Contact us</a> page.</p>
      <p className="font-bold">Quick Links:</p>
      <ul>
        <li><a href="/my-account" className="text-blue-400 font-bold px-2">> My Apps</a></li>
        <li><a href="/my-account" className="text-blue-400 font-bold px-2">> AI Tools</a></li>
        <li><a href="https://ai-pro.org/articles/" className="text-blue-400 font-bold px-2">> Articles</a></li>
        <li><a href="https://ai-pro.org/tutorials/" className="text-blue-400 font-bold px-2">> Tutorials</a></li>
      </ul>
    </div>
  );
};

const LogOutContent = () => {

  function logout() {
    RemoveCookie("access");
    RemoveCookie("ci_session");

    axios.get(`${process.env.REACT_APP_API_URL}/logout`).then(function(){
      window.location.href = '/login';
    });
  };

  return (
    <div>
      <motion.button
        className="bg-blue-500 mb-1 text-white font-bold py-3 px-6 my-3 rounded-lg"
        whileTap={{ scale: 0.9 }}
        onClick={logout}
      >
        Logout
      </motion.button>
    </div>
  );
};

const DeleteAccount = (props) => {
  const [email] = useState(props.email);
  const [isShowModal, setIsShowModal] = useState(false);

  const showDeleteModal = () => {
    setIsShowModal(true);
  };
  const closeModal = () => {
    setIsShowModal(false);
  };

  const proceedDeleteAccount = () => {
    document.querySelector(".loader-container").classList.add('active');
    axios.post(`${process.env.REACT_APP_API_URL}/delete-account`, {
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;
      if(output.success) {
        window.mixpanel.people.set_once({ "$email": email });
        window.mixpanel.identify(email);
        window.mixpanel.track("delete_account", {});
        //
        window.toastr.success("Success");
        window.location.reload();
        return;
      }
      document.querySelector(".loader-container").classList.remove('active');
      window.toastr.error("Fail");
    });
  }
  return (
    <>
      <span className="cursor-pointer hover:underline hover:decoration-solid" onClick={showDeleteModal}>Delete Account</span>
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Delete Account
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                    Deleting your account will permanently remove all of your data and information associated with it. This action is irreversible, and you won't be able to recover your account or any of its contents.
                    </p>
                    <p className="text-sm text-gray-500">
                    Are you sure you want to delete your account?
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 mr-2 text-sm font-medium text-white hover:bg-[#d30101]"
                      onClick={proceedDeleteAccount}
                    >
                      Yes! Delete My Account
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

const CancelSubscription = ({ pid, tk }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const auth = Auth();
  const showCancelModal = () => {
    setIsShowModal(true);
  };
  const closeModal = () => {
    setIsShowModal(false);
  };
  const unSubscribe = (event) => {
    if(!pid) return;
    closeModal();
    document.querySelector(".loader-container").classList.add('active');
    if(auth.surveydata === '' || auth.surveydata === null){
      window.location.href = '/survey';
    }else{
      axios.post(`${process.env.REACT_APP_API_URL}/cancel-subscription`, {
        tk,
        p_id: pid
      }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res){
        let output = res.data;
        if(output.success) {
          window.toastr.success("Success");
          window.location.reload();
          return;
        }
        document.querySelector(".loader-container").classList.remove('active');
        if(output.data) window.toastr.error(output.data.msg);
      });
    }

  };
  return (
    <>
      <button
      className={`hover:bg-sky-500/100 hover:text-white text-gray-900 group min-w-full items-center px-4 py-2 text-left`}
      pid={pid}
      onClick={showCancelModal}
      >
        Cancel
      </button>
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Cancel Subscription
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to cancel your subscription?
                    </p>
                  </div>

                  <div className="mt-4 text-right">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 mr-2 text-sm font-medium text-white hover:bg-[#d30101]"
                      onClick={unSubscribe}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

function limitDisplay(members, displayLimit) {
  return members.slice(0, displayLimit)
}

function getTotalPages(members, displayLimit) {
  if (members.length<displayLimit){
    return 1;
  }

  let totalMembers = members.length;
  let quotient = Math.floor(totalMembers/displayLimit); // => 4 => the times 3 fits into 13
  let remainder = totalMembers % displayLimit;

  if (remainder>0){
    quotient = quotient+1;
  }
  return quotient;
}

const MembersTabContent = ({handleShowAddMember, enterpriseMembers, handleReloadMembers, handleShowEditMemberModal}) => {

  const [search, setSearch] = useState("");
  const [cloneEnterpriseMembers, setcloneEnterpriseMembers] = useState([]);
  const [displayEnterpriseMembers, setDisplayEnterpriseMembers] = useState([]);
  const [showOnly, setShowOnly] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let members = enterpriseMembers;
    setcloneEnterpriseMembers(members);

    members = limitDisplay(members,5);
    setDisplayEnterpriseMembers(members);

    let PageCount = getTotalPages(enterpriseMembers,5);
    setPageCount(PageCount);
  }, [enterpriseMembers]);

	const handleSearch = () => {
    let input = document.getElementById("ent_search").value;
    let members = enterpriseMembers;

    members = enterpriseMembers.filter(function(item){
      let full_name = item.first_name + ' ' + item.last_name;
      return ((item.first_name.indexOf(input)>-1 || item.last_name.indexOf(input)>-1 || item.email.indexOf(input)>-1 || full_name.indexOf(input) > -1))
    })

    let PageCount = getTotalPages(members,showOnly);
    setPageCount(PageCount);
    setcloneEnterpriseMembers(members);

    members = limitDisplay(members,showOnly);
    setDisplayEnterpriseMembers(members);
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnly]);

  const generatePager = () => {
    let content = [];

    if (pageCount<=1 || enterpriseMembers.length===0){
      return content;
    }

    if (currentPage>1){
      content.push(<li className="p-2 cursor-pointer" key={'prev_page'} onClick={(e)=>handlePagerClick(currentPage-1)}>Previous</li>);
    }else{
      content.push(<li className="p-2 cursor-default" key={'prev_page'}>Previous</li>);
    }

    for (let i = 0; i < pageCount; i++) {
      const isActivePage = currentPage === i + 1;
      const className = `p-2 rounded cursor-pointer w-[30px] text-center ${isActivePage ? 'border' : ''}`;
      content.push(
        <li className={className} key={i + 1} onClick={(e) => handlePagerClick(i + 1)}>
          {i + 1}
        </li>
      );
    }

    if (currentPage<pageCount){
      content.push(<li className="p-2 cursor-pointer" key={'prev_page'} onClick={(e)=>handlePagerClick(currentPage+1)}>Next</li>);
    }else{
      content.push(<li className="p-2 cursor-default" key={'prev_page'}>Next</li>);
    }

    return content;
  }

  const generatePagerStats = () => {
    let totalEntries = cloneEnterpriseMembers.length;
    let ending = currentPage*showOnly;
    let starting = ending - (showOnly-1);

    if (ending > totalEntries){
      ending = totalEntries;
    }

    return "Showing "+ starting + " to " + ending + " of " +  totalEntries + " entries";
  }

  const handlePagerClick = (page) => {
    setCurrentPage(page);

    let members = cloneEnterpriseMembers;
    let arrayToLoad = (page-1)*showOnly;

    members = members.slice(arrayToLoad, members.length);
    members = limitDisplay(members,showOnly);

    setDisplayEnterpriseMembers(members);
  }

  const handleShowPerPage = (event) => {
    setShowOnly(event.target.value);
    setCurrentPage(1);
  }


  const handleEdit = (user_id, first_name, last_name, email) => {
    let params = {};
    params['user_id'] = user_id;
    params['first_name'] = first_name;
    params['last_name'] = last_name;
    params['email'] = email;

    handleShowEditMemberModal(true,params);
  }

  return (
    <>
    <div className='w-full'>
      <div className="block md:grid md:grid-cols-3 md:gap-4 text-[12px]">
        <div className="text-left md:col-span-1">
          <input className="w-full px-3 py-2 mb-2 border border-gray-300 rounded fs-exclude"
          type="text"
          id="ent_search"
          name="search"
          placeholder="Search by Name or Email"
          value={search}
          onChange={(e) => {
            handleSearch()
            setSearch(e.target.value);
          }}
          />
        </div>
        <div className="text-left md:col-span-1">
          <motion.button
          className="bg-sky-500 w-full md:w-48 text-white font-bold py-2 px-6 rounded-md proceed-pmt"
          whileHover={{ backgroundColor: "#49b1df" }}
          whileTap={{ scale: 0.9 }}
          onClick={()=> handleShowAddMember()}
          >
          + Add Member
          </motion.button>

        </div>
        {enterpriseMembers.length > 0 ?
        <div className="text-center md:text-right md:col-span-1 my-2 text-[11px]">
          Show <select className="border rounded-md" onChange={(e) => handleShowPerPage(e)}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select> entries
        </div>
        : ''}
      </div>

      <div className="overflow-x-scroll lg:overflow-x-visible overflow-y-visible container-full-width cm-scrollbar pb-1 min-h-[400px]">
        {displayEnterpriseMembers && displayEnterpriseMembers.length ?
        <table className="subs-table min-w-full divide-y bg-gray-50 divide-gray-200 min-h-[50px]">
          <thead>
            <tr className="sub_tbl h-3">
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Fullname</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Email</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Status</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Date Added</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Total Token Used</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] font-medium bg-gray-200 text-gray-500 uppercase tracking-wider border">Actions</td>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {displayEnterpriseMembers?.map((sub, index) => (
              <tr key={index} className="sub_tbl h-3">
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.first_name} {sub.last_name}</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.email}</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.status}</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{formatDate(sub.created_at)}</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">{sub.total_token}</td>
              <td className="px-6 py-2 bg-gray-50 text-center text-[12px] text-gray-500 uppercase tracking-wider border font-medium">
                { sub.status === 'active' ?
                <div className="text-[12px]">
                  <Menu as="div" className="relative inline-block text-center w-full text-[12px]">
                    <div className='w-full'>
                      <Menu.Button className="inline-flex w-full justify-center rounded-md bg-sky-500/100 px-4 py-2 font-medium text-white hover:bg-[#49b1df]">
                        Options
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className={`${
                        index < 3 ? "dp-top" : "dp-bot"
                      } z-[9999] absolute w-50 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-center min-w-full text-center`}>
                      <div className="text-center min-w-full">
                        <Menu.Item>
                          <button
                          className="group min-w-full items-center px-4 py-2 text-left border-b border-b-slate-100 text-gray-900"
                          onClick={(user_id, first_name, last_name, email) => handleEdit(sub.user_id, sub.first_name, sub.last_name, sub.email)}
                          >
                            Edit Info
                          </button>
                      </Menu.Item>
                        <Menu.Item>
                          <ResendPasswordMember member_user_id={sub.user_id} email={sub.email} />
                        </Menu.Item>
                        <Menu.Item>
                          <DeleteMember member_user_id={sub.user_id} email={sub.email} handleReloadMembers={handleReloadMembers} />
                        </Menu.Item>
                      </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div> : "" }
              </td>
            </tr>
            ))}
          </tbody>
        </table>
        : <div>
          <span className="text-[12px] text-gray-600"><FaInfoCircle className="inline text-lg mr-1"/>No members found.</span>
          </div>
        }
      </div>
      <div className="py-2 text-[11px]">
        <span className="block md:flex float-left w-full md:w-auto text-center md:text-right py-2 justify-center md:justify-normal items-center md:items-start">
          {generatePagerStats()}
        </span>
        <ul className="w-full md:w-auto flex float-none md:float-right justify-center md:justify-normal items-center md:items-end">
        {generatePager()}
        </ul>
      </div>

    </div>
    </>
  );
}

const EditMemberModal = ({showEditMember, setShowEditEnterprise, editMemberDetails, handleReloadMembers}) => {
  const [fullName, setfullName] = useState("");
  const [oldFullName, setoldFullName] = useState("");
  const [email, setEamil] = useState("");
  const [origEmail, setorigEmail] = useState("");
  const [userId, setUserId] = useState("");

  const modalEditMembersOpen = () => {
    let modal = document.getElementById("modalEditMembers");
    if (modal!==null){
			modal.style.display = "block";
    }
  }

  const modalEditMembersClose = () => {
    let modal = document.getElementById("modalEditMembers");
    if (modal!==null){
			let fieldEmail = document.getElementById("ent-edit-email");
			fieldEmail.title = "";
			fieldEmail.classList.remove('ent-field-error');

			let fieldName = document.getElementById("ent-edit-fullname");
			fieldName.title = "";
			fieldName.classList.remove('ent-field-error');

			console.log("modal close");


			modal.style.display = "none";
      setShowEditEnterprise(false);
    }
  }

  useEffect(() => {
    let first_name = editMemberDetails.first_name ? editMemberDetails.first_name : '';
    let last_name = editMemberDetails.last_name ?  editMemberDetails.last_name : '';
    let email = editMemberDetails.email ?  editMemberDetails.email : '';
    let user_id = editMemberDetails.user_id ?  editMemberDetails.user_id : '';

    setfullName(first_name + " " + last_name);
    setoldFullName(first_name + " " + last_name);
    setEamil(email);
    setorigEmail(email);
    setUserId(user_id);
  }, [editMemberDetails]);

  const validateEmail = (e) => {
    let email = e.target.value;
    let fieldEmailError = document.getElementById("ent-edit-email-error");

    if (email.trim()===""){
      fieldEmailError.innerHTML = "Email is required";
			fieldEmailError.style.display = "block";
      e.target.classList.add('ent-field-error');
		}else if (!/\S+@\S+\.\S+/.test(email)) {
      fieldEmailError.innerHTML = "Invalid email format";
			fieldEmailError.style.display = "block";
      e.target.classList.add('ent-field-error');
    }else{
      fieldEmailError.innerHTML = "";
			fieldEmailError.style.display = "none";
      e.target.classList.remove('ent-field-error');
    }
  };

  const validateName = (e) => {
    let full_name = e.target.value.trim();
    let fieldFullnameError = document.getElementById("ent-edit-fullname-error");

    if (full_name===""){
      fieldFullnameError.innerHTML = "Full Name is required";
			fieldFullnameError.style.display = "block";
      e.target.classList.add('ent-field-error');
    }else{
      fieldFullnameError.innerHTML = "";
			fieldFullnameError.style.display = "none";
      e.target.classList.remove('ent-field-error');
    }
	}

  if (showEditMember!==undefined && showEditMember === true){
    modalEditMembersOpen();
  }
  if (showEditMember!==undefined && showEditMember === false){
    modalEditMembersClose();
  }

  const updateMember = async () => {
    let fieldFullname = document.getElementById("ent-edit-fullname");
    let fieldFullnameError = document.getElementById("ent-edit-fullname-error");
    let fieldEmail = document.getElementById("ent-edit-email");
    let fieldEmailError = document.getElementById("ent-edit-email-error");

		// fieldError2.style.display = "block";

    if (fieldEmail.value.trim()===""){
      fieldEmailError.innerHTML = "Email is required";
			fieldEmailError.style.display = "block";
			fieldEmail.classList.add('ent-field-error');
			return;
		}else if (!/\S+@\S+\.\S+/.test(email)) {
      fieldEmailError.innerHTML = "Invalid email format";
			fieldEmailError.style.display = "block";
      fieldEmail.classList.add('ent-field-error');
			return;
		}

    if (fieldFullname.value.trim()===""){
      fieldFullnameError.innerHTML = "Full Name is required";
			fieldFullnameError.style.display = "block";
      fieldFullname.classList.add('ent-field-error');
			return;
		}

    document.querySelector(".loader-container").classList.add('active');

    if (oldFullName===fieldFullname.value && origEmail === fieldEmail.value){
      modalEditMembersClose();
      document.querySelector(".loader-container").classList.remove('active');
      return;
    }

    let url = `${process.env.REACT_APP_API_URL}/edit-enterprise-member`;
    axios.post(url, {
      member_user_id: userId,
      member_email: fieldEmail.value,
      member_fullname : fieldFullname.value,
      member_old_fullname: oldFullName,
      member_old_email: origEmail,
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;

      document.querySelector(".loader-container").classList.remove('active');

      if(output.success) {
        if (Array.isArray(output.data) && output.data.length > 0){
          for(var i = 0; i < output.data.length; i++) {
            let fieldEmail = document.getElementById("ent-edit-email");
            fieldEmail.title = output.data[i].error;
            fieldEmail.classList.add('ent-field-error');
            window.toastr.error(output.data[i].error);
          }
        }else{
          modalEditMembersClose();
          handleReloadMembers();
          window.toastr.success("Member Updated");
        }
      }else{
        modalEditMembersClose();
        handleReloadMembers();
        window.toastr.error("Update Member Failed");
      }

    }).catch(function (error) {
      modalEditMembersClose();
      document.querySelector(".loader-container").classList.remove('active');
      window.toastr.error("Something went wrong. Please try again in a bit!");
    });

  }

  return (
    <>
    <div id="modalEditMembers" className="modal z-[9999]">
      <div class="modal-content w-full md:w-[50%]">
        <div>
          <div className="mb-4 flex border-b pb-2">
            <div className="text-blue-500 w-full md:w-2/3 mr-2 md:mr-5 font-bold">
            Edit Members
            </div>
            <div className="float-right mt-[-5px] w-full md:w-1/3">
              <span class="close" onClick={()=> modalEditMembersClose()}>&times;</span>
            </div>
          </div>
          <div className="border-b border-[#dddddd] mx-auto my-4 pb-4">
              <div className="w-full text-[10px] md:text-[12px] font-bold pb-2">
                <p>Fulname *</p>
                <input type="text" id="ent-edit-fullname" className="w-full px-3 py-2 border border-gray-300 rounded"
                placeholder="Fullname"
                value={fullName}
                onChange={(e) => {
                  e.preventDefault();
                  setfullName(e.target.value);
                  validateName(e);
                  }
                }
                title=""
                ></input>
                <div class="member-error" id="ent-edit-fullname-error"></div>
              </div>
              <div className="w-full text-[10px] md:text-[12px] font-bold pb-2">
                <p>Email *</p>
                <input type="text" id="ent-edit-email" className="w-full px-3 py-2 border border-gray-300 rounded"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  e.preventDefault();
                  setEamil(e.target.value);
                  validateEmail(e);
                  }
                }
                title=""
                ></input>
                <div class="member-error" id="ent-edit-email-error"></div>
              </div>
          </div>
          <div className=''>
            <div className="text-right">
              <input type="button" value="Update Member" className="border rounded font-bold bg-blue-500 text-white py-2 px-4 cursor-pointer text-[12px] md:text-[14px] mr-2" onClick={()=> updateMember()}/>
              <input type="button" value="Close" className="border rounded font-bold bg-white text-black py-2 px-4 cursor-pointer text-[12px] md:text-[14px]" onClick={()=> modalEditMembersClose()}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

const AddMemberModal = ({showAddMember, setshowAddMember, handleReloadMembers, entMaxMembers, enterpriseMembers}) => {

  const [members, setMembers] = useState([
    { name: '', email: '', error: '' }
  ]);

  const addMember = () => {
    setMembers(current => [...current, { name: '', email: '', error: '' }]);
  };

  const resetMember = async () => {
    await setMembers(current => {
      return current.filter((_, i) => i <= -1)
    });
    addMember();
  };

  const removeMember = (index) => {
    setMembers(current => {
      return current.filter((_, i) => i !== index)
    });
  };

  const saveMember = async () => {

    let total_members_after_insert = members.length+enterpriseMembers.length;
    if (total_members_after_insert>entMaxMembers){
      window.toastr.error("Members exceeded. Max. members should only "+entMaxMembers+" members.");
      return;
    }

    let hasError = await validateFields();
    if (hasError){
      return;
    }

    var url = `${process.env.REACT_APP_API_URL}/add-enterprise-member`;

    if (members.length<=0){
      modalAddMembersClose();
      return;
    }

    document.querySelector(".loader-container").classList.add('active');

    axios.post(url, {
      members: JSON.stringify(members)
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;

      document.querySelector(".loader-container").classList.remove('active');

      if(output.success) {
        if (Array.isArray(output.data) && output.data.length > 0){
          for(var i = 0; i < output.data.length; i++) {
            let index = output.data[i].index;
            let fieldEmail = document.getElementById("email-"+index);
            fieldEmail.title = output.data[i].error;
            fieldEmail.classList.add('ent-field-error');

            let fieldError2 = document.getElementById("error2-"+index);
            fieldError2.style.display = "block";
            fieldError2.innerHTML = output.data[i].error;
          }
        }else{
          window.toastr.success("Members Added");
          modalAddMembersClose();
          handleReloadMembers();
          resetMember();
        }
      }else{
        window.toastr.error("Adding Members Failed");
        modalAddMembersClose();
        handleReloadMembers();
        resetMember();
      }

    }).catch(function (error) {
      modalAddMembersClose();
      document.querySelector(".loader-container").classList.remove('active');
      window.toastr.error("Something went wrong. Please try again in a bit!");
    });

  }

  const removeLastMember = () => {
    if (members.length>0){
      let lastIndex = members.length - 1;
      removeMember(lastIndex);
    }
  }

  const modalAddMembersOpen = () => {
    let modal = document.getElementById("modalAddMembers");
    if (modal!==null){
      modal.style.display = "block";
    }
  }

  const modalAddMembersClose = () => {
    let modal = document.getElementById("modalAddMembers");
    if (modal!==null){
      modal.style.display = "none";
      setshowAddMember(false);
    }
  }


  const validateEmail = (e, index) => {
    let email = e.target.value;
    let isValid = true;
    let fieldName = document.getElementById("name-"+index);
    let full_name = fieldName.value.trim();
    let fieldError1 = document.getElementById("error1-"+index);
    let fieldError2 = document.getElementById("error2-"+index);

    if (email.trim()===""){
      e.target.title = "Email is required";
      e.target.classList.add('ent-field-error');
      fieldError2.style.display = "block";
      fieldError2.innerHTML = "Email is required";
      isValid = false;
    }else if (!/\S+@\S+\.\S+/.test(email)) {
      e.target.title = "Invalid email format";
      e.target.classList.add('ent-field-error');
      fieldError2.style.display = "block";
      fieldError2.innerHTML = "Invalid email format";
      isValid = false;
    }

    if (isValid){
      e.target.title = "";
      e.target.classList.remove('ent-field-error');
      fieldError1.style.display = "none";
      fieldError1.innerHTML = "";
      fieldError2.style.display = "none";
      fieldError2.innerHTML = "";
    }

    if (isValid && full_name==='') {
      fieldName.title = "Full Name is required";
      fieldName.classList.add('ent-field-error');
      fieldError1.style.display = "block";
      fieldError1.innerHTML = "Full Name is required";
    }
  };

  const validateName = (e, index) => {
    let full_name = e.target.value.trim();
    let fieldEmail = document.getElementById("email-"+index).value;
    let fieldError1 = document.getElementById("error1-"+index);

    if (full_name==="" && fieldEmail!==''){
      e.target.title = "Full Name is required";
      e.target.classList.add('ent-field-error');
      fieldError1.style.display = "block";
      fieldError1.innerHTML = "Full Name is required";
    }else{
      e.target.title = "";
      e.target.classList.remove('ent-field-error');
      fieldError1.style.display = "none";
      fieldError1.innerHTML = "";

    }
  }

  const validateFields = async () => {
    let i = 0;
    let hasError = false;
    let fieldName = "";
    let fieldEmail = "";
    let full_name = "";
    let fieldError1 = "";
    let fieldError2 = "";
    let server_error = "";
    let doesExist = false;

    while (i < members.length) {
      fieldName = document.getElementById("name-"+i);
      fieldEmail = document.getElementById("email-"+i);
      fieldError1 = document.getElementById("error1-"+i);
      fieldError2 = document.getElementById("error2-"+i);

      let email = members[i].email.trim();
      full_name = members[i].name.trim();
      server_error = members[i].error.trim();

      fieldError1.style.display = "none";
      fieldError2.style.display = "none";
      fieldName.title = "";
      fieldName.classList.remove('ent-field-error');
      fieldEmail.title = "";
      fieldEmail.classList.remove('ent-field-error');

      if (email==="") {
        fieldEmail.title = "Email is required";
        fieldEmail.classList.add('ent-field-error');
        fieldError2.style.display = "block";
        fieldError2.innerHTML = "Email is required"
        hasError = true;
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        fieldEmail.title = "Invalid email format";
        fieldEmail.classList.add('ent-field-error');
        fieldError2.style.display = "block";
        fieldError2.innerHTML = "Invalid email format"
        hasError = true;
      }

      if (server_error!==""){
        fieldEmail.title = server_error;
        fieldEmail.classList.add('ent-field-error');
        hasError = true;
      }

      if (full_name===""){
        fieldName.title = "Full Name is required";
        fieldName.classList.add('ent-field-error');
        fieldError1.innerHTML = "Full Name is required"
        fieldError1.style.display = "block";
        hasError = true;
      }

      if (!hasError){
        doesExist = members.filter(function(item){
          return ((item.email.trim()===email))
        })

        if (doesExist.length>1){
          fieldEmail.title = "Duplicate email";
          fieldEmail.classList.add('ent-field-error');
          fieldError2.innerHTML = "Duplicate email"
          fieldError2.style.display = "block";
          hasError = true;
        }
      }

      i++;
    }

    return hasError;
  }

  if (showAddMember!==undefined && showAddMember === true){
    modalAddMembersOpen();
  }
  if (showAddMember!==undefined && showAddMember === false){
    modalAddMembersClose();
  }

  return (
    <>
		<div id="modalAddMembers" className="modal z-[9999]">
			<div class="modal-content w-full md:w-[60%]">
        <div>
          <div className="mb-4 flex border-b pb-2">
            <div className="text-blue-500 w-full md:w-2/3 mr-2 md:mr-5 font-bold">
            Add Members
            </div>
            <div className="float-right mt-[-5px] w-full md:w-1/3">
              <span class="close" onClick={()=> modalAddMembersClose()}>&times;</span>
            </div>
          </div>
          <div className="text-[11px] pb-2">
          <b>Note:</b> The password for each member will be sent to their email address
          </div>
          <div className="">
            <div className="border rounded-tr rounded-tl flex p-2">
              <div className="w-1/2 text-[10px] md:text-[12px] font-bold">
              Full Name
              </div>
              <div className="w-1/2 ml-[-4px] text-[10px] md:text-[12px] font-bold">
              Email
              </div>
            </div>
            {members?.map((mem, index) => (
            <div className="flex py-2 pl-2 bg-gray-100">
              <div className="w-1/2 mr-2 text-[11px] md:text-[14px]">
                <input type="text" id={"name-"+index} index={index} className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude" placeholder="Enter Full Name" value={mem.name}
                onChange={(e) => {
                  setMembers(s => {
                    const newArr = s.slice();
                    newArr[index].name = e.target.value;
                    validateName(e, index);
                    return newArr;
                  });
                }}
                title=""
                ></input>
                <div class="member-error" id={"error1-"+index}></div>
              </div>
              <div className="w-1/2 mr-2 text-[11px] md:text-[14px]">
                <input type="text" id={"email-"+index} index={index} className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude" placeholder="Enter Email" value={mem.email}
                onChange={(e) => {
                  setMembers(s => {
                    const newArr = s.slice();
                    newArr[index].email = e.target.value;
                    validateEmail(e, index)
                    return newArr;
                  });
                }}
                title=""
                ></input>
                <div class="member-error" id={"error2-"+index}></div>
              </div>
              <div className="w-1/20 text-right pt-2">
                <button onClick={() => removeMember(index)}>
                  <span><FaTrash className="text-lg mr-2 text-red-500" /></span>
                </button>
              </div>
            </div>
            ))}
          </div>
          <div className="border-b border-[#dddddd] mx-auto my-4 pb-4">
            <input type="button" value="Add More" className="border rounded font-bold bg-white py-2 px-4 cursor-pointer text-[12px] md:text-[14px]" onClick={()=> addMember()}/>
            <input type="button" value="Remove Last" className="border rounded font-bold bg-white float-right py-2 px-4 cursor-pointer text-[12px] md:text-[14px]" onClick={()=> removeLastMember()}/>
          </div>
          <div className="text-right">
            <input type="button" value="Add Member" className="border rounded font-bold bg-blue-500 text-white py-2 px-4 cursor-pointer text-[12px] md:text-[14px]" onClick={()=> saveMember()}/>
          </div>
        </div>
			</div>
		</div>
    </>
  );
}

const DeleteMember = ({ member_user_id, email, handleReloadMembers }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const showDeleteMemberModal = () => {
    setIsShowModal(true);
  };
  const closeModal = () => {
    setIsShowModal(false);
  };

  const deleteMember = (event) => {
    document.querySelector(".loader-container").classList.add('active');
    closeModal();

    axios.post(`${process.env.REACT_APP_API_URL}/delete-enterprise-member`, {
      member_user_id: member_user_id,
      member_email: email
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res){
      let output = res.data;
      if(output.success) {
        document.querySelector(".loader-container").classList.remove('active');
        window.toastr.success("Delete success.");
        handleReloadMembers();
        return;
      }
      document.querySelector(".loader-container").classList.remove('active');
      if(output.data) window.toastr.error(output.data.msg);
    });


  };
  return (
    <>
      <button
      className={`hover:bg-sky-500/100 hover:text-white text-gray-900 group min-w-full items-center px-4 py-2 text-left`}
      onClick={showDeleteMemberModal}
      >
        Delete
      </button>
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-red-800 w-full md:w-2/3 mr-2 md:mr-5 font-bold"
                  >
                    Delete Member
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 break-words border-t border-b border-[#dddddd] py-2">
                      <AiFillWarning className="inline text-sm mr-1 text-red-800"/>Are you sure you want to delete <strong>{email}</strong> account?
                    </p>
                  </div>

                  <div className="mt-4 text-right">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 mr-2 text-sm font-medium text-white hover:bg-[#d30101]"
                      onClick={deleteMember}
                    >
                      Delete Member
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

const ResendPasswordMember = ({ member_user_id, email }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const showDeleteMemberModal = () => {
    setIsShowModal(true);
  };
  const closeModal = () => {
    setIsShowModal(false);
  };

  const resendPassword = (event) => {
    document.querySelector(".loader-container").classList.add('active');
    closeModal();

    axios.post(`${process.env.REACT_APP_API_URL}/resend-pass-enterprise-member`, {
      member_user_id: member_user_id,
      member_email: email
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res){
      let output = res.data;
      if(output.success) {
        window.toastr.success("New Password sent.");
        document.querySelector(".loader-container").classList.remove('active');
        return;
      }
      document.querySelector(".loader-container").classList.remove('active');
      if(output.data) window.toastr.error(output.data.msg);
    });


  };
  return (
    <>
      <button
      className={`hover:bg-sky-500/100 hover:text-white text-gray-900 group min-w-full items-center px-4 py-2 text-left`}
      onClick={showDeleteMemberModal}
      >
        Resend Password
      </button>
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Resend Password
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 break-words">
                      Do you want to proceed with sending the new password to <strong>{email}</strong>?
                    </p>
                  </div>

                  <div className="mt-4 text-right">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 mr-2 text-sm font-medium text-white hover:bg-[#d30101]"
                      onClick={resendPassword}
                    >
                      Resend Password
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Manage;