import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import Header from "../header/headerlogo";
import { Auth } from "../core/utils/auth";
import { SetCookie, GetCookie } from "../core/utils/cookies";
import { ValidatePassword } from "../core/utils/validation";
import { Helmet } from "react-helmet";
import errors from "../locales/errors.json";
import img1 from "../assets/images/google_icon.png";

const params = new URLSearchParams(window.location.search);

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [willRedirect, setWillRedirect] = useState(true);
  const members = GetCookie("members") || "";
  const reg_google = GetCookie("reg_google") || "off";
  const fromChatapp = GetCookie("from_chatapp") || "";
  const auth = Auth();
  const smooth_login = GetCookie("smooth_login") || "off";
  const spanErrorRefs = useRef([]);
  const formRef = useRef(null);

  const handleRedirect = (path) => {
    window.location.href = path;
  };

  const setSpanHeights = useCallback(() => {
    if (smooth_login === "on") {
      spanErrorRefs.current.forEach((ref, i) => {
        if (ref) {
          const errMsg = ref.textContent;
          const currHeight = ref.offsetHeight;
          const height = !!errMsg
            ? currHeight !== 0
              ? currHeight
              : (ref.scrollHeight ?? 0) + 12
            : 0;

          setTimeout(() => {
            ref.style.height = `${height}px`;
          }, 100);
        }
      });
    }
  }, [smooth_login]);

  /**
   * useEffect(() => {}, [formRef.current]) is currently not working.
   * Using setTimeout instead.
   */
  setTimeout(() => {
    const form = formRef.current;
    const isChromium =
      navigator.userAgent.includes("Chrome") ||
      navigator.userAgent.includes("Edg");

    if (form && isChromium) {
      const inputs = form.querySelectorAll("input");

      if (inputs && inputs.length > 0) {
        inputs.forEach((input) => {
          if (input.matches(":-internal-autofill-selected")) {
            input.classList.add("autofilled");
          }
        });
      }
    }
  }, 200);

  useEffect(() => {
    if (smooth_login === "on") {
      setSpanHeights();
      window.addEventListener("resize", setSpanHeights);

      return () => {
        window.removeEventListener("resize", setSpanHeights);
      };
    }
  }, [smooth_login, setSpanHeights]);

  useEffect(() => {
    if (smooth_login === "on") {
      setSpanHeights();
    }
  }, [emailError, passwordError, smooth_login, setSpanHeights]);

  useEffect(() => {
    let google_error_code = params.get("e");
    let google_error = "";
    if (google_error_code !== null && google_error_code !== "") {
      google_error = errors.find(
        ({ error_code }) => error_code === google_error_code
      );
      if (google_error !== undefined) {
        window.toastr.error(google_error.msg);
      } else {
        window.toastr.error(google_error_code);
      }
    }

    if (auth === undefined) return;
    if (willRedirect) {
      if (!auth === false) {
        if (auth.status === "active") {
          handleRedirect("/my-account");
          return;
        }

        const redirectPath = members === "on" ? "/my-account" : "/pricing";
        let currentLocation = window.location.href;
        let prefix = "";

        const urlParams = new URLSearchParams(window.location.search);
        const fromChatappQS = urlParams.get("from_chatapp");

        if (fromChatappQS === "on" && redirectPath === "/pricing") {
          if (currentLocation.indexOf("staging") > -1) {
            prefix = "staging.";
          } else if (currentLocation.indexOf("dev") > -1) {
            prefix = "dev.";
          }
          handleRedirect("https://" + prefix + "chatpro.ai-pro.org/");
        } else {
          handleRedirect(redirectPath);
        }
      }
    }
  }, [auth, willRedirect, members]);

  const validateEmail = () => {
    let isPassed = false;
    if (!email) {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
      isPassed = true;
    }
    return isPassed;
  };

  const validatePassword = () => {
    let msg = ValidatePassword(password);
    if (msg) {
      setPasswordError(msg);
      return false;
    }
    return true;
  };

  function registerUser() {
    setWillRedirect(false);
    var isEmailPassed = validateEmail();
    var isPasswordPassed = validatePassword();
    if (!isEmailPassed || !isPasswordPassed) return;

    document.querySelector(".loader-container").classList.add("active");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/t/register`,
        {
          email,
          password,
          pass_con: password,
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then(function (res) {
        let output = res.data;

        if (output.success) {
          try {
            // Quora tracking
            window.qp("track", "CompleteRegistration");
            // TikTok pixel tracking code
            window.ttq.identify({ email: `${email}` });
            window.ttq.track("Registration Page", {
              contents: [
                {
                  content_id: `${output.data.login_token}`,
                  content_name: `${email}`,
                },
              ],
              description: "Registration (Arcana)",
            });
          } catch (error) {
            console.error("An error occurred:", error.message);
          }
          window.toastr.success("Success");
          SetCookie("access", output.data.login_token);

          if (members === "on") {
            handleRedirect("/my-account");
          } else {
            handleRedirect("/pay");
          }
          return;
        }
        document.querySelector(".loader-container").classList.remove("active");
        if (output.data) window.toastr.error(output.data.msg);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 429) {
          document
            .querySelector(".loader-container")
            .classList.remove("active");
          window.toastr.error(
            "Sorry, too many requests. Please try again in a bit!"
          );
        }
      });
  }

  const handleChange = (event, inputType) => {
    const el = event.target;
    const value = el.value;
    const isFocus = event.type === "focus";
    const isInput = event.type === "change" || event.type === "keyup";

    if (!!value || isFocus || isInput) {
      el.classList.add("autofilled");
    } else {
      el.classList.remove("autofilled");
    }

    if (smooth_login !== "on" || (smooth_login === "on" && isInput)) {
      switch (inputType) {
        case "email":
          setEmailError("");
          setEmail(value);
          break;
        case "password":
          setPasswordError("");
          setPassword(value);
          break;
        default:
          if (event.keyCode === 13) {
            registerUser();
          }
          break;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Pro | Account Registration</title>
        <meta
          name="description"
          content="Join now and register for a free account at AI Pro. Unlock access to cutting-edge AI tools and resources. Sign up today to get started!"
        />
      </Helmet>
      {fromChatapp ? null : <Header />}
      <div className="p-[20px] bg-gray-100 min-h-[600px] h-screen flex justify-center items-center pt-10 md:pt-2">
        <div className="container py-10 px-0 md:px-4 sm:px-0 w-96 mx-auto">
          <div className="reg_col text-center mb-8">
            <h1 className="text-2xl lg:text-2xl font-bold text-center mb-6 lg:mb-8">
              Create your account
            </h1>
            <div
              className="bg-white rounded-lg shadow-lg overflow-hidden"
              ref={formRef}
            >
              <div className="px-6 py-10">
                {smooth_login === "on" ? (
                  <>
                    <div className="bg-white w-full relative mb-3 sm:text-sm">
                      <input
                        className="peer block border-2 border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1  w-full pt-6 pb-2 px-6 placeholder:text-transparent placeholder:[user-select:_none]"
                        placeholder="Email *"
                        type="email"
                        name="email"
                        onBlur={(e) => handleChange(e, "email")}
                        onChange={(e) => handleChange(e, "email")}
                        onFocus={(e) => handleChange(e, "email")}
                        onKeyUp={handleChange}
                      />
                      <label
                        className="transition-all text-slate-400 duration-100 ease-in absolute top-1/2 -translate-y-1/2 left-6 pointer-events-none [.autofilled~&]:top-[17px] [.autofilled~&]:text-xs [.autofilled~&]:left-[25px]"
                        for="email"
                      >
                        Email
                      </label>
                    </div>
                    <span
                      className="block text-red-500 text-xs text-center w-full h-0 transition-[height] duration-200 ease-in overflow-hidden"
                      ref={(el) => (spanErrorRefs.current[0] = el)}
                    >
                      {emailError}
                    </span>
                    <div className="bg-white w-full relative mb-3 sm:text-sm">
                      <input
                        className="peer block border-2 border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1  w-full pt-6 pb-2 px-6 placeholder:text-transparent placeholder:[user-select:_none]"
                        placeholder="Password *"
                        type="password"
                        name="password"
                        onBlur={(e) => handleChange(e, "password")}
                        onChange={(e) => handleChange(e, "password")}
                        onFocus={(e) => handleChange(e, "password")}
                        onKeyUp={handleChange}
                      />
                      <label
                        className="transition-all text-slate-400 duration-100 ease-in absolute top-1/2 -translate-y-1/2 left-6 pointer-events-none [.autofilled~&]:top-[17px] [.autofilled~&]:text-xs [.autofilled~&]:left-[25px]"
                        for="password"
                      >
                        Password
                      </label>
                    </div>
                    <span
                      className="block text-red-500 text-xs text-center w-full h-0 transition-[height] duration-200 ease-in overflow-hidden"
                      ref={(el) => (spanErrorRefs.current[1] = el)}
                    >
                      {passwordError}
                    </span>
                  </>
                ) : (
                  <label className="relative block">
                    {emailError && (
                      <span className="text-red-500 text-xs text-left w-full mb-2">
                        {emailError}
                      </span>
                    )}
                    <input
                      className="placeholder:italic placeholder:text-[#6E7277] block bg-white w-full border border-slate-300 rounded-md mb-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      placeholder="Email Address *"
                      aria-label="Email Address"
                      type="email"
                      name="email"
                      onBlur={validateEmail}
                      onKeyUp={(event) => {
                        setEmailError("");
                        setEmail(event.target.value);
                        if (event.keyCode === 13) registerUser();
                      }}
                    />
                    {passwordError && (
                      <span className="text-red-500 text-xs text-left w-full mb-2">
                        {passwordError}
                      </span>
                    )}
                    <input
                      className="placeholder:italic placeholder:text-[#6E7277] block bg-white w-full border border-slate-300 rounded-md mb-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      placeholder="Password *"
                      aria-label="Password"
                      type="password"
                      name="password"
                      onBlur={validatePassword}
                      onKeyUp={(event) => {
                        setPasswordError("");
                        setPassword(event.target.value);
                        if (event.keyCode === 13) registerUser();
                      }}
                    />
                  </label>
                )}
                <motion.button
                  onClick={registerUser}
                  className="bg-blue-600 mb-1 text-white font-bold py-3 px-6 my-3 rounded-lg w-full register-btn"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  aria-label="continue"
                >
                  Continue
                </motion.button>
                <motion.button
                  onClick={() => handleRedirect("/login")}
                  className="bg-gray-50 mb-1 text-black py-3 rounded-lg w-full"
                  whileHover={{ backgroundColor: "#eee" }}
                  whileTap={{ scale: 0.9 }}
                  aria-label="login"
                >
                  Login
                </motion.button>

                {reg_google === "on" ? (
                  <div className="inline-flex mt-[25px] w-full">
                    <button
                      type="button"
                      className="bg-white w-full py-3 border border-slate-300 rounded-lg"
                      onClick={() => handleRedirect("/google/register")}
                      aria-label="register via google"
                    >
                      <div className="inline-flex align-middle mr-[5px]">
                        <img
                          src={img1}
                          loading="lazy"
                          alt="GoogleLogin"
                          className="w-[20px] max-h-[20px]"
                        />
                      </div>
                      <div className="inline-flex">Register via Google</div>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
