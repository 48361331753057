export function ValidatePassword(password, label = 'Password') {
    let msg = '';
    if (!password) {
        msg = 'Password is required';
    } else if (password.length < 6) {
        msg = 'Password should be at least 6 characters';
    }
    return msg;
}

export function ValidateConfirmPassword(password, confpassword) {
    let msg = '';

    if(password !== confpassword) {
        msg = 'Passwords do not match.';
    }

    return msg;
}