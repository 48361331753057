import React, { useState, useEffect, useRef } from 'react';
import './start-chat-gpt.css';
import Header from '../header';
import Headerlogo from '../header/headerlogo';
import Footer from '../footer';
import "particles.js";
import { Auth } from '../core/utils/auth';
import img1 from '../assets/images/chatgpt_sm.gif';
import img23 from '../assets/images/img23.png';
import img24 from '../assets/images/img24.png';
import img25 from '../assets/images/img25.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
import BackToTopButton from '../footer/backtotop';
import { GetCookie } from '../core/utils/cookies';
import ailogocert from '../assets/images/openai-03.png';

function StartChatGpt() {
  const auth = Auth();
  const [isMobile, setIsMobile] = useState(false);
  const [navmenu, setNavmenu] = useState('hide');
  const [lp_vid, setLpVid] = useState('off');
  const [splash, setSplash] = useState('');
  const [ailplogo, setAilplogo] = useState('off');
  const headerRef = useRef(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const hideLinks = GetCookie('qW1eMlya') && GetCookie('qW1eMlya') === 'on' ? true : false;

  useEffect(() => {
    // checkflag
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('navmenu=')) {
        setNavmenu(cookie.substring('navmenu='.length));
      } else if (cookie.startsWith('splash=')) {
        setSplash(cookie.substring('splash='.length));
      } else if (cookie.startsWith('ailplogo=')) {
        setAilplogo(cookie.substring('ailplogo='.length));
      } else if (cookie.startsWith('lp_vid=')) {
        setLpVid(cookie.substring('lp_vid='.length));
      }
    }
    // end checkflag

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      const headerBottom = headerRef.current.getBoundingClientRect().bottom;
      setIsHeaderVisible(window.pageYOffset < headerBottom);
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };

  }, [auth]);

  const checkSubscription = () => {
    if(!(auth)){
      window.location.href = '/register';
    } else if (auth && auth.status === 'active') {
      window.location.href = '/my-account';
    } else {
      window.location.href = '/pricing';
    }
  };

  if(auth === undefined) return;
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>AI Pro | Start Using ChatGPT</title>
        <meta name="description" content="Discover ChatGPT and engage in natural, human-like conversations and get answers to your questions. Experience the future of AI-driven communication." />
      </Helmet>
      {navmenu === 'show' ? (
        <Header hideNavLink={hideLinks} />
      ) : (
        <Headerlogo hideNavLink={hideLinks} />
      )}
        <div ref={headerRef}></div>
        <BackToTopButton isHeaderVisible={isHeaderVisible} />
        <div className="startchatgpt bg-gray-100">
          <div id="particles-jsbg" className="z-0 top-0 md:top-8"></div>
          <div className="intro container mx-auto pt-0 pb-10 md:pt-20 md:px-20">
            <div className="flex flex-col md:flex-row pt-16 md:pt-10 lg:pt-16 pb-10 lg:p-[70px] lg:pb-10">
              <div className="md:w-1/2 p-8 lg:ml-8 text-center md:text-left">
                <h4 className="text-md font-bold sm:pt-6 lg:pt-8 lg:mt-2">ChatGPT AI is Finally Here</h4>
                <h1 className="text-4xl font-black mb-4 sm:pt-4">Start Using ChatGPT</h1>
                <p className="text-[16px] mb-4">
                  Automate your writing, answer questions, write code and much more. Get more creative and free up your time using ChatGPT’s powerful language capabilities, powered by GPT-4.<br/><br/>
                  The most powerful AI Language ChatBot available today.
                </p>
                <button className="ctabtn gradient-hover-effect text-white font-bold py-3 px-6 rounded-lg mx-auto md:mx-0" onClick={splash === 'on' ? () => { window.location.href = '/splash' } : checkSubscription}>Start Now</button>
                {ailplogo === 'on' ? <img src={ailogocert} alt="openai" className="w-[140px] pt-4 mx-auto md:mx-0"/> : ''}
              </div>
              <div className="md:w-1/2 banner_img">
                <img src={img1} alt="chatgpt" className="w-full shadow"/>
              </div>
            </div>
          </div>
          <div className="features container mx-auto md:px-28">
            <div className="lg:flex lg:justify-center text-center">
              <div className="w-full max-w-screen-lg mt-16">
                <h2 className="text-3xl lg:text-4xl font-bold mb-8">
                  For Business, School, and Life
                </h2>
                <p className="mx-auto max-w-2xl mb-16 text-center">
                  By utilizing ChatGPT‘s AI–powered conversational interface, businesses can improve their workflow, customer service and automate tasks.
                </p>
              </div>
            </div>
            <div className="lg:flex lg:justify-center">
              <div className="lg:w-full">
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10 text-center">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-window-restore text-blue-600 pb-4" aria-hidden="true"></i> Draft Contracts & Proposals
                    </h4>
                    <p className="text-md mx-auto">
                      ChatGPT can help you create contracts and proposals more easily. This saves you time and effort because you don't have to start from scratch. ChatGPT can also check your drafts for mistakes and make sure they are formatted correctly. Make the process of creating contracts and proposals quicker and easier with ChatGPT.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10 text-center">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-envelope text-blue-600 pb-4" aria-hidden="true"></i> Write Emails & Chat Replies
                    </h4>
                    <p className="text-md mx-auto">
                      ChatGPT can help you save time and effort by writing emails and chat replies for your work or business. ChatGPT can also assist with editing and proofreading the generated responses to ensure they are accurate and professional. Let ChatGPT handle your email and chat communication, freeing you up to focus on more important tasks.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10 text-center">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-video-camera text-blue-600 pb-4" aria-hidden="true"></i> Create Video Scripts
                    </h4>
                    <p className="text-md mx-auto">
                      ChatGPT can assist you in creating video scripts by automating the process of generating draft scripts based on certain prompts or keywords. ChatGPT can also help with editing and proofreading the generated drafts to ensure they are accurate and well-written. Utilize ChatGPT's capabilities to streamline the process of creating video scripts.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-8">
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10 text-center">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-pencil-square text-blue-600 pb-4" aria-hidden="true"></i> Write Stories, Poems & Songs
                    </h4>
                    <p className="text-md mx-auto">
                      ChatGPT is a powerful language model that can assist with writing tasks, including songwriting, poetry, stories, and books. Simply provide a prompt and let the model generate creative and original ideas. ChatGPT can write in various styles and tones. Try it out to boost creativity and productivity.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10 text-center">
                    <h4 className="text-md font-bold mb-4">
                      <i className="fa fa-code text-blue-600 pb-4" aria-hidden="true"></i> Use Prompts & Commands
                    </h4>
                    <p className="text-md mx-auto">
                      Explore our comprehensive resources and enhance your understanding of AI prompts and commands. From beginner to advanced, our guides will help you improve your skills and strengthen your communication with your AI. Get started now and take your AI capabilities to the next level.
                    </p>
                  </div>
                  <div className="lg:w-1/3 text-left mb-4 lg:mb-0 py-5 px-10 text-center">
                    <i className="fa fa-credit-card text-blue-600 pb-4" aria-hidden="true"></i>
                    <h4 className="text-md font-bold mb-4">
                      Make Money with AI
                    </h4>
                    <p className="text-md mx-auto">
                      Looking to leverage the power of AI to generate income? We will show you how to utilize AI to create new streams of revenue. You'll learn the skills and strategies you need to succeed in the lucrative world of AI. Don't miss out on this opportunity to transform your financial future with AI!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col mx-auto py-6">
              <motion.button
                onClick={splash === 'on' ? () => { window.location.href = '/splash' } : checkSubscription}
                className="bg-blue-500 text-white font-bold py-3 px-6 mb-12 rounded-lg mx-auto ctabtn"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Start Now
              </motion.button>
            </div>
          </div>

          <div className="gallery mx-auto">
            <div className="flex flex-col items-center lg:py-8 bg-blue-400">
              {isMobile ? (
                <div className="flex flex-col lg:flex-row justify-center">
                  <Carousel className="py-4" showArrows={true} emulateTouch={true} autoPlay={true} centerSlidePercentage={true}>
                    <img src={img23} alt="img23" className="lp_img block md:inline w-full lg:w-1/3 p-2"/>
                    <img src={img24} alt="img24" className="lp_img block md:inline w-full lg:w-1/3 p-2"/>
                    <img src={img25} alt="img25" className="lp_img block md:inline w-full lg:w-1/3 p-2"/>
                  </Carousel>
                </div>
              ) : (
                <>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                  <img src={img23} alt="img23" className="lp_img block md:inline flex-grow w-full lg:w-1/3 p-2"/>
                  <img src={img24} alt="img24" className="lp_img block md:inline flex-grow w-full lg:w-1/3 p-2"/>
                  <img src={img25} alt="img25" className="lp_img block md:inline flex-grow w-full lg:w-1/3 p-2"/>
                </div>
                </>
              )}
            </div>
          </div>

          {lp_vid === 'on' ? (
            <div className="vidgallery mx-auto flex justify-center px-4 py-8 sm:px-12 sm:py-24">
              <iframe width="900" height="500" src="https://www.youtube.com/embed/BbZCYDHjsiU" title="ChatGPT" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
            </div>
          ) : (
            <div className="vidgallery mx-auto">
            </div>
          )}

        </div>
        <BackToTopButton isHeaderVisible={isHeaderVisible} />
      <Footer auth={auth} hideNavLink={hideLinks}/>
    </>
  );
}

export default StartChatGpt;
