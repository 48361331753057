import React, { useState, useEffect } from 'react';
import './style.css';
import { motion } from "framer-motion";
import Header from '../header';
import { GetCookie, RemoveCookie } from '../core/utils/cookies';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
const { getSignedUrl } = require("@aws-sdk/s3-request-presigner");


const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

function Thankyou() {
  const [user] = useState(window.user_data);
  const [ctaText, setCtaText] = useState('');
  const [url, setUrl] = useState('');
  const [redirectMyAccount, setRedirectMyAccount] = useState(true);

  useEffect(() => {

    if (!user.email){
      window.location.href = '/login';
      return;
    }
    // Quora tracking
    window.qp('track', 'Purchase');
    if (window.twq) {
      window.twq('event', 'tw-oebtr-oebtv', {
        value: "Thank You Page (Arcana)",
        contents: [{
          content_id: `${user.user_pid}`,
          content_type: `${user.plan}`,
          content_name: `${user.email}`
        }]
      });
    } else {
      console.error('Twitter script not loaded');
    }
  }, [user]);

  useEffect(() => {
    const avatarMaker = GetCookie('avatarmaker');
    const appUrl = GetCookie('appurl');

    if (appUrl && (avatarMaker && appUrl.includes('avatar'))) {
      const path = `avatarmaker/${process.env.REACT_APP_AWS_ENV}/${avatarMaker}/image_0.png`;
      const command = new GetObjectCommand({
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: path,
      });
    getSignedUrl(s3Client, command)
      .then((url) => {
        if (url !== '') {
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'highresimage.png');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => console.log('Error:', error));
      RemoveCookie('avatarmaker', { domain: '.ai-pro.org', path: '/' });
    }
  }, []);
  const appurl = GetCookie('appurl') ? GetCookie('appurl') : '';
  const unpFlow = GetCookie('unpFlow') ? GetCookie('unpFlow') : ''; // #28596
  if(!url && unpFlow === '1') {
    if(appurl) {
      if(appurl.includes("http:") || appurl.includes("https:")) {
        setUrl(appurl);
      } else {
        setUrl("https://" + appurl);
      }
      setRedirectMyAccount(false);
      setCtaText("Continue");
    } else {
      setCtaText("Continue to your Account");
      setUrl('/my-account');
    }
  } else if (!url) {
    setCtaText("Continue to your Account");
    setUrl('/my-account');
  }

  const email = user.email;

  useEffect(() => {
      // TikTok pixel tracking code
    if (window.ttq) {
      window.ttq.identify({ "email": `${email}` })
      window.ttq.track('Payment Page', {
        "contents": [{
            "content_id": `${user.user_pid}`,
            "content_type": `${user.plan}`,
            "content_name": `${user.email}`
          }],
          "value": `${user.plan_id}`,
          "currency": `${user.currency.toUpperCase()}`,
          "description": "Thank you Page (Arcana)"
      });
    } else {
      console.error('Tiktok script not loaded');
    }
  }, [email, user.user_pid, user.plan, user.email, user.plan_id, user.currency]);
  
  return (
    <>
      <Header auth={user} />
      <div className="Thankyou bg-gray-100 min-h-[600px] flex justify-center items-center">
        <div className="container mx-auto py-10 px-4 sm:px-0">
          <div className="reg_col text-center mb-8">
            <h1 className="text-2xl lg:text-3xl font-bold text-center mb-6 lg:mb-8"><span className="text-gradient font-black">Thank you</span> for subscribing.</h1>
            <div className="overflow-hidden">
              <div className="px-6 py-10">
                  <div className="relative block">
                    You now have access to our entire library of content, dedicated to AI learning.<br/>
                    { redirectMyAccount ? "Click below to continue to your main account page." : "Click below to continue." }
                    <input type="hidden" id="tyemail" value={email} />
                  </div>
              </div>
            </div>
            <motion.a
              className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
              whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
              whileTap={{ scale: 0.9 }}
              href={url}
            >
              { ctaText }
            </motion.a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Thankyou;