import { io } from 'socket.io-client';
import { GetCookie } from './cookies';

export function initSocket() {
  let aiwp_logged_in = GetCookie('aiwp_logged_in');
  let run_socket = true;
  if (aiwp_logged_in!==undefined && aiwp_logged_in!==null && aiwp_logged_in!==""){
    let myarr = aiwp_logged_in.split("|");
    if(typeof myarr[2] !== 'undefined') {
      if (myarr[2]==='1'){
        run_socket = false;
      }
    }
  }
  
  if (!run_socket){
    return;
  }

  const URL = process.env.REACT_APP_SOCKET_URL;
  const socket = io(URL);
  let sidx = ''; // user_ip
  if(GetCookie('user_ip')) {
    sidx = GetCookie('user_ip');
  }

  socket.emit('register-session', { id: sidx, email: GetCookie('user_email'), socket_key: process.env.REACT_APP_SOCKET_KEY });

  return { socket, sidx };
}