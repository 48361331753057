import React, { useState, useEffect } from 'react';
import './style.css';
import { motion } from "framer-motion";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { FaArrowRight } from 'react-icons/fa';
import img1 from '../assets/images/dream-photo-icon.png';
import img2 from '../assets/images/interior-ai-icon.png';
import img3 from '../assets/images/restore-photo-icon.png';
import img4 from '../assets/images/remove-background-icon.png';
import avatar_maker_icon from '../assets/images/avatar-maker-icon.png';
import story_book_icon from '../assets/images/story-book-icon.png';
import ask_vid_icon from '../assets/images/ask-vid-icon.png';
import { returnAppHref, redirectApp } from '../core/utils/app';
const tools = process.env.REACT_APP_TOOLS_URL ? JSON.parse(process.env.REACT_APP_TOOLS_URL) : {};

function AiArt(props) {
  const [isMobile, setIsMobile] = useState(false);
  const auth = props && props.auth ? props.auth : null;
  const upid = auth && auth.user_pid ? "?upid=" + auth.user_pid : "";

  const text2imagelink = tools.txt2img ? tools.txt2img + upid : 'https://app.ai-pro.org/dream-photo'+upid;
  const interiorgptlink = tools.interiorgpt ? tools.interiorgpt + upid : 'https://interiorgpt.ai-pro.org'+upid;
  const restorephotolink = tools.restorephoto ? tools.restorephoto + upid : 'https://restorephotos.ai-pro.org'+upid;
  // const imagepromptlink = 'https://ai-pro.org/ai-tool-ai-image-prompt-gallery' + upid;
  const removebg = tools.removebg ? tools.removebg + upid : 'https://clearbg.ai-pro.org'+upid;
  const avatar_maker_link = tools.avatar_make ? tools.avatar_make + upid : 'https://avatarmaker.ai-pro.org'+upid;
  const story_book_link = tools.storybook ? tools.storybook + upid : 'https://storybook.ai-pro.org'+upid;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850);
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fnRedirectApp = (url) => {
    redirectApp(auth, url);
  };

  const fnReturnAppHref = (url) => {
    return returnAppHref(auth, url);
  };
  return (
    <div className="aiart bg-gray-100 flex items-center mt-4 md:mt-0">
      <div className="container mx-auto sm:px-0">
        <div className="max-w-6xl mx-auto pb-5">
          <div className="bg-white drop-shadow-sm py-6 px-4 rounded-tr-md rounded-br-md rounded-bl-md flex flex-col">
            <div className="apptitle flex flex-wrap">
              <h1 className="text-xl font-bold text-gray-800 inline w-56">
                AI Art-Generator
              </h1>
              <span className="text-[12px] text-left md:text-right ml-auto md:w-96">Access the latest AI Art generation software. Describe what you want to create, and within seconds you have your image.</span>
            </div>
            { isMobile ? (
              <Carousel className="py-4" infiniteLoop={true} showArrows={true} emulateTouch={true} autoPlay={true} centerSlidePercentage={true}>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={img1} alt="DreamPhoto" aria-label="DreamPhoto" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">DreamPhoto</h4>
                  </div>

                  <p className="mydesc text-[14px] text-black text-left">Create amazing digital artwork from text and phrases using the latest AI Image Generation technology.</p>
                  <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(text2imagelink)}
                      target={ fnReturnAppHref(text2imagelink) === text2imagelink ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(text2imagelink)}}
                      name="Go to DreamPhoto" aria-label="Go to DreamPhoto" role="button"
                    >
                      Go to DreamPhoto <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={img2} alt="Interior Ai" aria-label="Interior Ai" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">Interior AI</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">Generate dream rooms using AI. Upload a photo of your room and see how artificial intelligence remodels your space virtually.</p>
                  <motion.a
                    className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    whileHover={{ backgroundColor: "#5997fd" }}
                    whileTap={{ scale: 0.9 }}
                    href={fnReturnAppHref(interiorgptlink)}
                    target={ fnReturnAppHref(interiorgptlink) === interiorgptlink ? "_blank" : "" }
                    onClick={()=>{fnRedirectApp(interiorgptlink)}}
                    name="Go to Interior AI" aria-label="Go to Interior AI" role="button"
                  >
                    Go to Interior AI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={img3} alt="RestorePhoto" aria-label="RestorePhoto" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">Restore Photo</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">Effortlessly restore and enhance your old photos with cutting-edge artificial intelligence.</p>
                  <motion.a
                    className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 px-3 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    whileHover={{ backgroundColor: "#5997fd" }}
                    whileTap={{ scale: 0.9 }}
                    href={fnReturnAppHref(restorephotolink)}
                    target={ fnReturnAppHref(restorephotolink) === restorephotolink ? "_blank" : "" }
                    onClick={()=>{fnRedirectApp(restorephotolink)}}
                    name="Go to Restore Photo" aria-label="Go to Restore Photo" role="button"
                  >
                    Go to Restore Photo <FaArrowRight className="inline text-xs mt-0.5 float-right"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={img4} alt="Remove Background" aria-label="Remove Background" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">Remove Background</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">Seamlessly remove backgrounds from your photos, making your subjects stand out.</p>
                  <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(removebg)}
                      target={ fnReturnAppHref(removebg) === removebg ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(removebg)}}
                      name="Go to Remove BG" aria-label="Go to Remove BG" role="button"
                    >
                      Go to Remove BG <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="ribbon-container">
                    <div className="ribbon">NEW</div>
                  </div>
                  <div className="flex mytitle items-center">
                    <img src={avatar_maker_icon} alt="Avatar Maker" aria-label="Avatar Maker" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]" style={{ height:"100px", width:"auto" }} />
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">Avatar Maker</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                    Transform user-uploaded images into customized artworks,
                    adhering to specific prompts and delivering unique visual
                    interpretations.
                  </p>
                  <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(avatar_maker_link)}
                      target={ fnReturnAppHref(avatar_maker_link) === avatar_maker_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(avatar_maker_link)}}
                      name="Go to Avatar Maker" aria-label="Go to Avatar Maker" role="button"
                    >
                      Go to Avatar Maker <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="ribbon-container">
                    <div className="ribbon">NEW</div>
                  </div>
                  <div className="flex mytitle items-center">
                    <img src={story_book_icon} alt="StoryBook" aria-label="StoryBook" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]" style={{ height:"100px", width:"auto" }} />
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">StoryBook</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                  Seamlessly create captivating visuals from simple prompts, opening up a realm of boundless creative potential, perfect for crafting stunning illustrations for your books.
                  </p>
                  <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(story_book_link)}
                      target={ fnReturnAppHref(story_book_link) === story_book_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(story_book_link)}}
                      name="Go to StoryBook" aria-label="Go to StoryBook" role="button"
                    >
                      Go to StoryBook <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={ask_vid_icon} alt="Ask My Video" aria-label="Ask My Video" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]" style={{ height:"100px", width:"auto" }} />
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">Ask My Video</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                  Engage in video interaction and deliver precise answers derived from the content itself.
                  </p>
                  <motion.a
                      tabIndex="-1"
                      className="bg-slate-400 cursor-default text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none' }}
                      href={ask_vid_icon}
                      whileTap={{ scale: 0.9 }}
                      name="Coming Soon" aria-label="Coming Soon" role="button"
                    >
                      Coming Soon
                  </motion.a>
                </div>
              </Carousel>
            ) : (
              <>
                <div className="apps md:flex md:flex-row">
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={img1} alt="DreamPhoto logo" aria-label="DreamPhoto logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">DreamPhoto</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">Create amazing digital artwork from text and phrases using the latest AI Image Generation technology.</p>
                    <motion.a
                        className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        whileHover={{ backgroundColor: "#5997fd" }}
                        whileTap={{ scale: 0.9 }}
                        href={fnReturnAppHref(text2imagelink)}
                        target={ fnReturnAppHref(text2imagelink) === text2imagelink ? "_blank" : "" }
                        onClick={()=>{fnRedirectApp(text2imagelink)}}
                        name="Go to DreamPhoto" aria-label="Go to DreamPhoto" role="button"
                      >
                        Go to DreamPhoto <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={img2} alt="Interior AI logo" aria-label="Interior AI logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">Interior AI</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">Generate dream rooms using AI. Upload a photo of your room and see how artificial intelligence remodels your space virtually.</p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(interiorgptlink)}
                      target={ fnReturnAppHref(interiorgptlink) === interiorgptlink ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(interiorgptlink)}}
                      name="Go to Interior AI" aria-label="Go to Interior AI" role="button"
                    >
                      Go to Interior AI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={img3} alt="RestorePhoto logo" aria-label="RestorePhoto logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">Restore Photo</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">Effortlessly restore and enhance your old photos with cutting-edge artificial intelligence.</p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 px-3 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(restorephotolink)}
                      target={ fnReturnAppHref(restorephotolink) === restorephotolink ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(restorephotolink)}}
                      name="Go to Restore Photo" aria-label="Go to Restore Photo" role="button"
                    >
                      Go to Restore Photo <FaArrowRight className="inline text-xs mt-0.5 float-right"/>
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={img4} alt="Remove Background logo" aria-label="Remove Background logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">Remove Background</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">Seamlessly remove backgrounds from your photos, making your subjects stand out.</p>
                    <motion.a
                        className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        whileHover={{ backgroundColor: "#5997fd" }}
                        whileTap={{ scale: 0.9 }}
                        href={fnReturnAppHref(removebg)}
                        target={ fnReturnAppHref(removebg) === removebg ? "_blank" : "" }
                        onClick={()=>{fnRedirectApp(removebg)}}
                        name="Go to Remove BG" aria-label="Go to Remove BG" role="button"
                      >
                        Go to Remove BG <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                </div>
                <div className="apps md:flex md:flex-row">
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="ribbon-container">
                      <div className="ribbon">NEW</div>
                    </div>
                    <div className="flex mytitle items-center">
                      <img
                        src={avatar_maker_icon}
                        alt="Avatar Maker logo"
                        aria-label="Avatar Maker logo"
                        
                        className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"
                      />
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">
                        Avatar Maker
                      </h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                      Transform user-uploaded images into customized artworks,
                      adhering to specific prompts and delivering unique visual
                      interpretations.
                    </p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(avatar_maker_link)}
                      target={ fnReturnAppHref(avatar_maker_link) === avatar_maker_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(avatar_maker_link)}}
                      name="Go to Avatar Maker" aria-label="Go to Avatar Maker" role="button"
                    >
                      Go to Avatar Maker
                      <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]" />
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="ribbon-container">
                      <div className="ribbon">NEW</div>
                    </div>
                    <div className="flex mytitle items-center">
                      <img
                        src={story_book_icon}
                        alt="StoryBook logo"
                        aria-label="StoryBook logo"
                        
                        className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"
                      />
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">
                        StoryBook
                      </h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                    Seamlessly create captivating visuals from simple prompts, opening up a realm of boundless creative potential, perfect for crafting stunning illustrations for your books.
                    </p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(story_book_link)}
                      target={ fnReturnAppHref(story_book_link) === story_book_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(story_book_link)}}
                      name="Go to StoryBook" aria-label="Go to StoryBook" role="button"
                    >
                      Go to StoryBook
                      <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]" />
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img
                        src={ask_vid_icon}
                        alt="Ask My Video logo"
                        aria-label="Ask My Video logo"
                        className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"
                      />
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2">
                        Ask My Video
                      </h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                    Engage in video interaction and deliver precise answers derived from the content itself.
                    </p>
                    <motion.a
                      tabIndex="-1"
                      className="bg-slate-400 cursor-default text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: "none" }}
                      whileTap={{ scale: 0.9 }}
                      name="Coming Soon" aria-label="Coming Soon" role="button"
                    >
                      Coming Soon
                    </motion.a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AiArt;