import React from 'react';
import './style.css';
import aiproLogo from '../assets/images/AIPRO.png';

function welcome_email() {

  return (
    <div className="bg-gray-100 flex items-center text-left w-full">
      <div className="container mx-auto max-w-3xl mx-auto mt-10">

        <div class="max-w-3xl mx-auto bg-white p-8 mt-1 rounded shadow">
        <h1 class="text-md font-bold mb-2 text-blue-800 mb-9">Subject: Welcome to AI-Pro!</h1>
          <p class="mb-6">Hi <span class="font-bold">%FirstName%</span>,</p>
          <p class="mb-6">Thank you for registering with us.</p>
          <p class="mb-6">Log in to your account <a href="login" class="text-blue-500">here</a>.</p>
          <p class="mb-6">Your subscription details are as follows:</p>
          <ul class="mb-6 bg-gray-100 p-4">
            <li>Username: <span class="font-bold">%fillinemailaddress%</span></li>
            <li>Plan: <span class="font-bold">%fillinplanname% (%fillinamount% monthly)</span></li>
          </ul>
          <p class="mb-6">We regularly update and release new apps and features to provide you with a comprehensive resource for all your AI needs.</p>
          <p class="mb-6">If you need assistance or have questions, you may contact our <a href="mailto:support@ai-pro.org" className="text-blue-500">support team</a>.</p>
          <a href="/" class="inline-block bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">Get Started</a>
          <p class="mt-6">Sincerely,</p>
          <p class="font-bold">AI-Pro Team</p>
          <img src={aiproLogo} alt="AI-Pro Logo" className="aiprologo"/>
        </div>
      </div>
    </div>
  );
}

export default welcome_email;