import React, { useState } from 'react';
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from 'axios';
import { ValidatePassword, ValidateConfirmPassword } from '../core/utils/validation';
import { GetSubdomain } from '../core/utils/cookies';
const Header = GetSubdomain() ? null : require('../header').default;
const Footer = GetSubdomain() ? null : require('../footer').default;

var email = '';
var tk = '';
async function checkAccessLink() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/check-access-link`, {
    email, tk
  }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })

  const output = response.data;
  if(output.success) {
    return true;
  } else {
    return false;
  }
}

function Resetpass() {
  let [searchParams] = useSearchParams();
  tk = searchParams.get('tk');
  email = searchParams.get('email');
  const { data } = useQuery("users", checkAccessLink);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const renderHeader = !GetSubdomain();

  const handleRedirect = (path) => {
    window.location.href = path;
  };

  if(data === undefined) return;
  if(data === false) {
    handleRedirect('/login');
    return;
  }

  const validatePassword = () => {
    let msg = ValidatePassword(password);
    if (msg) {
      setPasswordError(msg);
      return false;
    }

    return true;
  };

  const validateConfirmPassword = () => {
    let msg = ValidateConfirmPassword(password, confirmPassword);
    if (msg) {
      setPasswordError(msg);
      return false;
    }

    return true;
  }

  const handleResetPassword = (e) => {
    let isPassedValidate = validatePassword();
    let isConfirmPasswordPassedValidate = validateConfirmPassword();
    if(!isPassedValidate || !isConfirmPasswordPassedValidate) return;

    document.querySelector(".loader-container").classList.add('active');
    axios.post(`${process.env.REACT_APP_API_URL}/change-password`, {
      access_token: tk,
      password: password,
      confpassword: confirmPassword
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;
      if(output.success) {
        window.toastr.success("Success");
        setTimeout(function(){
          handleRedirect('/login');
        }, 3000);
        return;
      }

      document.querySelector(".loader-container").classList.remove('active');
      if(output.data) window.toastr.error(output.data.msg);
    });
  };
  
  return (
    <>
      {renderHeader && <Header />}
      <div className="register bg-gray-100 h-screen flex justify-center items-center pt-10 p-[20px] md:pt-2">
        <div className="container mx-auto py-10 px-0 md:px-4 sm:px-0 w-96 mx-auto">
          <div className="reg_col text-center mb-8">
            <h1 className="text-2xl lg:text-2xl font-bold text-center mb-6 lg:mb-8">Reset Password</h1>
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="px-6 py-10">

                  <label className="relative block">
                    {passwordError && <span className="text-red-500 text-xs text-left w-full mb-2">{passwordError}</span>}
                    <input
                      className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md my-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      placeholder="Enter New Password *"
                      aria-label="New Password"
                      type="password"
                      name="password"
                      onKeyUp={(e) => {
                        setPasswordError('');
                        setPassword(e.target.value);
                        if(e.keyCode === 13) handleResetPassword();
                      }}
                      onBlur={validatePassword}
                      required
                    />
                    <input
                      className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md my-3 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      placeholder="Confirm New Password *"
                      aria-label='Confirm Password'
                      type="password"
                      name="confirmpassword"
                      onKeyUp={(e) => {
                        setConfirmPassword(e.target.value);
                        if(e.keyCode === 13) handleResetPassword();
                      }}
                      required
                    />
                  </label>
                  <motion.button
                    type="submit"
                    className="bg-blue-600 text-white font-bold py-3 px-6 my-3 rounded-lg w-full"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handleResetPassword}
                    aria-label="Reset Password"
                  >
                    Reset Password
                  </motion.button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderHeader && <Footer />}
    </>
  );
}

export default Resetpass;
