import React from 'react';
import './style.css';
import Header from '../header';
import WelcomeEmail from './welcome_email';
import ChangePassword from './change_password';

function emailTemplates() {

  return (
    <>
      <Header />
      <div className="bg-gray-100 h-screen flex justify-center items-top pt-8 text-center">
        <div className="container mx-auto px-4 sm:px-0">
          <div className="mx-auto pb-5 pt-20">
              <h1 className="text-xl font-bold text-gray-800">
                Email Templates
              </h1>
              <WelcomeEmail />
              <ChangePassword />
          </div>
        </div>
      </div>
    </>
  );
}

export default emailTemplates;