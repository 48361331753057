export default function getPlan(user_plan, user_plan_currency) {
    var ppg_id = "";
    var urlParams = new URLSearchParams(window.location.search);

    const is_from_chatbotpro = (document.referrer.includes("chatpro") || document.referrer.includes("localhost:3090") || document.referrer.includes("localhost:3080"));
    const is_gpt4promaxbtn = urlParams.get('vjicon');

    var mx = urlParams.get('mx');
    if (mx===null){
        mx = "";
    }

    console.log('is_from_chatbotpro', is_from_chatbotpro);
    console.log('is_gpt4promaxbtn', is_gpt4promaxbtn);
    console.log('user_plan_currency', user_plan_currency);
    console.log('user_plan', user_plan);
    console.log('REACT_APP_PRO_MAX_UPGRADE_ID ',  process.env.REACT_APP_PRO_MAX_UPGRADE_ID );
    console.log('REACT_APP_BASIC_UPGRADE_ID2 ',  process.env.REACT_APP_BASIC_UPGRADE_ID2 );
    console.log('REACT_APP_BASIC_UPGRADE_ID ',  process.env.REACT_APP_BASIC_UPGRADE_ID );
    console.log('REACT_APP_BASIC_ANNUAL_UPGRADE_ID ',  process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID );
    console.log('REACT_APP_PRO_ANNUAL_UPGRADE_ID ',  process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID );
    console.log('REACT_APP_PRO_UPGRADE_ID ',  process.env.REACT_APP_PRO_UPGRADE_ID );
    console.log('REACT_APP_PROMAX_UPGRADE_ID ',  process.env.REACT_APP_PROMAX_UPGRADE_ID );
    console.log('REACT_APP_PROMAX_ANNUAL_UPGRADE_ID ',  process.env.REACT_APP_PROMAX_ANNUAL_UPGRADE_ID );
    
    if(!user_plan_currency || !user_plan) {
        return "";
    } else if(user_plan_currency === 'USD') {
        if(user_plan === 'BASIC' && is_from_chatbotpro && is_gpt4promaxbtn) {
            ppg_id = process.env.REACT_APP_PRO_MAX_UPGRADE_ID ? process.env.REACT_APP_PRO_MAX_UPGRADE_ID : ppg_id;
        } else if(user_plan === 'BASIC' && is_from_chatbotpro) {
            ppg_id = process.env.REACT_APP_BASIC_UPGRADE_ID2 ? process.env.REACT_APP_BASIC_UPGRADE_ID2 : ppg_id;
        } else if(user_plan === 'BASIC') {
            ppg_id = process.env.REACT_APP_BASIC_UPGRADE_ID ? process.env.REACT_APP_BASIC_UPGRADE_ID : ppg_id;
        } else if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID ? process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID ? process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID : ppg_id;
        } else if (user_plan === "PRO" && is_from_chatbotpro) {
            ppg_id = process.env.REACT_APP_PRO_MAX_UPGRADE_ID ? process.env.REACT_APP_PRO_MAX_UPGRADE_ID : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_UPGRADE_ID ? process.env.REACT_APP_PRO_UPGRADE_ID : ppg_id;
        } else if(user_plan === 'PROMAX') {
            ppg_id = process.env.REACT_APP_PROMAX_UPGRADE_ID ? process.env.REACT_APP_PROMAX_UPGRADE_ID : ppg_id;
        } else if(user_plan === 'PROMAX_ANNUAL') {
            ppg_id = process.env.REACT_APP_PROMAX_ANNUAL_UPGRADE_ID ? process.env.REACT_APP_PROMAX_ANNUAL_UPGRADE_ID : ppg_id;
        }

        if (mx==='1'){ //USD
            if(user_plan === 'BASIC' || user_plan === 'BASIC_ANNUAL') {
                ppg_id="16,74,73,75"; //Pro monthly, Pro Annual, Promax, Promax Annual
            }else if (user_plan === 'PRO' || user_plan === 'PRO_ANNUAL'){
                ppg_id="73,75"; // Promax, Promax Annual
            }else if (user_plan === 'PROMAX' || user_plan === 'PROMAX_ANNUAL'){
                ppg_id="62"; //Enterprise
            }
        }        
    } else if(user_plan_currency === 'GBP') {
        if(user_plan === 'BASIC') {
            ppg_id = process.env.REACT_APP_BASIC_UPGRADE_ID_GBP ? process.env.REACT_APP_BASIC_UPGRADE_ID_GBP : ppg_id;
        } else if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_GBP ? process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_GBP : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_UPGRADE_ID_GBP ? process.env.REACT_APP_PRO_UPGRADE_ID_GBP : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_GBP ? process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_GBP : ppg_id;
        } else if(user_plan === 'PROMAX') {
            ppg_id = process.env.REACT_APP_PROMAX_UPGRADE_ID_GBP ? process.env.REACT_APP_PROMAX_UPGRADE_ID_GBP : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_ENTERPRISE_ID;
        }

        if (mx==='1'){ //GBP
            if(user_plan === 'BASIC' || user_plan === 'BASIC_ANNUAL') {
                ppg_id="30,32,76,77"; //Pro monthly, Pro Annual, Promax, Promax Annual
            }else if (user_plan === 'PRO' || user_plan === 'PRO_ANNUAL'){
                ppg_id="76,77"; // Promax, Promax Annual
            }else if (user_plan === 'PROMAX' || user_plan === 'PROMAX_ANNUAL'){
                ppg_id="62"; //Enterprise
            }
        }        
    } else if(user_plan_currency === 'EUR') {
        if(user_plan === 'BASIC') {
            ppg_id = process.env.REACT_APP_BASIC_UPGRADE_ID_EUR ? process.env.REACT_APP_BASIC_UPGRADE_ID_EUR : ppg_id;
        } else if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_EUR ? process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_EUR : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_UPGRADE_ID_EUR ? process.env.REACT_APP_PRO_UPGRADE_ID_EUR : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_EUR  ? process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_EUR  : ppg_id;
        } else if(user_plan === 'PROMAX') {
            ppg_id = process.env.REACT_APP_PROMAX_UPGRADE_ID_EUR ? process.env.REACT_APP_PROMAX_UPGRADE_ID_EUR : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_ENTERPRISE_ID;
        }

        if (mx==='1'){ //EUR
            if(user_plan === 'BASIC' || user_plan === 'BASIC_ANNUAL') {
                ppg_id="34,36,78,79"; //Pro monthly, Pro Annual, Promax, Promax Annual
            }else if (user_plan === 'PRO' || user_plan === 'PRO_ANNUAL'){
                ppg_id="78,79"; // Promax, Promax Annual
            }else if (user_plan === 'PROMAX' || user_plan === 'PROMAX_ANNUAL'){
                ppg_id="62"; //Enterprise
            }
        }        
    } else if(user_plan_currency === 'BRL') {
        if(user_plan === 'BASIC') {
            ppg_id = process.env.REACT_APP_BASIC_UPGRADE_ID_BRL ? process.env.REACT_APP_BASIC_UPGRADE_ID_BRL : ppg_id;
        } else if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_BRL ? process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_BRL : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_UPGRADE_ID_BRL ? process.env.REACT_APP_PRO_UPGRADE_ID_BRL : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_BRL ? process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_BRL : ppg_id;
        } else if(user_plan === 'PROMAX') {
            ppg_id = process.env.REACT_APP_PROMAX_UPGRADE_ID_BRL ? process.env.REACT_APP_PROMAX_UPGRADE_ID_BRL : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_ENTERPRISE_ID;
        }

        if (mx==='1'){ //BRL
            if(user_plan === 'BASIC' || user_plan === 'BASIC_ANNUAL') {
                ppg_id="38,40,80,81"; //Pro monthly, Pro Annual, Promax, Promax Annual
            }else if (user_plan === 'PRO' || user_plan === 'PRO_ANNUAL'){
                ppg_id="80,81"; // Promax, Promax Annual
            }else if (user_plan === 'PROMAX' || user_plan === 'PROMAX_ANNUAL'){
                ppg_id="62"; //Enterprise
            }
        }        
    } else if(user_plan_currency === 'SAR') {
        if(user_plan === 'BASIC') {
            ppg_id = process.env.REACT_APP_BASIC_UPGRADE_ID_SAR ? process.env.REACT_APP_BASIC_UPGRADE_ID_SAR : ppg_id;
        } else if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_SAR ? process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_SAR : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_UPGRADE_ID_SAR ? process.env.REACT_APP_PRO_UPGRADE_ID_SAR : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_SAR ? process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_SAR : ppg_id;
        } else if(user_plan === 'PROMAX') {
            ppg_id = process.env.REACT_APP_PROMAX_UPGRADE_ID_SAR ? process.env.REACT_APP_PROMAX_UPGRADE_ID_SAR : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_ENTERPRISE_ID;
        }

        if (mx==='1'){ //SAR
            if(user_plan === 'BASIC' || user_plan === 'BASIC_ANNUAL') {
                ppg_id="42,44,82,83"; //Pro monthly, Pro Annual, Promax, Promax Annual
            }else if (user_plan === 'PRO' || user_plan === 'PRO_ANNUAL'){
                ppg_id="82,83"; // Promax, Promax Annual
            }else if (user_plan === 'PROMAX' || user_plan === 'PROMAX_ANNUAL'){
                ppg_id="62"; //Enterprise
            }
        }        
    } else if(user_plan_currency === 'AED') {
        if(user_plan === 'BASIC') {
            ppg_id = process.env.REACT_APP_BASIC_UPGRADE_ID_AED ? process.env.REACT_APP_BASIC_UPGRADE_ID_AED : ppg_id;
        } else if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_AED ? process.env.REACT_APP_BASIC_ANNUAL_UPGRADE_ID_AED : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_UPGRADE_ID_AED ? process.env.REACT_APP_PRO_UPGRADE_ID_AED : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_AED ? process.env.REACT_APP_PRO_ANNUAL_UPGRADE_ID_AED : ppg_id;
        } else if(user_plan === 'PROMAX') {
            ppg_id = process.env.REACT_APP_PRO_MAX_UPGRADE_ID_AED ? process.env.REACT_APP_PRO_MAX_UPGRADE_ID_AED : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_ENTERPRISE_ID;
        }

        if (mx==='1'){ //AED
            if(user_plan === 'BASIC' || user_plan === 'BASIC_ANNUAL') {
                ppg_id="46,48,84,85"; //Pro monthly, Pro Annual, Promax, Promax Annual
            }else if (user_plan === 'PRO' || user_plan === 'PRO_ANNUAL'){
                ppg_id="84,85"; // Promax, Promax Annual
            }else if (user_plan === 'PROMAX' || user_plan === 'PROMAX_ANNUAL'){
                ppg_id="62"; //Enterprise
            }
        }                
    }
    console.log('ppg_id:', ppg_id);
    return ppg_id;
}