import React, { useState, useEffect, useRef } from 'react';
import './text-to-image.css';
import Header from '../header';
import Headerlogo from '../header/headerlogo';
import Footer from '../footer';
import { Auth } from '../core/utils/auth';
import img1 from '../assets/images/ai-art-robot.png';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
import lpPic1 from '../assets/images/wizard-1.png';
import BackToTopButton from '../footer/backtotop';
import { GetCookie } from '../core/utils/cookies';

function Test2Image() {
  const auth = Auth();
  const [navmenu, setNavmenu] = useState('hide');
  const headerRef = useRef(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const hideLinks = GetCookie('qW1eMlya') && GetCookie('qW1eMlya') === 'on' ? true : false;

  useEffect(() => {
    // checkflag
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('navmenu=')) {
        setNavmenu(cookie.substring('navmenu='.length));
        break;
      }
    }
    // end checkflag

    const handleScroll = () => {
      const headerBottom = headerRef.current.getBoundingClientRect().bottom;
      setIsHeaderVisible(window.pageYOffset < headerBottom);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };


  }, [auth]);
  
  const iframeSrc = process.env.REACT_APP_TXT2IMG;

  const checkSubscription = () => {
    if(!(auth)){
      window.location.href = '/register';
    } else if (auth && auth.status === 'active') {
      window.location.href = '/my-account';
    } else {
      window.location.href = '/pricing';
    }
  };

  if(auth === undefined) return;
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>AI Pro | Create Art | Visualize Your Words</title>
        <meta name="description" content="Introducing our powerful DreamPhoto app. Bring your text to life with beautiful pictures. Explore our tool for creative expression today!" />
      </Helmet>
      {navmenu === 'show' ? (
        <Header hideNavLink={hideLinks} />
      ) : (
        <Headerlogo hideNavLink={hideLinks} />
      )}
        <div ref={headerRef}></div>
        <BackToTopButton isHeaderVisible={isHeaderVisible} />
        <div className="text-to-image bg-gray-100">
          <div className="intro mx-auto block pt-0 pb-10 md:pt-20">
            <div className="mainbanner block md:flex md:flex-row">
              <div className="w-full md:w-2/5 bg-gray-900 text-white text-center mt-20 sm:mt-20 md:mt-0 py-20 sm:py-[140px] md:pt-[165px] lg:pt-[185px] px-8">
                <h1 className="text-3xl md:text-3xl lg:text-4xl mb-4 sm:pt-4 pb-8 font-bold drop-shadow-2xl">Text-to-Image</h1>
                <p className="text-[16px] mb-4">
                  Generate any kind of image, just by typing out text!<br/> Through AI-PRO's intelligent AI engine,<br/> you can now create stunning, captivating,<br/> and elaborate pictures, in a matter of seconds!
                </p>
              </div>
              <div className="w-full h-[600px] md:w-3/5 banner_img">
                <iframe id="createartdemo" title="Create Art Demo" src={iframeSrc} frameBorder="0">
                </iframe>
              </div>
            </div>
          </div>

          <div className="howto container mx-auto block">
            <div className="lg:justify-center text-center">
              <div className="flex flex-wrap text-center md:text-left my-4">
                <div className="w-full lg:w-2/5 xl:w-2/5 p-4">
                  <h2 className="text-3xl md:text-6xl text-blue-700 font-bold">How to Use<br/> Create Art</h2>
                </div>
                <div className="w-full lg:w-1/5 xl:w-1/5 p-4">
                  <h4 className="font-bold text-xl mb-4">Login to your account</h4>
                  <p>Make sure that you are logged in to your account. You may <a href="/register" className="underline">sign in here</a>.</p>
                </div>
                <div className="w-full lg:w-1/5 xl:w-1/5 p-4">
                  <h4 className="font-bold text-xl mb-4">Describe the Image</h4>
                  <p>Once logged in, you may generate your AI art by describing the image that you want inside the Positive Prompt box.</p>
                </div>
                <div className="w-full lg:w-1/5 xl:w-1/5 p-4">
                  <h4 className="font-bold text-xl mb-4">Experiment & download</h4>
                  <p>Experiment with different prompts. Use the Negative Prompt box to describe the things you don’t want to appear in your image. Click Download to save the image in your device.</p>
                </div>
              </div>
            </div>
            <div className="lg:flex lg:justify-center">
              <div className="w-full banner_img">
                <img src={img1} alt="bot" className="object-cover w-full shadow"/>
              </div>
            </div>
          </div>
        </div>
        <div className="useai mx-auto pt-10 z-1 my-12">
          <div className="flex flex-col items-center p-10 lg:py-16 lg:pb-28">
            <div className="w-full lg:w-1/2 bg-white rounded-xl p-6 text-center mx-4 lg:mx-0">
              <h2 className="text-blue-800 text-xl md:text-5xl font-bold text-center pb-6">
                Use Artificial Intelligence
              </h2>
              <p className="text-md text-black text-center md:p-6 leading-8">
                At AI-PRO, we believe that everyone should have access to the resources and guidance they need to succeed in the world of AI. That’s why we offer a variety of membership options to suit your needs and budget. Whether you’re an individual looking to learn about AI or a business looking to adopt AI solutions, we have a plan that’s right for you.
              </p>
              <motion.button
                onClick={checkSubscription}
                className="cta bg-blue-800 hover:bg-blue-700 mb-1 text-white mx-auto text-center font-bold py-3 px-6 my-3 rounded-2xl md:w-1/2"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Discover AI Now
              </motion.button>
            </div>
          </div>
          <div className="flex flex-col items-center w-full text-center bg-gray-200 h-[400px]">
            <img src={lpPic1} alt="ChatGPT-Now AI" className="mx-auto mt-2 md:mt-[-30px]" />
          </div>

        </div>
      <Footer auth={auth} hideNavLink={hideLinks}/>
    </>
  );
}

export default Test2Image;
