import React, { useState, useEffect  } from 'react';
import './style.css';
import { motion } from "framer-motion";
import Header from '../header/headerlogo';
import aiproLogo from '../assets/images/AIPRO.png';
import { FaInfoCircle, FaLock, FaCheck } from 'react-icons/fa';
import ccImages from '../assets/images/cc_v3.png';
import { Auth } from '../core/utils/auth';
import { GetCookie, RemoveCookie } from '../core/utils/cookies';
import { useQuery } from "react-query";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { SetCookie } from '../core/utils/cookies';
import { numberWithCommas } from '../core/utils/main';
import { initSocket } from '../core/utils/socket';

var client_io;
const pricing = GetCookie("pricing") ? GetCookie("pricing") : "";
const tk = GetCookie("access") ? GetCookie("access") : "";
const previous_price_txt = GetCookie("display_txt1") ? GetCookie("display_txt1") : "";

var plan = null;
var show_top_info = false;
async function getPlan() {
  if(plan) return plan;
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-plan`, { plan_id: pricing }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  const output = response.data;
  if(output.success) {
    plan = output.data;
    return output.data;
  } else {
    return [];
  }
}

function Payment() {
  const { data } = useQuery("users", getPlan);
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [nameError, setNameError] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardDateError, setCardDateError] = useState("");
  const [cvvError, setCVVError] = useState("");
  const [willRedirect, setWillRedirect] = useState(true);
  const [enterpriseMembers, setenterpriseMembers] = useState(0);
  const [enterprisePricePerMember, setenterprisePricePerMember] = useState(0);
  const [enterpriseBasePrice, setenterpriseBasePrice] = useState("");
  const [enterpriseTotalAmount, setenterpriseTotalAmount] = useState("");
  const [userPid, setuserPid] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isShowCCDetails, setisShowCCDetails] = useState(true);

	const auth = Auth('/register');

  useEffect(() => {
    if(data) {
      setenterpriseMembers(parseInt(data.max_members)+1);
      setenterprisePricePerMember(data.price_per_member);
      setenterpriseBasePrice(numberWithCommas(data.price));
      setenterpriseTotalAmount(data.price.toLocaleString("en-US"));
    }
  }, [data]);

  useEffect(() => {
    if(data) {
      let totalAmount = data.price;
      if (enterpriseMembers > parseInt(data.max_members)+1){
        totalAmount = parseFloat(enterpriseMembers) * parseFloat(data.price_per_member);
        totalAmount = totalAmount.toLocaleString("en-US")
        setenterpriseTotalAmount(numberWithCommas(totalAmount));
        setisShowCCDetails(false);
      }else{
        totalAmount = data.price;
        totalAmount = totalAmount.toLocaleString("en-US")
        setenterpriseTotalAmount(numberWithCommas(totalAmount));
        setisShowCCDetails(true);
      }

      if (parseInt(enterpriseMembers)===parseInt(data.max_members)+1){
        setButtonDisabled(true);
      }else{
        setButtonDisabled(false);
      }
    }
  }, [data, enterpriseMembers]);

  useEffect(() => {
    client_io = initSocket();
  }, []);

  if(auth === undefined || auth === false) return;

  if(willRedirect) {
    if(auth.status === 'active' && auth.expired === 'no' && pricing!==process.env.REACT_APP_ENTERPRISE_ID) {
      window.location.href = '/my-account';
      return;
    }
  }

  if(auth.status === 'active' && auth.expired === 'no' && auth.plan!=='enterprise') {
    show_top_info = true;
  }

  var date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  var today = new Date();
  var expire_date = new Date();
  expire_date.setDate(today.getDate() + 30);

  if (data && data.currency && data.price){
    var amount = data.price;
    if (data.trial_price !== ''){
      amount = data.trial_price;
    }
    SetCookie('currency', data.currency, { expires: expire_date, path: '/' });
    SetCookie('currency', data.currency, { expires: expire_date, domain: '.ai-pro.org', path: '/' });
    SetCookie('amount', amount, { expires: expire_date, path: '/' });
    SetCookie('amount', amount, { expires: expire_date, domain: '.ai-pro.org', path: '/' });
  }

  const handleNameChange = (event) => {
    let input = event.target.value;
    // Remove non-alphabetic characters
    input = input.replace(/[^A-Za-z ]/g, "");
    // Limit the input to 50 characters
    input = input.slice(0, 50);
    setName(input);
  };

  const handleCardNumberChange = (event) => {
    let input = event.target.value;
    // Remove non-numeric characters
    input = input.replace(/\D/g, "");
    // Remove any existing dashes from the input
    input = input.replace(/-/g, "");
    // Add a dash after every fourth digit
    input = input.replace(/(\d{4})/g, "$1-");
    // Remove any trailing dash
    input = input.replace(/-$/, "");
    // Limit the input to 16 digits
    input = input.slice(0, 19);
    setCardNumber(input);
  };

  const handleCardDateChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    // Limit the input to 4 characters
    input = input.slice(0, 4);
    // Add "/" after the first 2 characters
    if (input.length >= 3) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }
    setCardDate(input);
  };

  const handleCVVChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    // Limit the input to 5 characters
    input = input.slice(0, 5);
    setCVV(input);
  };

  const submitAction = () => {
    let total = enterpriseMembers;
    if (parseInt(total)>parseInt(data.max_members)+1){
      sendViaEmail(false);
      moreEnterpriseOpen();
    }else{
      submitPayment();
    }
  }

  const submitPayment = () => {
    setWillRedirect(false);

    setNameError("");
    setCardNumberError("");
    setCardDateError("");
    setCVVError("");

    // Perform validation
    let isValid = true;


    if (!name || name.length === 0) {
      setNameError("required");
      isValid = false;
    } else if (!name.includes(" ")) {
      setNameError("enter at least two names separated by a space");
      isValid = false;
    }

    if (!cardNumber) {
      setCardNumberError("required");
      isValid = false;
    }

    if (!cardDate || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(cardDate)) {
      setCardDateError("MM/YY");
      isValid = false;
    }

    if (!cvv || !/^\d{3,5}$/.test(cvv)) {
      setCVVError("required");
      isValid = false;
    }

    // If any validation error occurred, stop further processing
    if (!isValid) {
      return;
    }

    document.querySelector(".loader-container").classList.add('active');
    var name_split = name.split(" ");
    var first_name = name_split[0];
    var last_name = name_split[name_split.length - 1];
    var ccmonth = cardDate.split("/")[0];
    var ccyr = cardDate.split("/")[1];

    var url = `${process.env.REACT_APP_API_URL}/t/create-subscription`;

    axios.post(url, {
      tk,
      first_name,
      last_name,
      cc: cardNumber,
      ccmonth: ccmonth,
      ccyr: "20" + ccyr,
      cvv: cvv,
      plan_id: pricing
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;
      if(output.success) {
        window.toastr.success("Success");
        RemoveCookie('pricing');
        if(GetCookie('tag-as') === "1") {
          logoutAllSession();
          return;
        }
        window.location.href = '/thankyou/?plan='+plan.label.replace(" ","").replace(" ","");
        return;
      }
      document.querySelector(".loader-container").classList.remove('active');
      if(output.data) {
        if(output.data.message) {
          window.toastr.error(output.data.message);
        } else {
          window.toastr.error(output.data.msg);
        }
      }
    }).catch(function (error) {
      if (error.response && error.response.status===429) {
        document.querySelector(".loader-container").classList.remove('active');
        window.toastr.error("Sorry, too many requests. Please try again in a bit!");
      }
    });
  }

  const enterprisePlus = () => {
    let total = parseInt(enterpriseMembers) + 1
    setenterpriseMembers(total);
  }

  const enterpriseMinus = () => {
    let total = enterpriseMembers;
    if (parseInt(total)>(parseInt(data.max_members)+1)){
      total = parseInt(enterpriseMembers) - 1;
      setenterpriseMembers(total);
    }
  }

	const moreEnterpriseOpen = () => {
		setuserPid(auth.user_id);
		let modal = document.getElementById("moreThanTenEnt");
		modal.style.display = "block";
	}

	const moreEnterpriseClose = () => {
		let modal = document.getElementById("moreThanTenEnt");
		modal.style.display = "none";
	}

	const sendViaEmail = (showToaster) => {
    var members = enterpriseMembers;
    var total_amount = enterpriseTotalAmount;
    var url = `${process.env.REACT_APP_API_URL}/t/send-enterprise-payment-info`;

    if (showToaster){
      moreEnterpriseClose();
      document.querySelector(".loader-container").classList.add('active');
    }

    axios.post(url, {
      members,
      total_amount
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;

      document.querySelector(".loader-container").classList.remove('active');

      if (showToaster){
        if(output.success) {
          window.toastr.success("Email sent to "+output.data);
        }else{
          window.toastr.error("Email Failed.");
        }
      }
    }).catch(function (error) {
      document.querySelector(".loader-container").classList.remove('active');
      if (error.response && error.response.status===429) {
        document.querySelector(".loader-container").classList.remove('active');
        window.toastr.error("Sorry, too many requests. Please try again in a bit!");
      }
    });
	}

  const submitPaymentInformation = () => {
    window.location.href = '/payment-reference';
    return;
  }

  const logoutAllSession = () => {
    if(!client_io) return;
    let socket = client_io.socket;
    socket.emit('logout-account', {
      id: client_io.sidx, email: GetCookie('user_email'), socket_key: process.env.REACT_APP_SOCKET_KEY
    });
    setTimeout(function(){
      RemoveCookie('tag-as');
      window.location.href = '/manage-account?tab=mem';
    }, 1000);
  };

  return (
    <>
      <Helmet>
        <title>AI Pro | Payment Option</title>
        <meta name="description" content="Safely complete your purchase with our secure payment options. Buy now with confidence!" />
      </Helmet>
      <Header auth={auth} />
      { data ?
      <div className="Payment bg-gray-100 min-h-[600px] flex">

					<div id="moreThanTenEnt" className="modal z-[9999]">
						<div class="w-full md:w-[600px] border-[#888] md:mt-[15px] mx-[auto] bg-[#fefefe] p-6">
              <span class="close" onClick={()=> moreEnterpriseClose()}>&times;</span>
              <div className="border-b pb-[10px] border-[#d5d5d5]"><img src={aiproLogo} alt="AI-Pro Logo" className="aiprologo mx-auto"/></div>
              <h1 className="font-bold text-center p-2 text-gray-700 text-[20px] md:text-[24px]">Payment Details<br/>for Enterprise Order</h1>
							<div className="text-center text-[12px] md:text-[14px]">Adding more than 10 Enterprise users requires prior payment.</div>
							<div className="text-center text-[12px] md:text-[14px]">Please use the provided payment details below to settle your Enterprise account.</div>
							<div className="py-2">
                <div className="font-bold text-[11px]">No. of Members: {enterpriseMembers}</div>
  							<div className="font-bold text-[11px]">Enterprise - Total: ${enterpriseTotalAmount}</div>
              </div>
							<div className="border rounded p-2 text-[12px] md:text-[14px] leading-7 my-2">
								<div className="font-bold">Bank Information</div>
								<div className="float-right text-blue-400 font-bold cursor-pointer mt-[-28px] text-[12px]" onClick={()=> sendViaEmail(true)}>Send via Email</div>
								<div><span className="font-bold">Beneficiary:</span> TELECOM BUSINESS SOLUTIONS INC.</div>
								<div><span className="font-bold">SWIFT:</span> BOFAUS3N</div>
								<div><span className="font-bold">Bank Name:</span> Bank of America</div>
								<div><span className="font-bold">Routing (Wire):</span> 026009593</div>
								<div><span className="font-bold">Routing Number (Paper & Electronic):</span> 021200339</div>
								<div><span className="font-bold">Account Number:</span> 3810-6766-2647</div>
								<div><span className="font-bold">Customer Number:</span> {userPid}</div>
								<div className="bg-[#dddddd] px-4 py-2 rounded text-center mt-4"><FaInfoCircle className="inline text-lg mr-2"/>Customer Number must be included in the bank transfer description field for your funds to transfer successfully.</div>
							</div>

							<div className="text-center text-[12px] md:text-[14px] mt-4">
								Once the payment is received, our dedicated account manager will contact you to assist in the seamless setup of your Enterprise account.
							</div>
							<div className="text-center text-[12px] md:text-[14px]">
								Please allow <b>2-3 banking days</b> for the payment to reflect in the account.
							</div>
              <div className="text-center">
                <motion.button
                  className="bg-blue-500 text-white font-bold py-3 px-4 rounded my-4 proceed-pmt"
                  whileHover={{ backgroundColor: "#5997fd" }}
                  whileTap={{ scale: 0.9 }}
                  onClick={submitPaymentInformation}
                >
                  Send Payment Confirmation
                </motion.button>
              </div>
						</div>
					</div>

          <div className="px-4 mx-auto py-10">
            <div className="flex flex-col items-center py-10 lg:py-16">
              <div className="flex flex-wrap md:flex-wrap justify-center w-full">
                <div className="pay_left px-4 mb-8 w-full md:w-1/2">
                  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="px-6 pb-10">
                      <div className="">
                        {show_top_info ?
                        <div className="py-2">
                          <div className="block py-2">
                            <span className="text-md mb-2 font-bold">
                            Upgrade to New Plan:
                            </span>
                          </div>

                          <div className="flex">
                            <label
                              htmlFor="plan1"
                              className='border rounded-md border-blue-400 px-8 py-4 mr-2 text-center relative cursor-pointer text-sm opacity-50'
                            >
                              <span className="font-bold block uppercase">{auth.plan}</span>
                              <span className="text-sm">{numberWithCommas(previous_price_txt)}</span>
                              <input
                                type="radio"
                                id="plan1"
                                name="plan"
                                className="absolute top-0 left-0 m-2 ml-[8px]"
                                disabled
                              />
                            </label>
                            <label
                              htmlFor="plan2"
                              className='border rounded-md border-blue-400 px-8 py-4 mr-2 text-center relative cursor-pointer text-sm plan2'
                            >
                              <span className="font-bold block uppercase">ENTERPRISE PRO</span>
                              <span className="text-sm">${enterpriseBasePrice}/year</span>
                              <input
                                type="radio"
                                id="plan2"
                                name="plan"
                                className="absolute top-0 left-0 m-2 ml-[8px]"
                                checked
                              />
                            </label>
                          </div>

                        </div>
                        : ""}
                        <h2 className="text-xl font-bold mb-4 pt-10">Enter Billing Details</h2>

                          <div className="mb-4 flex">
                            <div className="expdate w-full md:w-2/3 mr-2 md:mr-5 text-[14px]">
                              ENTERPRISE PRO
                            </div>
                            <div className="cvv w-full md:w-1/3 text-right">
                              ${enterpriseBasePrice}
                            </div>
                          </div>

                          <div className="mb-4 flex">
                            <div className="expdate w-full md:w-2/3 mr-2 md:mr-5 text-[14px]">
                              <div className='mb-2'>
                                Total Users
                              </div>
                              <div>
                                Enterprise Pro plan includes {parseInt(data.max_members)+1} users by default. You may add more users at <strong>${enterprisePricePerMember}</strong> each.
                              </div>
                            </div>
                            <div className="cvv w-full md:w-1/3 text-right">
                              <button
                                className={`bg-blue-500 text-white font-bold rounded proceed-pmt inline px-2 ${isButtonDisabled ? 'disableButton' : ''}`}
                                whileHover={{ backgroundColor: "#5997fd" }}
                                whileTap={{ scale: 0.9 }}
                                onClick={enterpriseMinus}
                                disabled={isButtonDisabled}
                              >
                                -
                              </button>
                              <label className="text-xs block mx-2 mt-1 inline">{enterpriseMembers}</label>
                              <button
                                className="bg-blue-500 text-white font-bold rounded proceed-pmt inline px-2"
                                whileHover={{ backgroundColor: "#5997fd" }}
                                whileTap={{ scale: 0.9 }}
                                onClick={enterprisePlus}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          {isShowCCDetails === true ? (
                          <div className="text-[14px]">
                            <div className="mb-4">
                            <label className="text-xs block mb-0 mt-1" htmlFor="name">Name on Card <span className="text-red-500">*</span>
                            { nameError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{nameError}</span> }</label>
                            <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="John Doe"
                            value={name}
                            onChange={handleNameChange}
                            onKeyUp={(event) => {
                              setName(event.target.value);
                            }}/>
                          </div>

                          <div className="mb-4">
                            <label className="text-xs block mb-0 mt-1" htmlFor="card-number">Card Number <span className="text-red-500">*</span>
                            { cardNumberError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cardNumberError}</span> }</label>
                            <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                            type="text"
                            id="card-number"
                            name="card-number"
                            placeholder="1234 5678 9012 3456"
                            value={cardNumber}
                            onChange={handleCardNumberChange}
                            onKeyUp={(event) => {
                              setCardNumber(event.target.value);
                            }}/>
                          </div>
                          <div className="mb-4 flex">
                            <div className="expdate w-full md:w-2/3 mr-2 md:mr-5">
                              <label className="text-xs block mb-0 mt-1" htmlFor="expiration-date">Expiration Date <span className="text-red-500">*</span>
                              { cardDateError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cardDateError}</span> }</label>
                              <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                              type="text"
                              id="expiration-date"
                              name="expiration-date"
                              placeholder="MM/YY"
                              value={cardDate}
                              onChange={handleCardDateChange}
                              onKeyUp={(event) => {
                                setCardDate(event.target.value);
                              }}/>
                            </div>
                            <div className="cvv w-full md:w-1/3">
                              <label className="text-xs block mb-0 mt-1" htmlFor="cvv">CVV <span className="text-red-500">*</span>
                              { cvvError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cvvError}</span> }</label>
                              <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                              type="text"
                              id="cvv"
                              name="cvv"
                              placeholder="CVV"
                              value={cvv}
                              onChange={handleCVVChange}
                              onKeyUp={(event) => {
                                setCVV(event.target.value);
                              }}/>
                            </div>
                          </div>
                        </div>
                        ) : ""}

                        <div className="my-2 flex border-t pt-[10px] border-[#d5d5d5] font-bold">
                          <div className="expdate w-full md:w-2/3 mr-2 md:mr-5">
                          Total:
                          </div>
                          <div className="cvv w-full md:w-1/3 text-right">
                          ${enterpriseTotalAmount}/year
                          </div>
                        </div>

                          <motion.button
                            className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg w-full my-4 proceed-pmt"
                            whileHover={{ backgroundColor: "#5997fd" }}
                            whileTap={{ scale: 0.9 }}
                            onClick={submitAction}
                          >
                            Complete Purchase
                          </motion.button>
                      </div>
                      <span className="text-[12px] text-gray-600"><FaInfoCircle className="inline text-lg mr-1"/> By clicking the “Complete Purchase” button, I have read and agreed to the Terms and Conditions.</span>
                    </div>
                  </div>
                </div>

                <div className="pay_right px-4 mb-8 md:w-2/5">
                  <div className="border px-8 rounded border-gray-300">
                    <div className="py-5">
											<div className="flex flex-wrap mb-2 text-sm mr-6">
                        <div className="text-lg font-bold mt-4 w-1/2">Enterprise Pro</div>
                        <div className="text-lg font-bold mt-4 w-1/2 text-right">${enterpriseBasePrice}</div>
                      </div>
                      <div className="mb-8 text-sm mt-6 border-b pb-[20px] border-[#d5d5d5]">
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">No. of users: {enterpriseMembers}</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">ChatGPT-powered tools</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">3.7M Token Cap - comprehensive and exhaustive responses</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">
													Customizable Response Models - can provide scenario-based responses
													<span>(ex. I am a college professor. Write me a lecture about..)</span>
												</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">Save Chat History - store up to hundreds of research results accessible any time</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">Choose Light or Dark Mode - for all screen types</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">Export Conversations - Image, Text, CSV, or JSON files</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">Live Support</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">ChatGPT-4 model</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">Chatbot Themes - change your background color</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">ChatPDF - ask questions based on your PDF document</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">Interior AI - create new room designs easily</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">Stable Diffusion-powered tools to create realistic images</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">AI Art Maker - generate art based on prompts</span></div>
												<div className="leading-6 ml-[24px]"><span className="ml-[-24px]"><FaCheck className="inline text-xs mr-3 text-[#7fafff]"/></span><span className="inline text-[12px]">AI Art Prompt Gallery</span></div>
											</div>
                      <div className="flex flex-wrap mb-2 text-sm mr-2 md:mr-6">
                        <div className="text-lg font-bold mt-4 w-1/2">TOTAL:</div>
                        <div className="text-lg mt-4 w-1/2 text-right">${enterpriseTotalAmount}/year</div>
                      </div>
                      <div className="flex flex-wrap mb-2 text-sm mr-2 md:mr-6">
                        <div className="text-[12px] w-1/2"></div>
                        <div className="text-[12px] w-full text-right italic">Monthly investment of only <br/><b>$16/month per user</b></div>
                      </div>
                      <div className="mb-8 text-sm mt-6">
                        { data.display_txt3 ? data.display_txt3 : "Your subscription will renew monthly until you cancel it."}
                      </div>
                    </div>
                  </div>
                  <div className="securecont block p-5 mx-auto text-left">
                    <div className="securetext mb-2 text-sm w-full"><FaLock className="inline text-lg mr-1 text-orange-500 text-xs"/> Secure Checkout</div>
                    <div className="securelogo mb-2 text-sm w-full">
                      <img src={ccImages} alt="Secure Logo" className="cclogo inline"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
      </div> : "" }
    </>
  );
}

export default Payment;