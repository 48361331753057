import React, { useState, useEffect } from 'react';
import './style.css';
import { motion } from "framer-motion";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { FaArrowRight } from 'react-icons/fa';
import img1 from '../assets/images/chatbot-pro-icon.png';
import img2 from '../assets/images/chatpdf-icon.png';
import img3 from '../assets/images/grammar-ai-icon.png';
import img4 from '../assets/images/prompt-library-icon.png';

import teacher_ai_icon from '../assets/images/teacher-ai-icon.png';
import trips_ai_icon from '../assets/images/trips-ai-icon.png';
import recipe_maker_icon from '../assets/images/recipe-maker-icon.png';
import translate_now_icon from '../assets/images/translate-now-icon.png';
import { returnAppHref, redirectApp } from '../core/utils/app';
import searchai_icon from '../assets/images/searchai-icon.png';
import multillm_icon from '../assets/images/multillm-icon.png';
import emailwriter_icon from '../assets/images/email_writer.png';

const tools = process.env.REACT_APP_TOOLS_URL ? JSON.parse(process.env.REACT_APP_TOOLS_URL) : {};

function AiChatbots(props) {
  const [isMobile, setIsMobile] = useState(false);
  const auth = props && props.auth ? props.auth : null;
  const upid = auth && auth.user_pid ? '?upid=' + auth.user_pid : "";
  const chatbotprolink = tools.chatpro ? tools.chatpro + upid : 'https://chatpro.ai-pro.org/chat'+upid;

  const chatpdflink = tools.chatpdf ? tools.chatpdf + upid : 'https://chatpdf.ai-pro.org'+upid;
  const promptlibrarylink = tools.promptlibrary ? tools.promptlibrary + upid : 'https://chatlibrary.ai-pro.org'+upid;
  const convertenglishlink = tools.convert2english ? tools.convert2english + upid : 'https://app.ai-pro.org/convert-to-proper-english'+upid;
  // const resourceslink = 'https://ai-pro.org/tag/chatgpt?upid='+upid;

  const teacher_ai_link = tools.teacher_ai ? tools.teacher_ai + upid : 'https://teacher.ai-pro.org'+upid;
  const trips_ai_link = tools.trips_ai ? tools.trips_ai + upid : 'https://chatlibrary.ai-pro.org/ai/travel'+upid;
  const recipe_maker_link = tools.recipe_maker ? tools.recipe_maker + upid : 'https://chatlibrary.ai-pro.org/ai/recipe'+upid;
  const translate_now_link = tools.translate_now ? tools.translate_now + upid : 'https://chatlibrary.ai-pro.org/ai/writing/translate'+upid;
  const searchai_link = tools.searchai ? tools.searchai + upid : 'https://search.ai-pro.org'+upid;
  const multillm_link = tools.multillm ? tools.multillm + upid : 'https://multillm.ai-pro.org'+upid;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850);
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fnRedirectApp = (url) => {
    redirectApp(auth, url);
  };

  const fnReturnAppHref = (url) => {
    return returnAppHref(auth, url);
  };
  return (
    <div className="aichatbot bg-gray-100 flex items-center mt-4 md:mt-0">
      <div className="container mx-auto sm:px-0">
        <div className="max-w-6xl mx-auto pb-5">
          <div id="carousel-aichat-container" className="bg-white drop-shadow-sm py-6 px-4 rounded-tr-md rounded-br-md rounded-bl-md flex flex-col">
            <div className="apptitle flex flex-wrap">
              <h1 className="text-xl font-bold text-gray-800 inline w-40">
                Chatbot Pro
              </h1>
              <span className="text-[12px] text-left md:text-right ml-auto md:w-96">ChatGPT technology generates essays, reports, website content, contracts, poems, or ask questions and get answers.</span>
            </div>

            { isMobile ? <>
              <Carousel className="py-4" infiniteLoop={true} showArrows={true} emulateTouch={true} autoPlay={true} centerSlidePercentage={true}>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="ribbon-container">
                    <div className="ribbon popular">Popular</div>
                  </div>
                  <div className="flex mytitle items-center">
                    <img src={img1} alt="chatbot pro logo" aria-label="chatbot pro logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <div style={{lineHeight: "13px"}}>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left">Chatbot Pro</h4>
                      <small style={{ textAlign: "left", display: "block" }}>
                        powered by <b>OpenAI</b>
                      </small>
                    </div>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">Advanced features of ChatGPT. Change Model & Temperature settings to generate captivating content, technical information, and programming code.</p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(chatbotprolink)}
                      target={ fnReturnAppHref(chatbotprolink) === chatbotprolink ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(chatbotprolink)}}
                      name="Go to Chatbot Pro" aria-label="Go to Chatbot Pro" role="button"
                    >
                      Go to Chatbot Pro <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>

                </div>
                <div className="appbox border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={img2} alt="ChatPDF logo" aria-label="ChatPDF logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">ChatPDF</h4>
                  </div>

                  <p className="mydesc text-[14px] text-black text-left">Upload your PDF File and have a chat with it. Ask questions, get a summary, get feedback, and many more. Start meaningful conversations with your PDF.</p>
                  <motion.a
                    className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    whileHover={{ backgroundColor: "#5997fd" }}
                    whileTap={{ scale: 0.9 }}
                    href={fnReturnAppHref(chatpdflink)}
                    target={ fnReturnAppHref(chatpdflink) === chatpdflink ? "_blank" : "" }
                    onClick={()=>{fnRedirectApp(chatpdflink)}}
                    name="Go to ChatPDF" aria-label="Go to ChatPDF" role="button"
                  >
                    Go to ChatPDF <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={img3} alt="Grammar AI logo" aria-label="Grammar AI" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Grammar AI</h4>
                  </div>

                  <p className="mydesc text-[14px] text-black text-left">Convert texts to proper English without slang or grammatical errors. Translate languages to proper English language in seconds.</p>
                  <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(convertenglishlink)}
                      target={ fnReturnAppHref(convertenglishlink) === convertenglishlink ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(convertenglishlink)}}
                      name="Go to Grammar AI" aria-label="Go to Grammar AI" role="button"
                    >
                      Go to Grammar AI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                {/* <div className="appbox border rounded-md p-4 m-2 flex flex-col justify-start">
                  <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">ChatGPT Resources</h4>
                  <p className="mydesc text-[14px] text-black text-left">Learn about ChatGPT and its wide array of uses. Discover how to maximize this tool from writing simple texts to composing documents.</p>
                  <motion.a className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none' }}
                      href={resourceslink}
                      target="_blank"
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                    >
                      Go to Resources <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>*/}
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="ribbon-container">
                    <div className="ribbon beta">Beta</div>
                  </div>
                  <div className="flex mytitle items-center">
                    <img src={img4} alt="Prompt Library logo" aria-label="Prompt Library logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Prompt Library</h4>
                  </div>

                  <p className="mydesc text-[14px] text-black text-left">Prepare and create your own ChatGPT Prompts easily. Assists with building useful instructions to maximize ChatGPT's response.</p>
                  <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(promptlibrarylink)}
                      target={ fnReturnAppHref(promptlibrarylink) === promptlibrarylink ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(promptlibrarylink)}}
                      name="Go to Library" aria-label="Go to Library" role="button"
                    >
                      Go to Library <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={teacher_ai_icon} alt="TeacherAI logo" aria-label="TeacherAI logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">TeacherAI</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                    Your all-in-one learning partner, offering a seamless fusion of written inquiries and interactive text-to-speech outputs, for an enhanced and effortless learning journey.
                  </p>
                  <motion.a
                    className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    whileHover={{ backgroundColor: "#5997fd" }}
                    whileTap={{ scale: 0.9 }}
                    href={fnReturnAppHref(teacher_ai_link)}
                    target={ fnReturnAppHref(teacher_ai_link) === teacher_ai_link ? "_blank" : "" }
                    onClick={()=>{fnRedirectApp(teacher_ai_link)}}
                    name="Go to TeacherAI" aria-label="Go to TeacherAI" role="button"
                  >
                    Go to TeacherAI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={trips_ai_icon} alt="TripsAI logo" aria-label="TripsAI logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">TripsAI</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                    Let AI create customized itineraries for you. Plan your perfect getaway with AI-powered precision!
                  </p>
                  <motion.a
                    className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    whileHover={{ backgroundColor: "#5997fd" }}
                    whileTap={{ scale: 0.9 }}
                    href={fnReturnAppHref(trips_ai_link)}
                    target={ fnReturnAppHref(trips_ai_link) === trips_ai_link ? "_blank" : "" }
                    onClick={()=>{fnRedirectApp(trips_ai_link)}}
                    name="Go to TripsAI" aria-label="Go to TripsAI" role="button"
                  >
                    Go to TripsAI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={recipe_maker_icon} alt="Recipe Maker logo" aria-label="Recipe Maker logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Recipe Maker</h4>
                  </div>

                  <p className="mydesc text-[14px] text-black text-left">
                    Turn your ingredients into culinary masterpieces. Just enter your ingredients, and we'll do the rest! Start cooking today.
                  </p>
                  <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(recipe_maker_link)}
                      target={ fnReturnAppHref(recipe_maker_link) === recipe_maker_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(recipe_maker_link)}}
                      name="Go to Recipe Maker" aria-label="Go to Recipe Maker" role="button"
                    >
                      Go to Recipe Maker <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={translate_now_icon} alt="TranslateNow logo" aria-label="TranslateNow logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">TranslateNow</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                    Experience lightning-fast text translation in over 100 languages, right at your fingertips!
                  </p>
                  <motion.a
                    className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    whileHover={{ backgroundColor: "#5997fd" }}
                    whileTap={{ scale: 0.9 }}
                    href={fnReturnAppHref(translate_now_link)}
                    target={ fnReturnAppHref(translate_now_link) === translate_now_link ? "_blank" : "" }
                    onClick={()=>{fnRedirectApp(translate_now_link)}}
                    name="Go to TranslateNow" aria-label="Go to TranslateNow" role="button"
                  >
                    Go to TranslateNow <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={searchai_icon} alt="SearchAI logo" aria-label="SearchAI logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">SearchAI</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                  Stay ahead of the curve with SearchAI's ability to fetch and deliver current data, revolutionizing the way you access information.
                  </p>
                  <motion.a
                    className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    whileHover={{ backgroundColor: "#5997fd" }}
                    whileTap={{ scale: 0.9 }}
                    href={fnReturnAppHref(searchai_link)}
                    target={ fnReturnAppHref(searchai_link) === searchai_link ? "_blank" : "" }
                    onClick={()=>{fnRedirectApp(searchai_link)}}
                    name="Go to SearchAI" aria-label="Go to SearchAI" role="button"
                  >
                    Go to SearchAI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={multillm_icon} alt="Multi-Chat logo" aria-label="Multi-Chat logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Multi-Chat</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                  Explore diverse answers effortlessly with Multi-Chat—an app leveraging collective intelligence from various language models for enriched and dynamic interactions.
                  </p>
                  <motion.a
                    className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    whileHover={{ backgroundColor: "#5997fd" }}
                    whileTap={{ scale: 0.9 }}
                    href={fnReturnAppHref(multillm_link)}
                    target={ fnReturnAppHref(multillm_link) === multillm_link ? "_blank" : "" }
                    onClick={()=>{fnRedirectApp(multillm_link)}}
                    name="Go to Multi-Chat" aria-label="Go to Multi-Chat" role="button"
                  >
                    Go to Multi-Chat <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                  </motion.a>
                </div>
                <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start">
                  <div className="flex mytitle items-center">
                    <img src={emailwriter_icon} alt="Email Writer logo" aria-label="Email Writer logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Email Writer</h4>
                  </div>
                  <p className="mydesc text-[14px] text-black text-left">
                  Say goodbye to the email response struggle. Seamlessly craft tailored responses that leave a lasting impression.
                  </p>
                  <motion.a
                    className="bg-slate-400 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                    style={{ textDecoration: 'none'}}
                    name="Coming Soon" aria-label="Coming Soon" role="button"
                  >
                    Coming Soon
                  </motion.a>
                </div>
              </Carousel>
              </> :
              <>
                <div className="apps md:flex md:flex-row">
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="ribbon-container">
                      <div className="ribbon popular">Popular</div>
                    </div>
                    <div className="flex mytitle items-center">
                      <img src={img1} alt="chatbot pro logo" aria-label="chatbot pro logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <div style={{lineHeight: "13px"}}>
                        <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left">Chatbot Pro</h4>
                        <small className="poweredby">
                          powered by <b>OpenAI</b>
                        </small>
                      </div>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">Advanced features of ChatGPT. Change Model & Temperature settings to generate captivating content, technical information, and programming code.</p>
                      <motion.a
                        className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 w-full rounded-md mt-[20px]"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        whileHover={{ backgroundColor: "#5997fd" }}
                        whileTap={{ scale: 0.9 }}
                        href={fnReturnAppHref(chatbotprolink)}
                        target={ fnReturnAppHref(chatbotprolink) === chatbotprolink ? "_blank" : "" }
                        onClick={()=>{fnRedirectApp(chatbotprolink)}}
                        name="Go to Chatbot Pro" aria-label="Go to Chatbot Pro" role="button"
                      >
                        Go to Chatbot Pro <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                      </motion.a>
                  </div>
                  <div className="appbox border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={img2} alt="ChatPDF logo" aria-label="ChatPDF logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">ChatPDF</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">Upload your PDF File and have a chat with it. Ask questions, get a summary, get feedback, and many more. Start meaningful conversations with your PDF.</p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(chatpdflink)}
                      target={ fnReturnAppHref(chatpdflink) === chatpdflink ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(chatpdflink)}}
                      name="Go to ChatPDF" aria-label="Go to ChatPDF" role="button"
                    >
                      Go to ChatPDF <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                  {/*<div className="appbox border rounded-md p-4 m-2 flex flex-col justify-between flex-grow w-full">
                    <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">ChatGPT Resources</h4>
                    <p className="mydesc text-[14px] text-black text-left">Learn about ChatGPT and its wide array of uses. Discover how to maximize this tool from writing simple texts to composing documents.</p>
                    <motion.a
                        className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                        style={{ textDecoration: 'none' }}
                        href={resourceslink}
                        target="_blank"
                        whileHover={{ backgroundColor: "#5997fd" }}
                        whileTap={{ scale: 0.9 }}
                      >
                        Go to Resources <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>*/}

                  <div className="appbox border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={img3} alt="Grammar AI logo" aria-label="Grammar AI logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Grammar AI</h4>
                    </div>

                    <p className="mydesc text-[14px] text-black text-left">Convert texts to proper English without slang or grammatical errors. Translate languages to proper English language in seconds.</p>
                    <motion.a
                        className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        whileHover={{ backgroundColor: "#5997fd" }}
                        whileTap={{ scale: 0.9 }}
                        href={fnReturnAppHref(convertenglishlink)}
                        target={ fnReturnAppHref(convertenglishlink) === convertenglishlink ? "_blank" : "" }
                        onClick={()=>{fnRedirectApp(convertenglishlink)}}
                        name="Go to Grammar AI" aria-label="Go to Grammar AI" role="button"
                      >
                        Go to Grammar AI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>

                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="ribbon-container">
                      <div className="ribbon beta">Beta</div>
                    </div>
                    <div className="flex mytitle items-center">
                      <img src={img4} alt="Prompt Library logo" aria-label="Prompt Library logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 " style={{"line-height":"20px"}}>Prompt Library</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">Prepare and create your own ChatGPT Prompts easily. Assists with building useful instructions to maximize ChatGPT's response.</p>
                    <motion.a
                        className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        whileHover={{ backgroundColor: "#5997fd" }}
                        whileTap={{ scale: 0.9 }}
                        href={fnReturnAppHref(promptlibrarylink)}
                        target={ fnReturnAppHref(promptlibrarylink) === promptlibrarylink ? "_blank" : "" }
                        onClick={()=>{fnRedirectApp(promptlibrarylink)}}
                        name="Go to Library" aria-label="Go to Library" role="button"
                      >
                        Go to Library <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                </div>
                <div className="apps md:flex md:flex-row">
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={teacher_ai_icon} alt="TeacherAI logo" aria-label="TeacherAI logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">TeacherAI</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                      Your all-in-one learning partner, offering a seamless fusion of written inquiries and interactive text-to-speech outputs, for an enhanced and effortless learning journey.
                    </p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(teacher_ai_link)}
                      target={ fnReturnAppHref(teacher_ai_link) === teacher_ai_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(teacher_ai_link)}}
                      name="Go to TeacherAI" aria-label="Go to TeacherAI" role="button"
                    >
                      Go to TeacherAI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={trips_ai_icon} alt="TripsAI logo" aria-label="TripsAI logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">TripsAI</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                      Let AI create customized itineraries for you. Plan your perfect getaway with AI-powered precision!
                    </p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(trips_ai_link)}
                      target={ fnReturnAppHref(trips_ai_link) === trips_ai_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(trips_ai_link)}}
                      name="Go to TripsAI" aria-label="Go to TripsAI" role="button"
                    >
                      Go to TripsAI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={recipe_maker_icon} alt="Recipe Maker logo" aria-label="Recipe Maker logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Recipe Maker</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                        Turn your ingredients into culinary masterpieces. Just enter your ingredients, and we'll do the rest! Start cooking today.
                    </p>
                    <motion.a
                        className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        whileHover={{ backgroundColor: "#5997fd" }}
                        whileTap={{ scale: 0.9 }}
                        href={fnReturnAppHref(recipe_maker_link)}
                        target={ fnReturnAppHref(recipe_maker_link) === recipe_maker_link ? "_blank" : "" }
                        onClick={()=>{fnRedirectApp(recipe_maker_link)}}
                        name="Go to Recipe Maker" aria-label="Go to Recipe Maker" role="button"
                      >
                        Go to Recipe Maker <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={translate_now_icon} alt="TranslateNow logo" aria-label="TranslateNow logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">TranslateNow</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                      Experience lightning-fast text translation in over 100 languages, right at your fingertips!
                    </p>
                    <motion.a
                        className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        whileHover={{ backgroundColor: "#5997fd" }}
                        whileTap={{ scale: 0.9 }}
                        href={fnReturnAppHref(translate_now_link)}
                        target={ fnReturnAppHref(translate_now_link) === translate_now_link ? "_blank" : "" }
                        onClick={()=>{fnRedirectApp(translate_now_link)}}
                        name="Go to TranslateNow" aria-label="Go to TranslateNow" role="button"
                      >
                        Go to TranslateNow <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                </div>
                <div className="apps md:flex md:flex-row">
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="ribbon-container">
                      <div className="ribbon">NEW</div>
                    </div>
                    <div className="flex mytitle items-center">
                      <img src={searchai_icon} alt="SearchAI logo" aria-label="SearchAI logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">SearchAI</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                    Stay ahead of the curve with SearchAI's ability to fetch and deliver current data, revolutionizing the way you access information.
                    </p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(searchai_link)}
                      target={ fnReturnAppHref(searchai_link) === searchai_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(searchai_link)}}
                      name="Go to SearchAI" aria-label="Go to SearchAI" role="button"
                    >
                      Go to SearchAI <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="ribbon-container">
                      <div className="ribbon">NEW</div>
                    </div>
                    <div className="flex mytitle items-center">
                      <img src={multillm_icon} alt="Multi-Chat logo" aria-label="Multi-Chat logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Multi-Chat</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                    Explore diverse answers effortlessly with Multi-Chat—an app leveraging collective intelligence from various language models for enriched and dynamic interactions.
                    </p>
                    <motion.a
                      className="bg-blue-500 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      whileHover={{ backgroundColor: "#5997fd" }}
                      whileTap={{ scale: 0.9 }}
                      href={fnReturnAppHref(multillm_link)}
                      target={ fnReturnAppHref(multillm_link) === multillm_link ? "_blank" : "" }
                      onClick={()=>{fnRedirectApp(multillm_link)}}
                      name="Go to Multi-Chat" aria-label="Go to Multi-Chat" role="button"
                    >
                      Go to Multi-Chat <FaArrowRight className="inline text-xs mt-0.5 float-right mr-[-16px]"/>
                    </motion.a>
                  </div>
                  <div className="appbox relative overflow-hidden border rounded-md p-4 m-2 flex flex-col justify-start flex-grow w-full">
                    <div className="flex mytitle items-center">
                      <img src={emailwriter_icon}  alt="Email Writer logo" aria-label="Email Writer logo" className="p-2 pl-0 pt-0 md:p-0 lg:p-2 w-[50px]"/>
                      <h4 className="text-[16px] md:text-[12px] lg:text-[16px] text-gray-800 font-bold text-left pb-2 ">Email Writer</h4>
                    </div>
                    <p className="mydesc text-[14px] text-black text-left">
                      Say goodbye to the email response struggle. Seamlessly craft tailored responses that leave a lasting impression.
                    </p>
                    <motion.a
                      className="bg-slate-400 text-white text-left text-[12px] font-bold py-2 pl-3 pr-6 mt-5 w-full rounded-md"
                      style={{ textDecoration: 'none'}}
                      name="Coming Soon" aria-label="Coming Soon" role="button"
                    >
                       Coming Soon
                    </motion.a>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AiChatbots;