import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function SetCookie(name, value, option = { path: '/' }) {
    cookies.set(name, value, option);
};
export function RemoveCookie(name, option = { path: '/' }) {
    cookies.remove(name, option);
};
export function GetCookie(name) {
    return cookies.get(name);
};

export function GetSubdomain() {
    const fullHostName = window.location.hostname;
    const checkDomain = fullHostName.includes("chatapp");
    return checkDomain;
}