import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './home';
import Pricing from './pricing';
import Upgrade from './upgrade';
import UpgradePay from './pay-upgrade';
import Downgrade from './downgrade';
import Register from './register';
import Login from './login';
import Forgot from './forgot';
import Reset from './resetpass';
import Pay from './pay';
import PayV1 from './pay/index_01';
import PayV2 from './pay/index_02';
import PayV3 from './pay/index_03';
import PayV4 from './pay/index_04';
import PayRef from './pay-reference/index';
import PayConfirm from './pay-confirm/index';
import Thankyou from './thankyou';
import MyAccount from './my-account';
import Manage from './manage-account';
import ChangeCard from './change-card';
import EmailTemplates from './emailtemplates';
import ActiveSession from './active-session';
import './tailwind.scss';
import './index.css';
import { QueryClient, QueryClientProvider } from "react-query";
import NotFoundPage from './404';
import RedirectAccount from './redirect-account-required';
import SplashPage from './splashscreen';
// LPs
import StartStableDiffusion from './lp/start-stable-diffusion';
import StartChatGPT from './lp/start-chat-gpt';
import StartChatGPTgo from './lp/start-chatgpt-go';
import StartChatGPTv2 from './lp/start-chatgpt-v2';
import Text2Image from './lp/text-to-image';
import Survey from './survey';

const queryClient = new QueryClient();

function App() {

  return (

    <Router>
      <div>
        <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/upgrade" element={<Upgrade />} />
          <Route path="/upgrade/:id" element={<UpgradePay />} />
          <Route path="/downgrade" element={<Downgrade />} />
          <Route path="/downgrade/:id" element={<UpgradePay />} />
          <Route path="/register" element={<Register />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/pay/1LCXJMZNX6" element={<Pay />} />
          <Route path="/pay/bH0w05VJXk" element={<PayV1 />} />
          <Route path="/pay/VS6lni4hKx" element={<PayV2 />} />
          <Route path="/pay/uHTinVqsUl" element={<PayV3 />} />
          <Route path="/pay/mcWiDilmgQ" element={<PayV4 />} />
          <Route path="/payment-reference" element={<PayRef />} />
          <Route path="/payment-confirm" element={<PayConfirm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/resetpass" element={<Reset />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/manage-account" element={<Manage />} />
          <Route path="/change-card" element={<ChangeCard />} />
          <Route path="/emailtemplates" element={<EmailTemplates />} />
          <Route path="/redirect-account-required" element={<RedirectAccount />} />
          <Route path="/start-stable-diffusion" element={<StartStableDiffusion />} />
          <Route path="/start-chat-gpt" element={<StartChatGPT />} />
          <Route path="/start-chatgpt-go" element={<StartChatGPTgo />} />
          <Route path="/start-chatgpt-v2" element={<StartChatGPTv2 />} />
          <Route path="/text-to-image" element={<Text2Image />} />
          <Route path="/splash" element={<SplashPage />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/active-session" element={<ActiveSession />} />
          <Route path="/*" element={<NotFoundPage />} />
                    
        </Routes>
        </QueryClientProvider>
        <div className="loader-container"><div className="overlay"></div><div className="lds-ai"><div></div><div></div><div></div></div></div>
      </div>
    </Router>
  );
}
export default App;