import React, { useState } from 'react';
import './style.css';
import { motion } from "framer-motion";
import { FaInfoCircle } from 'react-icons/fa';
import ccImages from '../assets/images/cc_v2.png';
import ccAuth from '../assets/images/secure90x72.gif';
import { Auth } from '../core/utils/auth';
import { GetCookie, RemoveCookie } from '../core/utils/cookies';
import { useQuery } from "react-query";
import axios from 'axios';
import aiproLogo from '../assets/images/AIPRO.png';
import { getPricePlan } from '../core/utils/main';
import { Helmet } from 'react-helmet';
import { SetCookie } from '../core/utils/cookies';

const pricing = GetCookie("pricing") ? GetCookie("pricing") : "";
const tk = GetCookie("access") ? GetCookie("access") : "";
const cta_pmt = GetCookie("cta_pmt") ? GetCookie("cta_pmt") : "";

var plan = null;
async function getPlan() {
  if(plan) return plan;
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-plan`, { plan_id: pricing }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  const output = response.data;
  if(output.success) {
    plan = output.data;
    return output.data;
  } else {
    return [];
  }
}

function Payment() {
  const { data } = useQuery("users", getPlan);
  const [selectedMethod, setSelectedMethod] = useState('creditCard');
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [nameError, setNameError] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardDateError, setCardDateError] = useState("");
  const [cvvError, setCVVError] = useState("");
  const [willRedirect, setWillRedirect] = useState(true);


  const auth = Auth('/register');
  if(auth === undefined || auth === false) return;

  if(willRedirect) {
    if(auth.status === 'active' && auth.expired === 'no' ) {
      window.location.href = '/my-account';
      return;
    }
  }

  var date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  var today = new Date();
  var expire_date = new Date();
  expire_date.setDate(today.getDate() + 30);

  if (data && data.currency && data.price){
    var amount = data.price;
    if (data.trial_price !== ''){
      amount = data.trial_price;
    }
    SetCookie('currency', data.currency, { expires: expire_date, path: '/' });
    SetCookie('currency', data.currency, { expires: expire_date, domain: '.ai-pro.org', path: '/' });
    SetCookie('amount', amount, { expires: expire_date, path: '/' });
    SetCookie('amount', amount, { expires: expire_date, domain: '.ai-pro.org', path: '/' });
  }

  const handleButtonClick = (method) => {
    setSelectedMethod(method);
  };

  const handleNameChange = (event) => {
    let input = event.target.value;
    // Remove non-alphabetic characters
    input = input.replace(/[^A-Za-z ]/g, "");
    // Limit the input to 50 characters
    input = input.slice(0, 50);
    setName(input);
  };

  const handleCardNumberChange = (event) => {
    let input = event.target.value;
    // Remove non-numeric characters
    input = input.replace(/\D/g, "");
    // Remove any existing dashes from the input
    input = input.replace(/-/g, "");
    // Add a dash after every fourth digit
    input = input.replace(/(\d{4})/g, "$1-");
    // Remove any trailing dash
    input = input.replace(/-$/, "");
    // Limit the input to 16 digits
    input = input.slice(0, 19);
    setCardNumber(input);
  };

  const handleCardDateChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    // Limit the input to 4 characters
    input = input.slice(0, 4);
    // Add "/" after the first 2 characters
    if (input.length >= 3) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }
    setCardDate(input);

  };

  const handleCVVChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    // Limit the input to 5 characters
    input = input.slice(0, 5);
    setCVV(input);
  };

  const submitPayment = () => {
    setWillRedirect(false);

    setNameError("");
    setCardNumberError("");
    setCardDateError("");
    setCVVError("");

    // Perform validation
    let isValid = true;


    if (!name || name.length === 0) {
      // setNameError("required");
      window.toastr.error("Full Name is required");
      isValid = false;
    } else if (!name.includes(" ")) {
      // setNameError("enter at least two names separated by a space");
      window.toastr.error("Enter at least two names separated by a space");
      isValid = false;
    }

    if (!cardNumber) {
      // setCardNumberError("required");
      window.toastr.error("Card Number is required");
      isValid = false;
    }

    if (!cardDate || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(cardDate)) {
      if (!cardDate) {
        window.toastr.error("MM/YY is required");
      } else {
        window.toastr.error("Invalid MM/YY");
      }
      isValid = false;
    }

    if (!cvv || !/^\d{3,5}$/.test(cvv)) {
      // setCVVError("required");
      window.toastr.error("CVC is required");
      isValid = false;
    }

    // If any validation error occurred, stop further processing
    if (!isValid) {
      return;
    }

    document.querySelector(".loader-container").classList.add('active');
    var name_split = name.split(" ");
    var first_name = name_split[0];
    var last_name = name_split[name_split.length - 1];
    var ccmonth = cardDate.split("/")[0];
    var ccyr = cardDate.split("/")[1];

    var url = `${process.env.REACT_APP_API_URL}/t/create-subscription`;

    axios.post(url, {
      tk,
      first_name,
      last_name,
      cc: cardNumber,
      ccmonth: ccmonth,
      ccyr: "20" + ccyr,
      cvv: cvv,
      plan_id: pricing
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;
      if(output.success) {
        window.toastr.success("Success");
        RemoveCookie('pricing');
        window.location.href = '/thankyou/?plan='+plan.label.replace(" ","").replace(" ","");
        return;
      }
      document.querySelector(".loader-container").classList.remove('active');
      if(output.data) window.toastr.error(output.data.msg);
    }).catch(function (error) {
      if (error.response && error.response.status===429) {
        document.querySelector(".loader-container").classList.remove('active');
        window.toastr.error("Sorry, too many requests. Please try again in a bit!");
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>AI Pro | Payment Option</title>
        <meta name="description" content="Safely complete your purchase with our secure payment options. Buy now with confidence!" />
      </Helmet>
      <div className="Payment bg-gray-100 min-h-[600px] flex">
          <div className="mx-auto py-10 px-4">
            <div className="flex flex-col items-center py-4 sm:py-16">
              <div className="flex flex-wrap md:flex-wrap justify-center w-full">
                <div className="pay_left px-0 sm:px-4 mb-8 w-full lg:w-1/2">
                  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <img src={aiproLogo} alt="AI-Pro Logo" className="aiprologo text-center mx-auto py-4"/>
                    <div className="px-3 sm:px-6 py-6">
                      <div className="hidden">
                        <h1 className="text-xl font-bold mb-4">Choose Payment Method</h1>
                        <button
                          className={`bg-white border ${
                            selectedMethod === 'creditCard' ? 'text-blue-500' : 'text-gray-500'
                          } font-bold py-3 px-6 rounded-lg mr-2`}
                          onClick={() => handleButtonClick('creditCard')}
                        >
                          Credit Card
                        </button>
                        <button
                          className={`bg-white border ${
                            selectedMethod === 'paypal' ? 'text-blue-500' : 'text-gray-500'
                          } font-bold py-3 px-6 rounded-lg mr-2`}
                          onClick={() => handleButtonClick('paypal')}
                        >
                          Paypal
                        </button>
                      </div>
                      <div className="">
                        <h2 className="text-lg mb-4 pt-4 text-gray-600 font-bold">Payment Method</h2>
                          <div className="mb-4">
                            <input className={`text-xs sm:text-sm w-full px-2 sm:px-4 py-3 border ${nameError ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none`}
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Full name"
                              value={name}
                              onChange={handleNameChange}
                              onKeyUp={(event) => {
                                setName(event.target.value);
                            }}/>
                          </div>
                          <div className="mb-4">
                            <input className="text-xs sm:text-sm w-full px-2 sm:px-4 py-3 border border-gray-300 rounded focus:outline-none bg-gray-100"
                              disabled
                              type="email"
                              id="name"
                              name="name"
                              placeholder="Email"
                              value={auth.email}
                            />
                          </div>
                          <div className={`pb-0 pt-1 sm:pb-0 sm:pt-0 mb-4 border ${cardNumberError || cardDateError || cvvError ? 'border-red-500' : 'border-gray-300'} rounded input-container flex`}>
                            <div className="w-1/2 sm:w-2/3 pr-2">
                              <input className={`text-xs sm:text-sm w-full px-2 sm:px-4 py-3 rounded fs-exclude  focus:outline-none focus:border-indigo-500`}
                                type="text"
                                id="card-number"
                                name="card-number"
                                placeholder="Card number"
                                value={cardNumber}
                                onChange={handleCardNumberChange}
                                onKeyUp={(event) => {
                                  setCardNumber(event.target.value);
                              }}/>
                            </div>
                            <div className="w-1/4 sm:w-1/4 pr-2">
                              <input className="text-xs sm:text-sm w-full px-2 sm:px-4 py-3 rounded focus:outline-none focus:border-indigo-500 fs-exclude"
                              type="text"
                              id="expiration-date"
                              name="expiration-date"
                              placeholder="MM/YY"
                              value={cardDate}
                              onChange={handleCardDateChange}
                              onKeyUp={(event) => {
                                setCardDate(event.target.value);
                              }}/>
                            </div>
                            <div className="w-1/4 sm:w-1/6">
                              <input className="text-xs sm:text-sm w-full px-2 sm:px-4 py-3 rounded focus:outline-none focus:border-indigo-500 fs-exclude"
                              type="text"
                              id="cvv"
                              name="cvv"
                              placeholder="CVC"
                              value={cvv}
                              onChange={handleCVVChange}
                              onKeyUp={(event) => {
                                setCVV(event.target.value);
                              }}/>
                            </div>

                          </div>
                          { data ?
                          <div className="flex py-5 border-b">
                            <div className="w-1/2 text-sm"><b className="text-md">{ data.label ? data.label : "" }</b><br/>
                            { data.trial_days ? "Initial Payment" : ""}</div>
                            { data.trial_days ?
                              <div className="w-1/2 text-right text-sm"><b>{getPricePlan(data.currency, data.trial_price)}</b><br/>
                              for { data.trial_days ? data.trial_days : "" } days</div>
                            : <div className="w-1/2 text-right text-sm"><b>{getPricePlan(data.currency, data.price)}</b><br/>
                              per { data.payment_interval === "Monthly" ? "Month" : "Year" }</div>
                            }
                          </div> : "" }
                          { data ?
                          <div className="flex py-5">
                            <div className="w-1/2 text-sm"><b className="text-md">Subtotal</b></div>
                            { data.trial_days ?
                              <div className="w-1/2 text-right text-sm"><b>{getPricePlan(data.currency, data.trial_price)}</b>
                              <br/>for { data.trial_days ? data.trial_days : "" } days</div>
                            : <div className="w-1/2 text-right text-sm"><b>{getPricePlan(data.currency, data.price)}</b><br/>
                              per { data.payment_interval === "Monthly" ? "Month" : "Year" }</div>
                            }
                          </div> : "" }

                          <motion.button
                            className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg w-full my-4 proceed-pmt"
                            whileHover={{ backgroundColor: "#5997fd" }}
                            whileTap={{ scale: 0.9 }}
                            onClick={submitPayment}
                          >
                            {cta_pmt ? cta_pmt : "Complete Purchase"}
                          </motion.button>
                      </div>
                      <span className="text-[12px] text-gray-600"><FaInfoCircle className="inline text-lg mr-1"/> By clicking the “{cta_pmt ? cta_pmt : "Complete Purchase"}” button, I have read and agreed to the Terms and Conditions.</span>
                    </div>
                  </div>
                      <div className="securecont border-t-2 border-gray-300 flex py-5">
                        <div className="securelogo mb-2 text-sm w-full text-center w-1/2">
                          <img src={ccImages} alt="Secure Logo" className="cclogo inline text-center"/>
                          <img src={ccAuth} alt="Authorize" className="inline text-center md:text-right py-2 w-20 mx-auto"/>
                        </div>
                      </div>

                </div>

              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default Payment;