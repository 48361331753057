import React, { useState } from 'react';
import './style.css';
import { motion } from "framer-motion";
// import Header from '../header';
import Header from '../header/headerlogo';
import { FaInfoCircle, FaLock } from 'react-icons/fa';
import ccImages from '../assets/images/cc_v3.png';
// import ccAuth from '../assets/images/secure90x72.gif';
import { Auth } from '../core/utils/auth';
import { GetCookie, RemoveCookie } from '../core/utils/cookies';
import { useQuery } from "react-query";
import axios from 'axios';
import { getPrice, getPricePlan } from '../core/utils/main';
import { Helmet } from 'react-helmet';
import { SetCookie } from '../core/utils/cookies';

const pricing = GetCookie("pricing") ? GetCookie("pricing") : "";
const tk = GetCookie("access") ? GetCookie("access") : "";
const cta_pmt = GetCookie("cta_pmt") ? GetCookie("cta_pmt") : "";
var plan = null;
async function getPlan() {
  if(plan) return plan;
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-plan`, { plan_id: pricing }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  const output = response.data;
  if(output.success) {
    plan = output.data;
    return output.data;
  } else {
    return [];
  }
}

function Payment() {
  const { data } = useQuery("users", getPlan);
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [nameError, setNameError] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardDateError, setCardDateError] = useState("");
  const [cvvError, setCVVError] = useState("");
  const [zip, setZIP] = useState("");
  const [zipError, setZIPError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [willRedirect, setWillRedirect] = useState(true);

  const auth = Auth('/register');
  if(auth === undefined || auth === false) return;

  if(willRedirect) {
    if(auth.status === 'active') {
      window.location.href = '/my-account';
      return;
    }
  }

  var date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  var today = new Date();
  var expire_date = new Date();
  expire_date.setDate(today.getDate() + 30);

  if (data && data.currency && data.price){
    var amount = data.price;
    if (data.trial_price !== ''){
      amount = data.trial_price;
    }
    SetCookie('currency', data.currency, { expires: expire_date, path: '/' });
    SetCookie('currency', data.currency, { expires: expire_date, domain: '.ai-pro.org', path: '/' });
    SetCookie('amount', amount, { expires: expire_date, path: '/' });
    SetCookie('amount', amount, { expires: expire_date, domain: '.ai-pro.org', path: '/' });
  }

  const handleZIPChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    // input = input.replace(/\D/g, "");
    // // Limit the input to 3 characters
    // input = input.slice(0, 10);
    setZIP(input);
  };

  const handleNameChange = (event) => {
    let input = event.target.value;
    // Remove non-alphabetic characters
    input = input.replace(/[^A-Za-z ]/g, "");
    // Limit the input to 50 characters
    input = input.slice(0, 50);
    setName(input);
  };

  const handleCardNumberChange = (event) => {
    let input = event.target.value;
    // Remove non-numeric characters
    input = input.replace(/\D/g, "");
    // Remove any existing dashes from the input
    input = input.replace(/-/g, "");
    // Add a dash after every fourth digit
    input = input.replace(/(\d{4})/g, "$1-");
    // Remove any trailing dash
    input = input.replace(/-$/, "");
    // Limit the input to 16 digits
    input = input.slice(0, 19);
    setCardNumber(input);
  };

  const handleCardDateChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    // Limit the input to 4 characters
    input = input.slice(0, 4);
    // Add "/" after the first 2 characters
    if (input.length >= 3) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }
    setCardDate(input);
  };

  const handleCVVChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    // Limit the input to 5 characters
    input = input.slice(0, 5);
    setCVV(input);
  };

  const handlePhoneChange = (event) => {
    let input = event.target.value;
    // Remove non-digit characters
    input = input.replace(/\D/g, "");
    setPhone(input);
  };

  const submitPayment = () => {
    setWillRedirect(false);

    setNameError("");
    setCardNumberError("");
    setCardDateError("");
    setCVVError("");

    // Perform validation
    let isValid = true;


    if (!name || name.length === 0) {
      setNameError("required");
      isValid = false;
    } else if (!name.includes(" ")) {
      setNameError("enter at least two names separated by a space");
      isValid = false;
    }

    if (!cardNumber) {
      setCardNumberError("required");
      isValid = false;
    }

    if (!cardDate || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(cardDate)) {
      setCardDateError("MM/YY");
      isValid = false;
    }

    if (!cvv || !/^\d{3,5}$/.test(cvv)) {
      setCVVError("required");
      isValid = false;
    }

    if(!zip) {
      setZIPError("required");
      isValid = false;
    }

    if(!phone) {
      setPhoneError("required");
      isValid = false;
    }

    // If any validation error occurred, stop further processing
    if (!isValid) {
      return;
    }

    document.querySelector(".loader-container").classList.add('active');
    var name_split = name.split(" ");
    var first_name = name_split[0];
    var last_name = name_split[name_split.length - 1];
    var ccmonth = cardDate.split("/")[0];
    var ccyr = cardDate.split("/")[1];

    axios.post(`${process.env.REACT_APP_API_URL}/t/create-subscription`, {
      tk,
      first_name,
      last_name,
      cc: cardNumber,
      ccmonth: ccmonth,
      ccyr: "20" + ccyr,
      cvv: cvv,
      plan_id: pricing,
      postal: zip,
      phone: phone,
      gateway: '2'
    }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(function(res) {
      let output = res.data;
      if(output.success) {
        window.toastr.success("Success");
        RemoveCookie('pricing');
        window.location.href = '/thankyou/?plan='+plan.label.replace(" ","").replace(" ","");
        return;
      }
      document.querySelector(".loader-container").classList.remove('active');
      if(output.data) window.toastr.error(output.data.msg);
    }).catch(function (error) {
      if (error.response && error.response.status===429) {
        document.querySelector(".loader-container").classList.remove('active');
        window.toastr.error("Sorry, too many requests. Please try again in a bit!");
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>AI Pro | Payment Option</title>
        <meta name="description" content="Safely complete your purchase with our secure payment options. Buy now with confidence!" />
      </Helmet>
      <Header auth={auth} />
      { data ?
      <div className="Payment bg-gray-100 min-h-[600px] flex">

          <div className="px-4 mx-auto py-10">
            <div className="flex flex-col items-center py-10 lg:py-16">
              <div className="flex flex-wrap md:flex-wrap justify-center w-full">
                <div className="pay_left px-4 mb-8 w-full md:w-1/2">
                  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="px-6 pb-10">
                      <div className="">
                        <h2 className="text-xl font-bold mb-4 pt-10">Enter Billing Details</h2>
                          <div className="mb-4">
                            <label className="text-xs block mb-0 mt-1" htmlFor="name">Name on Card <span className="text-red-500">*</span>
                            { nameError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{nameError}</span> }</label>
                            <input className="w-full px-3 py-2 border border-gray-300 rounded"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="John Doe"
                            value={name}
                            onChange={handleNameChange}
                            onKeyUp={(event) => {
                              setName(event.target.value);
                            }}/>
                          </div>
                          <div className="mb-4">
                            <label className="text-xs block mb-0 mt-1" htmlFor="card-number">Card Number <span className="text-red-500">*</span>
                            { cardNumberError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cardNumberError}</span> }</label>
                            <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                            type="text"
                            id="card-number"
                            name="card-number"
                            placeholder="1234 5678 9012 3456"
                            value={cardNumber}
                            onChange={handleCardNumberChange}
                            onKeyUp={(event) => {
                              setCardNumber(event.target.value);
                            }}/>
                          </div>
                          <div className="mb-4 md:flex">
                            <div className="expdate w-full md:w-2/3 mr-2 md:mr-5">
                              <label className="text-xs block mb-0 mt-1" htmlFor="expiration-date">Expiration Date <span className="text-red-500">*</span>
                              { cardDateError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cardDateError}</span> }</label>
                              <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                              type="text"
                              id="expiration-date"
                              name="expiration-date"
                              placeholder="MM/YY"
                              value={cardDate}
                              onChange={handleCardDateChange}
                              onKeyUp={(event) => {
                                setCardDate(event.target.value);
                              }}/>
                            </div>

                            <div className=' w-full sm:w-full md:w-2/4 md:flex'>
                              <div className="cvv w-full mr-2 md:mr-5">
                                <label className="text-xs block mb-0 mt-1" htmlFor="cvv">CVV <span className="text-red-500">*</span>
                                { cvvError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{cvvError}</span> }</label>
                                <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                                type="text"
                                id="cvv"
                                name="cvv"
                                placeholder="CVV"
                                value={cvv}
                                onChange={handleCVVChange}
                                onKeyUp={(event) => {
                                  setCVV(event.target.value);
                                }}/>
                              </div>
                              <div className="zip w-full w-6/6">
                                <label className="text-xs block mb-0 mt-1" htmlFor="zip">Zip <span className="text-red-500">*</span>
                                { zipError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{zipError}</span> }</label>
                                <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                                type="text"
                                id="zip"
                                name="zip"
                                placeholder="ZIP"
                                value={zip}
                                onChange={handleZIPChange}
                                onKeyUp={(event) => {
                                  setZIP(event.target.value);
                                }}/>
                              </div>
                            </div>
                          </div>

                          <div className="mb-4">
                            <label className="text-xs block mb-0 mt-1" htmlFor="phone-number">Phone Number <span className="text-red-500">*</span>
                            { phoneError && <span className="text-red-500 text-xs text-left w-full mb-2 inline">{phoneError}</span> }</label>
                            <input className="w-full px-3 py-2 border border-gray-300 rounded fs-exclude"
                            type="text"
                            id="phone-number"
                            name="phone-number"
                            placeholder="1234567890"
                            value={phone}
                            onChange={handlePhoneChange}
                            onKeyUp={(event) => {
                              setPhone(event.target.value);
                            }}/>
                          </div>

                          <motion.button
                            className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg w-full my-4 proceed-pmt"
                            whileHover={{ backgroundColor: "#5997fd" }}
                            whileTap={{ scale: 0.9 }}
                            onClick={submitPayment}
                          >
                            {cta_pmt ? cta_pmt : "Complete Purchase"}
                          </motion.button>
                      </div>
                      <span className="text-[12px] text-gray-600"><FaInfoCircle className="inline text-lg mr-1"/> By clicking the “{cta_pmt ? cta_pmt : "Complete Purchase"}” button, I have read and agreed to the Terms and Conditions.</span>
                    </div>
                  </div>
                </div>

                <div className="pay_right px-4 mb-8 md:w-2/5">
                  <div className="border px-8 rounded border-gray-300">
                    <h2 className="text-xl font-bold mb-4 pt-10 pb-0">Order Summary</h2>
                    <div className="py-5">
                      <div className="mb-2 text-sm pb-4 border-b border-gray-300">
                        <b className="text-lg text-uppercase">{ data.plan_type_display }</b>
                      </div>
                      <div className="flex flex-wrap mb-2 text-sm mt-4 mr-6">
                        <div className="text-lg font-bold mt-4 w-1/2">TOTAL:</div>
                        <div className="text-lg font-bold mt-4 w-1/2 text-right">{ getPricePlan(data.currency, getPrice(data)) }</div>
                      </div>
                      <div className="mb-8 text-sm mt-6">
                        { data.display_txt3 ? data.display_txt3 : "Your subscription will renew monthly until you cancel it."}
                      </div>
                    </div>
                  </div>
                  <div className="securecont block p-5 mx-auto text-left">
                    <div className="securetext mb-2 text-sm w-full"><FaLock className="inline text-lg mr-1 text-orange-500 text-xs"/> Secure Checkout</div>
                    <div className="securelogo mb-2 text-sm w-full">
                      <img src={ccImages} alt="Secure Logo" className="cclogo inline"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
      </div> : "" }
    </>
  );
}

export default Payment;