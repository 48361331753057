import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { getPricePlan } from '../../core/utils/main';
import { GetCookie } from '../../core/utils/cookies';
import '../css/style.css';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import TpReviews from '../../features/tpreviews';

const tooltips = [
  'arcana.pricing.vprice_02.basic.desc1_tt',
  'arcana.pricing.vprice_02.basic.desc2_tt',
  'arcana.pricing.vprice_02.basic.desc3_tt',
  'arcana.pricing.vprice_02.basic.desc4_tt',
  'arcana.pricing.vprice_02.basic.desc5_tt',
  'arcana.pricing.vprice_02.basic.desc6_tt',
  'arcana.pricing.vprice_02.basic.desc7_tt',
  'arcana.pricing.vprice_02.basic.desc8_tt',
  'arcana.pricing.vprice_02.basic.desc9_tt',
];

const descContents = [
  'arcana.pricing.vprice_02.basic.desc1',
  'arcana.pricing.vprice_02.basic.desc2',
  'arcana.pricing.vprice_02.basic.desc3',
  'arcana.pricing.vprice_02.basic.desc4',
  'arcana.pricing.vprice_02.basic.desc5',
  'arcana.pricing.vprice_02.basic.desc6',
  'arcana.pricing.vprice_02.basic.desc7',
  'arcana.pricing.vprice_02.basic.desc8',
  'arcana.pricing.vprice_02.basic.desc9',
];

const tooltipspro = [
  'arcana.pricing.vprice_02.pro.desc1_tt',
  'arcana.pricing.vprice_02.pro.desc2_tt',
  'arcana.pricing.vprice_02.pro.desc3_tt',
  'arcana.pricing.vprice_02.pro.desc4_tt',
  'arcana.pricing.vprice_02.pro.desc5_tt',
  'arcana.pricing.vprice_02.pro.desc6_tt',
  'arcana.pricing.vprice_02.pro.desc7_tt',
  'arcana.pricing.vprice_02.pro.desc8_tt',
  'arcana.pricing.vprice_02.pro.desc9_tt',
  'arcana.pricing.vprice_02.pro.desc10_tt',
  'arcana.pricing.vprice_02.pro.desc11_tt',
  'arcana.pricing.vprice_02.pro.desc12_tt',
];

const tooltipspromax = [
  'arcana.pricing.vprice_02.promax.desc1_tt',
  'arcana.pricing.vprice_02.promax.desc2_tt',
  'arcana.pricing.vprice_02.promax.desc3_tt',
  'arcana.pricing.vprice_02.promax.desc4_tt',
  'arcana.pricing.vprice_02.promax.desc5_tt',
  'arcana.pricing.vprice_02.promax.desc6_tt',
  'arcana.pricing.vprice_02.promax.desc7_tt',
  'arcana.pricing.vprice_02.promax.desc8_tt',
  'arcana.pricing.vprice_02.promax.desc9_tt',
  'arcana.pricing.vprice_02.promax.desc10_tt',
  'arcana.pricing.vprice_02.promax.desc11_tt',
  'arcana.pricing.vprice_02.promax.desc12_tt',
];

const tooltipsNoChatPDFpro = [
  'arcana.pricing.vprice_02.pro.desc1_tt',
  'arcana.pricing.vprice_02.pro.desc2_tt',
  'arcana.pricing.vprice_02.pro.desc3_tt',
  'arcana.pricing.vprice_02.pro.desc4_tt',
  'arcana.pricing.vprice_02.pro.desc5_tt',
  'arcana.pricing.vprice_02.pro.desc6_tt',
  'arcana.pricing.vprice_02.pro.desc7_tt',
  'arcana.pricing.vprice_02.pro.desc8_tt',
  'arcana.pricing.vprice_02.pro.desc9_tt',
  'arcana.pricing.vprice_02.pro.desc12_tt',
];

const descContentspro = [
  'arcana.pricing.vprice_02.pro.desc1',
  'arcana.pricing.vprice_02.pro.desc2',
  'arcana.pricing.vprice_02.pro.desc3',
  'arcana.pricing.vprice_02.pro.desc4',
  'arcana.pricing.vprice_02.pro.desc5',
  'arcana.pricing.vprice_02.pro.desc6',
  'arcana.pricing.vprice_02.pro.desc7',
  'arcana.pricing.vprice_02.pro.desc8',
  'arcana.pricing.vprice_02.pro.desc9',
  'arcana.pricing.vprice_02.pro.desc10',
  'arcana.pricing.vprice_02.pro.desc11',
  'arcana.pricing.vprice_02.pro.desc12',
];

const descContentspromax = [
  'arcana.pricing.vprice_02.promax.desc1',
  'arcana.pricing.vprice_02.promax.desc2',
  'arcana.pricing.vprice_02.promax.desc3',
  'arcana.pricing.vprice_02.promax.desc4',
  'arcana.pricing.vprice_02.promax.desc5',
  'arcana.pricing.vprice_02.promax.desc6',
  'arcana.pricing.vprice_02.promax.desc7',
  'arcana.pricing.vprice_02.promax.desc8',
  'arcana.pricing.vprice_02.promax.desc9',
  'arcana.pricing.vprice_02.promax.desc10',
  'arcana.pricing.vprice_02.promax.desc11',
  'arcana.pricing.vprice_02.promax.desc12',
];

const descContentsNoChatPDFpro = [
  'arcana.pricing.vprice_02.pro.desc1',
  'arcana.pricing.vprice_02.pro.desc2',
  'arcana.pricing.vprice_02.pro.desc3',
  'arcana.pricing.vprice_02.pro.desc4',
  'arcana.pricing.vprice_02.pro.desc5',
  'arcana.pricing.vprice_02.pro.desc6',
  'arcana.pricing.vprice_02.pro.desc7',
  'arcana.pricing.vprice_02.pro.desc8',
  'arcana.pricing.vprice_02.pro.desc9',
  'arcana.pricing.vprice_02.pro.desc12',
];

const descContentsEnterprise = [
  'arcana.pricing.vprice_02.enterprise.desc1',
  'arcana.pricing.vprice_02.enterprise.desc2'
];

const tooltipsenterprise = [
  'arcana.pricing.vprice_02.enterprise.desc1_tt',
  'arcana.pricing.vprice_02.enterprise.desc2_tt'
];


function VPrice02(props) {
  const data = props.data ? props.data : null;
  const setPricing = props.setPricing ? props.setPricing : ()=>{};
  var ppg = GetCookie("ppg") ? GetCookie("ppg") : process.env.REACT_APP_DEFAULT_PPG ? process.env.REACT_APP_DEFAULT_PPG : "14";
  const ppgArrayWithToggle = ['40','48','52'];
  const showToggle = (ppgArrayWithToggle.includes(ppg) && props.showToggle) ? props.showToggle : false;
  var billedAnnualDisplay = false;

  if (ppg==='48'){
    billedAnnualDisplay = true;
  }

  const [ planInterval, setPlanInterval ] = useState(billedAnnualDisplay ? "yearly" : "monthly");
  const { t } = useTranslation();
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [activeTooltippro, setActiveTooltippro] = useState(null);
  const [activePriceCol, setActivePriceCol] = useState(null);
  const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  const tpreviews = GetCookie("tp_reviews") ? GetCookie("tp_reviews") : "";
  const enterprise = GetCookie("enterprise") ? GetCookie("enterprise") : "off";

  const translatedTooltips = tooltips.map(tooltipKey => {
    return t(tooltipKey);
  });

  const translatedTooltipspro = (plan) => {
    return getTooltip(plan).map(tooltipKeypro => {
      return t(tooltipKeypro);
    })
  };
  
  const translatedTooltipspromax = tooltipspromax.map(tooltipKeypro => {
    return t(tooltipKeypro);
  });

  const translatedTooltipsEnterprise = tooltipsenterprise.map(tooltipKey => {
    return t(tooltipKey);
  });

  const basicPlansDescToInclude = ['Basic', '7-Day Trial', 'ANNUAL', '14-Day Trial', '3-Day Trial', 'BASIC', 'BASIC Annual'];
  const proPlansDescToInclude = ['7-Day Trial', '14-Day Trial', '3-Day Trial', 'PRO', 'PRO Annual'];
  const enterprisePlansDescToInclude = ['ENTERPRISE'];

  const intervalChange = function() {
    if(planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const checkPlanInterval = function(plan) {
    if(!showToggle) return true;
    if(plan.payment_interval.toLowerCase() === planInterval) return true;
    return false;
  }

  const isPro = (plan) => {
    let returnData = false;
    if( (proPlansDescToInclude.includes(plan.label) && plan.plan_type === 'Pro') ) {
      returnData = true;
    }
    return returnData
  }
  const getPlanDescContent = (plan) => {
    if(plan.plan_id === "86") return descContentsNoChatPDFpro;
    if(plan.plan_type.toLowerCase() === 'promax') return descContentspromax;
    return descContentspro;
  }

  const getTooltip = (plan) => {
    if(plan.plan_id === "86") return tooltipsNoChatPDFpro;
    if(plan.plan_type.toLowerCase() === 'promax') return tooltipspromax;
    return tooltipspro;
  }
  const enterpriseTab = function() {
    return (
        <div className="price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 relative">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="px-6 py-10 price-content">
              <h3 className="text-xl font-bold mb-4">{t('arcana.pricing.vprice_02.enterprise.title')}</h3>
              <p className="text-4xl font-bold text-gray-800 mb-4">{t('arcana.pricing.vprice_02.enterprise.price')}</p>
              { planInterval === 'yearly' && ppg === '48' ? <div className='text-xs mb-4'>(billed yearly)</div> : '' }
              <div className="mb-6">
                <ul className="text-sm text-gray-600">
                  <li className="mb-2 text-left font-bold"><div>{t('arcana.pricing.vprice_02.enterprise.desc1')}</div></li>
                  <li className="mb-2 text-left"><div>{t('arcana.pricing.vprice_02.enterprise.desc2')}</div></li>
                </ul>
              </div>
              <motion.button
                className="bg-blue-500 text-white font-bold py-3 px-3 rounded-lg"
                whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setPricing(62)}
              >
              {t('arcana.pricing.vprice_02.enterprise.cta')}
              </motion.button>
            </div>
          </div>
        </div>
    );
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <div className="v-pricing-02 pricing bg-gray-100">
        <div className="pricing_columns container mx-auto py-10">
          <div className="flex flex-col items-center py-10 lg:py-14">
            <h1 className="text-4xl lg:text-4xl font-bold text-center mb-4">
              {t('arcana.pricing.vprice_02.title')}
            </h1>
            {(ppg === '40' || ppg === '48' || ppg === '52') && showToggle ? (
              <div className="p-4">
                <div className="text-1xl lg:text-1xl font-bold text-center mb-4">
                  <div>{t('arcana.pricing.vprice_02.toggle_desc')}</div>
                </div>
                <div className="flex items-center justify-center w-full mb-8">
                  <label for="toggleB" className="flex items-center cursor-pointer">
                    <div className={`${planInterval === 'monthly' ? "text-blue-700 font-bold" : "text-gray-700"} mr-3 uppercase`}>
                      {t('arcana.pricing.vprice_02.monthly')}
                    </div>
                    <div className="relative">
                      <input type="checkbox" id="toggleB" className="sr-only toggle" onChange={intervalChange} defaultChecked={billedAnnualDisplay}/>
                      <div className="block bg-gray-400 w-12 h-6 rounded-full"></div>
                      <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                    </div>
                    <div className={`${planInterval === 'yearly' ? "text-blue-700 font-bold" : "text-gray-700"} ml-3 uppercase`}>
                      {t('arcana.pricing.vprice_02.yearly')}
                    </div>
                  </label>
                </div>
              </div>
            ) : ""}

            {(ppg === '40' || ppg === '48' || ppg === '52') && showToggle ? (
              isMobile ? (
                <div className="mobile02 pricing-toggle flex flex-col md:flex-row justify-center one">
                  {data?.map((plan, index) => (
                    checkPlanInterval(plan) ? (
                      <div key={index} className="price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 relative"
                        onMouseEnter={() => setActivePriceCol(plan.plan_id)}
                        onMouseLeave={() => setActivePriceCol(null)}
                      >
                        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                          <div className="px-6 py-10 price-content">
                            <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                            {
                            billedAnnualDisplay && plan.payment_interval==='Yearly' ? <p className="text-4xl font-bold text-gray-800">{getPricePlan(plan.currency, parseFloat(plan.price/12).toFixed(2))}<span className="text-sm"> /month</span></p> :
                            plan.trial_price ?
                            <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.trial_price)}</p>
                            : <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.price)}<span className="text-sm"> /{ plan.payment_interval === "Monthly" ? "month" : "year" }</span></p>
                            }
                            {
                              billedAnnualDisplay && plan.payment_interval==='Yearly' ? <div className='text-xs mb-4'>(billed yearly)</div> : ''
                            }
                            <div className="mb-6">
                              <ul className="text-sm text-gray-600">
                                <li className={`mb-2 text-left ${basicPlansDescToInclude.includes(plan.label) && plan.plan_type === 'Basic' ? 'block' : 'hidden' }`}>
                                  {descContents.map((content, index) => (
                                    <div
                                      key={index}
                                      className={`relative group hover:text-[#3b82f6] ${activeTooltipIndex === index ? 'active' : ''}`}
                                      onClick={() => setActiveTooltipIndex(activeTooltipIndex === index ? null : index)}
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                      {(activeTooltipIndex === index && activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={index}
                                          show={activeTooltip === index}
                                          enter="transition-opacity ease-in duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip bottom-0">
                                            <div className="tooltip_content p-3 bg-white text-black text-[11px] rounded-xl shadow-lg">
                                              {translatedTooltips[index]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 z text-left ${plan.plan_type.toUpperCase() === 'PROMAX' ? 'block' : 'hidden' }`}>
                                  {descContentspromax.map((content, index) => (
                                    <div
                                      key={index}
                                      className={`relative group hover:text-[#3b82f6] ${activeTooltipIndex === index ? 'active' : ''}`}
                                      onClick={() => setActiveTooltipIndex(activeTooltipIndex === index ? null : index)}
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                      {(activeTooltipIndex === index && activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={index}
                                          show={activeTooltip === index}
                                          enter="transition-opacity ease-in duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip bottom-0">
                                            <div className="tooltip_content p-3 bg-white text-black text-[11px] rounded-xl shadow-lg">
                                              {translatedTooltipspromax[index]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 text-left ${isPro(plan) ? 'block' : 'hidden' }`}>
                                  {getPlanDescContent(plan).map((contentpro, indexpro) => (
                                    <div
                                      key={indexpro}
                                      className={`relative group hover:text-[#3b82f6] ${activeTooltipIndex === indexpro ? 'active' : ''}`}
                                      onClick={() => setActiveTooltipIndex(activeTooltipIndex === indexpro ? null : indexpro)}
                                      onMouseEnter={() => setActiveTooltippro(indexpro)}
                                      onMouseLeave={() => setActiveTooltippro(null)}
                                    > {t(contentpro)}
                                      {(activeTooltipIndex === indexpro && activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          show={activeTooltippro === indexpro}
                                          enter="transition-opacity ease-in duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip bottom-0">
                                            <div className="tooltip_content p-3 bg-white text-black text-[11px] rounded-xl shadow-lg">
                                              {translatedTooltipspro(plan)[indexpro]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                              </ul>
                            </div>
                            <motion.button
                              className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                              whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => setPricing(plan.plan_id)}
                            >
                              {t('arcana.pricing.vprice_02.ctabtn')}
                            </motion.button>
                          </div>
                        </div>
                        { planInterval === 'yearly' && showToggle ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                          <div dangerouslySetInnerHTML={{ __html: t('arcana.pricing.vprice_02.promo') }}></div>
                        </div> : null }
                      </div>
                    ) : ""
                  ))}
                  {(enterprise === 'on' && ppg !== '46' && planInterval === 'yearly' && data[0].currency === 'USD') ?
                    <>
                    {enterpriseTab()}
                    </>
                  : null }
                </div>
              ) : (
                <div className="pricing-toggle flex flex-col md:flex-row justify-center two">
                  {data?.map((plan, index) => (
                    checkPlanInterval(plan) ? (
                      <div key={index} className="price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 relative"
                        onMouseEnter={() => setActivePriceCol(plan.plan_id)}
                        onMouseLeave={() => setActivePriceCol(null)}
                      >
                        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                          <div className="px-6 py-10 price-content">
                            <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                            {
                            billedAnnualDisplay && plan.payment_interval==='Yearly' ? <p className="text-4xl font-bold text-gray-800">{getPricePlan(plan.currency, parseFloat(plan.price/12).toFixed(2))}<span className="text-sm"> /month</span></p> :
                            plan.trial_price ?
                            <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.trial_price)}</p>
                            : <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.price)}<span className="text-sm"> /{ plan.payment_interval === "Monthly" ? "month" : "year" }</span></p>
                            }
                            {
                              billedAnnualDisplay && plan.payment_interval==='Yearly' ? <div className='text-xs mb-4'>(billed yearly)</div> : ''
                            }
                            <div className="mb-6">
                              <ul className="text-sm text-gray-600">
                                <li className={`mb-2 text-left ${basicPlansDescToInclude.includes(plan.plan_type.toUpperCase()) && plan.plan_type === 'Basic' ? 'block' : 'hidden' }`}>
                                  {descContents.map((content, index) => (
                                    <div
                                      key={index}
                                      className="relative group hover:text-[#3b82f6]"
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                      {(activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={index}
                                          show={activeTooltip === index}
                                          className="items"
                                          enter="transition-opacity duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip absolute">
                                            <div className="bottom-0 absolute tooltip_content p-3 bg-white text-black text-[11px] border border-blue-400 rounded-xl shadow-lg">
                                              {translatedTooltips[index]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 x text-left ${plan.plan_type.toUpperCase() === 'PROMAX' ? 'block' : 'hidden' }`}>
                                  {descContentspromax.map((content, index) => (
                                    <div
                                      key={index}
                                      className="relative group hover:text-[#3b82f6]"
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                      {(activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={index}
                                          show={activeTooltip === index}
                                          className="items"
                                          enter="transition-opacity duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip absolute">
                                            <div className="bottom-0 absolute tooltip_content p-3 bg-white text-black text-[11px] border border-blue-400 rounded-xl shadow-lg">
                                              {translatedTooltipspromax[index]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}

                                </li>
                                <li className={`mb-2 text-left ${isPro(plan) ? 'block' : 'hidden' }`}>
                                  {getPlanDescContent(plan).map((contentpro, indexpro) => (
                                    <div
                                      key={indexpro}
                                      className="relative group hover:text-[#3b82f6]"
                                      onMouseEnter={() => setActiveTooltippro(indexpro)}
                                      onMouseLeave={() => setActiveTooltippro(null)}
                                    > {t(contentpro)}
                                      {(activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={indexpro}
                                          show={activeTooltippro === indexpro}
                                          className="items"
                                          enter="transition-opacity duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip absolute">
                                            <div className="bottom-0 absolute tooltip_content p-3 bg-white text-black text-[11px] border border-blue-400 rounded-xl shadow-lg">
                                              {translatedTooltipspro(plan)[indexpro]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                              </ul>
                            </div>
                            <motion.button
                              className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                              whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => setPricing(plan.plan_id)}
                            >
                              {t('arcana.pricing.vprice_02.ctabtn')}
                            </motion.button>
                          </div>
                        </div>
                        { planInterval === 'yearly' && showToggle ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                          <div dangerouslySetInnerHTML={{ __html: t('arcana.pricing.vprice_02.promo') }}></div>
                        </div> : null }
                      </div>
                    ) : ""
                  ))}
                  {(enterprise === 'on' && ppg !== '46' && planInterval === 'yearly' && data[0].currency === 'USD') ?
                    <>
                    {enterpriseTab()}
                    </>
                  : null }
                </div>
              )


            ) : (
              isMobile ? (
                <div className="mobile02  pricing-toggle flex flex-col md:flex-row justify-center three">
                  {data?.map((plan, index) => (
                    checkPlanInterval(plan) ? (
                      <div key={index} className="price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 relative"
                        onMouseEnter={() => setActivePriceCol(plan.plan_id)}
                        onMouseLeave={() => setActivePriceCol(null)}
                      >
                        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                          <div className="px-6 py-10 price-content">
                            <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                            {
                            plan.label.toLowerCase()==="enterprise" ? <p className="text-4xl font-bold text-gray-800 mb-4">>Custom Plan</p> :
                            plan.trial_price ?
                            <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.trial_price)}</p>
                            : <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.price)}<span className="text-sm"> /{ plan.payment_interval === "Monthly" ? "month" : "year" }</span></p>
                            }
                            <div className="mb-6">
                              <ul className="text-sm text-gray-600">
                                <li className={`mb-2 text-left ${basicPlansDescToInclude.includes(plan.plan_type.toUpperCase()) && plan.plan_type === 'Basic' ? 'block' : 'hidden' }`}>
                                  {descContents.map((content, index) => (
                                    <div
                                      key={index}
                                      className={`relative group hover:text-[#3b82f6] ${activeTooltipIndex === index ? 'active' : ''}`}
                                      onClick={() => setActiveTooltipIndex(activeTooltipIndex === index ? null : index)}
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                      {(activeTooltipIndex === index && activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={index}
                                          show={activeTooltip === index}
                                          enter="transition-opacity ease-in duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip bottom-0">
                                            <div className="tooltip_content p-3 bg-white text-black text-[11px] rounded-xl shadow-lg">
                                              {translatedTooltips[index]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 v text-left ${plan.plan_type.toUpperCase() === 'PROMAX' ? 'block' : 'hidden' }`}>
                                  {descContentspromax.map((content, index) => (
                                    <div
                                      key={index}
                                      className={`relative group hover:text-[#3b82f6] ${activeTooltipIndex === index ? 'active' : ''}`}
                                      onClick={() => setActiveTooltipIndex(activeTooltipIndex === index ? null : index)}
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                      {(activeTooltipIndex === index && activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={index}
                                          show={activeTooltip === index}
                                          enter="transition-opacity ease-in duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip bottom-0">
                                            <div className="tooltip_content p-3 bg-white text-black text-[11px] rounded-xl shadow-lg">
                                              {translatedTooltipspromax[index]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 text-left ${isPro(plan) ? 'block' : 'hidden' }`}>
                                  {getPlanDescContent(plan).map((contentpro, indexpro) => (
                                    <div
                                      key={indexpro}
                                      className={`relative group hover:text-[#3b82f6] ${activeTooltipIndex === indexpro ? 'active' : ''}`}
                                      onClick={() => setActiveTooltipIndex(activeTooltipIndex === indexpro ? null : indexpro)}
                                      onMouseEnter={() => setActiveTooltippro(indexpro)}
                                      onMouseLeave={() => setActiveTooltippro(null)}
                                    > {t(contentpro)}
                                      {(activeTooltipIndex === indexpro && activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          show={activeTooltippro === indexpro}
                                          enter="transition-opacity ease-in duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip bottom-0">
                                            <div className="tooltip_content p-3 bg-white text-black text-[11px] rounded-xl shadow-lg">
                                              {translatedTooltipspro(plan)[indexpro]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 text-left ${enterprisePlansDescToInclude.includes(plan.plan_type.toUpperCase()) && plan.plan_type === 'Enterprise' ? 'block' : 'hidden' }`}>
                                  {descContentsEnterprise.map((content, index) => (
                                    <div
                                      key={index}
                                      className={`relative group hover:text-[#3b82f6] ${activeTooltipIndex === index ? 'active' : ''}`}
                                      onClick={() => setActiveTooltipIndex(activeTooltipIndex === index ? null : index)}
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                      {(activeTooltipIndex === index && activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={index}
                                          show={activeTooltip === index}
                                          enter="transition-opacity ease-in duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip bottom-0">
                                            <div className="tooltip_content p-3 bg-white text-black text-[11px] rounded-xl shadow-lg">
                                              {translatedTooltipsEnterprise[index]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>

                              </ul>
                            </div>
                            <motion.button
                              className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                              whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => setPricing(plan.plan_id)}
                            >
                              {t('arcana.pricing.vprice_02.ctabtn')}
                            </motion.button>
                          </div>
                        </div>
                        { planInterval === 'yearly' && showToggle ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                          <div dangerouslySetInnerHTML={{ __html: t('arcana.pricing.vprice_02.promo') }}></div>
                        </div> : null }
                      </div>
                    ) : ""
                  ))}
                  {(enterprise === 'on' && ppg !== '46' && data[0].currency === 'USD') ?
                    <>
                    {enterpriseTab()}
                    </>
                  : null }
                </div>
              ) : (
                <div className="flex flex-col md:flex-row justify-center four">
                  {data?.map((plan, index) => (
                    checkPlanInterval(plan) ? (
                      <div key={index} className="price_col w-full lg:w-[330px] xl:w-[380px] text-center px-4 mb-8 relative"
                        onMouseEnter={() => setActivePriceCol(plan.plan_id)}
                        onMouseLeave={() => setActivePriceCol(null)}
                      >
                        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                          <div className="px-6 py-10 price-content">
                            <h3 className="text-xl font-bold mb-4">{plan.label}</h3>
                            {
                            plan.label.toLowerCase()==="enterprise" ? <p className="text-4xl font-bold text-gray-800 mb-4">Custom Plan</p> :
                            plan.trial_price ?
                            <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.trial_price)}</p>
                            : <p className="text-4xl font-bold text-gray-800 mb-4">{getPricePlan(plan.currency, plan.price)}<span className="text-sm"> /{ plan.payment_interval === "Monthly" ? "month" : "year" }</span></p>
                            }
                            <div className="mb-6">
                              <ul className="text-sm text-gray-600">
                                <li className={`mb-2 text-left ${basicPlansDescToInclude.includes(plan.plan_type.toUpperCase()) && plan.plan_type === 'Basic' ? 'block' : 'hidden' }`}>
                                  {descContents.map((content, index) => (
                                    <div
                                      key={index}
                                      className="relative group hover:text-[#3b82f6]"
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                        {(activePriceCol === plan.plan_id) ? (
                                          <Transition
                                            key={index}
                                            show={activeTooltip === index}
                                            className="items"
                                            enter="transition-opacity duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-opacity duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <div className="tooltip absolute">
                                              <div className="bottom-0 absolute tooltip_content p-3 bg-white text-black text-[11px] border border-blue-400 rounded-xl shadow-lg">
                                                {translatedTooltips[index]}
                                              </div>
                                            </div>
                                          </Transition>
                                        ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 b text-left ${plan.plan_type.toUpperCase() === 'PROMAX' ? 'block' : 'hidden' }`}>
                                  {descContentspromax.map((content, index) => (
                                    <div
                                      key={index}
                                      className="relative group hover:text-[#3b82f6]"
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                    > {t(content)}
                                        {(activePriceCol === plan.plan_id) ? (
                                          <Transition
                                            key={index}
                                            show={activeTooltip === index}
                                            className="items"
                                            enter="transition-opacity duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-opacity duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <div className="tooltip absolute">
                                              <div className="bottom-0 absolute tooltip_content p-3 bg-white text-black text-[11px] border border-blue-400 rounded-xl shadow-lg">
                                                {translatedTooltipspromax[index]}
                                              </div>
                                            </div>
                                          </Transition>
                                        ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 text-left ${isPro(plan) ? 'block' : 'hidden' }`}>
                                  {getPlanDescContent(plan).map((contentpro, indexpro) => (
                                    <div
                                      key={indexpro}
                                      className="relative group hover:text-[#3b82f6]"
                                      onMouseEnter={() => setActiveTooltippro(indexpro)}
                                      onMouseLeave={() => setActiveTooltippro(null)}
                                    > {t(contentpro)}
                                      {(activePriceCol === plan.plan_id) ? (
                                        <Transition
                                          key={indexpro}
                                          show={activeTooltippro === indexpro}
                                          className="items"
                                          enter="transition-opacity duration-300"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="transition-opacity duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="tooltip absolute">
                                            <div className="bottom-0 absolute tooltip_content p-3 bg-white text-black text-[11px] border border-blue-400 rounded-xl shadow-lg">
                                              {translatedTooltipspro(plan)[indexpro]}
                                            </div>
                                          </div>
                                        </Transition>
                                      ) : null}
                                    </div>
                                  ))}
                                </li>
                                <li className={`mb-2 text-left ${enterprisePlansDescToInclude.includes(plan.plan_type.toUpperCase()) && plan.plan_type === 'Enterprise' ? 'block' : 'hidden' }`}>
                                  {descContentsEnterprise.map((content, index) => (
                                    <div
                                      key={index}
                                      className="relative group hover:text-[#3b82f6]"
                                      onMouseEnter={() => setActiveTooltip(index)}
                                      onMouseLeave={() => setActiveTooltip(null)}
                                      dangerouslySetInnerHTML={{ __html: t(content) }}
                                    >
                                    </div>
                                  ))}
                                </li>

                              </ul>
                            </div>
                            <motion.button
                              className="bg-blue-500 text-white font-bold py-3 px-6 rounded-lg"
                              whileHover={{ scale: 1.1, backgroundColor: "#5997fd" }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => setPricing(plan.plan_id)}
                            >
                              {plan.label.toLowerCase()==="enterprise" ? "Build My Plan" : t('arcana.pricing.vprice_02.ctabtn') }
                            </motion.button>
                          </div>
                        </div>
                        { planInterval === 'yearly' && showToggle ? <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 text-blue-500 font-bold py-1 px-4 text-xs span-highlight">
                          <div dangerouslySetInnerHTML={{ __html: t('arcana.pricing.vprice_02.promo') }}></div>
                        </div> : null }
                      </div>
                    ) : ""
                  ))}
                  {(enterprise === 'on' && ppg !== '46' && data[0].currency === 'USD') ?
                    <>
                    {enterpriseTab()}
                    </>
                  : null }
                </div>
              )
            )}

            <p className="text-xs max-w-md text-center leading-relaxed mb-10 lg:mb-12">
              {t('arcana.pricing.vprice_02.note')}
            </p>

            { tpreviews === 'on' ?
              <>
                <TpReviews/>
              </>
            : null }
          </div>
        </div>
      </div>
  )
}

export default VPrice02;