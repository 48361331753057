export default function getPlan(user_plan, user_plan_currency) {
    var ppg_id = "";
    if(!user_plan_currency || !user_plan) {
        return "";
    } else if(user_plan_currency === 'USD') {
        if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID ? process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID ? process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID ? process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID : ppg_id;
        } else if(user_plan === 'PROMAX') {
            ppg_id = process.env.REACT_APP_PROMAX_DOWNGRADE_ID ? process.env.REACT_APP_PROMAX_DOWNGRADE_ID : ppg_id;
        } else if(user_plan === 'PROMAX_ANNUAL') {
            ppg_id = process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID ? process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_DOWNGRADE_ID ? process.env.REACT_APP_DOWNGRADE_ID : ppg_id;
        }
    } else if(user_plan_currency === 'GBP') {
        if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_GBP ? process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_GBP : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_GBP ? process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_GBP : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_GBP ? process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_GBP : ppg_id;
        } else if(user_plan === 'PROMAX_ANNUAL') {
            ppg_id = process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_GBP ? process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_GBP : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_DOWNGRADE_ID_GBP ? process.env.REACT_APP_DOWNGRADE_ID_GBP : ppg_id;
        }
    } else if(user_plan_currency === 'EUR') {
        if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_EUR ? process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_EUR : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_EUR ? process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_EUR : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_EUR ? process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_EUR : ppg_id;
        } else if(user_plan === 'PROMAX_ANNUAL') {
            ppg_id = process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_EUR ? process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_EUR : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_DOWNGRADE_ID_EUR ? process.env.REACT_APP_DOWNGRADE_ID_EUR : ppg_id;
        }
    } else if(user_plan_currency === 'BRL') {
        if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_BRL ? process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_BRL : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_BRL ? process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_BRL : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_BRL ? process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_BRL : ppg_id;
        } else if(user_plan === 'PROMAX_ANNUAL') {
            ppg_id = process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_BRL ? process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_BRL : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_DOWNGRADE_ID_BRL ? process.env.REACT_APP_DOWNGRADE_ID_BRL : ppg_id;
        }
    } else if(user_plan_currency === 'SAR') {
        if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_SAR ? process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_SAR : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_SAR ? process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_SAR : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_SAR ? process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_SAR : ppg_id;
        } else if(user_plan === 'PROMAX_ANNUAL') {
            ppg_id = process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_SAR ? process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_SAR : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_DOWNGRADE_ID_SAR ? process.env.REACT_APP_DOWNGRADE_ID_SAR : ppg_id;
        }
    } else if(user_plan_currency === 'AED') {
        if(user_plan === 'BASIC_ANNUAL') {
            ppg_id = process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_AED ? process.env.REACT_APP_BASIC_ANNUAL_DOWNGRADE_ID_AED : ppg_id;
        } else if(user_plan === 'PRO') {
            ppg_id = process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_AED ? process.env.REACT_APP_PRO_DOWNGRADE_BASIC_ID_AED : ppg_id;
        } else if(user_plan === 'PRO_ANNUAL') {
            ppg_id = process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_AED ? process.env.REACT_APP_PRO_ANNUAL_DOWNGRADE_ID_AED : ppg_id;
        } else if(user_plan === 'PROMAX_ANNUAL') {
            ppg_id = process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_AED ? process.env.REACT_APP_PROMAX_ANNUAL_DOWNGRADE_ID_AED : ppg_id;
        } else {
            ppg_id = process.env.REACT_APP_DOWNGRADE_ID_AED ? process.env.REACT_APP_DOWNGRADE_ID_AED : ppg_id;
        }
    }
    return ppg_id;
}