import React from 'react';
import './style.css';
import aiproLogo from '../assets/images/AIPRO.png';

function change_password() {

  return (
    <div className="bg-gray-100 flex items-center text-left w-full">
      <div className="container mx-auto max-w-3xl mx-auto mt-10">

        <div class="max-w-3xl mx-auto bg-white p-8 mt-1 rounded shadow">
        <h1 class="text-md font-bold mb-2 text-blue-800 mb-9">Subject: Reset Password Request</h1>
          <p class="mb-4">Hi,</p>
          <p class="mb-4">We have received your request to reset your password.</p>
          <p class="mb-4">Your current information is:</p>
          <ul class="mb-6 bg-gray-100 p-4">
            <li>Email: <span class="font-bold">%User_Email%</span></li>
          </ul>
          <p class="mb-4">If you wish to reset your password, visit the following link:</p>
          <a href="forgot" class="inline-block bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">Reset Password</a>
          <p class="mt-4">Otherwise, ignore this email to keep your current account information.</p>
          <p class="mt-4">Thank you for using AI-PRO!</p>
          <img src={aiproLogo} alt="AI-Pro Logo" className="aiprologo"/>
        </div>
      </div>
    </div>
  );
}

export default change_password;