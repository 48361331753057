import React, { useState } from 'react';
import Header from '../header';
import Footer from '../footer';
import { Helmet } from 'react-helmet';

const PremiumContentPage = () => {
  const [ user ] = useState(window.user_data);
  var tk = null;
  if(user.email) tk = user;

  return (
    <>
      <Helmet>
        <title>AI Pro | Access Required</title>
        <meta name="description" content="Our premium membership options also give you access to all of our AI applications, such as AI Chatbots, text generators, text-to-image generators, and more, so be sure to check them out if you're interested in getting more out of your AI learning experience." />
      </Helmet>
      <Header auth={tk}/>

      <div className="container mx-auto py-10 flex pt-10 md:pt-2">
        <div className="bg-white flex flex-col pb-16 p-8 md:m-[150px] lg:pb-16">
          <h1 className="text-3xl font-bold mb-6 text-left font-bold mb-8 lg:mb-8">
            Access to this application requires a paid subscription.
          </h1>
          <div className="container mx-auto flex flex-col md:flex-wrap mb-6">
            <div className="md:w-full">
              <p className="mb-4">
                { tk ? <></> : <>
                <a href="/register" className="text-blue-600 font-bold">
                  Sign Up
                </a>{' '}
                now or{' '}
                </> }
                <a href="/login" className="text-blue-600 font-bold">
                  Login
                </a>{' '}
                here to access the application.
              </p>
              <p>
                Our premium membership options also give you access to all of our AI applications, such as AI Chatbots, text generators, text-to-image generators, and more, so be sure to check them out if you're interested in getting more out of your AI learning experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer auth={tk} />
    </>
  );
};

export default PremiumContentPage;
