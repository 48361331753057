export function getCurrency(currency) {
    if(!currency) return "";
    if(currency.toLowerCase() === 'usd') return "$";
    if(currency.toLowerCase() === 'eur') return "€";
    if(currency.toLowerCase() === 'gbp') return "£";
    if(currency.toLowerCase() === 'brl') return "R$";
    if(currency.toLowerCase() === 'sar') return "R$";
    return "";
}

export function getCountry(currency) {
    if(!currency) return "";
    if(currency.toLowerCase() === 'usd') return "US";
    if(currency.toLowerCase() === 'eur') return "US";
    if(currency.toLowerCase() === 'gbp') return "GB";
    if(currency.toLowerCase() === 'brl') return "US";
    if(currency.toLowerCase() === 'sar') return "AE";
    return "US";
}

export function formatDate(dateStr) {
    if(!dateStr) return "";
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const date = new Date(dateStr.replace(/ /g,"T"));

    return month[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear();
}

export function getPrice(data) {
    if(data.trial_price) return data.trial_price;
    if(data.price) return data.price;
    return "0";
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getPricePlan(currency, price) {
    if(!currency || !price) return "";
    // Check if price is a number
    if(isNaN(price)) return "";
    // Check if price is positive number
    if(parseFloat(price) >= 0) {
        if(currency.toLowerCase() === 'sar') {
            return parseFloat(price).toLocaleString("en-US") + "ر.س.";
        } else if(currency.toLowerCase() === 'aed') {
            return parseFloat(price).toLocaleString("en-US") + "AED";
        }
        return getCurrency(currency) + parseFloat(price).toLocaleString("en-US");
    }
    // Negative number
    return "-" + getCurrency(currency) + (parseFloat(price) * -1).toLocaleString("en-US");
}

export function diffMin(dt1, dt2)
{
    dt1 = new Date(dt1);
    dt2 = new Date(dt2);
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

export function formatDateTime(dateStr) {
    if(!dateStr) return "";
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const date = new Date(dateStr.replace(/ /g,"T"));

    return month[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();
}