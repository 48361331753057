import React, { useEffect } from 'react';
import './style.css';
import aiproLogoFooter from '../assets/images/AIPRO.png';

function Footer(props) {
  const auth = props && props.auth ? props.auth : null;
  const showLink = props.hideNavLink ? false : true;
  const currentYear = new Date().getFullYear();

  const base_url = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const script = document.createElement('script');
    script.src = base_url + 'snippets/com.global.vuzo/js/com.global.vuzo.js';
    script.async = true;
    script.onload = () => {
      // window.displayGooglePlay();
      // window.displayQR();
    };
    document.body.appendChild(script);
  }, [base_url]);
  return (
    <>
    <footer className="bg-gray-100 text-black py-4">
      <div className="container mx-auto text-center">
        <img src={aiproLogoFooter} alt="AI-Pro Logo" className="aiprologofooter"/>
        { showLink ? (
        <nav className="sm:block inline-flex center" id="menu">
          <ul className="text-center pb-1">
            <li className="text-link py-5"><a href="/" className="mx-4">Home</a></li>
            {auth ? '' : <li className="text-link py-5"><a href="/pricing" className="mx-4">Pricing</a></li> }
            {auth ? <li className="text-link py-5"><a href="/my-account" className="mx-4">My Apps</a></li> : <li className="text-link py-5"><a href="/login" className="mx-4">Sign In</a></li> }
            <li className="text-link py-5"><a href="https://ai-pro.org/disclaimer/" className="mx-4">Disclaimer</a></li>
            <li className="text-link py-5"><a href="https://ai-pro.org/affiliates/" className="mx-4" target="_blank" rel="noreferrer">Affiliates</a></li>
            <li className="text-link py-5"><a href="https://ai-pro.org/about-us/" className="mx-4">About Us</a></li>
            <li className="text-link py-5"><a href="https://ai-pro.org/contact-us/" className="mx-4">Contact Us</a></li>
          </ul>
        </nav>
        ) : ""}
        <div class="p-2 text-center inline-flex mx-auto">
          <div class="vuzo_gplay px-2"></div>
          <div class="vuzo_qr px-2"></div>
        </div>
        {/* <span className="font-bold text-[12px] block text-center">Download our VUZO mobile app!</span> */}
        <p className="mx-8 my-8">&copy; {currentYear} AI-Pro. All rights reserved.</p>

        <p className="text-[10px] text-left"><b>Disclaimer:</b> The information provided on this website is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. Furthermore, we are not in any way affiliated or related to OpenAI and Stability AI. <a href="https://ai-pro.org/disclaimer/" target="_blank" rel="noreferrer" className="text-blue-700">Read more here</a>.</p>
      </div>
    </footer>
    </>
  );
}

export default Footer;
